import React from 'react'
import { number, string, bool } from 'prop-types'
import { SvgLoader } from './styles'

const Loader = (props) => {
  const {
    size, color, withShadow, centralized,
  } = props

  return (
    <SvgLoader
      {...props}
      width={size}
      height={size}
      viewBox="0 0 52 52"
      withShadow={withShadow}
      centralized={centralized}
    >
      <circle
        cx="26px"
        cy="26px"
        r="20px"
        fill="none"
        stroke={color}
        strokeWidth="4px"
      />
    </SvgLoader>
  )
}

Loader.propTypes = {
  size: number,
  color: string,
  withShadow: bool,
  centralized: bool,
}

Loader.defaultProps = {
  size: 30,
  color: '#FFF',
  withShadow: false,
  centralized: false,
}

export default Loader
