import api from './api'
import { toast } from 'react-toastify'

export const getUserTrades = () => api.get('trade').then(res => res.data)

export const selectShippingMethod = shippingMethodForm => api.post('trade/shipping', shippingMethodForm).then(res => res.data)

export const declineTrade = tradeId => api.post('trade/decline', { tradeId }).then(res => res.data)

export const createRate = (dimensions, tradeId) => api.post('trade/rate', { dimensions, tradeId }).then(res => res.data)

export const createLabel = (rateId, tradeId) => api.post('trade/label', { rateId, tradeId }).then(res => res.data).catch(error => {
  toast.dismiss()

  if (error.response.data.errors){
    error.response.data.errors.map(item => toast.error(item))
  } else {
    toast.error(
      error.response && error.response.data.message
        ? error.response.data.message
        : 'An error occurred. Try again later.',
    )
  }
})

export const recoverLabel = transactionId =>
  api.get(`trade/label/${transactionId}`).then(res => res.data).catch(error => {
    toast.dismiss()

    if (error.response.data.errors){
      error.response.data.errors.map(item => toast.error(item))
    } else {
      toast.error(
        error.response && error.response.data.message
          ? error.response.data.message
          : 'An error occurred. Try again later.',
      )
    }
  })

export const getTracking = transactionId => api.post('trade/tracking', { transactionId }).then(res => res.data)

export const reportTrade = (tradeId, reason, description, image) => api.post('trade/report', {
  tradeId, reason, description, image,
}).then(res => res.data)

export const reviewTrade = (tradeId, rating, description) => api.post('trade/review', { tradeId, rating, description }).then(res => res.data)

export const getReview = tradeId => api.get(`trade/review/${tradeId}`).then(res => res.data)

export const createDimension = tradeId => api.post('/trade/dimensions', { tradeId }).then(res => res.data)
