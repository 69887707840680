import React from 'react'

import { navigateToNext } from '../../../store'
import { Grid, Button } from '../../../../../commons'
import {
  Column,
  ShippingStatus,
  ButtonsGroup,
} from '../../styles'
import { TransactionResume } from '../../components'

const ShippingRevoked = ({
  shippingMethod,
  shippingIcon,
  myTrade,
  hisTrade,
  setShippingProcess,
  handleSelectPickUp,
  dispatch,
  handleCancelTrade,
  isCanceling
}) => {
  const [myMedia] = myTrade.trading
  const [hisMedia] = hisTrade.trading
  const handleProceed = (event) => {
    event.preventDefault()
    if (shippingMethod === 'pickup') {
      handleSelectPickUp()
      return dispatch(navigateToNext())
    }
    return setShippingProcess('chooseAddress')
  }
  return (
    <Grid container justify="center">
      <Column item md={12} lg={12}>
        <img src={shippingIcon} alt="pickup" />
      </Column>
      <ShippingStatus item md={12} lg={12}>
        <h1>
          {hisTrade.user.displayName}
          {' '}
          requested
          <span>
            {' '}
            {hisTrade.shipping.method}
          </span>
          , do you want
          <span> Proceed or Cancel*?</span>
        </h1>
      </ShippingStatus>
      <TransactionResume myMedia={myMedia} hisMedia={hisMedia} />
      <ButtonsGroup>
        <Button isLoading={isCanceling} variation="danger" onClick={handleCancelTrade}>CANCEL</Button>
        <Button variation="success" onClick={handleProceed}>PROCEED</Button>
      </ButtonsGroup>
    </Grid>
  )
}

export default ShippingRevoked
