import styled from 'styled-components'

const status = [
  {
    key: 'selected',
    bgColor: '#0BA0BE',
    bgColor2: '#006E9D',
  },
  {
    key: 'success',
    bgColor: '#147E14',
    bgColor2: '#0C5E0C',
  },
  {
    key: 'danger',
    bgColor: '#FF0606',
    bgColor2: '#9F080D',
  },
  {
    key: 'default',
    bgColor: '#0A1C2E',
    bgColor2: '#0879A6',
  },
  {
    key: 'ghost',
    bgColor: 'transparent',
    bgColor2: 'transparent',
  },
]

export const GlobalUserShowcase = styled.div`
  width: min-content;
  height: 100%;
  padding: ${props => `${props.theme.spacing}px`};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-image: ${props => `linear-gradient(${
    status.find(type => type.key === props.status).bgColor
  }, ${status.find(type => type.key === props.status).bgColor2})`};
`

export const GlobalAvatar = styled.div`
  margin-bottom: ${props => `${props.theme.spacing / 2}px`};
`
