function convert(timezone, lang = 'en-US') {
  if (!timezone) return

  const timezoneSplitted = timezone.split('-')
  const year = timezoneSplitted[0]
  const monthIndex = parseInt(timezoneSplitted[1] - 1, 10)
  const monthNames = [
    { 'en-US': 'Jan', 'es-US': 'Jan', 'pt-BR': 'Jan' },
    { 'en-US': 'Feb', 'es-US': 'Feb', 'pt-BR': 'Fev' },
    { 'en-US': 'Mar', 'es-US': 'Mar', 'pt-BR': 'Mar' },
    { 'en-US': 'Apr', 'es-US': 'Apr', 'pt-BR': 'Abr' },
    { 'en-US': 'May', 'es-US': 'May', 'pt-BR': 'Mai' },
    { 'en-US': 'Jun', 'es-US': 'Jun', 'pt-BR': 'Jun' },
    { 'en-US': 'Jul', 'es-US': 'Jul', 'pt-BR': 'Jul' },
    { 'en-US': 'Aug', 'es-US': 'Aug', 'pt-BR': 'Ago' },
    { 'en-US': 'Sept', 'es-US': 'Sept', 'pt-BR': 'Set' },
    { 'en-US': 'Oct', 'es-US': 'Oct', 'pt-BR': 'Out' },
    { 'en-US': 'Nov', 'es-US': 'Nov', 'pt-BR': 'Nov' },
    { 'en-US': 'Dec', 'es-US': 'Dec', 'pt-BR': 'Dez' },
  ]
  const month = monthNames[monthIndex][lang]

  return `${year}/${month}`
}

export default convert
