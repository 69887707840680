import styled from 'styled-components'

export const GlobalSearch = styled.div`
  position: relative;
`

export const GlobalSubmit = styled.div`
  display: flex;
  justify-content: flex-start;
`

export const ToggleContainer = styled.div`
  margin-top: -15px;
`

export const ToggleGroup = styled.div`
  display: inline-flex;
  & > div:first-child {
    border-radius: 5px 0 0 5px;
  }
  & > div:last-child {
    border-radius: 0 5px 5px 0;
  }
`

export const ToggleGroupLabel = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-right: ${props => `${props.theme.spacing * 2}px`};
`

export const ToggleDecorator = styled.span`
  display: flex;
  align-items: baseline;
  margin-bottom: ${props => props.theme.spacing / 2}px;

  :before {
    content: '';
    display: block;
    width: 4px;
    height: 14px;
    border-top-right-radius: 45px;
    overflow: hidden;
    background-color: ${props => (props.color ? props.color : props.theme.colorPrimaryFeatured)};
    margin-right: ${props => props.theme.spacing - 2}px;
    margin-left: 2px;
    transition: background-color 300ms;
  }
`

export const ToggleButton = styled.div`
  background: ${props => (props.toggled
    ? 'linear-gradient(180deg, #00CBFF 0%, #006E9D 100%)'
    : 'transparent')};
  padding: 4px 16px;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  border: 1px solid #006e9d;
  cursor: pointer;
`

export const HeaderMyLibrary = styled.div`
  display: flex;
  justify-content: space-between;
`
