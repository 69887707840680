import React, { useState } from 'react'
import { Dialog, Button } from 'evergreen-ui'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import api from '../../../services/api'
import { DialogContent, DialogFooter, DialogHeader } from './styles'

export const DeleteMediaDialog = ({
  isOpen,
  handleClose,
  mediaToDelete,
  callbackDelete,
}) => {
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)

  const handleDeleteMedia = async () => {
    try {
      setIsLoading(true)
      await api.delete(`/media/${mediaToDelete}`)
      toast.dismiss()
      toast.success(t('Media successfully removed'))
      callbackDelete()
    } catch (error) {
      setIsLoading(false)
      handleClose()
      toast.dismiss()
      toast.error(
        error.response && error.response.data.message
          ? error.response.data.message
          : t('An error occurred. Try again later.'),
      )
    }
  }

  return (
    <Dialog
      isShown={isOpen}
      hasFooter={false}
      hasHeader={false}
      width={566}
      contentContainerProps={{ padding: 0 }}
      topOffset="30vh"
    >
      <DialogHeader>
        <h1>{t('Warning')}</h1>
      </DialogHeader>
      <DialogContent>
        <p>
          {t(
            'You are about to delete your media, are you sure you want to do that?',
          )}
        </p>
      </DialogContent>
      <DialogFooter>
        <Button
          marginRight={12}
          intent="danger"
          appearance="primary"
          onClick={handleClose}
        >
          {t('Cancel')}
        </Button>
        <Button
          intent="success"
          appearance="primary"
          onClick={handleDeleteMedia}
          isLoading={isLoading}
        >
          {t('Agree')}
        </Button>
      </DialogFooter>
    </Dialog>
  )
}

export default DeleteMediaDialog
