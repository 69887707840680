export const initialState = {
  data: {},
  loading: false,
  loaded: false,
  messages: [],
}

export default (state, action) => {
  switch (action.type) {
    case 'USER_FETCH':
      return { ...state, loading: true, loaded: false }
    case 'USER_FETCH_SUCCESS':
      return {
        ...state, data: action.payload, loading: false, loaded: true,
      }
    case 'USER_FETCH_ERROR':
      return {
        ...state, data: {}, loading: false, loaded: true,
      }
    case 'USER_CLEAR':
      return {
        ...state, data: {}, loading: false, loaded: true,
      }
    case 'clearMessages':
      return { ...state, messages: [] }
    default:
      return state
  }
}
