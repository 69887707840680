import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const StyledModalContent = styled.div`
  padding: ${props => `${20 + props.theme.spacing * 3}px ${props.theme.spacing * 3}px ${props
    .theme.spacing * 3}px`};
  background-color: #0d2032;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
  border-radius: 8px;
  position: relative;
  cursor: auto;

  :before {
    display: block;
    content: '';
    width: 100%;
    height: ${props => props.headerHeight}px;
    background-color: #0b1625;
    position: absolute;
    top: 0;
    left: 0;
  }
`

const ModalContent = ({ children, headerHeight }) => (
  <StyledModalContent headerHeight={headerHeight}>
    {children}
  </StyledModalContent>
)

ModalContent.propTypes = {
  children: PropTypes.node,
  headerHeight: PropTypes.string,
}

ModalContent.defaultProps = {
  children: '',
  headerHeight: '40',
}

export default ModalContent
