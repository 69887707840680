import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { SearchBar, MediaCard, Grid, Meta } from '../../commons'
import { useStore } from '../../../store'
import { useRouter, useMedia } from '../../../hooks'
import AnimatedBanner from './AnimatedBanner'
import HomeSliders from './HomeSliders'
import { DefaultTemplateBackground } from '../../templates/DefaultTemplate'
import { searchBarActions } from '../../templates/DefaultTemplate/Header/HiddenContent'

import { getZindex } from '../../../helpers'
import * as searchActions from '../../commons/SearchBar/actions'
import {
  Container,
  MainSection,
  MainText,
  SecondaryText,
  Links,
  MediaTypesBanner,
  MediaBanner,
  ContentSection,
  ButtonToggle,
  SearchResultContainer,
  Title,
} from './styles'

export default function Home() {
  const {
    search: { activeFilter, searchResultsHome, searchResults },
    dispatch,
  } = useStore()
  const { history } = useRouter()
  const { t } = useTranslation()
  let canShow = false
  const isSm = useMedia('(min-width: 576px)')
  const isMd = useMedia('(min-width: 768px)')

  function clearSearch() {
    dispatch(searchActions.clearPopulatedHomeSearch())
  }

  function activateFilter(filter) {
    dispatch(searchActions.changeActiveFilter(filter))
    clearSearch()
  }

  function onScroll(e) {
    if (e.target.scrollTop >= window.innerHeight / 2 - 90 && !canShow) {
      canShow = true
      dispatch(searchBarActions.showAHiddenContent('searchBar'))
    }
    if (e.target.scrollTop < window.innerHeight / 2 - 90 && canShow) {
      canShow = false
      dispatch(searchBarActions.hideTheHiddenContent())
    }
  }

  function onSearchSubmit() {
    if (searchResults.length) {
      dispatch(searchActions.populateHomeSearch(searchResults))
      dispatch(searchActions.clearSearchResults())

      history.push('/')
      setTimeout(() => {
        document.getElementById('wrapperGoToTop').scroll({
          top: window.innerHeight - 170,
          behavior: 'smooth',
        })
      }, 600)
    }
  }

  useEffect(() => {
    const wrapper = document.getElementById('wrapperGoToTop')
    wrapper.addEventListener('scroll', onScroll)
    return () => wrapper.removeEventListener('scroll', onScroll)
  }, [])

  const metaDescription = 'Exchange movies, music, games and books.' +
    ' Find what you like and order the item or even post what you want to trade.'

  return (
    <DefaultTemplateBackground bottom>
      <Meta metaDescription={metaDescription} />
      <Container>
        <AnimatedBanner activeFilter={activeFilter} />

        <MainSection>
          <div className="top-section">
            <MainText>{t('Start Trading')}</MainText>
            <SecondaryText>{t('Games, Movies, Music and Books')}</SecondaryText>

            <SearchBar
              inputId="page-search"
              maxWidth={720}
              maxHeightResult={300}
              maxWidthResult={680}
              zIndex={getZindex('default')}
              hasAnimationOnFocus
            />

            <div>
              <ButtonToggle
                activeFilter={activeFilter}
                onClick={onSearchSubmit}
              >
                {t('Search')}
              </ButtonToggle>
              <ButtonToggle
                activeFilter={activeFilter}
                onClick={() => dispatch(searchActions.openSearchLocal())}
              >
                {t('Search Local')}
              </ButtonToggle>
            </div>
          </div>

          <div className="footer-section">
            <Links isSm={isSm}>
              <Link to="/how-it-works">{t('How it works')}</Link>
              <Link to="/user-agreement">{t('User Agreement')}</Link>
              <Link to="/privacy-policy">{t('Privacy policy')}</Link>
              <Link to="/faq">{t('FAQ')}</Link>
            </Links>
            <MediaTypesBanner isMd={isMd}>
              <div className="spacer back-space" />
              <div className="spacer front-space" />

              <Grid container>
                <Grid key={Math.random()} item xs={6} sm={3}>
                  <MediaBanner title="video game control"
                    active={activeFilter === 'GAME'}
                    type="GAME"
                    onClick={() => activateFilter('GAME')}
                  >
                    <span>{t('Games')}</span>
                    <div className="fade" />
                  </MediaBanner>
                </Grid>

                <Grid key={Math.random()} item xs={6} sm={3}>
                  <MediaBanner title="video camera"
                    active={activeFilter === 'MOVIE'}
                    type="MOVIE"
                    onClick={() => activateFilter('MOVIE')}
                  >
                    <span>{t('Movies')}</span>
                    <div className="fade" />
                  </MediaBanner>
                </Grid>

                <Grid key={Math.random()} item xs={6} sm={3}>
                  <MediaBanner title="books"
                    active={activeFilter === 'BOOK'}
                    type="BOOK"
                    onClick={() => activateFilter('BOOK')}
                  >
                    <span>{t('Books')}</span>
                    <div className="fade" />
                  </MediaBanner>
                </Grid>

                <Grid key={Math.random()} item xs={6} sm={3}>
                  <MediaBanner title="music folder"
                    active={activeFilter === 'MUSIC'}
                    type="MUSIC"
                    onClick={() => activateFilter('MUSIC')}
                  >
                    <span>{t('Music')}</span>
                    <div className="fade" />
                  </MediaBanner>
                </Grid>
              </Grid>
            </MediaTypesBanner>
          </div>
        </MainSection>

        <ContentSection>
          {searchResultsHome && searchResultsHome.length ? (
            <>
              <SearchResultContainer>
                <div className="filter">
                  {/* <Title>Filter By</Title> */}
                  <Title>{t('Search Results')}</Title>
                  <div className="row">
                    <div className="filters">
                      {/* <select>
                        <option>Platform</option>
                      </select>
                      <select>
                        <option>Sort by</option>
                      </select> */}
                    </div>
                    <div className="actions">
                      <button type="button" onClick={clearSearch}>
                        {t('Back to Home')}
                      </button>
                    </div>
                  </div>
                </div>
                <Grid container>
                  {searchResultsHome.map(item => (
                    <Grid key={item.id} item xs={6} sm={4} md={3} lg={2}>
                      <MediaCard data={item} showUser actions="default" />
                    </Grid>
                  ))}
                </Grid>
              </SearchResultContainer>
            </>
          ) : (
            <>
              <HomeSliders activeFilter={activeFilter} />
            </>
          )}
        </ContentSection>
      </Container>
    </DefaultTemplateBackground>
  )
}
