import React from 'react'
import { number } from 'prop-types'
import ICONS from '../../../../ui/ICONS'
import { Icon } from '../../../../commons'
import { GlobalNotificationButton, GlobalBadge, Badge } from './styles.js'

const NotificationButton = ({ notifications, ...props }) => (
  <GlobalNotificationButton {...props} notifications={notifications}>
    <GlobalBadge>
      <Icon icon={ICONS.NOTIFICATION} />
      {notifications > 0 && <Badge>{notifications}</Badge>}
    </GlobalBadge>
  </GlobalNotificationButton>
)

NotificationButton.propTypes = {
  notifications: number,
}

NotificationButton.defaultProps = {
  notifications: 0,
}

export default NotificationButton
