import React from 'react'
import { withTheme } from 'styled-components'
import { useTranslation } from 'react-i18next'
import {
  Modal,
  ModalContent,
  Button,
} from '../../../commons'
import { Title } from './styles'
import { googleStore, appleStore } from '../../../../assets'

const ModalComeToApp = (props) => {
  const { t } = useTranslation()
  const { open, handleClose } = props


  return (
    <Modal open={open} handleClose={handleClose}>
      <ModalContent>
        <Title>{t('For a better experience, please download our app!')}</Title>
        <div style={{
          display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
        }}
        >
          <div style={{ marginBottom: 10 }}>
            <a style={{ display: 'block' }} href="https://play.google.com/store/apps/details?id=com.newusedmedia" target="_blank" rel="noopener noreferrer">
              <img style={{ height: 50, display: 'block' }} src={googleStore} alt="Get it on Google Play" />
            </a>
          </div>

          <div style={{ marginBottom: 20 }}>
            <a style={{ display: 'block' }} href="https://apps.apple.com/us/app/new-used-media/id1478021026" target="_blank" rel="noopener noreferrer">
              <img style={{ height: 50, display: 'block' }} src={appleStore} alt="Download on the app store" />
            </a>
          </div>
        </div>

        <div style={{ textAlign: 'center' }}>
          <Button onClick={handleClose}>{t('Continue to the website')}</Button>
        </div>

      </ModalContent>
    </Modal>
  )
}

export default withTheme(ModalComeToApp)
