import styled from 'styled-components'

export const GlobalTextArea = styled.label`
  width: 100%;
  display: inline-block;
  margin-bottom: ${props => props.spacing}px;
`

export const StyledTextArea = styled.textarea`
  background-color: ${props => props.theme.colorInput};
  color: ${props => props.theme.colorPrimaryFeatured};
  border: none;
  padding: 6px 14px;
  border-radius: 8px;
  font-size: 16px;
  width: 100%;
  border: ${props => (props.error.status ? '2px solid red' : '2px solid transparent')};
  box-sizing: border-box;
  resize: none;
  height: auto;

  &:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
  }
  &::placeholder {
    color: ${props => props.theme.colorPlaceholder};
  }
  &:focus {
    outline: none;
    border: 2px solid ${props => props.theme.colorPrimaryFeatured};
    background-color: #ffffff;
    font-weight:600;
    color:black;
  }
`

export const Legend = styled.span`
  color: ${props => props.theme.colorNegative};
  font-size: ${props => props.theme.fontSizes.default2};
`
