import styled from 'styled-components'
import { Title as UiTitle } from '../../ui'
import { appBackground } from '../../../assets'

export const Title = styled(UiTitle)`
  margin-bottom: 22px;
`

export const SubTitle = styled.h3`
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  margin-top: 30px;
  margin-bottom: 5px;
  first-child {
    margin-top: 0;
  }
  @media (max-width: 599px) {
    font-size: 0.8rem;
  }
`

export const PageSection = styled.div`
  margin-bottom: ${props => props.theme.spacing * 6}px;
  p {
    font-family: Titillium Web;
    font-style: normal;
    font-weight: 300;
    font-size: 14.5971px;
    line-height: 19px;
  }
  .bellow-player {
    margin-top: 39px;
  }
  @media (min-width: ${props => props.theme.breakpoints.md}px) {
    :last-child {
      margin-bottom: 0;
    }
  }
  @media (max-width: 599px) {
    p {
      font-size: 1rem;
    }
    .bellow-player {
      margin-top: 22px;
    }
  }
`

export const TemplateBackground = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  /* top: 200px; */
  height: 200px;
  max-height: 25%;
  width: 100%;
  opacity: 0.1;
  background-image: url(${appBackground});
  /* background: orange; */
  & > div {
    height: 100%;
    width: 100%;
    background-image: linear-gradient(#0d2032, transparent 100%);
    position: absolute;
    top: 0;
  }
`
