import React from 'react'
import { shape, oneOf } from 'prop-types'

import { Container } from './styles'

function BrandBadge({ platform, size }) {
  return (
    <Container color={platform.color} size={size}>
      <img alt={platform.name} src={platform.image} />
    </Container>
  )
}

BrandBadge.propTypes = {
  platform: shape({}).isRequired,
  size: oneOf(['small', 'medium']),
}

BrandBadge.defaultProps = {
  size: 'medium',
}

export default BrandBadge
