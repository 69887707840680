import styled from 'styled-components'
import { Link as RouterLink } from 'react-router-dom'
import { DefaultPageWrapper } from '../../templates/DefaultPage'
import { appBackground } from '../../../assets'

export const Link = styled(RouterLink)`
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  background: linear-gradient(180deg, #0ba0be 25.86%, #006e9d 100%);
  box-sizing: border-box;
  border: 0.810948px solid #000000;
  border-radius: 4.05474px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  margin: 0 auto;
  margin-left: 20px;
  text-align: center;
  width: 191px;
  height: 32px;
`

export const ContentWrapper = styled(DefaultPageWrapper)`
  @media (max-width: 599px) {
    position: relative;
    top: 80px;
    width: 100vw;
    height: 300px;
    margin-bottom: 100px;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    -webkit-scrollbar: none;
    overflow-y: hidden;
  }
`

export const PageSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: ${props => props.theme.spacing * 6}px;

  img {
    margin: 30px;
    margin: 0 auto;
    @media (max-width: 599px) {
      width: 300px;
    }
  }

  h4 {
    font-family: Titillium Web;
    font-style: normal;
    font-weight: 300;
    font-size: 22px;
    line-height: 33px;
    margin: 0 auto;
    padding: 20px;
    text-align: center;
  }
`

export const TemplateBackground = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  height: 200px;
  max-height: 25%;
  width: 100%;
  opacity: 0.1;
  background-image: url(${appBackground});
  & > div {
    height: 100%;
    width: 100%;
    background-image: linear-gradient(#0d2032, transparent 100%);
    position: absolute;
    top: 0;
  }
`
