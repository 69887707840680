import HiddenContent from './component'
import * as actions from './actions'
import {
  reducerSearchBar,
  reducerHiddenContent,
  initialStateSearchResults,
  initialStateHiddenContent,
} from './reducer'

export const searchBarActions = actions
export const searchBarReducer = reducerSearchBar
export const hiddenContentReducer = reducerHiddenContent
export const searchResultsInitialState = initialStateSearchResults
export const hiddenContentInitialState = initialStateHiddenContent

export default HiddenContent
