import styled from 'styled-components'
import React from 'react'
import { getZindex } from '../../../../helpers'

const GlobalSlogan = styled.div`
  position: relative;
  z-index: ${getZindex('default')};
  font-size: 12px;
  padding: 4px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
  text-align: center;
  letter-spacing: 1px;
  background-color: ${props => props.theme.colorPrimaryDark2};
  color: rgba(255, 255, 255, 0.7);
`

const Slogan = () => (
  <GlobalSlogan>THE AFFORDABLE ENTERTAINMENT REVOLUTION™</GlobalSlogan>
)

export default Slogan
