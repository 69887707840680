import React from 'react'
import styled from 'styled-components'
import { getZindex } from '../../../helpers'
import { appBackground } from '../../../assets'

const AppBackground = styled.div`
  position: absolute;
  height: 100vh;
  width: 100vw;
  z-index: ${getZindex('negative')};
`

const TopSide = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 300px;
  max-height: 40%;
  width: 100%;
  opacity: 0.1;
  background-image: url(${appBackground});
  & > div {
    height: 100%;
    width: 100%;
    background-image: linear-gradient(transparent, #0d2032 100%);
    position: absolute;
    bottom: 0;
  }
`

const BotSide = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  height: 200px;
  max-height: 25%;
  width: 100%;
  opacity: 0.1;
  background-image: url(${appBackground});
  & > div {
    height: 100%;
    width: 100%;
    background-image: linear-gradient(#0d2032, transparent 100%);
    position: absolute;
    top: 0;
  }
`

export default () => (
  <AppBackground>
    <TopSide>
      <div />
    </TopSide>
    <BotSide>
      <div />
    </BotSide>
  </AppBackground>
)
