import React, { useState, Fragment } from "react";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { StripeProvider, Elements } from "react-stripe-elements";
import { ApolloProvider } from "react-apollo";
import client from "./services/graphql";
import { useDocumentTitle } from "./hooks";
import { ContextProvider } from "./store";
import { AuthCtxProvider } from "./reducers";
import { GlobalStyle, darkTheme } from "./themes";
import Routes from "./Routes";

const App = () => {
  useDocumentTitle("New Used Media - Trade of Media");
  const fonts = [
    {
      cssSrc:
        "https://fonts.googleapis.com/css?family=Titillium+Web:200,200i,300,300i,400,400i,600,600i,700,700i,900&subset=latin-ext",
    },
  ];
  const [theme] = useState(darkTheme);
  const stripeApiKey = process.env.REACT_APP_STRIPE_API_KEY

  return (
    <ApolloProvider client={client}>
      <ContextProvider>
        <BrowserRouter>
          <AuthCtxProvider>
            <StripeProvider
              apiKey={stripeApiKey}
            >
              <Elements fonts={fonts}>
                <ThemeProvider theme={theme}>
                  <Fragment>
                    <GlobalStyle />
                    <Routes />
                  </Fragment>
                </ThemeProvider>
              </Elements>
            </StripeProvider>
          </AuthCtxProvider>
        </BrowserRouter>
      </ContextProvider>
    </ApolloProvider>
  );
};

export default App;
