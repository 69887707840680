import styled from 'styled-components'

export const GlobalAddress = styled.address`
  height: auto;
  border-width: ${props => (props.active === 'callToNew' ? '2px' : '1px')};
  border-style: ${props => (props.active === 'callToNew' ? 'dashed' : 'solid')};
  border-color: rgba(255, 255, 255, 0.4);
  border-radius: 4px;
  padding: ${props => (props.active === 'callToNew' ? 0 : `${props.theme.spacing * 2}px`)};
`

export const GlobalActions = styled.div`
  display: flex;
`

export const GlobalActionsEdit = styled.div`
  display: flex;
  justify-content: flex-end;
`

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
`

export const GlobalMarks = styled.div`
  display: flex;
  align-items: start;
`

export const Mark = styled.div`
  background-color: ${props => (props.disabled ? '#999' : props.theme.colorPositive)};
  font-size: ${props => props.theme.fontSizes.legend};
  border-radius: 4px;
  padding: ${props => `0 ${props.theme.spacing / 2}px`};
  margin-right: ${props => `${props.theme.spacing / 2}px`};

  &:last-child {
    margin-right: 0;
  }
`

export const GlobalAdd = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center;
`

export const Add = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: bold;
  padding: ${props => props.theme.spacing * 4}px 0;
`
