import styled from 'styled-components'
import {
  DefaultPageBox,
  DefaultPageBoxContent,
} from '../../templates/DefaultPage'

export const Container = styled(DefaultPageBox)`
  margin: 0 auto;
  max-width: 640px;
`

export const Content = styled(DefaultPageBoxContent)`
  padding: 48px;
  position: relative;
`

export const Header = styled.div`
  height: 20px;
  background-color: #0b1625;
`

export const Form = styled.form`
  display: grid;
  grid-gap: 8px;
`

export const TypeSelectorContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, auto);
  grid-gap: 12px;
  margin-bottom: 8px;
`

export const FilterButton = styled.button.attrs({ type: 'button' })`
  color: ${props => (props.selected ? '#08a4de' : '#fff')};
  border: 2px solid ${props => (props.selected ? '#08a4de' : '#fff')};
  border-radius: 4px;
  padding: 4px 8px;
  font-size: 12px;
  cursor: pointer;
  box-sizing: border-box;

  &:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
  }

  &:focus {
    outline: none;
    border: 2px solid #08a4de;
  }
`

export const Input = styled.input`
  height: 44px;
  background-color: #253f58;
  color: #08a4de;
  border: none;
  padding: 8px;
  border-radius: 4px;
  font-size: 16px;
  width: 100%;
  border: 2px solid transparent;
  box-sizing: border-box;

  &:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
  }

  &:focus {
    outline: none;
    border: 2px solid #08a4de;
    background-color: #ffffff;
    font-weight:600;
    color:black;
  }
`

export const GoBack = styled.button.attrs({ type: 'button' })`
  color: #fff;
  cursor: pointer;
  display: contents;
  font-size: 12px;
  margin-bottom: 6px;

  &:hover {
    color: #08a4de;
  }
`

export const AutoCompleteResult = styled.div`
  width: 100%;
  position: relative;
  &:focus {
    outline: none;
    border: 2px solid #08a4de;
    background-color: #ffffff;
    font-weight:600;
    color:black;
  }
`

export const ResultContainer = styled.div`
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.4);
  position: absolute;
  width: 100%;
  max-height: 140px;
  top: -6px;
  overflow-y: auto;
  z-index: 2;
  background-color: #0d2132;
`

export const PlatformSelect = styled.select`
  height: 44px;
  background-color: #253f58;
  color: #08a4de;
  border: none;
  padding: 8px;
  font-size: 16px;
  width: 100%;
  border: 2px solid transparent;
  box-sizing: border-box;
  cursor: pointer;

  &:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
  }

  &:invalid {
    color: gray;
  }

  &:focus {
    outline: none;
    border: 2px solid #08a4de;
    background-color: #ffffff;
    font-weight:600;
    color:black;

  }
`

export const Button = styled.button`
  color: #fff;
  margin: 12px auto;
  padding: 4px 16px;
  border-radius: 50px;
  background: linear-gradient(180deg, #4cd964 0%, #0c9d25 93.75%);
  box-sizing: border-box;
  cursor: pointer;
  width: 50%;
  min-width: 300px;
  align-self: center;

  &:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
  }

  &:focus {
    outline: none;
    opacity: 0.8;
  }
`

export const ItemWrapper = styled.div`
  padding: 12px;
  border-radius: 4px;
  border: 2px solid rgba(0, 0, 0, 0.6);
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const MediaPicture = styled.div`
  width: 72px;
  height: 72px;
  border-radius: 50%;
  background-image: url(${props => props.src});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`

export const MediaInfo = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-left: 12px;
`

export const MediaTitle = styled.strong`
  font-size: 18px;
  line-height: 26px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
`

export const MediaReleaseYear = styled.small`
  font-size: 12px;
  font-style: italic;
`

export const ClearSelected = styled.div`
  & > button {
    cursor: pointer;
  }
`
