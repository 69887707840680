import styled from 'styled-components'

export default styled.div`
  font-size: ${props => props.theme.fontSizes.heading2};
  font-weight: 600;
  display: flex;
  align-items: baseline;
  margin-bottom: ${props => props.theme.spacing * 2}px;

  :before {
    content: '';
    display: block;
    width: 4px;
    height: 20px;
    border-top-right-radius: 4px;
    background-color: ${props => (props.color ? props.color : props.theme.colorPrimaryFeatured)};
    margin-right: ${props => props.theme.spacing}px;
  }
`
