import React from 'react'
import { ArrowsSmall, MediaSection, Container } from './styles'
import {
  leftArrow,
  rightArrow,
  game,
  movie,
  music,
  book
} from '../../../../../assets'

const TransactionResume = ({
  myMedia,
  hisMedia,
  marginTop,
  marginBottom,
  showMediaName,
}) => {
  const myMediaImage = myMedia.pictures ? myMedia.pictures[0] : undefined
  const hisMediaImage = hisMedia.pictures ? hisMedia.pictures[0] : undefined
  const defaultImage = {
    game,
    book,
    music,
    movie
  }
  const renderMediaThumb = (mediaImage, type) => mediaImage
    ? <img height={48} width={48} src={mediaImage} alt="media"/>
    : <img src={defaultImage[type]} alt="media" />
  return (
    <Container marginTop={marginTop} marginBottom={marginBottom} container>
      <MediaSection justify="flex-end" item md={5} lg={5}>
        {showMediaName && <p>{myMedia.title}</p>}
        {renderMediaThumb(myMediaImage, myMedia.mediaType)}
      </MediaSection>
      <ArrowsSmall>
        <img height={16} width={42} src={leftArrow} alt="left arrow" />
        <img height={16} width={42} src={rightArrow} alt="right arrow" />
      </ArrowsSmall>
      <MediaSection justify="flex-start" item md={5} lg={5}>
        {renderMediaThumb(hisMediaImage, hisMedia.mediaType)}
        {showMediaName && <p>{hisMedia.title}</p>}
      </MediaSection>
    </Container>
  )
}

TransactionResume.defaultProps = {
  marginTop: 0,
  marginBottom: 0,
  showMediaName: true,
}

export default TransactionResume
