import styled, { keyframes } from 'styled-components'

export const GlobalNotificationItem = styled.div`
  background-color: ${props => (props.read ? '#0D2032' : '#08658e')};
  border-bottom: ${props => (props.read ? '1px solid #143454' : '1px solid #0c7eb0')};
  padding: 8px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 150ms;

  :hover {
    background-color: ${props => (props.read ? '#152e46' : '#096e9a')};
  }

  :last-child {
    border-bottom: 0;
  }
`

export const GlobalMedias = styled.div`
  display: flex;
  align-items: center;
  padding: 0 16px 0 8px;
`

export const Title = styled.div`
  display: flex;

  > div {
    margin-left: 8px;
  }
`

export const GlobalTitle = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
  text-align: center;
`

export const Time = styled.div`
  font-size: 12px;
  opacity: 0.5;
`

export const GlobalArrows = styled.div`
  width: 30px;
  margin: 0 8px;
`

export const Arrow = styled.img`
  width: 100%;

  &:first-child {
    transform: rotate(180deg);
    opacity: 0.5;
  }
`

export const GlobalShowcase = styled.div`
  display: flex;
  align-items: center;
`

export const HistoryToggle = styled.button`
  display: flex;
  position: relative;
  background-color: ${props => (props.read ? '#1b3b5a' : '#085577')};
  border-radius: 5px;
  margin-right: 8px;

  svg {
    opacity: 0.3;
    transition: opacity 150ms;
  }

  :hover {
    svg {
      opacity: 1;
    }
  }
`

export const Badge = styled.div`
  min-width: 18px;
  height: 18px;
  line-height: 18px;
  background-color: #ee302a;
  color: #fff;
  border-radius: 9px;
  font-size: 14px;
  text-align: center;
  padding: 0 4px;
  position: absolute;
  top: -9px;
  right: -9px;
`

export const ShowHistory = styled.div`
  /* max-height: ${props => (props.open ? '300px' : '0')};
  transition: max-height 150ms ease-out;
  */
  overflow: hidden;
`

const SHOW_GLOBAL_HISTORY = keyframes`
  from {
    transform: translateY(-100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`

export const GlobalHistory = styled.div`
  animation: ${SHOW_GLOBAL_HISTORY} 200ms ease-out;
  border-radius: 5px;
  background-color: ${props => (props.read ? '#1b3b5a' : '#085577')};
  margin-top: 8px;
  padding: 8px;
`

const newRead = keyframes`
  from {
    color: #EE302A;
  }

  to {
    color: #FFFFFF;
  }
`

export const HistoryItem = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  padding-bottom: 8px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
  font-weight: ${props => (props.itemRead ? 'inherit' : 'bold')};
  animation: ${newRead} 1500ms ease-out;

  :last-child {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: 0;
  }

  div:before {
    content: '';
    display: ${props => (props.itemRead ? 'none' : 'inline-block')};
    width: 10px;
    height: 10px;
    border-radius: 10px;
    background-color: #ee302a;
    margin-right: 4px;
  }

  ${props => props.itemRead
    && `
    animation: none;
  `}

  span {
    margin-left: 8px;
    opacity: 0.5;
    font-size: 12px;
    white-space: nowrap;
  }
`

export const GlobalMedia = styled.div`
  position: relative;
`

export const BadgeMedia = styled.div`
  min-width: 18px;
  height: 18px;
  line-height: 18px;
  background-color: #0879a6;
  color: #fff;
  border-radius: 5px;
  font-size: 14px;
  text-align: center;
  padding: 0 4px;
  position: absolute;
  top: -9px;
  right: -9px;
`

export const NudgeButton = styled.button`
  font-size: 12px;
  background-color: #FFF;
  color: #1b3b5a;
  border-radius: 4px;
  padding: 0 4px;
  margin-left: 4px;
  font-weight: bold;
  cursor: pointer;
  opacity: ${props => props.nudging ? '0.3' : '1'};
  transition: opacity 200ms;

  :hover {
    opacity: ${props => props.nudging ? '0.3' : '0.7'};
  }

`