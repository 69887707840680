import React from 'react'
import { Rating, Icon } from '../../../../commons'
import ICONS from '../../../../ui/ICONS'
import {
  Container, Avatar, Info, Name,
} from './styles'

export default function TradeHeading({
  name,
  rating,
  avatar = 'https://app.newusedmedia.com/api/files/avatars/5d0912cefea93865eaf6e555.jpg',
  vip,
}) {
  return (
    <Container>
      <Avatar src={avatar} />
      <Info>
        <Name>
          {name}
          {vip && <Icon icon={ICONS.YES} size={22} color="green" />}
        </Name>
        <Rating rate={rating} />
      </Info>
    </Container>
  )
}
