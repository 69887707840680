import React from 'react'
import { bool } from 'prop-types'
import styled from 'styled-components'
import { useRouter } from '../../../hooks'
import { Rating, Avatar } from '..'

const UserInfo = styled.div`
  display: flex;
  align-items: center;
  color: ${props => props.theme.colorSecondary};
`

const UserName = styled.span`
  flex: ${props => (props.justify ? 1 : 'initial')};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: ${props => props.theme.fontSizes.default2};
  margin: 0 0.325em;
  font-weight: 200;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`

const User = (props) => {
  const { data, justify, onClick } = props
  const { history } = useRouter()

  function handleClick() {
    history.push(`/library/${data.id}`)
  }

  return (
    <UserInfo onClick={onClick}>
      <Avatar src={data.avatar} size="notSoSmall" />
      <UserName justify={justify} onClick={handleClick}>
        {data.username}
      </UserName>

      {data.rating >= 0 && <Rating rate={data.rating} />}
    </UserInfo>
  )
}

Avatar.propTypes = {
  justify: bool,
}

Avatar.defaultProps = {
  justify: false,
}

export default User
