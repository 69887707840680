import styled from 'styled-components'

export const GlobalMedia = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 5px;
  padding: 4px;
  border: ${props => (props.isSelected ? '2px solid rgb(0, 203, 255)' : '2px solid white')};
  background: linear-gradient(#167091, #0b2c48);
  transition: background-color 300ms;
  cursor: pointer;
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.3);
`
