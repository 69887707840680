import React, { useEffect, useState } from 'react'
import { withTheme } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useStore } from '../../../store'
import {
  Button,
  MediaCard,
  User,
  Map,
  TradeDialog,
  DeleteMediaDialog,
} from '../../commons'
import api from '../../../services/api'
import { useMedia, useRouter } from '../../../hooks'
import {
  DefaultPageWrapper,
  DefaultPageBox,
  DefaultPageBoxContent,
  DefaultPageNewBackground,
  DefaultPageFadeBackground,
} from '../../templates/DefaultPage'
import { DefaultTemplateBackground } from '../../templates/DefaultTemplate'
import { Title } from '../../ui'
import {
  Header,
  HeaderColumn,
  HeaderColumnChildren,
  MediaTitle,
  MediaSection,
  BoxHeader,
} from './styles'

const Library = (props) => {
  const { theme, computedMatch } = props
  const { t } = useTranslation()
  const { user } = useStore()
  const [media, setMedia] = useState({})
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [requestModalIsOpen, setRequestModalIsOpen] = useState(false)
  const isSm = useMedia('(min-width: 576px)')
  const { history } = useRouter()

  const fetchMedia = async () => {
    try {
      const response = await api.get(`/media/id/${computedMatch.params.id}`)

      setMedia(response.data)
    } catch (err) {
      console.log(err)
    }
  }

  const onClickRequestItem = async (e) => {
    e.stopPropagation()

    if (Object.keys(user.data).length > 0) {
      setRequestModalIsOpen(true)
    } else {
      history.push('/signin')
    }
  }

  useEffect(() => {
    fetchMedia()
  }, [computedMatch])

  return (
    <DefaultTemplateBackground bottom>
      <DefaultPageNewBackground
        src={media.pictures ? media.pictures[0] : null}
        withBlur title={`${media.title} out of focus`}
      />
      <DefaultPageFadeBackground />

      <DefaultPageWrapper>
        <Header isSm={isSm}>
          <HeaderColumn isSm={isSm}>
            <MediaCard
              data={{
                ...media,
                thumb: media.pictures ? media.pictures[0] : null,
              }}
              showTitle={false}
            />
          </HeaderColumn>

          <HeaderColumn isSm={isSm}>
            <HeaderColumnChildren>
              <MediaTitle>
                {media.title}
                {' '}
(
                {media.releaseDate}
)
              </MediaTitle>

              {media.user && <User data={media.user} />}
            </HeaderColumnChildren>

            <HeaderColumnChildren />
          </HeaderColumn>
        </Header>

        <DefaultPageBox>
          <BoxHeader>
            {media.user && media.user.id === user.data.id && (
              <>
                <Button
                  variation="change"
                  onClick={() => history.push(`/edit-media/${media.id}`)}
                  style={{ marginRight: '8px' }}
                >
                  {t('Edit media')}
                </Button>

                <Button
                  variation="danger"
                  onClick={() => setIsDeleteModalOpen(true)}
                >
                  {t('Delete media')}
                </Button>

                <DeleteMediaDialog
                  isOpen={isDeleteModalOpen}
                  mediaToDelete={media.id}
                  callbackDelete={() => history.push('/')}
                  handleClose={() => setIsDeleteModalOpen(false)}
                />
              </>
            )}

            {media.user && media.user.id !== user.data.id && (
              <>
                <Button variation="success" onClick={onClickRequestItem}>
                  {t('Request Item')}
                </Button>

                {requestModalIsOpen && (
                  <TradeDialog
                    isOpen={requestModalIsOpen}
                    handleClose={() => setRequestModalIsOpen(false)}
                    mediaId={media.id}
                    userId={media.user.id}
                  />
                )}
              </>
            )}
          </BoxHeader>

          <DefaultPageBoxContent>
            <MediaSection>
              <Title>{t('Description')}</Title>

              <p>
                {media.description
                  ? media.description
                  : t("This item doesn't have a description.")}
              </p>
            </MediaSection>

            {media.cityLocation && (
              <MediaSection>
                <Title>{t('Shipping address')}</Title>

                <Map
                  coordinates={{
                    lat: media.cityLocation.coordinates[1],
                    lng: media.cityLocation.coordinates[0],
                  }}
                  colorCircle={theme.colorPrimaryFeatured}
                  radius={10}
                  hasCircle
                />
              </MediaSection>
            )}
          </DefaultPageBoxContent>
        </DefaultPageBox>
      </DefaultPageWrapper>
    </DefaultTemplateBackground>
  )
}

export default withTheme(Library)
