import styled from 'styled-components'
import { Grid } from '../../../commons'

export const ShippingAlert = styled.div`
  font-size: 14px;
  color: #000;
  background-color: rgba(253, 244, 197, 1);
  padding: 4px 8px;
  margin: 8px 0;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.6);
`

export const MediaContainer = styled(Grid)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: ${props => props.align};
  margin-bottom: ${props => (props.marginBottom ? `${props.marginBottom}px` : '0px')};
  margin-top: ${props => (props.marginTop ? `${props.marginTop}px` : '0px')};
  font-size: 16px;
  font-weight: 300;
  & > div {
    position: relative;
    width: 100%;
    max-width: 190px;
    margin: 0 auto;

    @media (min-width: 768px) {
      margin: 0;
    }

    & > p {
      margin-left: 60px;
    }
  }
`

export const Header = styled(Grid)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  font-weight: 300;
  text-align: center;
  & > h1 > span {
    font-weight: 600;
  }
`

export const Title = styled.h1`
  font-weight: 600;
  font-size: 28px;
  line-height: 32px;
  text-align: center;
  margin-bottom: 15px;
  text-transform: uppercase;
`

export const Column = styled(Grid)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: ${props => (props.marginBottom ? `${props.marginBottom}px` : '0px')};
  margin-top: ${props => (props.marginTop ? `${props.marginTop}px` : '0px')};
`

export const ShippingOption = styled(Grid)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 100px;
  margin-top: 100px;
  width: 100%;
`

export const ShippingStatus = styled(Grid)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  font-weight: 300;
  text-align: center;
  margin-top: 65px;
  margin-bottom: 82px;
  & > h1 > span:first-child {
    font-weight: 600;
    color: #4cd964;
    text-transform: capitalize;
  }
  & > h1 > span:last-child {
    font-weight: 600;
  }
`

export const Option = styled.p`
  color: ${props => (props.isChecked ? '#4CD964' : '#FFF')};
  font-size: 18px;
  font-weight: 600;
  line-height: 20px;
  line-break: auto;
  white-space: nowrap;
`

export const Arrows = styled(Grid)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  & > img:first-child {
    margin-right: 20px;
  }
  & > img:last-child {
    margin-left: 20px;
  }
`

export const ButtonsGroup = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-bottom: 35px;
  margin-top: 35px;
  & > div:first-child {
    margin-right: 8px;
  }
  & > div:last-child {
    margin-left: 8px;
  }
`

export const Addresses = styled(Grid)`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 84px;
`

export const Address = styled.div`
  padding: 12px;
  background: #003049;
  border: ${props => (props.isSelected ? '2px solid #4CD964' : '')};
  border-radius: 5px;
  width: 49%;
  height: 88px;
  margin-top: 8px;
  margin-left: 8px;
  cursor: pointer;
  & > p {
    font-size: 13px;
    line-height: 20px;
    margin-top: -14px;
  }
  & > div {
    font-size: 14px;
    & > span {
      text-transform: uppercase;
    }
  }
`

export const NewAddress = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 2px solid #003049;
  width: 49%;
  height: 88px;
  margin-top: 8px;
  margin-left: 8px;
  border-radius: 5px;
  font-size: 13px;
  cursor: pointer;
`

export const Dimensions = styled.div`
  display: flex;
  height: 32px;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 26px;
  margin-bottom: 26px;
  border-radius: 5px;
`

export const Dimension = styled.div`
  height: 32px;
  width: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 14px;
  border: 1px solid #006e9d;
  background: ${props => (props.isSelected
    ? 'linear-gradient(180deg, #00CBFF 0%, #006E9D 100%)'
    : 'transparent')};
  &:first-child {
    border-radius: 5px 0px 0px 5px;
  }
  &:last-child {
    border-radius: 0px 5px 5px 0px;
  }
`

export const ShippingInfos = styled(Grid)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: ${props => props.align};
  margin: 0 auto;
  @media (max-width: 576px) {
    align-items: center;
  }
  & > p {
    font-size: 22px;
    color: #08a4de;
  }
  & > span {
    color: #08a4de;
  }
  & > img {
    cursor: pointer;
  }
`

export const TrackingStatus = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 22px;
  & > div:nth-child(2) {
    margin-left: 2px;
    margin-right: 2px;
  }
  & > div:first-child {
    border-radius: 5px 0px 0px 5px;
  }
  & > div:last-child {
    border-radius: 0px 5px 5px 0px;
  }
`

export const Status = styled.div`
  max-height: 27px;
  padding: 4px 22px;
  background: ${props => (props.isReturned
    ? 'linear-gradient(180deg, #EE773B 0%, #BA4F1A 100%);'
    : props.isFailure
      ? ' linear-gradient(180deg, #A11D21 25%, #58070A 100%);'
      : props.isActualState
        ? 'linear-gradient(180deg, #4CD964 0%, #0C9D25 93.75%)'
        : '#253F58')};
`

export const UserInfo = styled(Grid)`
  display: flex;
  align-items: center;
  justify-content: center;

  & > div > h2 {
    font-size: 24px;
    font-weight: 600;
    text-transform: capitalize;
  }

  & > div:last-child {
    margin-left: 20px;
  }

  & > div:last-child > div {
    display: flex;
    align-items: center;
    justify-content: center;
    & > span {
      font-size: 16px;
      font-weight: normal;
      margin-left: 6px;
      margin-right: 12px;
    }
  }
`

export const Avatar = styled.img`
  width: 81px;
  height: 80px;
  border-radius: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const ReportButton = styled.button`
  background: linear-gradient(180deg, #ff0606 25%, #9f080d 100%);
  color: #fff;
  padding: 1px 9px;
  font-size: 10px;
  line-height: 16px;
  border-radius: 3px;
  text-align: center;
  font-weight: 600;
  text-transform: uppercase;
  &:not(:disabled) {
    cursor: pointer;
  }
  &:disabled {
    opacity: 0.65;
  }
`

export const ReviewInfos = styled(Grid)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: end;
  margin: 0 auto;

  & > div:nth-child(2) {
    margin-bottom: 22px;
  }
`

export const Description = styled.textarea`
  background-color: ${props => props.theme.colorInputBackground};
  color: ${props => props.theme.colorPrimaryFeatured};
  width: 438px;
  height: 110px;
  border-radius: 8px;
  font-size: 16px;
  border: ${props => (props.error ? '2px solid red' : '2px solid transparent')};
  box-sizing: border-box;
  padding: ${props => props.theme.spacing}px;
  resize: none;
  margin-bottom: 24px;
  &:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
  }

  &::placeholder {
    color: ${props => props.theme.colorInputPlaceholder};
  }

  &:focus {
    outline: none;
    border: 2px solid ${props => props.theme.colorPrimaryFeatured};
  }
`

export const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  & > p {
    align-self: flex-end;
    margin-bottom: 4px;
  }
`

export const TableSection = styled.section`
  margin: 0 auto;
  width: 100%;
  height: 100%;
`

export const MapContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`

export const InputContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`

export const InputIcon = styled.img`
  position: absolute;
  transform: translateY(${props => props.translate}px);
  top: 50%;
  right: 10px;
`

export const ShippingLabel = styled(Grid)`
  height: 254px;
  & > iframe {
    min-height: 254px;
    min-width: 410px;
  }
`

export const TrackingSection = styled(Grid)`
  margin: 0 0 116px;
`

export const TrackingHistory = styled(Grid)`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
