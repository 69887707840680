import styled from 'styled-components'

export const Table = styled.table`
  width: 100%;
`

export const Tbody = styled.tbody``

export const Thead = styled.thead`
  display: ${props => (props.isMd ? 'table-header-group' : 'none')};
`

export const Td = styled.td`
  padding: ${props => `${props.theme.spacing}px`};
  font-size: ${props => props.theme.fontSizes.legend};
  display: ${props => (props.isMd ? 'table-cell' : 'flex')};

  &:before {
    display: ${props => (props.isMd ? 'none' : 'block')};
    content: ${props => `'${props.legend}'`};
    width: 50%;
    text-transform: uppercase;
  }
`

export const Tr = styled.tr`
  > ${Td} {
    background-color: #152f48;
  }

  &:nth-child(even) ${Td} {
    background-color: #253f58;
  }
`

export const Th = styled.th`
  padding: ${props => `${props.theme.spacing}px`};
  background: #0b1625;
  text-align: left;
  text-transform: uppercase;
  font-weight: normal;
  font-size: ${props => props.theme.fontSizes.legend};
`
