import styled from 'styled-components'

export const GlobalTemplate = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

export const GlobalScroll = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
`

export const GlobalContent = styled.article`
  flex: 1 1 auto;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`
