import styled from 'styled-components'

export const GlobalSwipe = styled.div`
  cursor: pointer;
  padding: 2px;
  width: 44px;
  background-color: ${props => (props.status ? '#4CD964' : '#7AA6B8')};
  border-radius: 20px;
  transition: background-color 200ms;

  :before {
    display: block;
    content: '';
    width: 20px;
    height: 20px;
    border-radius: 20px;
    background-color: #fff;
    box-shadow: ${props => (props.status
    ? '-1px 1px 4px 2px rgba(0, 0, 0, 0.3)'
    : '1px 1px 4px 2px rgba(0, 0, 0, 0.3)')};
    transform: translateX(${props => (props.status ? '20px' : 0)});
    transition: transform 200ms;
  }
`
