import React, { useEffect, useState } from 'react'
import { useStore } from '../../../store'
import MyLibrary from './MyLibrary'
import UserLibrary from './UserLibrary'

const Library = (props) => {
  const { computedMatch } = props
  const { user } = useStore()
  const [render, setRender] = useState(<div />)

  useEffect(() => {
    if (!user.loaded) return

    setRender(
      user.data.id === computedMatch.params.id
        || user.data.username === computedMatch.params.id ? (
          <MyLibrary {...props} />
        ) : (
          <UserLibrary {...props} />
        ),
    )
  }, [user.loaded, computedMatch])

  return render
}

export default Library
