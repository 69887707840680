import React from 'react'
import {
  string, func, bool, any, number, oneOfType,
} from 'prop-types'
import { useTranslation } from 'react-i18next'
import { LabelForm } from '../../ui'
import ICONS from '../../ui/ICONS'
import { IconButton, Icon, Loader } from '..'
import {
  StyledInput,
  GlobalInput,
  Legend,
  GlobalStyledInput,
  GlobalButton,
} from './styles'

const Input = (props) => {
  const {
    value,
    placeholder,
    label,
    id,
    type,
    onChange,
    pattern,
    readOnly,
    required,
    error,
    colorLabel,
    onClear,
    icon,
    isGettingData,
    spacing,
  } = props
  const { t } = useTranslation()

  return (
    <GlobalInput htmlFor={id} spacing={spacing}>
      {label && <LabelForm color={colorLabel}>{label}</LabelForm>}

      <GlobalStyledInput>
        {icon && (
          <GlobalButton left>
            {isGettingData && <Loader />}

            {!isGettingData && <Icon icon={icon} />}
          </GlobalButton>
        )}

        <StyledInput
          type={type}
          id={id}
          value={value}
          placeholder={placeholder}
          onChange={onChange}
          pattern={pattern}
          readOnly={readOnly}
          disabled={readOnly}
          required={required}
          error={error}
          hasIcon={!!icon}
          hasClearButton={!!onClear}
        />

        {onClear && value && (
          <GlobalButton right>
            <IconButton
              icon={ICONS.NO}
              onClick={onClear}
              width={40}
              height={40}
            />
          </GlobalButton>
        )}
      </GlobalStyledInput>

      {error.status && <Legend>{t(error.message)}</Legend>}
    </GlobalInput>
  )
}

Input.propTypes = {
  id: string.isRequired,
  label: string,
  value: oneOfType([string, number]),
  type: string,
  onChange: func,
  pattern: string,
  readOnly: bool,
  required: bool,
  error: any,
  onClear: func,
  spacing: number,
}

Input.defaultProps = {
  label: '',
  value: '',
  type: 'text',
  onChange: null,
  pattern: null,
  readOnly: false,
  required: false,
  error: { status: false, message: '' },
  onClear: undefined,
  spacing: 0,
}

export default Input
