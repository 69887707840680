import styled from 'styled-components'

export default styled.div`
  :first-child {
    display: none;

    @media (min-width: ${props => props.theme.breakpoints.md}px) {
      display: block;
    }
  }
`
