import styled, { keyframes } from 'styled-components'

const SHOW_OVERLAY = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`

export default styled.div`
  animation: ${SHOW_OVERLAY} 160ms ease-out;
  cursor: pointer;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: ${props => props.zIndex};
`
