import styled from 'styled-components'

import { SELECT_OPTIONS } from '../constants'

export const Container = styled.div`
  position: relative;
  top: ${props => `${props.spacing}px` || '0'};
  display: flex;
  justify-content: center;
`

export const ContainerAbsolute = styled.div`
  display: flex;
  position: absolute;
  width: 100%;
  max-width: ${props => `${props.maxWidthResult}px` || '100%'};
  flex-direction: column;
  background-color: #0b111b;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.6);
  z-index: 2;
  border-radius: 8px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  overflow: hidden;
`

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
`

export const ItemWrapper = styled.div`
  max-height: ${props => `${props.maxHeight}px` || '400px'};
  overflow: auto;
`

export const Button = styled.button.attrs({ type: 'button' })`
  color: #fff;
  margin: 12px 6px;
  padding: 4px 16px;
  border-radius: 50px;
  background: ${props => (props.activeFilter
    ? SELECT_OPTIONS.find(option => option.key === props.activeFilter).color
    : 'linear-gradient(180deg, #4CD964 0%, #0C9D25 93.75%)')};
  cursor: pointer;
`
