import styled from 'styled-components'
import SocialButton from '../styles'

export default styled(SocialButton)`
  width: 100%;
  background: #3a589e;
  color: #fff;
  &:hover {
    background: linear-gradient(#3a589e 10%, #314165);
  }
`
