import React from 'react'
import PropTypes from 'prop-types'
import { googleLogo } from '../../../../assets'
import StyledGoogleButton from './styles'

const navigateTo = () => {
  window.location = `${process.env.REACT_APP_API_URL}/auth/google`
}

const GoogleButton = ({ children }) => (
  <StyledGoogleButton onClick={navigateTo}>
    <img src={googleLogo} alt="Google" />
    {children}
  </StyledGoogleButton>
)

GoogleButton.propTypes = {
  children: PropTypes.node.isRequired,
}

export default GoogleButton
