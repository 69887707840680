import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useRouter } from '../../../../hooks'
import { Button, Rating } from '../../../commons'
import { useTranslation } from 'react-i18next'
import arrowBlue from '../../../../assets/actions/arrow-blue.png'
import deniedMark from '../../../../assets/actions/denied-mark.png'
import avatarDefault from '../../../../assets/avatar_default.png'
import completedMark from '../../../../assets/actions/completed-mark.png'
import moment from 'moment';
import { toast } from 'react-toastify'
import {
  Icon
} from '../../../commons'
import ICONS from '../../../ui/ICONS'
import api from '../../../../services/api'


import {
  HistoryItem,
  AvatarBox,
  TitleThumbnail,
  StatusIcon,
  GlobalIcon,
  GlobalItemAction,
  StatusTrade
} from '../styles'


export default function ItemHistory({ trade, i }) {

  const { expired, status, notifications } = trade
  const { history } = useRouter()
  const [showNudge, setShowNudge] = useState(false);
  const [nudging, setNudging] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState('1')
  const { t } = useTranslation()

  useEffect(() => {
    if (notifications.length > 0) {
      const lastNotification = notifications[notifications.length - 1]
      const lastUpdateN = lastNotification.createdAt
      const diff = moment.utc(moment().diff(moment.utc(lastUpdateN)))
      const diffSplitted = diff.format('DD-HH').split('-')
      const diffDays = parseInt(diffSplitted[0]) - 1 // because diff returns 1 hour more
      const diffHours = parseInt(diffSplitted[1])
      if ((diffDays >= 1) && status !== 'declined') {
        setShowNudge(true && !expired)
      } else {
        setShowNudge(false);
      }
    }
  }, [])
  const onNudgeUser = async (e) => {
    e.preventDefault()
    e.stopPropagation()
    setNudging(true)
    try {
      await api.get(`/trade/nudge/${trade.id}`)
      setShowNudge(false)
      toast.dismiss()
      toast.success('You nugded the user.')
    } catch (error) {
      toast.dismiss()
      toast.error('An error occurred. Try again later.')
    }
    setNudging(false)
  }
  // User action status
  // TODO * to be reviewed!
  const userStatus = (status) => {
    switch (status) {
      case 'accepted': {
        return { type: 'proceed', message: t(' trade set to accepted.') }
      }
      case 'received': {
        return { type: 'details', message: t(' trade was received.') }
      }
      case 'waiting': {
        return { type: 'actions', message: t(' offer waiting response.') }
      }
      case 'declined': {
        return { type: 'denied', message: t(' this trade was canceled') }
      }
      case 'reviewed': {
        return { type: 'details', message: t(' offer was viewed.') }
      }
      case 'reported': {
        return { type: 'details', message: t(' this offer was reported.') }
      }
      case 'pending-shipping-method': {
        return {
          type: 'details',
          message: t(' waiting both parties to select the shipping method.'),
        }
      }
      case 'shipping-method-changed': {
        return {
          type: 'details',
          message: t(' one side wants to change the shipping method.'),
        }
      }
      case 'shipping-method-selected': {
        return { type: 'details', message: t(' shipping method chosen.') }
      }
      case 'shipping': {
        return { type: 'details', message: t(' has shipped your package.') }
      }
      /* One for success and trade completedAt */
      default:
        break
    }
  }

  const getIcon = (trade) => {
    switch (trade.status) {
      case 'pending': {
        return arrowBlue
      }
      case 'accepted': {
        return completedMark
      }
      case 'declined': {
        return deniedMark
      }
      case 'finalized': {
        return completedMark
      }
      case 'completed': {
        return completedMark
      }
      default:
        break
    }
  }

  const onClickLibrary = (e, userId) => {
    e.stopPropagation()
    history.push(`/library/${userId}`)
  }

  return (
    <HistoryItem
      id={trade.id}
      onClick={e => setSelectedItem(e.currentTarget.id)}
      className={selectedItem === trade.id && 'selected'}
    >
      <AvatarBox
        className={`${trade.expired ? 'avatar-expired' : trade.status
          } avatar`}
        isFirst={i === 0}
      >
        <img onClick={e => onClickLibrary(e, trade.to.user.id)}
          className="avatar" style={{ cursor: "pointer" }}
          src={trade.to.user.avatar || avatarDefault}
          alt="avatar"
        />

        <Rating rate={trade.to.user.rating} size={8} />
      </AvatarBox>

      <div className="trade-info top cell-text">
        <strong>{trade.to.user.displayName}</strong>
        <span className="trade-status">
          {trade.status === 'completed'
            ? t('trade completed')
            : userStatus(trade.user.status).message}
        </span>
        <GlobalItemAction
          onClick={e => onClickLibrary(e, trade.to.user.id)}
          variation="library"
        >
          <GlobalIcon variation="library">
            <Icon icon={ICONS.LIBRARY} />
          </GlobalIcon>
          {t('See Library')}
        </GlobalItemAction>

      </div>

      <div className="titles-flow middle">
        {trade.from.trading[0] ? (
          <TitleThumbnail
            src={trade.from.trading[0].pictures[0]}
            alt="media cover"
          />
        ) : (
            <p>{t('No media')}</p>
          )}
        <div>
          <StatusIcon icon={() => getIcon(trade)} />
        </div>
        {trade.to.trading[0] ? (
          <TitleThumbnail
            src={trade.to.trading[0].pictures[0]}
            alt="media cover"
          />
        ) : (
            <p>{t('No media')}</p>
          )}
      </div>

      <div className="bottom-left trade-started cell-text">
        <strong>{t('Trade Started')}</strong>
        <span>
          {trade.createdAt
            ? moment(trade.createdAt).format('MMM DD YY, h:mm A')
            : '-'}
        </span>
      </div>

      <div className="trade-completed hide-sm cell-text">
        <strong>{t('Trade Completed')}</strong>
        <span>
          {trade.createdAt
            ? moment(trade.createdAt).format('MMM DD YY, h:mm A')
            : '-'}
        </span>
      </div>

      <div className="trade-type bottom-right">
        <div className="hide-sm cell-text">
          <strong>{t('Trade by')}</strong>
          <span>
            {trade.user.shipping ? trade.user.shipping.method : '-'}
          </span>
        </div>
      </div>
      <div style={{ flexDirection: "column" }}
        className={`trade-actions ${trade.expired ? 'expired' : userStatus(trade.user.status).type
          }`}
      >
        <Link style={{ marginTop: 4 }} to={`/trade/${trade.id}`}>
          <Button variation={trade.expired ? 'warning' : 'info'}>
            {t('details')}
          </Button>
        </Link>

        {showNudge && (<Button style={{ marginTop: 4 }} variation={'info'} onClick={e => onNudgeUser(e)}>
          {t('Nudge user')}
        </Button>)}
      </div>
    </HistoryItem>
  )

}