export const changeActiveFilter = payload => ({
  type: 'SEARCH_CHANGE_ACTIVE_FILTER',
  payload,
})

export const changeSearchTerm = payload => ({
  type: 'SEARCH_CHANGE_SEARCH_TERM',
  payload,
})

export const fetchSearchResults = () => ({
  type: 'SEARCH_FETCH_SEARCH_RESULTS',
})

export const successSearchResults = payload => ({
  type: 'SEARCH_SUCCESS_SEARCH_RESULTS',
  payload,
})

export const errorSearchResults = () => ({
  type: 'SEARCH_ERROR_SEARCH_RESULTS',
})

export const clearSearchResults = () => ({
  type: 'SEARCH_CLEAR_SEARCH_RESULTS',
})

export const openSearchResults = () => ({
  type: 'SEARCH_OPEN_SEARCH_RESULTS',
})

export const closeSearchResults = () => ({
  type: 'SEARCH_CLOSE_SEARCH_RESULTS',
})

export const populateHomeSearch = payload => ({
  type: 'SEARCH_POPULATE_HOME_SEARCH',
  payload,
})

export const clearPopulatedHomeSearch = () => ({
  type: 'SEARCH_CLEAR_POPULATED_HOME_SEARCH',
})

export const openSearchLocal = () => ({
  type: 'SEARCH_OPEN_SEARCH_LOCAL',
})

export const openIncreaseMyChances = () => ({
  type: 'SEARCH_OPEN_INCREASE_MY_CHANCES',
})

export const closeSearchLocal = () => ({
  type: 'SEARCH_CLOSE_SEARCH_LOCAL',
})

export const closeIncreaseMyChances = () => ({
  type: 'SEARCH_CLOSE_INCREASE_MY_CHANCES',
})
