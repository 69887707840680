import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import { withTheme } from 'styled-components'
import { Button as Button2 } from 'evergreen-ui'
import { useTranslation } from 'react-i18next'
import { useStore } from '../../../store'

import api from '../../../services/api'
import { addMediaBackground } from '../../../assets'
import { useDebounce, useRouter } from '../../../hooks'
import {
  DefaultPageWrapper,
  DefaultPageTitle,
  DefaultPageBox,
  DefaultPageBoxContent,
  DefaultPageNewBackground,
  DefaultPageFadeBackground,
  DefaultPageNewBoxHeader,
} from '../../templates/DefaultPage'
import { DefaultTemplateBackground } from '../../templates/DefaultTemplate'
import ResultsSearchByTitle from './ResultsSearchByTitle'
import {
  Grid,
  Input,
  Select,
  TextArea,
  Button,
  UploadPicture,
  MediaCondition,
  MediaCard,
  ViewMode,
  MediaTypeOptions,
} from '../../commons'
import { Title } from '../../ui'
import {
  GlobalSubmit,
  ToggleButton,
  ToggleContainer,
  ToggleDecorator,
  ToggleGroup,
  ToggleGroupLabel,
  GlobalSearch,
  HeaderMyLibrary,
} from './styles'

const MediaForm = (props) => {
  const { computedMatch } = props
  const { t } = useTranslation()
  const [isBarcodeToggled, setBarcodeToggled] = useState(false)
  const [query, setQuery] = useState('')
  const [isGettingDataByQuery, setIsGettingDataByQuery] = useState(false)
  const [submittingForm, setSubmittingForm] = useState(false)
  const [platforms, setPlatforms] = useState({})
  const [itemsSearchByTitle, setItemsSearchByTitle] = useState({})
  const [openResults, setOpenResults] = useState(false)
  const [viewMode, setViewMode] = useState('more')
  const { theme } = props
  const fieldsInitialState = {
    platform: '',
    title: '',
    releaseDate: '',
    description: '',
    pictures: [],
    condition: 'used',
    type: window.localStorage.getItem('itemType') || 'game',
    externalId: null,
  }
  const [fields, setFields] = useState(fieldsInitialState)
  const [items, setItems] = useState([])
  const { user } = useStore()
  const { history } = useRouter()
  const cardGrid = {
    less: {
      xs: 12,
      sm: 6,
      md: 4,
      lg: 3,
    },
    more: {
      xs: 6,
      sm: 3,
      md: 2,
      lg: 2,
    },
  }
  const [typeMediaForm, setTypeMediaForm] = useState('')
  const debouncedQuery = useDebounce(query, 400)

  useEffect(() => {
    setTypeMediaForm(
      computedMatch.path.includes('add-media') ? 'addMedia' : 'editMedia',
    )
  }, [computedMatch])

  const fetchMediaDataToEdit = async (mediaId) => {
    try {
      const response = await api.get(`/media/id/${computedMatch.params.id}`)

      setFields({
        platform: response.data.platform.id,
        title: response.data.title,
        releaseDate: response.data.releaseDate,
        description: response.data.description,
        pictures: response.data.pictures,
        condition: response.data.condition,
        type: response.data.mediaType,
      })
    } catch (err) {
      toast.error(t('An error occurred. Try again later.'))
    }
  }

  useEffect(() => {
    if (typeMediaForm !== 'editMedia') return

    fetchMediaDataToEdit(computedMatch.params.id)
  }, [typeMediaForm])

  const handleChangeFields = (e) => {
    const { id, value } = e.target
    setFields((prev) => {
      const returnObj = { ...prev }
      returnObj[id] = value
      return returnObj
    })
  }

  const handleChangeByCallback = (id, newState) => {
    setFields((prev) => {
      const returnObj = { ...prev }
      returnObj[id] = newState
      return returnObj
    })
  }

  const onWay = (route) => {
    history.push(route)
    toast.dismiss()
  }

  const submitFormToApi = async () => {
    try {
      let response = null
      response = typeMediaForm === 'addMedia'
        ? await api.post('/media', fields)
        : await api.put(`/media/${computedMatch.params.id}`, fields)
      await setFields(fieldsInitialState)
      setQuery('')

      setItems((prev) => {
        const newItems = JSON.parse(JSON.stringify(prev))
        newItems.unshift(response.data.media)
        return newItems
      })

      if (typeMediaForm === 'addMedia') {
        toast.success(
          <>
            <div style={{ marginBottom: '8px' }}>{response.data.message}</div>

            <Button2
              onClick={() => onWay(`/media/${response.data.media.id}`)}
              style={{ marginRight: '8px' }}
            >
              {t('Go to media page')}
            </Button2>
            <Button2
              onClick={() => onWay(`/library/${response.data.media.user.id}`)}
            >
              {t('View my library')}
            </Button2>
          </>,
          { autoClose: 12000 },
        )
      } else {
        history.goBack()
        toast.dismiss()
        toast.success(t('Media updated'))
      }
    } catch (error) {
      toast.dismiss()
      toast.error(t('An error occurred. Try again later.'))
    }
  }

  const submitForm = async (e) => {
    e.preventDefault()
    setSubmittingForm(true)

    try {
      await api.get('user/required-register')
    } catch (error) {
      console.log(error)
    }

    await submitFormToApi()
    setSubmittingForm(false)
  }

  useEffect(() => {
    let initialPlatform = null

    if (
      Object.keys(platforms).length > 0
      && platforms[fields.type].length > 0
    ) {
      initialPlatform = platforms[fields.type][0].value
    }
    window.localStorage.setItem('itemType', fields.type)
    handleChangeByCallback('platform', initialPlatform)
  }, [fields.type])

  const fetchDataByBarcode = async () => {
    try {
      const response = await api.get(`/media/upc/${debouncedQuery}`)

      setFields((prev) => {
        const returnObj = {
          ...prev,
          title: response.data.title ? response.data.title : prev.title,
          description: response.data.description
            ? response.data.description
            : prev.description,
          pictures: response.data.pictures ? [response.data.pictures[0]] : [],
        }
        return returnObj
      })
    } catch (error) {
      toast.dismiss()
      toast.error(
        error.response
          ? error.response.data.error
          : t('An error occurred. Try again later.'),
      )
    }
  }

  const fetchDataByTitle = async () => {
    try {
      const response = await api.get(
        `media/query/${debouncedQuery}?type=${fields.type}`,
      )

      setItemsSearchByTitle(
        response.data.map(item => ({
          thumb: item.pictures[0],
          title: item.title,
          releaseDate: item.releaseDate,
          externalId: item.id,
        })),
      )
    } catch (error) {
      toast.dismiss()
      toast.error(
        error.response
          ? error.response.data.error
          : t('An error occurred. Try again later.'),
      )
    }

    setOpenResults(true)
  }

  useEffect(() => {
    if (debouncedQuery) {
      setIsGettingDataByQuery(true)

      if (isBarcodeToggled) {
        fetchDataByBarcode()
      } else {
        fetchDataByTitle()
      }

      setIsGettingDataByQuery(false)
    }
  }, [debouncedQuery])

  const fetchPlatforms = async () => {
    try {
      const response = await api.get('media/platforms/all')
      const platformOptions = {}

      Object.keys(response.data).map(
        key => (platformOptions[key] = Object.keys(response.data[key]).map(
          keyOption => ({
            label: response.data[key][keyOption],
            value: keyOption,
          }),
        ).sort()),
      )
      setPlatforms(platformOptions)
    } catch (error) {
      console.log(error)
    }
  }

  const fetchLibrary = async () => {
    try {
      const response = await api.post(`/media/user/${user.data.id}`)

      setItems(response.data.docs)
    } catch (error) {
      toast.dismiss()
      toast.error(
        error.response
          ? error.response.data.error
          : t('An error occurred. Try again later.'),
      )
    }
  }

  useEffect(() => {
    if (Object.keys(platforms).length > 0) return

    fetchPlatforms()
  }, [])

  useEffect(() => {
    if (!user.loaded || typeMediaForm !== 'addMedia') return

    fetchLibrary()
  }, [user.loaded, typeMediaForm])

  const onClickItemOnResults = (item) => {
    setFields((prev) => {
      const returnObj = {
        ...prev,
        title: item.title,
        pictures: item.thumb ? [item.thumb] : [],
        releaseDate: item.releaseDate,
        externalId: item.externalId,
      }
      return returnObj
    })

    setOpenResults(false)
  }

  return (
    <DefaultTemplateBackground top bottom>
      <DefaultPageNewBackground src={addMediaBackground} />
      <DefaultPageFadeBackground />
      <DefaultPageWrapper>
        {typeMediaForm === 'editMedia' && (
          <DefaultPageTitle>{t('Edit media')}</DefaultPageTitle>
        )}

        {typeMediaForm === 'addMedia' && (
          <DefaultPageTitle>{t('Add NEW or USED Media')}</DefaultPageTitle>
        )}

        <DefaultPageBox>
          <DefaultPageNewBoxHeader />
          <DefaultPageBoxContent>
            <form onSubmit={submitForm} style={{ marginBottom: '64px' }}>
              {typeMediaForm === 'addMedia' && (
                <MediaTypeOptions
                  style={{ marginBottom: '32px' }}
                  active={fields.type}
                  callbackChangeMediaType={type => handleChangeByCallback('type', type)
                  }
                />
              )}

              <Grid container>
                {typeMediaForm === 'addMedia' && (
                  <>
                    <Grid item xlg={2}>
                      <ToggleContainer>
                        <ToggleGroupLabel>
                          <ToggleDecorator
                            color={theme.colorMediaTypes[fields.type][0]}
                          />
                          {t('Add by')}
                        </ToggleGroupLabel>
                        <ToggleGroup>
                          <ToggleButton
                            onClick={() => setBarcodeToggled(false)}
                            toggled={!isBarcodeToggled}
                          >
                            {t('By Title')}
                          </ToggleButton>
                          <ToggleButton
                            onClick={() => setBarcodeToggled(true)}
                            toggled={isBarcodeToggled}
                          >
                            {t('Barcode')}
                          </ToggleButton>
                        </ToggleGroup>
                      </ToggleContainer>
                    </Grid>

                    <Grid item xlg={10}>
                      <GlobalSearch>
                        <Input
                          id="search"
                          type="search"
                          isGettingData={isGettingDataByQuery}
                          colorLabel={theme.colorMediaTypes[fields.type][0]}
                          placeholder={
                            isBarcodeToggled
                              ? t('ReadTypeCode')
                              : t('TypeMediaTitle')
                          }
                          value={query}
                          onChange={e => setQuery(e.target.value)}
                          onClear={() => setQuery('')}
                          spacing={theme.spacing * 2}
                        />

                        {!isBarcodeToggled && openResults && (
                          <ResultsSearchByTitle
                            items={itemsSearchByTitle}
                            callbackOnClickItem={item => onClickItemOnResults(item)
                            }
                            searchTerm={debouncedQuery}
                            isLoading={isGettingDataByQuery}
                          />
                        )}
                      </GlobalSearch>
                    </Grid>
                  </>
                )}
              </Grid>

              <Grid container>
                <Grid item md={12}>
                  <Input
                    label={t('Title')}
                    colorLabel={theme.colorMediaTypes[fields.type][0]}
                    id="title"
                    type="text"
                    value={fields.title}
                    onChange={handleChangeFields}
                    spacing={theme.spacing * 2}
                    required
                  />
                </Grid>
              </Grid>

              <Grid container>
                <Grid item md={6} lg={4}>
                  <Select
                    label={t('Platform')}
                    colorLabel={theme.colorMediaTypes[fields.type][0]}
                    id="platform"
                    type="text"
                    options={platforms[fields.type]}
                    value={fields.platform || ''}
                    onChange={handleChangeFields}
                    required
                    emptyOption
                  />
                </Grid>

                <Grid item md={6} lg={2}>
                  <Input
                    label={t('Release year')}
                    colorLabel={theme.colorMediaTypes[fields.type][0]}
                    id="releaseDate"
                    type="number"
                    value={fields.releaseDate}
                    onChange={handleChangeFields}
                    spacing={theme.spacing * 2}
                    required
                  />
                </Grid>
              </Grid>

              <Grid container>
                <Grid item lg={6}>
                  <TextArea
                    label={t('Description')}
                    colorLabel={theme.colorMediaTypes[fields.type][0]}
                    id="description"
                    value={fields.description}
                    onChange={handleChangeFields}
                    spacing={theme.spacing * 2}
                    placeholder={t(
                      'placeholderDescriptionMedia',
                    )}
                  />
                </Grid>

                <Grid item lg={2}>
                  <UploadPicture
                    id="pictures"
                    label={t('Photo')}
                    colorLabel={theme.colorMediaTypes[fields.type][0]}
                    src={fields.pictures[0]}
                    onChange={image => handleChangeByCallback('pictures', image ? [image] : [])
                    }
                    enableCrop
                    aspectToCrop={4 / 4}
                  />
                </Grid>

                <Grid item lg={4}>
                  <MediaCondition
                    label={t('Condition')}
                    colorLabel={theme.colorMediaTypes[fields.type][0]}
                    initialCondition={fields.condition}
                    callbackChangeMediaCondition={condition => handleChangeByCallback('condition', condition)
                    }
                  />
                </Grid>
              </Grid>

              <GlobalSubmit>
                {typeMediaForm === 'addMedia' && (
                  <Button
                    variation="success"
                    type="submit"
                    isLoading={submittingForm}
                  >
                    {t('Add Media')}
                  </Button>
                )}

                {typeMediaForm === 'editMedia' && (
                  <Button
                    variation="success"
                    type="submit"
                    isLoading={submittingForm}
                  >
                    {t('Save')}
                  </Button>
                )}
              </GlobalSubmit>
            </form>

            {typeMediaForm === 'addMedia' && (
              <>
                <HeaderMyLibrary>
                  <Title>{t('My library')}</Title>

                  <ViewMode
                    style={{ marginBottom: '16px' }}
                    onChange={mode => setViewMode(mode)}
                    active={viewMode}
                  />
                </HeaderMyLibrary>

                <Grid container>
                  {items.length > 0
                    && items.map(item => (
                      <Grid
                        key={item.id}
                        item
                        xs={cardGrid[viewMode].xs}
                        sm={cardGrid[viewMode].sm}
                        md={cardGrid[viewMode].md}
                        lg={cardGrid[viewMode].lg}
                      >
                        <MediaCard data={item} showUser actions="default" />
                      </Grid>
                    ))}
                </Grid>
              </>
            )}
          </DefaultPageBoxContent>
        </DefaultPageBox>
      </DefaultPageWrapper>
    </DefaultTemplateBackground>
  )
}

export default withTheme(MediaForm)
