import styled from 'styled-components'

export const GlobalMediaPicture = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(${props => (props.hasMedia ? props.src : 'initial')});
  background-color: ${props => (props.hasMedia
    ? props.theme.colorPrimaryDark5
    : props.theme.colorGrayDark2)};
  background-size: contain;
  width: ${props => `${props.size}px`};
  height: ${props => `${props.size}px`};
  border-radius: 50%;
  cursor: ${props => (props.onClick ? 'pointer' : 'initial')}

  :first-child {
    top: 0;
    left: 0;
  }

  :last-child {
    bottom: 0;
    right: 0;
  }
`
