import styled from "styled-components";
import { DefaultPageBox, DefaultPageBoxContent } from "../../../templates/DefaultPage";

export const Container = styled(DefaultPageBox)`
  margin: 0 auto;
  max-width: 921px;
`

export const Content = styled(DefaultPageBoxContent)`
  padding: 28px 38px;
`

export const Steps = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #0B1625;
  padding-bottom: 22px;
  padding-top: 12px;
  cursor: pointer;
  
   @media(max-width: 576px) {
      flex-wrap: wrap;
    }
  
  & > span {
    position: absolute;
    right: 0;
    margin-right: 35px;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    cursor: pointer;
     @media(max-width: 576px) {
      position: relative;
      margin-top: 12px;
      justify-content: flex-end;
    }
  }
`

export const Step = styled.div`
  margin-right: 9px;
  margin-left: 9px;
  & > p {
     font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    text-align: center;
    color: #FFF;
    text-transform: uppercase; 
  }
  & > div {
    height: 8px;
    width: 104px;
    background: ${props => props.background ? props.background : '#253F58'};
    border-radius: 5px;
  }
`

export const Title = styled.h1`
  font-weight: 600;
  font-size: 28px;
  line-height: 32px;
  text-align: center;
  margin-bottom: 15px;
  text-transform: uppercase;
`
