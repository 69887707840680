import React from 'react'
import { useTranslation } from "react-i18next";
import { Form, GlobalSubmit, styles } from "../styles";
import { Button, Input } from "../../../../commons";
import  { CardElement } from "react-stripe-elements";

export const NewCardForm = ({
 isOpen,
 handleCreateCard,
 holdersName,
 isCreatingCard,
 handleChangeHoldersName,
 cardRef,
 setIsOpen,
}) => {
  const { t } = useTranslation()
  if (!isOpen) {
    return null
  }
  return (
    <Form onSubmit={handleCreateCard}>
      <Input
        label={t('Credit Card')}
        placeholder={t('Card Holder`s Name')}
        id="Card Holder's Name"
        type="text"
        value={holdersName}
        onChange={event => handleChangeHoldersName(event.target.value)}
        required
      />
      <CardElement ref={cardRef} style={styles} hidePostalCode />
      <GlobalSubmit>
        <Button
          variation="ghost"
          onClick={() => setIsOpen(false)}
          isLoading={isCreatingCard}
        >
          {t('Cancel')}
        </Button>
        <Button
          variation="success"
          type="submit"
          isLoading={isCreatingCard}
        >
          {t('Save')}
        </Button>
      </GlobalSubmit>
    </Form>
  )
}
