import React from 'react'
import { useTranslation } from 'react-i18next'
import { Wrapper } from '../../../ui'
import { Logo, IconButton } from '../../../commons'
import { useMedia } from '../../../../hooks'
import ICONS from '../../../ui/ICONS'
import {
  FooterTitle,
  SubFooter,
  MenuHorzItem,
  SubFooterColumn,
  FollowUs,
  FooterBand,
  FooterBrands,
} from './styles'

import './styles.css'
import { googleStore, appleStore, UTVOBsticker } from '../../../../assets'

const Footer = (props) => {
  const isLg = useMedia('(min-width: 1024px)')
  const { t } = useTranslation()

  return (
    <footer>
      <FooterBrands />

      <FooterBand isLg={isLg}>
        <Wrapper>
          <SubFooter>
            <SubFooterColumn>
              <FooterTitle>{t('Follow us')}</FooterTitle>

              <FollowUs>
                <IconButton
                  onClick={() => window.open('https://www.facebook.com/newusedmedia')
                  }
                  icon={ICONS.FACEBOOK}
                  size={30}
                  minClickable
                />

                <IconButton
                  onClick={() => window.open('https://www.instagram.com/new_used_media')
                  }
                  icon={ICONS.INSTAGRAM}
                  size={30}
                  minClickable
                />

                <IconButton
                  onClick={() => window.open('https://twitter.com/MediaUsed')}
                  icon={ICONS.TWITTER}
                  size={30}
                  minClickable
                />

                <IconButton
                  onClick={() => window.open('https://www.youtube.com/channel/UCXkPhWt3kfVLT9AaFBrWREw')}
                  icon={ICONS.YOUTUBE}
                  size={30}
                  minClickable
                />
                <IconButton
                  onClick={() => window.open('https://www.tiktok.com/@newusedmedia')}
                  icon={ICONS.TIKTOK}
                  size={30}
                  minClickable
                />
              </FollowUs>
            </SubFooterColumn>

            <SubFooterColumn>
              <Logo height="50px" />
            </SubFooterColumn>

            <SubFooterColumn>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div className="footer-links">
                  <MenuHorzItem to="/how-it-works">{t('How it works')}</MenuHorzItem>
                  <MenuHorzItem to="/user-agreement">{t('User Agreement')}</MenuHorzItem>
                  <MenuHorzItem to="/privacy-policy">{t('Privacy policy')}</MenuHorzItem>
                  <MenuHorzItem to="/faq">{t('FAQ')}</MenuHorzItem>
                </div>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  © Copyright {new Date().getFullYear()} New Used Media Corp.
                </div>

              </div>
            </SubFooterColumn>
          </SubFooter>
          <div className='last-links-container'>
            <div className='flag-container'>
                    <img src={UTVOBsticker} />
            </div>
            <div className="social-links">
              <a href="https://play.google.com/store/apps/details?id=com.newusedmedia" target="_blank" rel="noopener noreferrer">
                <img src={googleStore} alt="Get it on Google Play" />
              </a>

              <a href="https://apps.apple.com/us/app/new-used-media/id1478021026" target="_blank" rel="noopener noreferrer">
                <img src={appleStore} alt="Download on the app store" />
              </a>
            </div>
          </div>
        </Wrapper>
      </FooterBand>
    </footer>
  )
}

export default Footer
