import styled from 'styled-components'

export const Table = styled.table`
  background-color: #0b1625;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 300;
  line-height: 20px;
  max-height: 254px;
  & > div {
    height: 254px;
    overflow-y: auto;
  & > tr > th {
    text-align: left;
    padding: 6px 12px;
    }
  & > tr > td {
    padding-left: 12px;
    padding-right: 4px;
    vertical-align: middle;
    }
  & > tr:nth-child(even){
    background-color: #253F58;
    }
  & > tr:nth-child(odd){
    background-color: #152F48;
    }
  & > tr:first-child{
    background-color: #0b1625;
    }
  }
`
