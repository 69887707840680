import React from 'react'
import { bool } from 'prop-types'
import { useTranslation } from 'react-i18next'
import { searchBarActions } from '..'
import { useStore } from '../../../../../../store'
import { brazil, spain, unitedStates } from '../../../../../../assets'
import { GlobalLanguageNavigation, NavigationLink } from './styles'

const ChangeLanguageNavigation = (props) => {
  const { onNavigationMobile } = props
  const { dispatch } = useStore()
  const { i18n, t } = useTranslation()

  const changeLanguage = (language) => {
    i18n.changeLanguage(language)
    dispatch(searchBarActions.hideTheHiddenContent())
  }

  return (
    <GlobalLanguageNavigation onNavigationMobile={onNavigationMobile}>
      <NavigationLink
        onClick={() => changeLanguage('en-US')}
        onNavigationMobile={onNavigationMobile}
      >
        <img src={unitedStates} alt="pt-br" width="30" height="30" />
        {' '}
        {t('English')}
      </NavigationLink>
      <NavigationLink
        onClick={() => changeLanguage('es-US')}
        onNavigationMobile={onNavigationMobile}
      >
        <img src={spain} alt="pt-br" width="30" height="30" />
        {' '}
        {t('Spanish')}
      </NavigationLink>
      <NavigationLink
        onClick={() => changeLanguage('pt-BR')}
        onNavigationMobile={onNavigationMobile}
      >
        <img src={brazil} alt="pt-br" width="30" height="30" />
        {' '}
        {t('Portuguese')}
      </NavigationLink>
    </GlobalLanguageNavigation>
  )
}

ChangeLanguageNavigation.propTypes = {
  onNavigationMobile: bool,
}

ChangeLanguageNavigation.defaultProps = {
  onNavigationMobile: false,
}

export default ChangeLanguageNavigation
