import styled from 'styled-components'

import { avatarDefault } from '../../../assets'

export const Item = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #0c1726;
  margin-bottom: 2px;
  cursor: pointer;
  padding: 18px;

  &:hover {
    background-color: #032338;
  }

  &:last-child {
    margin-bottom: 0;
  }
`

export const MediaImage = styled.div`
  margin-right: 12px;
  height: 48px;
  width: 48px;
  background-color: black;
  background-image: url(${props => props.image});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 4px;
`

export const MediaInfo = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

export const MediaTitleContainer = styled.div`
  display: flex;
  align-items: baseline;
`

export const MediaTitle = styled.span`
  font-size: 16px;
  font-weight: 300;
`

export const MediaReleaseDate = styled.span`
  font-size: 10px;
  color: #767c83;
  margin-left: 8px;
  font-weight: 300;
`

export const UserInfo = styled.div`
  display: flex;
  align-items: center;
`

export const Avatar = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: yellow;
  background-image: url(${props => (props.src ? props.src : avatarDefault)});
  background-position: center;
  background-size: cover;
  margin-right: 8px;
`

export const UserName = styled.span`
  margin-right: 8px;
`
