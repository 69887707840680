import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Button } from '..'

const StyledModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top-right-radius: 0.4em;
  border-top-left-radius: 0.4em;
  padding: 0.75rem;
  background-color: #2da8d7;
  color: ${props => props.theme.fontColor};
`

const Title = styled.span`
  flex: auto;
  margin: 0 0.75rem;
`

const ModalHeader = ({ handleClose, children }) => (
  <StyledModalHeader>
    <Title>{children}</Title>
    <Button variation="ghost" onClick={() => handleClose()}>
      X
    </Button>
  </StyledModalHeader>
)

ModalHeader.propTypes = {
  handleClose: PropTypes.func.isRequired,
  children: PropTypes.node,
}

ModalHeader.defaultProps = {
  children: '',
}

export default ModalHeader
