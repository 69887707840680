import './dotenvConfig'
import './geocodeConfig'
import './config/i18n'
import React from 'react'
//import Snowfall from 'react-snowfall'

import ReactDOM from 'react-dom'
import App from './App'
import * as serviceWorker from './serviceWorker'

ReactDOM.render(
    <App />
, document.getElementById('root'))
serviceWorker.unregister()
