import styled from 'styled-components'

export const GlobalFilter = styled.ul``

export const FilterButton = styled.li`
  position: relative;
  display: inline-flex;
  align-items: center;
  color: ${props => props.theme.secondary};
  margin: 0 ${props => props.theme.spacing * 2}px;
  height: 30px;
  cursor: pointer;

  :hover {
    opacity: 0.7;
  }

  :after {
    display: ${props => (props.active ? 'block' : 'none')};
    content: '';
    width: 80%;
    height: 4px;
    background-color: ${props => props.theme.colorSecondary};
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    margin-top: ${props => props.theme.spacing}px;
    border-radius: 2px;
  }
`
