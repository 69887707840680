import React, { useState, useEffect } from 'react'
import { number, bool, string } from 'prop-types'
import { useTranslation } from 'react-i18next'
import { LabelForm } from '../../ui'
import {
  GlobalMediaCondition,
  GlobalInputRange,
  InputRange,
  Pointer,
  GlobalRange,
  GlobalPointer,
} from './styles'

const MediaCondition = (props) => {
  const {
    label,
    callbackChangeMediaCondition,
    noMargin,
    colorLabel,
    initialCondition,
  } = props
  const [actualCondition, setActualCondition] = useState(100)
  const conditions = {
    used: 0,
    likeNew: 50,
    new: 100,
  }
  const { t } = useTranslation()

  useEffect(() => {
    if (!initialCondition) return

    setActualCondition(conditions[initialCondition])
  }, [initialCondition])

  const setCondition = (condition) => {
    if (!callbackChangeMediaCondition) return

    setActualCondition(condition)

    callbackChangeMediaCondition(
      Object.keys(conditions).filter(key => conditions[key] === condition)[0],
    )
  }

  return (
    <GlobalMediaCondition noMargin={noMargin}>
      {label && <LabelForm color={colorLabel}>{label}</LabelForm>}

      <GlobalRange>
        <GlobalInputRange>
          <InputRange value={actualCondition} readOnly />
        </GlobalInputRange>

        <GlobalPointer>
          <Pointer
            onClick={() => setCondition(0)}
            value={actualCondition}
            hasCallback={!!callbackChangeMediaCondition}
          >
            {t('USED')}
          </Pointer>

          <Pointer
            onClick={() => setCondition(50)}
            value={actualCondition}
            hasCallback={!!callbackChangeMediaCondition}
          >
            {t('LIKE NEW')}
          </Pointer>

          <Pointer
            onClick={() => setCondition(100)}
            value={actualCondition}
            hasCallback={!!callbackChangeMediaCondition}
          >
            {t('NEW')}
          </Pointer>
        </GlobalPointer>
      </GlobalRange>
    </GlobalMediaCondition>
  )
}

MediaCondition.propTypes = {
  initialCondition: string,
  condition: number,
  noMargin: bool,
}

MediaCondition.defaultProps = {
  initialCondition: 'new',
  condition: 100,
  noMargin: false,
}

export default MediaCondition
