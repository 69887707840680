import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify'

import { useTranslation } from 'react-i18next'
import { DefaultPageWrapper } from '../../templates/DefaultPage'
import { DefaultTemplateBackground } from '../../templates/DefaultTemplate'
import { Input, Grid, Meta } from '../../commons'
import {
  GoogleButton,
  TwitchButton,
  FacebookButton,
} from '../../commons/SocialButtons'
import { useRouter } from '../../../hooks'
import { userServices } from '../../UserInfo'
import { useStore } from '../../../store'
import { signIn } from '../../../services/auth'
import {
  ButtonContainer,
  Container,
  Content,
  Form,
  Header,
  Paragraph,
  SignInButton,
  SignInSection,
  SignUpButton,
  SocialSignInSection,
  Title,
  Forget,
} from './styles'

const SignIn = (props) => {
  const { t } = useTranslation()
  const { computedMatch } = props
  const [submittingForm, setSubmittingForm] = useState(false)
  const { history } = useRouter()
  const { dispatch } = useStore()
  const [email, handleChangeEmail] = useState('')
  const [password, handleChangePassword] = useState('')

  const submitForm = async (e) => {
    e.preventDefault()
    try {
      setSubmittingForm(true)
      await signIn({ email, password })
      userServices.getUserInfo(dispatch)
      history.push('/')
    } catch (err) {
      if (
        err.response
        && err.response.data.error === 'User e-mail not verified'
      ) {
        history.push(`/signup-confirmation/${email}/not-verified`)
      } else {
        toast.dismiss()
        toast.error(
          err.response
            ? err.response.data.error
            : t('An error occurred. Try again later.'),
        )
      }
    } finally {
      setSubmittingForm(false)
    }
  }

  useEffect(() => {
    if (computedMatch.params.verified) {
      toast.success(
        <div>
          <strong>{t('Successful registration!')}</strong>
          <br />
          {' '}
          {t('You can now Sign In.')}
        </div>,
        { autoClose: 10000 },
      )
    }
  }, [])

  const metaDescription = 'Sign in or sign up for New Used Media and start' +
    ' exchanging with users across the country'

  return (
    <DefaultTemplateBackground top bottom>
      <Meta metaDescription={metaDescription} />
      <DefaultPageWrapper
        topXs="10px"
        topSm="50px"
        topLg="100px"
        topXlg="160px"
        botXs="10px"
        botSm="50px"
        botLg="100px"
        botXlg="160px"
      >
        <Container>
          <Header />
          <Content>
            <Grid container justify="center">
              <SignInSection justify="center" item md={6} lg={6}>
                <Title>{t('Sign In')}</Title>
                <Form onSubmit={submitForm}>
                  <Input
                    label={t('Username or Email')}
                    id="email"
                    type="text"
                    value={email}
                    onChange={event => handleChangeEmail(event.target.value)}
                    required
                  />
                  <Input
                    label={t('Password')}
                    id="password"
                    type="password"
                    value={password}
                    onChange={event => handleChangePassword(event.target.value)}
                    required
                  />
                  <Forget onClick={() => history.push('/recover-password')}>
                    {t('Forgot your password?')}
                  </Forget>
                  <ButtonContainer>
                    <SignInButton disabled={submittingForm} type="submit">
                      {t('Sign In')}
                    </SignInButton>
                  </ButtonContainer>
                </Form>
              </SignInSection>
              <SocialSignInSection justify="center" item md={6} lg={6}>
                <FacebookButton>{t('Sign In with Facebook')}</FacebookButton>
                <GoogleButton>{t('Sign In with Google')}</GoogleButton>
                <Paragraph>
                  {t('New on')}
                  {' '}
                  <span>{t('NEWUSEDMEDIA?')}</span>
                </Paragraph>
                <ButtonContainer>
                  <SignUpButton onClick={() => history.push('/signup')}>
                    {t('Create your account')}
                  </SignUpButton>
                </ButtonContainer>
              </SocialSignInSection>
            </Grid>
          </Content>
        </Container>
      </DefaultPageWrapper>
    </DefaultTemplateBackground>
  )
}

export default SignIn
