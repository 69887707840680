import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { subscribe, unsubscribe } from '../../../../../services/card'
import { Grid } from '../../../../commons'
import { Subscription, SubscriptionCard } from '../styles'
import { monthly, perTransaction } from '../../../../../assets'
import { WarningDialog } from '../Dialog'

export const SubscriptionForm = ({
  setSubscription,
  cards,
  subscription,
  fetchSubscription,
}) => {
  const { t } = useTranslation()
  const [isMonthly, setIsMonthly] = useState(false)
  const [isBasicDisabled, setIsBasicDisabled] = useState(false)
  const [isPremiumDisabled, setIsPremiumDisabled] = useState(false)
  const [isPerTransaction, setIsPerTransaction] = useState(false)
  const [isSubscribing, setIsSubscribing] = useState(false)
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const { cancel_at_period_end, id, status } = subscription
  const userHasCard = !!cards.length
  const userHasSubscription = !!id && !cancel_at_period_end
  const isStillAvailable = status === 'active'
  const isRenewable = cancel_at_period_end && isStillAvailable
  useEffect(() => {
    if (!userHasCard && !userHasSubscription) {
      setIsPerTransaction(false)
      setIsMonthly(false)
      setIsBasicDisabled(true)
      return setIsPremiumDisabled(true)
    }
    if (userHasCard && !userHasSubscription) {
      setIsMonthly(false)
      setIsBasicDisabled(true)
      setIsPremiumDisabled(false)
      return setIsPerTransaction(true)
    }
    if (userHasCard && userHasSubscription) {
      setIsPerTransaction(false)
      setIsBasicDisabled(false)
      setIsPremiumDisabled(true)
      return setIsMonthly(true)
    }
    if (!userHasCard && userHasSubscription) {
      setIsPerTransaction(false)
      return setIsMonthly(true)
    }
  }, [userHasCard, userHasSubscription])
  const handleSelectPerTransaction = (event) => {
    event.preventDefault()
    if (isMonthly) {
      setIsMonthly(false)
    }
    if (!isPerTransaction) {
      return setIsPerTransaction(true)
    }
    return setIsDialogOpen(true)
  }
  const handleSelectMonthly = (event) => {
    event.preventDefault()
    if (isPerTransaction) {
      setIsPerTransaction(false)
    }
    if (!isMonthly) {
      return setIsMonthly(true)
    }
    return setIsDialogOpen(true)
  }
  const handleUnsubscribe = async () => {
    try {
      setIsSubscribing(true)
      await unsubscribe()
      await fetchSubscription()
      toast.success(t('payPerItem'))
    } catch (error) {
      toast.dismiss()
      if (error.response.data.errors) {
        error.response.data.errors.map(item => toast.error(t(item)))
      } else {
        toast.error(
          error.response && error.response.data.message
            ? t(error.response.data.message)
            : t('An error occurred. Try again later.'),
        )
      }
    } finally {
      setIsSubscribing(false)
      setIsDialogOpen(false)
    }
  }
  const handleSubscribe = async () => {
    try {
      setIsSubscribing(true)
      const subs = await subscribe()
      toast.success(t('monthlySubs'))
      setSubscription(subs)
    } catch (error) {
      toast.dismiss()
      if (error.response.data.errors) {
        error.response.data.errors.map(item => toast.error(item))
      } else {
        toast.error(
          error.response && error.response.data.message
            ? error.response.data.message
            : t('An error occurred. Try again later.'),
        )
      }
    } finally {
      setIsSubscribing(false)
      setIsDialogOpen(false)
    }
  }
  const handleSubmit = () => {
    if (isMonthly) {
      return handleSubscribe()
    }
    return handleUnsubscribe()
  }
  return (
    <Grid container>
      <Subscription item xlg={6} md={12} lg={6} xs={12}>
        <SubscriptionCard isSelected={isPerTransaction || isRenewable}>
          <h1>{t('per transaction')}</h1>
          <p />
          <img src={perTransaction} height="60" width="93" alt="shake hands" />
          <div>
            <p>{t('With this option you will pay')}</p>
            <p>{t('tabpayment2')}</p>
          </div>
          <button type="button" onClick={handleSelectPerTransaction} disabled={isBasicDisabled || isRenewable}>
            {isPerTransaction ? t('Finish') : t('Select')}
          </button>
        </SubscriptionCard>
      </Subscription>
      <Subscription item xlg={6} md={12} lg={6} xs={12}>
        <SubscriptionCard
          isRenewable={isRenewable}
          isSelected={isMonthly}
        >
          <h1>{t('monthly Subscription')}</h1>
          <p>{t('Unlimited Monthly Trades')}</p>
          <img src={monthly} height="70" width="92" alt="calendar" />
          <div>
            <p>{t('On this option you will pay')}</p>
            <p>{t('tabpayment1')}</p>
            <p>{t('You will pay for shipping the moment')}</p>
            <p>{t('the transaction is accepted')}</p>
          </div>
          <button type="button" onClick={handleSelectMonthly} disabled={isPremiumDisabled && !isRenewable}>
            {cancel_at_period_end ? t('Renew') : isMonthly ? t('Finish') : t('Select')}
          </button>
        </SubscriptionCard>
      </Subscription>
      <WarningDialog
        isOpen={isDialogOpen}
        handleClose={() => setIsDialogOpen(false)}
        isLoading={isSubscribing}
        handleSubmit={handleSubmit}
      />
    </Grid>
  )
}
