export const initialStateSearchResults = {
  data: [],
  loading: false,
  searchTerm: '',
}

export const initialStateHiddenContent = ''

export const reducerSearchBar = (state, action) => {
  switch (action.type) {
    case 'SEARCH_RESULTS_CLEAR_DATA':
      return { loading: false, data: [], searchTerm: '' }
    case 'SEARCH_RESULTS_FETCH':
      return { ...state, loading: true, searchTerm: action.searchTerm }
    case 'SEARCH_RESULTS_FETCH_SUCCESS':
      return {
        ...state,
        loading: false,
        data: action.payload,
      }
    case 'SEARCH_RESULTS_FETCH_ERROR':
      return { ...state, loading: false }
    default:
      return state
  }
}

export const reducerHiddenContent = (state, action) => {
  switch (action.type) {
    case 'SHOW_A_HIDDEN_CONTENT':
      return action.contentToShow
    case 'HIDE_THE_HIDDEN_CONTENT':
      return ''
    default:
      return state
  }
}
