import styled from 'styled-components'
import { Wrapper } from '../../../ui'

export const GlobalEmptyState = styled(Wrapper)`
  height: 100%;
  display: flex;
  flex-direction: ${props => (props.isLg ? 'row' : 'column')};
  align-items: center;
  justify-content: center;
  text-align: ${props => (props.isLg ? 'left' : 'center')};
  color: ${props => props.theme.colorSecondary};
  padding-top: ${props => (props.isLg ? `${props.theme.spacing * 4}px` : `${props.theme.spacing}px`)};
  padding-bottom: ${props => props.theme.spacing * 4}px;
  padding-left: ${props => props.theme.spacing * 2}px;
  padding-right: ${props => props.theme.spacing * 2}px;
`

export const Column = styled.div`
  :first-child {
    margin-right: ${props => (props.isLg ? `${props.theme.spacing}px` : 'initial')};
  }
`

export const Title = styled.h2`
  font-size: ${props => props.theme.fontSizes.heading1};
  font-weight: bold;
  margin-bottom: ${props => props.theme.spacing}px;

  strong {
    color: ${props => props.theme.colorPrimaryFeatured};
    font-style: italic;
  }
`
