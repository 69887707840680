import styled, { keyframes } from 'styled-components'
import { Wrapper } from '../../ui'
import { getZindex } from '../../../helpers'

const SHOW_GLOBAL_CONTENT = keyframes`
  from {
    transform: translateY(-100%);
  }

  to {
    transform: translateY(0);
  }
`

export const GlobalContent = styled(Wrapper)`
  animation: ${SHOW_GLOBAL_CONTENT} 100ms ease-out;
  width: 100%;
  padding: 0;
  max-height: ${props => (props.isLg ? '60%' : '100%')};
  overflow-y: auto;
  z-index: ${getZindex('default')};
  position: relative;
  box-shadow: 0 10px 20px #000;
  background-color: #1e354c;
`
