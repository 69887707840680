import React from 'react'
import { string, arrayOf, shape } from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useMoment } from '../../../hooks'
import { Table } from './styles'

const TrackingTable = ({ trackingHistory }) => {

  const { t } = useTranslation()
  const moment = useMoment()
  const renderHistory = () => trackingHistory.map(
    ({
      object_id, status_date, status_details, status
    }) => (
      <tr key={object_id}>
        <td>{moment(status_date).format('MMM DD')}</td>
        <td>{status_details}</td>
        <td>
          {status}
        </td>
      </tr>
    ),
  )
  return (
    <Table>
      <div>
        <tr>
          <th>{t('DATE')}</th>
          <th>{t('TRACKING INFORMATION')}</th>
          <th>{t('STATUS')}</th>
        </tr>
        {renderHistory()}
      </div>
    </Table>
  )
}

TrackingTable.propTypes = {
  trackingHistory: arrayOf(
    shape({
      object_id: string,
      timestamp: string,
      details: string,
      location: shape({
        city: string,
        stack: string,
      }),
    }),
  ),
}

export default TrackingTable
