import React from 'react'
import { useTranslation } from 'react-i18next'
import { Dialog } from 'evergreen-ui'
import { DialogContent, DialogFooter, DialogHeader } from '../styles'
import { Button } from '../../../../commons'

export const DeleteCardDialog = ({
  isOpen,
  handleClose,
  handleAgree,
  isLoading,
}) => {
  const { t } = useTranslation()
  return (
    <Dialog
      isShown={isOpen}
      hasFooter={false}
      hasHeader={false}
      width={566}
      onCloseComplete={handleClose}
      contentContainerProps={{ padding: 0 }}
      topOffset="30vh"
    >
      <DialogHeader>
        <h1>{t('Warning')}</h1>
      </DialogHeader>
      <DialogContent>
        <p>
          {t(
            'You are about to delete your media, are you sure you want to do that?',
          )}
        </p>
      </DialogContent>
      <DialogFooter>
        <Button onClick={handleClose} variation="danger">
          {t('Cancel')}
        </Button>
        <Button variation="success" onClick={handleAgree} isLoading={isLoading}>
          {t('Agree')}
        </Button>
      </DialogFooter>
    </Dialog>
  )
}
