import React from 'react'
import { useTranslation } from 'react-i18next'
import Grid from '../Grid'
import { Content } from './styles'
import { noMedia } from '../../../assets'

const NoMedia = () => {
  const { t } = useTranslation()

  return (
    <Grid container justify="center">
      <Content>
        <img src={noMedia} alt="no-media" />
        <p>
          {t('This user')}
          {' '}
          <span>{t('doesn`t have')}</span>
          {' '}
          {t('any media')}
        </p>
      </Content>
    </Grid>
  )
}

export default NoMedia
