import React from 'react'

import { useStore } from '../../../../../store'
import Tracking from './Tracking'
import TransactionChat from './TransactionChat'

const TrackingOrChat = ({ state, dispatch }) => {
  const { from, to, _id, updatedAt, status } = state
  const { user: { data: { id } } } = useStore()
  const myTrade = from.user.id === id ? from : to
  const hisTrade = from.user.id !== id ? from : to
  if (myTrade.shipping.method === 'delivery'
      && hisTrade.shipping.method === 'delivery') {
    return (
      <Tracking
        dispatch={dispatch}
        hisTrade={hisTrade}
        myTrade={myTrade}
        tradeId={_id}
        lastUpdate={updatedAt}
      />
    )
  }
  return (
    <TransactionChat
      dispatch={dispatch}
      tradeId={_id}
      myTrade={myTrade}
      tradeStatus={status}
      hisTrade={hisTrade}
    />
  )
}

export default TrackingOrChat
