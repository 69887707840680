import styled, { css } from 'styled-components'
import { getZindex } from '../../../helpers'

const getColorsByValue = (props) => {
  let color = props.theme.colorPositive

  if (props.value === 0){
    color = props.theme.colorTertiary
  }

  if (props.value === 50){
    color = '#0075FF'
  }

  return color
}

export const GlobalMediaCondition = styled.div`
  margin-bottom: ${props => (props.noMargin ? '0' : props.theme.spacing * 3)}px;
`

export const GlobalRange = styled.div`
  position: relative;
`

export const GlobalInputRange = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 0 calc((40px - 16px) / 2);
`

export const GlobalPointer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`

export const Pointer = styled.div`
  color: ${props => props.theme.colorSecondary};
  text-align: center;
  width: 40px;
  margin-top: 2px;
  cursor: ${props => (props.hasCallback ? 'pointer' : 'initial')};
  z-index: ${getZindex('default')};

  :nth-child(2) {
    width: 80px;
  }

  :before {
    box-sizing: border-box;
    display: block;
    margin: 0 auto;
    content: '';
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: 1px solid;
    margin-bottom: ${props => props.theme.spacing}px;
  }

  :after {
    content: '';
    width: 100%;
    height: 2px;
    display: block;
  }

  :nth-child(1) {
    :before {
      border-color: ${props => (props.value === 0 ? 'transparent' : props.theme.colorSecondary)};
    }

    :after {
      background-color: ${props => (props.value === 0 ? props.theme.colorTertiary : 'transparent')};
    }
  }

  :nth-child(2) {
    :before {
      border-color: ${props => (props.value === 50 ? 'transparent' : props.theme.colorSecondary)};
    }

    :after {
      background-color: ${props => (props.value === 50 ? '#0075FF' : 'transparent')};
    }
  }

  :last-child {
    :before {
      border-color: ${props => (props.value === 100 ? 'transparent' : props.theme.colorSecondary)};
    }

    :after {
      background-color: ${props => (props.value === 100 ? props.theme.colorPositive : 'transparent')};
    }
  }
`

const baseThumb = css`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: ${props => getColorsByValue(props)};
`

const baseEffect = css`
  content: '';
  position: absolute;
  top: -6px;
  height: 4px;
  transform: translateY(-50%);
`

export const InputRange = styled.input.attrs({ type: 'range' })`
  /* margin-top: 100px; */
  position: relative;

  /* Base CSS styles */
  -webkit-appearance: none; /* Hides the slider so that custom slider can be made */
  width: 100%; /* Specific width is required for Firefox. */
  background: transparent; /* Otherwise white in Chrome */

  ::-webkit-slider-thumb {
    -webkit-appearance: none;
  }

  :focus {
    outline: none; /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
  }

  ::-ms-track {
    width: 100%;
    cursor: pointer;

    /* Hides the slider so custom styles can be added */
    background: transparent;
    border-color: transparent;
    color: transparent;
  }
  /* End base CSS styles */

  /* Thumb style */
  ::-webkit-slider-thumb {
    ${baseThumb}
  }

  ::-moz-range-thumb {
    ${baseThumb}
  }

  ::-ms-thumb {
    ${baseThumb}
  }

  /* Special styling for WebKit/Blink */
  ::-webkit-slider-thumb {
    -webkit-appearance: none;
    margin-top: -14px; /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */
  }

  &:after {
    ${baseEffect};
    display: ${props => (props.value === 100 || props.value === 50 ? 'block' : 'none')};
    width: ${props => `${props.value}%`};
    background: ${props => props.value === 100 ? `linear-gradient(to right, #01a85900 0%, ${
    props.theme.colorPositive
  } 100%)` : `linear-gradient(to right, #0075ff 0%, #0075ff00 100%)`};
    right: ${props => props.value === 50 ? 0 : 'auto'};
  }

  &::before {
    ${baseEffect};
    display: ${props => (props.value === 0 || props.value === 50 ? 'block' : 'none')};
    width: ${props => `${100 - props.value}%`};
    background: ${props => props.value === 0 ? `linear-gradient(to right, ${
    props.theme.colorTertiary
  } 0%, #ff660100 100%)` : `linear-gradient(to right, #0075ff00 0%, #0075FF 100%)`};
    left: 0;
  }
`
