import styled from 'styled-components'

export const GlobalSelectImage = styled.label`
  width: auto;
  max-width: auto;
`

export const GlobalContent = styled.div`
  display: flex;
  align-items: flex-end;
`

export const HiddenInput = styled.input.attrs({ type: 'file' })`
  display: none;
`

export const GlobalButton = styled.div`
  margin-left: ${props => props.theme.spacing * 2}px;
`

export const ChangePhoto = styled.div`
  opacity: 0;
  background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
  color: #fff;
  transition: opacity 200ms;
`

export const Picture = styled.div`
  height: 110px;
  width: 100px;
  background-color: ${props => (props.error ? props.theme.colorNegative : '#0b1625')};
  border-radius: 5px;
  display: inline-flex;
  font-size: 12px;
  text-align: center;
  color: #547384;
  line-height: 6px;
  font-weight: bold;
  background-image: url(${props => props.src});
  background-size: cover;
  background-position: center;
  cursor: pointer;

  :hover ${ChangePhoto} {
    opacity: 1;
  }
`

export const GlobalStates = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`
