import React from 'react'
import { shape, func } from 'prop-types'
import { Rating, BrandBadge } from '..'
import {
  Item,
  MediaImage,
  MediaInfo,
  MediaTitleContainer,
  UserInfo,
  MediaTitle,
  MediaReleaseDate,
  Avatar,
  UserName,
} from './styles'

const SearchItem = (props) => {
  const {
    data: {
      thumb, title, releaseDate, platform, user,
    },
    onClick,
  } = props

  return (
    <Item onClick={onClick}>
      <MediaImage image={thumb} />
      <MediaInfo>
        <MediaTitleContainer>
          <MediaTitle>{title}</MediaTitle>
          <MediaReleaseDate>{releaseDate}</MediaReleaseDate>
        </MediaTitleContainer>

        {user && (
          <UserInfo>
            <Avatar src={user.avatar} />
            <UserName>{user.username}</UserName>
            <Rating rate={user.rating} size={10} />
          </UserInfo>
        )}
      </MediaInfo>

      {platform && <BrandBadge platform={platform} />}
    </Item>
  )
}

SearchItem.propTypes = {
  data: shape({}).isRequired,
  onClick: func,
}

SearchItem.defaultProps = {
  onClick: null,
}

export default SearchItem
