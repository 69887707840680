import styled from 'styled-components'
import { bool, number, oneOf } from 'prop-types'

function calcString(column) {
  return `${(100 / 12) * column}%`
}

const Grid = styled.div`
  ${props => props.container
    && `
      width: 100%;
      display: flex;
      flex: 0 1 auto;
      flex-direction: row;
      flex-wrap: wrap;
      margin: -${!props.noMargin ? props.spacing : 0}px;
      justify-content: ${props.justify};
  `}

  ${props => props.item
    && `
      flex: 0 0 auto;
      padding: ${props.spacing}px;
      flex-basis: ${calcString(props.xs)};
      max-width: ${calcString(props.xs)};
    `}

  ${props => props.item
    && Object.keys(props.theme.breakpoints).map(
      keyBreakpoint => props[keyBreakpoint]
        && `
          @media (min-width: ${props.theme.breakpoints[keyBreakpoint]}px) {
            flex-basis: ${calcString(props[keyBreakpoint])};
            max-width: ${calcString(props[keyBreakpoint])};
          }
        `,
    )}
`

Grid.propTypes = {
  container: bool,
  item: bool,
  justify: oneOf([
    'flex-start',
    'flex-end',
    'center',
    'space-between',
    'space-around',
    'space-evenly',
  ]),
  spacing: number,
  xs: number,
  md: number,
  lg: number,
  xlg: number,
  noMargin: bool,
}

Grid.defaultProps = {
  container: false,
  item: false,
  justify: 'flex-start',
  spacing: 8,
  xs: 12,
  noMargin: false,
}

export default Grid
