import React, { useState, useEffect } from 'react'
import { isEqual } from 'lodash'

import { useTranslation } from 'react-i18next'
import {
  Container, Content, Step, Steps, Title, WarningText,
} from './styles'
import { WarningDialog } from './Dialog'
import { Button } from '../../../commons'

export const Wizard = ({
  entries,
  handleSubmit,
  currentPage,
  handleChangeCurrentPage,
  isLoading,
  form,
  state,
}) => {
  const [alreadyVisitedPage, setAlreadyVisitedPage] = useState([])
  const [whereToGo, setWhereToGo] = useState(undefined)
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [isSkipDialogOpen, setIsSkipDialogOpen] = useState(false)
  const { t } = useTranslation()
  const stepBackground = {
    active: 'linear-gradient(360deg, #E69814 0%, #F9C040 83.93%)',
    checked: 'linear-gradient(180deg, #4CD964 0%, #0C9D25 93.75%)',
    inactive: '#253F58',
  }
  const equalityMapper = {
    0: {
      prev: {
        address: {
          streetAddress1: state.address ? state.address.streetAddress1 : '',
          streetAddress2: state.address ? state.address.streetAddress2 : '',
          state: state.address ? state.address.state : '',
          city: state.address ? state.address.city : '',
          zipcode: state.address ? state.address.zipcode : '',
        },
        secondaryAddress: {
          streetAddress1: state.secondaryAddress ? state.secondaryAddress.streetAddress1 : '',
          streetAddress2: state.secondaryAddress ? state.secondaryAddress.streetAddress2 : '',
          state: state.secondaryAddress ? state.secondaryAddress.state : '',
          city: state.secondaryAddress ? state.secondaryAddress.city : '',
          zipcode: state.secondaryAddress ? state.secondaryAddress.zipcode : '',
        },
        billingSameAsShipping: state.billingSameAsShipping,
      },
      current: {
        address: { ...form.address },
        secondaryAddress: { ...form.secondaryAddress },
        billingSameAsShipping: form.billingSameAsShipping,
      },
    },
    1: {
      prev: {
        billingAddress: {
          streetAddress1: state.billingAddress ? state.billingAddress.streetAddress1 : '',
          streetAddress2: state.billingAddress ? state.billingAddress.streetAddress2 : '',
          state: state.billingAddress ? state.billingAddress.state : '',
          city: state.billingAddress ? state.billingAddress.city : '',
          zipcode: state.billingAddress ? state.billingAddress.zipcode : '',
        },
        cardToken: state.cardToken,
      },
      current: {
        billingAddress: {
          streetAddress1: form.billingAddress ? form.billingAddress.streetAddress1 : '',
          streetAddress2: form.billingAddress ? form.billingAddress.streetAddress2 : '',
          state: form.billingAddress ? form.billingAddress.state : '',
          city: form.billingAddress ? form.billingAddress.city : '',
          zipcode: form.billingAddress ? form.billingAddress.zipcode : '',
        },
        cardToken: form.cardToken,
      },
    },
    2: {
      prev: {
        subscriptionType: state.subscriptionType ? state.subscriptionType : '',
      },
      current: {
        subscriptionType: form.subscriptionType,
      },
    },
  }
  const isValidNavigation = navigatingTo => (currentPage !== navigatingTo && alreadyVisitedPage.includes(navigatingTo))

  const isSafeToNavigate = isEqual(
    equalityMapper[currentPage].prev,
    equalityMapper[currentPage].current,
  )

  const handleClickOnStep = (stepIndex) => {
    if (isValidNavigation(stepIndex)) {
      setWhereToGo(stepIndex)
    }
    if (isValidNavigation(stepIndex) && isSafeToNavigate) {
      return handleChangeCurrentPage(stepIndex)
    }
    if (isValidNavigation(stepIndex) && !isSafeToNavigate) {
      return setIsDialogOpen(true)
    }
  }
  const handleDialogAgree = (event) => {
    event.preventDefault()
    handleChangeCurrentPage(whereToGo)
    setIsDialogOpen(false)
  }
  const getBackgroundColor = (index) => {
    if (index === currentPage) {
      return stepBackground.active
    }
    if (alreadyVisitedPage.includes(index)) {
      return stepBackground.checked
    }
  }
  useEffect(() => {
    if (!alreadyVisitedPage.includes(currentPage)) {
      setAlreadyVisitedPage([...alreadyVisitedPage, currentPage])
    }
  }, [currentPage])
  return (
    <Container>
      <Steps>
        <Step
          onClick={() => handleClickOnStep(0, state.address, form.address)}
          background={getBackgroundColor(0)}
        >
          <p>address</p>
          <div />
        </Step>
        <Step
          onClick={() => handleClickOnStep(1)}
          background={getBackgroundColor(1)}
        >
          <p>payment</p>
          <div />
        </Step>
        <Step
          onClick={() => handleClickOnStep(2)}
          background={getBackgroundColor(2)}
        >
          <p>subscription</p>
          <div />
        </Step>
        {currentPage !== 2 &&
          <span>
            <Button
              onClick={() => setIsSkipDialogOpen(true)}
              variation="secondary"
            >
              Skip
            </Button>
          </span>
        }

      </Steps>
      <Content>
        <Title>{entries[currentPage].title}</Title>
        <WarningText>{t('You may skip this step for now, but you will not be able to trade later on.')}</WarningText>
        {entries[currentPage].component}
      </Content>
      <WarningDialog
        isOpen={isDialogOpen}
        handleClose={() => setIsDialogOpen(false)}
        handleSubmit={handleDialogAgree}
      />
      <WarningDialog
        isOpen={isSkipDialogOpen}
        handleClose={() => setIsSkipDialogOpen(false)}
        handleSubmit={handleSubmit}
        isLoading={isLoading}
      />
    </Container>
  )
}
