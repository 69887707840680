import { createGlobalStyle } from 'styled-components'

const globalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css?family=Titillium+Web:200,200i,300,300i,400,400i,600,600i,700,700i,900&subset=latin-ext');

  * {
    box-sizing: border-box;
    font-family: 'Titillium Web';
    margin: 0;
    padding: 0;
    border: none;
    outline: none;
    box-sizing: border-box;

    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-track {
      background-color: #0C1726;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 45px;
      background-color: #09577A;
    }
  }

  html, body, #root {
    width: 100%;
    height: 100%;
  }

  body {
    margin: 0;
    background-color: #0d2032;
    color: ${props => props.theme.colorSecondary};
    font-size: ${props => props.theme.fontSizes.default};
  }

  article, aside, figcaption, figure, footer, header, main, nav, section {
    display: block;
  }

  a {
    color: inherit;
  }

  ol, ul {
    list-style: none;
  }

  input, textarea, select, button {
    font-size: inherit;
    font-family: inherit;
  }

  button {
    background: transparent;
  }

  h1, h2, h3, h4, h5, h6 {
    font-size: inherit;
    font-weight: normal;
  }

  .StripeElement {
    box-sizing: border-box;
    height: 40px;
    padding: 10px 12px;
    border: 1px solid transparent;
    border-radius: 4px;
    background-color: #253F58;
    -webkit-transition: box-shadow 150ms ease;
    transition: box-shadow 150ms ease;
  }
  .📦bs_17sc08g {
    box-shadow: none !important;
  }

  .📦bg-clr_white {
    background: #0B1625 !important;
  }

  .📦bs_kymczs {
    box-shadow: none !important;
  }

  .slick-track {
    margin: 0 !important;
  }

  select option {
    color: #000;
  }
`

export default globalStyle
