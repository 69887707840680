import {
  filterBooks, filterGames, filterMovies, filterMusics,
} from './assets'

export const SELECT_OPTIONS = [
  { key: 'ALL', label: 'All', color: 'linear-gradient(#106594,#0B1F30)' },
  { key: 'GAME', label: 'Games', color: 'linear-gradient(#1690B1,#0C5B8D)' },
  { key: 'MOVIE', label: 'Movies', color: 'linear-gradient(#E8612E,#AA3C15)' },
  { key: 'BOOK', label: 'Books', color: 'linear-gradient(#176E10,#0B3509)' },
  { key: 'MUSIC', label: 'Music', color: 'linear-gradient(#8E0E19,#47010A)' },
]

export const FILTER_IMAGES = {
  ALL: [
    { key: 'GAME', image: filterGames, color: '#0BA0BE' },
    { key: 'MOVIE', image: filterMovies, color: '#EE773B' },
    { key: 'BOOK', image: filterBooks, color: '#147E14' },
    { key: 'MUSIC', image: filterMusics, color: '#A11D21' },
  ],
  GAME: [{ key: 'GAME', image: filterGames, color: '#0BA0BE' }],
  MOVIE: [{ key: 'MOVIE', image: filterMovies, color: '#EE773B' }],
  BOOK: [{ key: 'BOOK', image: filterBooks, color: '#147E14' }],
  MUSIC: [{ key: 'MUSIC', image: filterMusics, color: '#A11D21' }],
}
