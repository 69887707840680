import React, { useState, useEffect } from 'react'
import { Spinner } from 'evergreen-ui'

import {
  Container, Content, Step, Steps, Title,
} from './styles'
import { WarningDialog } from './Dialog'
import { updateTradeStatus } from '../store'
import { useStore } from '../../../../store'

const PENDING_SHIPPING = 'pending-shipping-method'
const SHIPPING_METHOD_SELECTED = 'shipping-method-selected'
const SHIPPING_REVOKED = 'shipping-method-changed'
const SHIPPING_COMPLETED = 'completed'
const TRADE_REVIEWED = 'reviewed'
const TRADE_REPORTED = 'reported'

export const Wizard = ({ entries, state, dispatch }) => {
  const {
    user: {
      data: { id },
    },
  } = useStore()
  useEffect(() => {
    if (!isLoading) {
      const { from, to } = state
      const myTrade = from.user.id === id ? from : to
      const hisTrade = from.user.id !== id ? from : to
      if (myTrade.status === PENDING_SHIPPING) {
        return dispatch(updateTradeStatus(0))
      }
      if (
        (myTrade.status === SHIPPING_METHOD_SELECTED
          && (hisTrade.status === PENDING_SHIPPING
            || hisTrade.status === SHIPPING_REVOKED))
        || (myTrade.status === SHIPPING_REVOKED
          && hisTrade.status === SHIPPING_METHOD_SELECTED)
      ) {
        return dispatch(updateTradeStatus(1))
      }
      if (
        myTrade.shipping.method === 'delivery'
        && !myTrade.shipping.transactionId
      ) {
        return dispatch(updateTradeStatus(1))
      }
      if (
        myTrade.status === SHIPPING_METHOD_SELECTED
        && (hisTrade.status === SHIPPING_METHOD_SELECTED
          || hisTrade.status === TRADE_REVIEWED)
      ) {
        setAlreadyVisitedPage([...alreadyVisitedPage, 0, 1])
        return dispatch(updateTradeStatus(2))
      }
      if (
        from.status === SHIPPING_COMPLETED
        && to.status === SHIPPING_COMPLETED
      ) {
        setAlreadyVisitedPage([...alreadyVisitedPage, 0, 1, 2])
        return dispatch(updateTradeStatus(3))
      }
      if (myTrade.status === TRADE_REVIEWED || myTrade.status === TRADE_REPORTED) {
        setAlreadyVisitedPage([...alreadyVisitedPage, 0, 1, 2, 3])
        return dispatch(updateTradeStatus(4))
      }
    }
  }, [state.from.status, state.to.status])
  const { isLoading, currentTradeStatus } = state
  const [alreadyVisitedPage, setAlreadyVisitedPage] = useState([])
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [isSkipDialogOpen, setIsSkipDialogOpen] = useState(false)
  const stepBackground = {
    active: 'linear-gradient(360deg, #E69814 0%, #F9C040 83.93%)',
    checked: 'linear-gradient(180deg, #4CD964 0%, #0C9D25 93.75%)',
    inactive: '#253F58',
  }
  const isValidNavigation = navigatingTo => currentTradeStatus !== navigatingTo
    && alreadyVisitedPage.includes(navigatingTo)

  const handleClickOnStep = (stepIndex) => {
    if (isValidNavigation(stepIndex)) {
      dispatch(updateTradeStatus(stepIndex))
    }
  }

  const getBackgroundColor = (index) => {
    if (index === currentTradeStatus) {
      return stepBackground.active
    }
    if (alreadyVisitedPage.includes(index)) {
      return stepBackground.checked
    }
  }
  useEffect(() => {
    if (!alreadyVisitedPage.includes(currentTradeStatus)) {
      setAlreadyVisitedPage([...alreadyVisitedPage, currentTradeStatus])
    }
  }, [currentTradeStatus])

  const renderContent = () => (isLoading ? (
    <Spinner marginX="auto" marginY="auto" />
  ) : (
    <Content>
      <Title>{entries[currentTradeStatus].title}</Title>
      {entries[currentTradeStatus].component}
    </Content>
  ))
  return (
    <Container>
      <Steps>
        {entries.map(({ label }, index) => (
          <Step
            key={label}
            onClick={() => handleClickOnStep(index)}
            background={getBackgroundColor(index)}
          >
            <p>{label}</p>
            <div />
          </Step>
        ))}
      </Steps>
      {renderContent()}
      <WarningDialog
        isOpen={isDialogOpen}
        handleClose={() => setIsDialogOpen(false)}
      />
      <WarningDialog
        isOpen={isSkipDialogOpen}
        handleClose={() => setIsSkipDialogOpen(false)}
        isLoading={isLoading}
      />
    </Container>
  )
}
