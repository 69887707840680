import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import games from '../../../assets/howitworks-bg.jpg'
import { DefaultTemplateBackground } from '../../templates/DefaultTemplate'
import {
  DefaultPageBox,
  DefaultPageFadeBackground,
  DefaultPageNewBackground,
  DefaultPageTitle,
  DefaultPageNewBoxHeader,
  DefaultPageWrapper,
  DefaultPageBoxContent,
} from '../../templates/DefaultPage'
import { Video, Grid, Meta } from '../../commons'
import { SubTitle, PageSection, Title } from './styles'

const HowItWorks = () => {
  const { t } = useTranslation()

  const metaDescription = 'New Used Media is a fully dedicated trading platform.' +
    'Our main purpose is to connect our members to other members who may be' +
    'across the country.'

  return (
    <DefaultTemplateBackground bottom>
      <Meta metaDescription={metaDescription} />
      <DefaultPageNewBackground src={games} />
      <DefaultPageFadeBackground />

      <DefaultPageWrapper>
        <DefaultPageTitle>{t('How it works')}</DefaultPageTitle>

        <DefaultPageBox>
          <DefaultPageNewBoxHeader />

          <DefaultPageBoxContent>
            <Grid container justify="center" spacing={16}>
              <Grid item spacing={16} md={6}>
                <PageSection>
                  <Title>{t('WHAT IS NUM?')}</Title>

                  <p>
                    {t(
                      'howItWorks1',
                    )}
                  </p>
                </PageSection>

                <PageSection>
                  <Title>{t('IS THERE A CATCH?')}</Title>

                  <p>
                    {t(
                      'isThereAcatch',
                    )}
                  </p>
                </PageSection>

                <PageSection>
                  <Title>{t('SO, WHAT CAN YOU TRADE?')}</Title>

                  <p>
                    {t(
                      'howItWorks2',
                    )}
                  </p>
                </PageSection>

                <PageSection>
                  <Title>{t('PRICE?')}</Title>

                  <p>
                    {t(
                      'priceFree',
                    )}
                  </p>
                  <p>
                    {t(
                      'priceFreeToBrowse',
                    )}
                  </p>
                  <p>
                    {t(
                      'priceSingle',
                    )}
                  </p>
                  <p>
                    {t(
                      'priceUnlimited',
                    )}
                  </p>
                </PageSection>

                <PageSection>
                  <Title>{t('HOW TO BEGIN')}</Title>
                  <p style={{ marginBottom: 8 }}>{t('howToBegin1')}
                    <Link style={{ color: "#fff" }} to="/signup">{t('createYourAccount')}</Link></p>

                  <p style={{ marginBottom: 8 }}>{t('howToBegin2')}
                    <a href="https://www.youtube.com/watch?v=j8cx47FPOGI&feature=youtu.be" target="_blank" style={{ color: "#fff" }} >{t('howToUpload')}</a></p>

                  <p style={{ marginBottom: 8 }}>{t('howToBegin3')}
                    <Link style={{ color: "blue" }} to="/add-media">{t('uploadYourMedia')}</Link></p>
                  <p style={{ marginBottom: 8 }}>{t('howToBegin4')}</p>
                </PageSection>

              </Grid>

              <Grid item spacing={16} md={6}>
                <PageSection>
                  <Title>{t('HEY THERE!')}</Title>

                  <Video src="https://www.youtube.com/embed/_yLG84w22W0" />

                </PageSection>

                <PageSection>
                  <Title>{t('SOME FEATURES')}</Title>

                  <SubTitle>{t('SEARCH LOCAL')}</SubTitle>

                  <p>
                    {t(
                      'howItWorks14',
                    )}
                  </p>

                  <SubTitle>{t('INCREASE MY CHANCES')}</SubTitle>

                  <p>
                    {t(
                      'howItWorks16',
                    )}
                  </p>

                  <SubTitle>{t('SEARCH BAR')}</SubTitle>

                  <p>
                    {t(
                      'howItWorks17',
                    )}
                  </p>
                </PageSection>

                <PageSection>
                  <Title>{t('TRADE NUDGE')}</Title>

                  <p>
                    {t(
                      'tradeNudge1',
                    )}

                  </p>
                  <br />
                  <p>
                    {t(
                      'tradeNudge2',
                    )}
                  </p>
                </PageSection>
              </Grid>
            </Grid>
          </DefaultPageBoxContent>
        </DefaultPageBox>
      </DefaultPageWrapper>
    </DefaultTemplateBackground>
  )
}

export default HowItWorks
