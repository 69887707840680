import React, { useState, useEffect } from 'react'

import api from '../../../../../../services/api'
import { updateTrade } from '../../../store'
import { Header, Body, Form } from './styles'
import {
  Modal,
  ModalContent,
  Avatar,
  Select,
  TextArea,
  UploadPicture,
  Button,
} from '../../../../../commons'
import { euaFlag } from '../../../../../../assets'
import { useRouter } from '../../../../../../hooks'

const ReportModal = ({
  handleClose, user, isOpen, handleReport, dispatch,
}) => {
  const { history } = useRouter()
  const [options, setOptions] = useState([])
  const [reason, setReason] = useState('')
  const [description, setDescription] = useState('')
  const [image, setImage] = useState('')

  const handleSubmit = async (event) => {
    event.preventDefault()

    const { trade } = await handleReport(reason, description, image)
    await dispatch(updateTrade(trade))
    handleClose()
    history.push('/')
  }

  const { avatar, displayName, cityLocation } = user

  async function fetchOptions() {
    try {
      const res = await api.get('report/reasons')
      setOptions(res.data)
    } catch (error) {
      setOptions([])
    }
  }

  useEffect(() => {
    fetchOptions()
  }, [])

  return (
    <Modal
      open={isOpen}
      handleClose={handleClose}
      width="600"
      headerHeight="40"
    >
      <ModalContent headerHeight="40">
        <Header>
          <Avatar src={avatar} alt="avatar" size="large" />
          <div className="column">
            <span className="username">{displayName}</span>
            <div className="row location">
              <img src={euaFlag} alt="flag" />
              <span>
                {cityLocation ? cityLocation.state : ''}
,
                {' '}
                {cityLocation ? cityLocation.city : ''}
              </span>
            </div>
          </div>
        </Header>
        <Body>
          <Form id="reportsubmit" onSubmit={handleSubmit}>
            <div className="gridtop">
              <Select
                label="Reason"
                form="reportsubmit"
                options={options}
                id="reason"
                value={reason}
                onChange={event => setReason(event.target.value)}
                required
                emptyOption
              />
            </div>
            <div className="gridleft">
              <TextArea
                label="Describe"
                placeholder="Describe your report"
                cols="10"
                rows="5"
                className="left"
                id="description"
                value={description}
                onChange={event => setDescription(event.target.value)}
                required
              />
            </div>
            <div className="gridright">
              <UploadPicture
                onChange={image => setImage(image)}
                label="Photo"
                id="image"
                enableCrop
                aspectToCrop={4 / 4}
              />
            </div>
            <div className="gridfooter">
              <Button type="submit" variation="danger">
                SEND REPORT
              </Button>
            </div>
          </Form>
        </Body>
      </ModalContent>
    </Modal>
  )
}

export default ReportModal
