import styled from 'styled-components'

export const GlobalMessage = styled.div`
  display: flex;
  justify-content: ${props => (props.data.from === props.myUserId ? 'flex-end' : 'flex-start')};
`

export const MessageText = styled.div`
  color: #fff;
  max-width: 70%;
  border-radius: ${props => (props.data.from === props.myUserId ? '10px 0 10px 10px' : '0 10px 10px')};
  background-color: ${props => (props.data.from === props.myUserId ? '#006E9D' : '#033753')};
  padding: ${props => `${props.theme.spacing}px ${props.theme.spacing}px`};
  margin-bottom: ${props => props.theme.spacing}px;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.6);
`
