import styled from "styled-components";

export const DialogHeader = styled.div`
  background: #0B1625;
  width: 100%;
  height: 38px;
  padding-left: 18px;
  border-radius: 5px 5px 0px 0px;
  & > h1 {
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
    padding-top: 4px;
  }
`

export const DialogContent = styled.div`
  background: #0D2032;
  width: 100%;
  height: 163px;
  padding-left: 30px;
  padding-top: 27px;
  font-weight: 300;
  font-size: 18px;
  line-height: 28px;
`

export const DialogFooter = styled.div`
  background: #0B1625;
  width: 100%;
  height: 55px;
  padding: 14px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
    border-radius: 0 0 5px 5px;
`
