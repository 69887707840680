import React from 'react'
import { Dialog, Button } from 'evergreen-ui'
import { useTranslation } from 'react-i18next'
import { DialogContent, DialogFooter, DialogHeader } from './styles'

export const WarningDialog = ({
  isOpen,
  handleClose,
  handleSubmit,
  isLoading,
}) => {
  const { t } = useTranslation()

  return (
    <Dialog
      isShown={isOpen}
      onCloseComplete={handleClose}
      hasFooter={false}
      hasHeader={false}
      width={566}
      contentContainerProps={{ padding: 0 }}
      topOffset="30vh"
    >
      <DialogHeader>
        <h1>{t('Warning')}</h1>
      </DialogHeader>
      <DialogContent>
        <p>
          {t(
            'Please note that when you initiate a transaction we will place a temporary hold on your funds for the transaction costs.',
          )}
        </p>
        <p>
          {t(
            'In the event that the other user rejects your request, we will return the funds to you as soon as possible.',
          )}
        </p>
      </DialogContent>
      <DialogFooter>
        <Button
          marginRight={12}
          intent="danger"
          appearance="primary"
          onClick={handleClose}
        >
          {t('Cancel')}
        </Button>
        <Button
          intent="success"
          appearance="primary"
          onClick={handleSubmit}
          isLoading={isLoading}
        >
          {t('Agree')}
        </Button>
      </DialogFooter>
    </Dialog>
  )
}
