import React from 'react'
import { GlobalFilter, FilterButton } from './styles'

const Filter = (props) => {
  const { options, active, onChange } = props

  return (
    <GlobalFilter>
      {options.map(option => (
        <FilterButton
          key={option.value}
          active={active === option.value}
          onClick={() => onChange(option.value)}
        >
          {option.label}
        </FilterButton>
      ))}
    </GlobalFilter>
  )
}

export default Filter
