import React from 'react'
import { useTranslation } from 'react-i18next'
import { DefaultTemplateBackground } from '../../templates/DefaultTemplate'
import { Grid } from '../../commons'

import notFound from '../../../assets/notfound.svg'

import { ContentWrapper, PageSection, Link } from './styles'

const HowItWorks = () => {
  const { t } = useTranslation()

  return (
    <DefaultTemplateBackground bottom>
      <ContentWrapper topXs="10px" topSm="50px" topLg="100px" topXlg="160px">
        <Grid container justify="center" spacing={16}>
          <Grid item spacing={16} md={6}>
            <PageSection>
              <img src={notFound} alt="not found 404" />
              <h4>
                {t('The page you are looking for does not exist in this site.')}
              </h4>
              <div>
                <Link to="/">{t('Go Home!')}</Link>
              </div>
            </PageSection>
          </Grid>
        </Grid>
      </ContentWrapper>
    </DefaultTemplateBackground>
  )
}

export default HowItWorks
