import React, {
  useEffect, useState, useRef, useLayoutEffect,
} from 'react'
import { string } from 'prop-types'
import socket from 'socket.io-client'

import api from '../../../../../../../services/api'
import { Avatar, Rating } from '../../../../../../commons'
import { TransactionResume } from '../../../components'
import Message from './Message'
import {
  Container,
  ChatHeader,
  ChatBody,
  InputContainer,
  SystemMessage,
} from './styles'

const { REACT_APP_API_URL } = process.env
const io = socket(REACT_APP_API_URL, {
  transports: ['websocket'],
})

const COMPLETED = 'completed'

const Chat = (props) => {
  const {
    tradeId, myTrade, hisTrade, tradeStatus,
  } = props
  const [myMedia] = myTrade.trading
  const [hisMedia] = hisTrade.trading
  const [inputValue, setInputValue] = useState('')
  const [messages, setMessages] = useState([])
  const chatRef = useRef(undefined)
  const { user } = hisTrade

  async function populateMessagesAndConnectToSocket() {
    try {
      const res = await api.get(`trade/messages/${tradeId}`)
      setMessages(res.data)
    } catch (error) {
      setMessages([])
    }
  }

  function sendMessage() {
    if (inputValue.length && tradeStatus !== COMPLETED) {
      if (io.connected === false) {
        io.connect()
      }
      
      io.emit('message', { tradeId, message: inputValue })
      setInputValue('')
    }
  }

  function handleKeyPress(e) {
    if (e.key === 'Enter') {
      sendMessage()
    }
  }

  function scrollToBottom() {
    chatRef.current.scrollTop = chatRef.current.scrollHeight
  }

  useEffect(() => {
    populateMessagesAndConnectToSocket()
  }, [])

  useEffect(() => {
    io.on('message', (data) => {
      if (data.tradeId === tradeId) {
        setMessages(prev => [...prev, data])
      }
    })

    return () => {
      io.off('message')
    }
  }, [])

  useLayoutEffect(() => {
    scrollToBottom()
  }, [messages])

  return (
    <Container>
      <ChatHeader>
        <div className="avatar">
          <Avatar src={user.avatar} alt="avatar" size="medium" />
        </div>
        <div className="row">
          <div className="rating">
            <Rating rate={user.rating} size={14} />
            <p>{user.displayName}</p>
          </div>
          <div className="medias">
            <TransactionResume
              myMedia={myMedia}
              hisMedia={hisMedia}
              showMediaName={false}
            />
          </div>
        </div>
      </ChatHeader>
      <ChatBody ref={chatRef}>
        <SystemMessage>
          Warning: attempting to bypass the system will get you banned for life!
          We reserve the right to monitor your chat communications. You may only
          chat about the items agreed up on in this transaction.
        </SystemMessage>
        {messages.map(message => (
          <Message key={message.id} data={message} myUserId={myTrade.user.id} />
        ))}
      </ChatBody>
      <InputContainer>
        <input
          value={inputValue}
          onChange={e => setInputValue(e.target.value)}
          onKeyPress={handleKeyPress}
          disabled={tradeStatus === COMPLETED}
        />
        <button
          disabled={tradeStatus === COMPLETED}
          type="submit"
          onClick={sendMessage}
        >
          Send
        </button>
      </InputContainer>
    </Container>
  )
}

Chat.propTypes = {
  tradeId: string.isRequired,
}

export default Chat
