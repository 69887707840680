import React, { useEffect, useState } from 'react'
import { Spinner } from 'evergreen-ui'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import { useMoment, useMedia } from '../../../../hooks'
import { euaFlag } from '../../../../assets'
import { useStore } from '../../../../store'
import {
  Avatar,
  Filter,
  Rating,
  MediaCard,
  Grid,
  MediasPopover,
  ViewMode,
  NoMedia,
  MediasFilter,
} from '../../../commons'
import {
  DefaultPageWrapper,
  DefaultPageBox,
  DefaultPageBoxContent,
  DefaultPageNewBackground,
  DefaultPageFadeBackground,
} from '../../../templates/DefaultPage'
import { DefaultTemplateBackground } from '../../../templates/DefaultTemplate'
import { Title } from '../../../ui'
import api from '../../../../services/api'
import './styles.css'

import {
  BoxHeader,
  RatingLabel,
  Header,
  HeaderColumn,
  HeaderColumnChildren,
  UserTitle,
  HeaderLibrary,
  GlobalProfile,
  ProfileSection,
  GlobalTransactions,
  MediaContainer,
} from './styles'
import { Link } from 'react-router-dom'

const UserLibrary = (props) => {
  const { computedMatch } = props
  const { t } = useTranslation()
  const [loadingItems, setLoadingItems] = useState(true)
  const [items, setItems] = useState({})
  const [filters, setFilters] = useState({})
  const [filterActive, setFilterActive] = useState('')
  const [fetchedUser, setFetchedUser] = useState({})
  const [itemsToShow, setItemsToShow] = useState([])
  const { user } = useStore()
  const isSm = useMedia('(min-width: 576px)')
  const moment = useMoment()
  const cardGrid = {
    less: {
      xs: 12,
      sm: 6,
      md: 4,
      lg: 3,
    },
    more: {
      xs: 6,
      sm: 3,
      md: 2,
      lg: 2,
    },
  }
  const [viewMode, setViewMode] = useState('more')
  const [mediaTypesFromLibrary, setMediaTypesFromLibrary] = useState({})

  const fetchUser = async () => {
    try {
      const { data } = await api.get(`/user/profile/${computedMatch.params.id}`)
      setFetchedUser(data)
    } catch (err) {
      console.log(err)
    }
  }

  const fetchLibrary = async () => {
    if (!fetchedUser.id) {
      return
    }
    setLoadingItems(true)

    try {
      const response = await api.post(`/media/user/${fetchedUser.id}`, { limit: '*' })

      setItems(response.data.docs)
      setItemsToShow(response.data.docs)
      setMediaTypesFromLibrary(response.data.mediaTypes)
    } catch (error) {
      toast.dismiss()
      toast.error(
        error.response
          ? error.response.data.error
          : t('An error occurred. Try again later.'),
      )
    }

    setLoadingItems(false)
  }

  useEffect(() => {
    fetchUser()
  }, [computedMatch])

  useEffect(() => {
    fetchLibrary()
  }, [fetchedUser])

  const renderTrade = (medias) => {
    const [firstMedia] = medias
    return (
      <MediaContainer key={firstMedia.id}>
        <MediaCard data={firstMedia} />
        <MediasPopover mediasArray={medias} />
      </MediaContainer>
    )
  }

  const {
    avatar,
    displayName,
    cityLocation,
    rating,
    profile,
    createdAt,
    tradeCount,
    tradesWithMe,
    reviews,
  } = fetchedUser

  useEffect(() => {
    if (!items.length || (!filterActive && Object.keys(filters).length)) return

    setItemsToShow(
      filterActive
        ? items.filter(item => item.condition === filterActive)
        : items,
    )

    setFilters({})
  }, [filterActive, items])

  useEffect(() => {
    if (
      (!Object.keys(filters).length && !items.length)
      || (!Object.keys(filters).length && filterActive)
    ) return

    setItemsToShow(() => {
      let newItemsToShow = []
      if (Object.keys(filters).length > 0) {
        const mediaTypes = Object.keys(filters)
        const platforms = Object.keys(filters).map(key => filters[key])
        newItemsToShow = items.filter(
          item => mediaTypes.includes(item.mediaType)
            && platforms.includes(item.platform.id),
        )
      } else {
        newItemsToShow = JSON.parse(JSON.stringify(items))
      }
      return newItemsToShow
    })

    setFilterActive('')
  }, [filters])

  const changeMediaFilter = (mediaType, platform) => {
    setFilters((prev) => {
      let newFilters = JSON.parse(JSON.stringify(prev))

      if (!mediaType) {
        newFilters = {}
      } else if (!platform) {
        delete newFilters[mediaType]
      } else {
        newFilters[mediaType] = platform
      }

      return newFilters
    })
  }

  return (
    <DefaultTemplateBackground bottom>
      <DefaultPageNewBackground src={avatar} withBlur />
      <DefaultPageFadeBackground />

      <DefaultPageWrapper>
        <Header>
          <HeaderColumn>
            <Avatar showStatus size="largest" src={avatar} />
          </HeaderColumn>

          <HeaderColumn>
            <HeaderColumnChildren>
              <UserTitle>{displayName}</UserTitle>
              <address>
                <img src={euaFlag} alt="flag" />
                {cityLocation ? cityLocation.state : ''}
,
                {' '}
                {cityLocation ? cityLocation.city : ''}
              </address>
            </HeaderColumnChildren>
          </HeaderColumn>
        </Header>

        <DefaultPageBox>
          <BoxHeader>
            <RatingLabel>{t('User rating')}</RatingLabel>

            <Rating rate={rating} size={20} spacing={1} />
          </BoxHeader>

          <DefaultPageBoxContent>
            <GlobalProfile isSm={isSm}>
              <Title>{t('DESCRIPTION')}</Title>

              <p>{profile}</p>

              <ProfileSection isSm={isSm}>
                <strong>{t('Member since')}</strong>
                <br />
                {moment(createdAt).format('YYYY/MMM')}
              </ProfileSection>
              <ProfileSection isSm={isSm}>
                <strong>{t('Trades')}</strong>
                <br />
                {tradeCount}
              </ProfileSection>
              <ProfileSection isSm={isSm}>
                <strong>{t('Added items')}</strong>
                <br />
                {items.all ? items.all.length : 0}
              </ProfileSection>
            </GlobalProfile>

            {user.data.id && (
              <>
                <Title>{t('TRANSACTIONS WITH THIS USER')}</Title>

                <GlobalTransactions>
                  {tradesWithMe && (
                    <Grid container>
                      {Object.keys(tradesWithMe).map(key => (
                        <Grid key={key} item xs={6} sm={4} md={3} xlg={2}>
                          {renderTrade(tradesWithMe[key])}
                        </Grid>
                      ))}
                    </Grid>
                  )}

                  {!tradesWithMe && <div>{t('No trades.')}</div>}
                </GlobalTransactions>
              </>
            )}

            <HeaderLibrary>
              <Title>{t('LIBRARY')}</Title>

              <Filter
                options={[
                  { label: t('USED'), value: 'used' },
                  { label: t('NEW'), value: 'new' },
                  { label: t('LIKE NEW'), value: 'likeNew' },
                  { label: t('ALL'), value: '' },
                ]}
                active={filterActive}
                onChange={filter => setFilterActive(filter)}
              />
            </HeaderLibrary>

            {loadingItems && (
              <Spinner marginX="auto" marginY="auto" size={58} />
            )}

            {!loadingItems && itemsToShow.length === 0 && <NoMedia />}

            {!loadingItems && itemsToShow.length > 0 && (
              <>
                {items && items.length > 0 && (
                  <>
                    <MediasFilter
                      mediaTypesFromLibrary={mediaTypesFromLibrary}
                      callbackChangeFilter={changeMediaFilter}
                      active={filters}
                    />

                    <ViewMode
                      style={{ marginBottom: '16px' }}
                      onChange={mode => setViewMode(mode)}
                      active={viewMode}
                    />
                  </>
                )}

                <Grid container>
                  {itemsToShow.map(item => (
                    <Grid
                      key={item.id}
                      item
                      xs={cardGrid[viewMode].xs}
                      sm={cardGrid[viewMode].sm}
                      md={cardGrid[viewMode].md}
                      lg={cardGrid[viewMode].lg}
                    >
                      <MediaCard data={item} showUser actions="default" />
                    </Grid>
                  ))}
                </Grid>

              </>
            )}
            <div style={{
              margin: '30px -48px 0', background: '#0b1625', boxShadow: 'inset 0px 0px 10px rgba(0,0,0,0.25)', padding: '32px 48px',
            }}
            >
              <Title>{t('COMMENTS')}</Title>

              {reviews !== undefined && (

              <div className="comments">
                {
                  reviews.map(review => (
                    <div className="comment" key={review._id}>
                      <div className="comment-header">
                        <Rating rate={review.rating} size={20} spacing={1} />
                        <span>
                          <span className="from">from</span>
                          <Link className="name" to={`/library/${review.from.id}`}>{review.from.name}</Link>
                        </span>
                      </div>
                      <div className="comment-description">{review.description}</div>
                    </div>

                  ))}
              </div>
              )}


              {reviews !== undefined && reviews.length === 0 && (
                <div> No comments utill now</div>
              )}
            </div>
          </DefaultPageBoxContent>
        </DefaultPageBox>
      </DefaultPageWrapper>
    </DefaultTemplateBackground>
  )
}


export default UserLibrary
