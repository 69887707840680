import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
`

export const Star = styled.img`
  width: ${props => props.size}px !important;
  height: ${props => props.size}px !important;
  margin: 0 ${props => props.spacing}px;
  cursor: ${props => props.isClickable ? 'pointer' : ''};
`
