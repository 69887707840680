import React from 'react'
import { Pill, Popover, Position } from 'evergreen-ui'
import {
  game, movie, music, book,
} from '../../../assets'
import { Container, Platform } from './styles'

const Content = ({ medias }) => {
  const defaultMediaImage = {
    game,
    movie,
    music,
    book,
  }
  const getMediaImage = (pictures, mediaType) => (pictures ? pictures[0] : defaultMediaImage[mediaType])
  return (
    <Container>
      {medias.map(({
        id, title, pictures, mediaType, platform,
      }) => (
        <div key={id}>
          <div>
            <img src={getMediaImage(pictures, mediaType)} alt="media" />
          </div>
          <div>
            <p>{title}</p>
          </div>
          <div>
            <Platform color={platform.color}>
              <img src={platform.image} alt={platform.name} />
            </Platform>
          </div>
        </div>
      ))}
    </Container>
  )
}

const MediasPopover = ({ mediasArray }) => {
  if (mediasArray.length <= 1) {
    return null
  }
  return (
    <Popover
      content={<Content medias={mediasArray} />}
      position={Position.BOTTOM_LEFT}
    >
      <Pill
        position="absolute"
        top={0}
        right={0}
        color="red"
        isSolid
        marginTop={4}
        cursor="pointer"
        zIndex={1}
      >
        +
        {' '}
        {mediasArray.length - 1}
      </Pill>
    </Popover>
  )
}

export default MediasPopover
