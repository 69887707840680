import React, { useState, useEffect, useRef } from 'react'
import { useMoment } from '../../../../hooks'
import { injectStripe } from 'react-stripe-elements'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'

import { SubscriptionForm } from "./SubscriptionForm";
import { NewCardForm } from "./NewCardForm";
import {
  getSubscription,
  getDefaultCard,
  getCards,
  changeDefaultCard,
  deleteCard,
  createCard,
} from '../../../../services/card'
import { Title } from '../../../ui'
import EmptyCards from './EmptyCards'
import ICONS from '../../../ui/ICONS'
import { DeleteCardDialog } from './DeleteCardDialog'
import {
  Button,
  Grid,
  IconButton,
} from '../../../commons'
import {
  amountImg, startPeriod, endPeriod,
} from '../../../../assets'
import {
  Card,
  GlobalSubmit,
  PaymentInfos,
  Cards,
  IconContainer,
  SubscriptionInfos,
  SubscriptionHeader,
  Container,
} from './styles'

const TabPayment = ({ stripe }) => {
  const cardRef = useRef(null)
  const { t } = useTranslation()
  const moment = useMoment()
  const [cardToDelete, setCardToDelete] = useState(undefined)
  const [isDeletingCard, setIsDeletingCard] = useState(false)
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [cards, setCards] = useState([])
  const [defaultCardId, setDefaultId] = useState('')
  const [subscription, setSubscription] = useState({ plan: { amount: 0 } })
  const [holdersName, setCardHoldersName] = useState('')
  const [isCreatingCard, setIsCreatingCard] = useState(false)
  const [isNewCardOpen, setIsNewCardOpen] = useState(false)
  const handleChangeHoldersName = char => setCardHoldersName(char.toUpperCase())
  useEffect(() => {
    fetchCards()
  }, [])
  useEffect(() => {
    fetchDefaultCard()
  }, [defaultCardId])
  useEffect(() => {
    fetchSubscription()
  }, [])
  const fetchCards = async () => {
    try {
      const cards = await getCards()
      setCards(cards)
    } catch (err) {
      console.log(err.message)
    }
  }
  const fetchDefaultCard = async () => {
    try {
      const { id } = await getDefaultCard()
      setDefaultId(id)
    } catch (err) {
      console.log(err.message)
    }
  }
  const fetchSubscription = async () => {
    try {
      const subscription = await getSubscription()
      if (subscription) {
        return setSubscription(subscription)
      }
    } catch (err) {
      console.log(err.message)
    }
  }
  const handleChangeDefaultCard = async (cardId) => {
    try {
      await changeDefaultCard(cardId)
      setDefaultId(cardId)
    } catch (error) {
      toast.dismiss()
      if (error.response.data.errors) {
        error.response.data.errors.map(item => toast.error(item))
      } else {
        toast.error(
          error.response && error.response.data.message
            ? error.response.data.message
            : t('An error occurred. Try again later.'),
        )
      }
    }
  }
  const handleDeleteCard = async (event) => {
    event.preventDefault()
    setIsDeletingCard(true)
    try {
      const cards = await deleteCard(cardToDelete)
      setCards(cards)
      setIsDialogOpen(false)
    } catch (err) {
      console.log(err.message)
    } finally {
      setIsDeletingCard(false)
    }
  }
  const handleCreateCard = async (event) => {
    event.preventDefault()
    try {
      setIsCreatingCard(true)
      const { token } = await stripe.createToken()
      const card = await createCard(token)
      setCards([...cards, card])
      handleChangeDefaultCard(card.id)
      setCardHoldersName('')
      cardRef.current._element.clear()
      setIsNewCardOpen(false)
    } catch (error) {
      toast.dismiss()
      if (error.response.data.errors) {
        error.response.data.errors.map(item => toast.error(item))
      } else {
        toast.error(
          error.response && error.response.data.message
            ? error.response.data.message
            : t('An error occurred. Try again later.'),
        )
      }
    } finally {
      setIsCreatingCard(false)
    }
  }
  const handleClickOnDelete = (cardId) => {
    setCardToDelete(cardId)
    setIsDialogOpen(true)
  }
  const {
    current_period_end,
    current_period_start,
    plan: { amount },
    id,
  } = subscription
  return (
    <Container container justify="center">
      <Grid item md={6} lg={6}>
        <Title>{t('Subscription')}</Title>
        <SubscriptionInfos hasSubscription={id}>
          <div>
            <SubscriptionHeader>
              <img src={amountImg} alt="amount" />
              <span>{t('Amount:')}</span>
            </SubscriptionHeader>
            <p>
              $
              {(amount / 100).toFixed(2)}
              {' '}
              {t('monthly')}
            </p>
          </div>
          <div>
            <SubscriptionHeader>
              <img src={startPeriod} alt="start period" />
              <span>{t('Start Period:')}</span>
            </SubscriptionHeader>
            <p>
              {moment(current_period_start * 1000)
                .format('MMMM Do YYYY, h:mm:ss a')}
            </p>
          </div>
          <div>
            <SubscriptionHeader>
              <img src={endPeriod} alt="end period" />
              <span>{t('End Period:')}</span>
            </SubscriptionHeader>
            <p>
              {moment(current_period_end * 1000).format(
                'MMMM Do YYYY, h:mm:ss a',
              )}
            </p>
          </div>
        </SubscriptionInfos>
        { cards && cards.length === 0 && !isNewCardOpen && <EmptyCards /> }
        <Cards>
          {cards.map(({
            brand, exp_month, exp_year, last4, id,
          }) => (
            <Card
              isDefault={id === defaultCardId}
              key={id}
            >
              <div onClick={() => handleChangeDefaultCard(id)}>
                <p>
                  {brand}
                  {' '}
                  ****
                  {' '}
                  {last4}
                </p>
                <p>
                  {t('Valid Thru:')}
                  {exp_month}
                      /
                  {exp_year}
                </p>
              </div>
              <IconContainer>
                <IconButton
                  icon={ICONS.DELETE}
                  size={20}
                  color="white"
                  onClick={() => handleClickOnDelete(id)}
                />
              </IconContainer>
            </Card>
          ))}
        </Cards>

        <GlobalSubmit>
          <Button
            variation="success"
            onClick={() => setIsNewCardOpen(true)}
          >
            {t('Add New Card')}
          </Button>
        </GlobalSubmit>
        <NewCardForm
          isOpen={isNewCardOpen}
          holdersName={holdersName}
          handleChangeHoldersName={handleChangeHoldersName}
          isCreatingCard={isCreatingCard}
          handleCreateCard={handleCreateCard}
          cardRef={cardRef}
          setIsOpen={setIsNewCardOpen}
        />
      </Grid>
      <PaymentInfos item md={6} lg={6}>
        <Title>{t('Change Subscription')}</Title>
        <SubscriptionForm
          setSubscription={setSubscription}
          fetchSubscription={fetchSubscription}
          cards={cards}
          subscription={subscription}
        />
      </PaymentInfos>
      <DeleteCardDialog
        isLoading={isDeletingCard}
        isOpen={isDialogOpen}
        handleClose={() => setIsDialogOpen(false)}
        handleAgree={handleDeleteCard}
      />
    </Container>
  )
}


export default injectStripe(TabPayment)
