import styled, { keyframes } from 'styled-components'
import { getZindex } from '../../../../../helpers'

const SHOW_GLOBAL_NAVIGATION = keyframes`
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
`

export const GlobalSearchBar = styled.div`
  position: fixed;
  top: 82px;
  width: 100%;
  display: flex;
  justify-content: ${props => props.active === "changeLanguage" ? 'flex-end' : 'flex-start'};
`

export const Content = styled.div`
  animation: ${SHOW_GLOBAL_NAVIGATION} 150ms ease-out;
  z-index: ${getZindex('default')};
`
