import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Icon } from '..'

const IconButton = ({
  icon,
  color,
  size,
  onClick,
  minClickable,
  width,
  height,
  style,
  className,
}) => {
  const StyledIconButton = styled.button.attrs({ type: 'button' })`
    background: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    width: ${width ? `${width}px` : 'auto'};
    height: ${height ? `${height}px` : 'auto'};

    ${minClickable
      && `
      display: flex;
      width: 50px;
      height: 50px;
      justify-content: center;
      align-items: center;
    `}

    &:hover {
      opacity: 0.8;
    }
  `

  return (
    <StyledIconButton className={className} style={style} onClick={onClick}>
      <Icon icon={icon} color={color} size={size} />
    </StyledIconButton>
  )
}

IconButton.propTypes = {
  icon: PropTypes.arrayOf(PropTypes.string).isRequired,
  size: PropTypes.number,
  color: PropTypes.string,
  minClickable: PropTypes.bool,
  onClick: PropTypes.func,
}

IconButton.defaultProps = {
  size: 30,
  color: '#FFF',
  minClickable: false,
  onClick: () => {},
}

export default IconButton
