import React, { useEffect, useState } from 'react'
import Geocode from 'react-geocode'
import { Checkbox } from 'evergreen-ui'
import { toast } from 'react-toastify'
import { validateAddress } from '../../../../services/auth'

import ICONS from '../../../ui/ICONS'
import {
  Grid,
  Icon,
  Input,
  Map,
  IconButton,
  Button,
} from '../../../commons'
import {
  ButtonContainer,
  Label,
  Form,
  MapContainer,
  SecondaryAddress,
  RowWithInputs,
} from './styles'
import {
  setAddress,
  setSecondaryAddress,
  setBillingAddressSameAsShipping,
} from '../store/wizardsReducer'
import {
  changeAddress,
  changeBillingAddressSameAsShipping,
  changeSecondaryAddress,
} from '../store/formReducer'

export const ShippingAddress = ({
  navigateToNext,
  hasSecondaryAddress,
  setHasSecondaryAddress,
  dispatch,
  form,
  state,
  changeForm,
}) => {
  useEffect(() => {
    if (state.address) {
      changeForm(
        changeAddress({
          streetAddress1: state.address.streetAddress1,
          streetAddress2: state.address.streetAddress2,
          zipcode: state.address.zipcode,
          state: state.address.state,
          city: state.address.city,
        }),
      )
    }
    if (state.secondaryAddress) {
      changeForm(
        changeSecondaryAddress({
          streetAddress1: state.secondaryAddress.streetAddress1,
          streetAddress2: state.secondaryAddress.streetAddress2,
          zipcode: state.secondaryAddress.zipcode,
          state: state.secondaryAddress.state,
          city: state.secondaryAddress.city,
        }),
      )
    }
    if (state.billingSameAsShipping) {
      changeForm(
        changeBillingAddressSameAsShipping(state.billingSameAsShipping),
      )
    }
  }, [])
  const { address, secondaryAddress, billingSameAsShipping } = form
  const [localHasSecondaryAddress, setLocalHasSecondaryAddress] = useState(
    hasSecondaryAddress,
  )
  const [latitude, handleChangeLatitude] = useState(0)
  const [longitude, handleChangeLongitude] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const { latitude, longitude } = position.coords
        handleChangeLatitude(latitude)
        handleChangeLongitude(longitude)
      })
    }
  }, [])
  useEffect(() => {
    const getLongitudeAndLatitudeFromAddress = async () => {
      try {
        const { results } = await Geocode.fromAddress(`${address.streetAddress1} ${address.streetAddress2} ${address.city} ${address.state}`)
        const { lat, lng } = results[0].geometry.location
        handleChangeLatitude(lat)
        handleChangeLongitude(lng)
      } catch (err) {
        console.log(err)
      }
    }
    getLongitudeAndLatitudeFromAddress()
  }, [address.streetAddress1, address.streetAddress2, address.city, address.state])
  const submitForm = async (e) => {
    e.preventDefault()
    try {
      setIsLoading(true)
      const submitAddress = {
        ...address
      }
      await validateAddress(submitAddress)
      dispatch(setAddress(submitAddress))
      if (secondaryAddress.streetAddress1) {
        const submitSecondaryAddress = {
          ...secondaryAddress,
        }
        await validateAddress(submitSecondaryAddress)
        dispatch(setSecondaryAddress(submitSecondaryAddress))
      }
      if (billingSameAsShipping) {
        dispatch(setBillingAddressSameAsShipping())
      }
      if (localHasSecondaryAddress) {
        setHasSecondaryAddress(true)
      }
      navigateToNext()
    } catch (error) {
      toast.dismiss()
      if (error.response.data.errors){
        error.response.data.errors.map(item => toast.error(item))
      } else {
        toast.error(
          error.response && error.response.data.message
            ? error.response.data.message
            : 'An error occurred. Try again later.',
        )
      }
    } finally {
      setIsLoading(false)
    }
  }
  return (
    <Grid container justify="center">
      <Grid item md={6} lg={6}>
        <Form onSubmit={submitForm}>
          <Input
            label="Shipping Address"
            id="shipping address line one"
            placeholder="Street Address"
            type="text"
            value={address.streetAddress1}
            onChange={event => changeForm(
              changeAddress({
                streetAddress1: event.target.value,
              }),
            )
            }
            required
          />
          <Input
            placeholder="Apt., ste., bldg. "
            id="shipping address line two"
            type="text"
            value={address.streetAddress2}
            onChange={event => changeForm(
              changeAddress({
                streetAddress2: event.target.value,
              }),
            )
            }
          />
          <div>
            <Input
              placeholder="City"
              id="shipping address line two"
              type="text"
              value={address.city}
              onChange={event => changeForm(
                changeAddress({
                  city: event.target.value,
                }),
              )
              }
              required
            />
            <Input
              placeholder="State"
              id="shipping address line two"
              type="text"
              value={address.state}
              onChange={event => changeForm(
                changeAddress({
                  state: event.target.value,
                }),
              )
              }
              required
            />

            <Input
              placeholder="Zip Code"
              id="shipping address line two"
              type="text"
              value={address.zipcode}
              onChange={event => changeForm(
                changeAddress({
                  zipcode: event.target.value,
                }),
              )
              }
              required
            />
          </div>
          <Checkbox
            checked={billingSameAsShipping}
            onChange={() => changeForm(
              changeBillingAddressSameAsShipping(!billingSameAsShipping),
            )
            }
            label={<Label>Billing Address is the same as Shipping</Label>}
          />
          <SecondaryAddressSection
            hasSecondaryAddress={localHasSecondaryAddress}
            setHasSecondaryAddress={setLocalHasSecondaryAddress}
            address3={secondaryAddress.streetAddress1}
            address4={secondaryAddress.streetAddress2}
            zipcode={secondaryAddress.zipcode}
            state={secondaryAddress.state}
            city={secondaryAddress.city}
            handleSecondaryAddress={changeForm}
          />
          <ButtonContainer>
            <Button
              isLoading={isLoading}
              type="submit"
              variation="success"
            >
              Next
            </Button>
          </ButtonContainer>
        </Form>
      </Grid>
      <MapContainer item md={6} lg={6}>
        <Map
          hasMarker
          defaultZoom={15}
          coordinates={{ lat: latitude, lng: longitude }}
        />
      </MapContainer>
    </Grid>
  )
}

const SecondaryAddressSection = ({
  hasSecondaryAddress,
  setHasSecondaryAddress,
  address3,
  address4,
  zipcode,
  state,
  city,
  handleSecondaryAddress,
}) => {
  if (hasSecondaryAddress) {
    return (
      <>
        <IconButton
          icon={ICONS.DELETE}
          size={20}
          color="white"
          onClick={() => setHasSecondaryAddress(false)}
        />
        <Input
          label="Secondary Address"
          id="secondary shipping address line one"
          placeholder="Street Address"
          type="text"
          value={address3}
          onChange={event => handleSecondaryAddress(
            changeSecondaryAddress({ streetAddress1: event.target.value }),
          )
          }
          required
        />
        <Input
          placeholder="Apt., ste., bldg. "
          id="secondary shipping address line two"
          type="text"
          value={address4}
          onChange={event => handleSecondaryAddress(
            changeSecondaryAddress({ streetAddress2: event.target.value }),
          )
          }
        />
        <RowWithInputs>
          <Input
            placeholder="City"
            id="shipping address line two"
            type="text"
            value={city}
            onChange={event => handleSecondaryAddress(
              changeSecondaryAddress({
                city: event.target.value,
              }),
            )
            }
            required
          />
          <Input
            placeholder="State"
            id="shipping address line two"
            type="text"
            value={state}
            onChange={event => handleSecondaryAddress(
              changeSecondaryAddress({
                state: event.target.value,
              }),
            )
            }
            required
          />
          <Input
            placeholder="Zipcode"
            id="shipping address line two"
            type="text"
            value={zipcode}
            onChange={event => handleSecondaryAddress(
              changeSecondaryAddress({
                zipcode: event.target.value,
              }),
            )
            }
            required
          />
        </RowWithInputs>
      </>
    )
  }

  return (
    <SecondaryAddress onClick={() => setHasSecondaryAddress(true)}>
      <Icon icon={ICONS.ADD} size={20} color="#0BA0B5" />
      <p>Add Secondary Shipping Address</p>
    </SecondaryAddress>
  )
}
