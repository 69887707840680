import React from 'react'
import { withTheme } from 'styled-components'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import Img from 'react-cool-img'
import { ghostThumb } from '../../../../assets'
import {
  GlobalMediaImage, Image, FooterInfo, GhostImage, MediaImageStyle,
} from './styles'
import Loader from './PacmanLoader.svg'


const MediaImage = (props) => {
  const { src, condition, name } = props
  const { t } = useTranslation()

  return (
    <GlobalMediaImage title={`image media - ${name}`}>
      <Img
        style={MediaImageStyle}
        className="image-background"
        src={src}
        title={`image media - ${name}`}
        placeholder={Loader}
        lazy
        cache
      />

      <GhostImage src={ghostThumb} alt={`image media - ${name}`} />

      {condition && (
        <FooterInfo condition={condition}>
          {condition === 'likeNew' ? t('Like new') : t(condition)}
        </FooterInfo>
      )}
    </GlobalMediaImage>
  )
}

MediaImage.propTypes = {
  src: PropTypes.string,
}

MediaImage.defaultProps = {
  src: '',
}

export default withTheme(MediaImage)
