import React from 'react'

import Chat from './Chat'
import { goBack, navigateToNext } from '../../../store'
import { Grid, MapWithSearch, Button } from '../../../../../commons'
import {
  ButtonsGroup,
  Column,
  MapContainer,
} from '../../styles'


const TransactionChat = ({
 tradeId,
 myTrade,
 hisTrade,
 dispatch,
 tradeStatus
}) => {

  const handleNext = (event) => {
    event.stopPropagation()
    dispatch(navigateToNext())
  }

  const handleBack = (event) => {
    event.stopPropagation()
    dispatch(goBack())
  }

  
  return (
    <Grid container justify="center">
      <Column item md={6} lg={6}>
        <Chat tradeStatus={tradeStatus} tradeId={tradeId} myTrade={myTrade} hisTrade={hisTrade} />
      </Column>
      <Column item md={6} lg={6}>
        <MapContainer>
          <MapWithSearch />
        </MapContainer>
      </Column>
      <ButtonsGroup>
        <Button
          onClick={event => handleBack(event)}
          variation="info"
        >
          Back
        </Button>
        <Button
          variation="success"
          onClick={event => handleNext(event)}
        >
          NEXT
        </Button>
      </ButtonsGroup>
    </Grid>
  )
}

export default TransactionChat
