import styled, { keyframes } from 'styled-components'

const increase = keyframes`
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.2);
  }
`

const decrease = keyframes`
  0% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
`

export const Container = styled.div`
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: ${props => props.isMd ? 'calc(100vh - 150px - 27px)' : 'calc(100vh - 82px - 27px)'};
  overflow: hidden;
`

export const Background = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  overflow: hidden;
`

export const BackgroundOne = styled(Background)`
  background-image: url(${props => props.bgImage});
  animation: ${increase} 25s linear 10ms infinite;
`

export const BackgroundTwo = styled(Background)`
  background-image: url(${props => props.bgImage});
  animation: ${decrease} 25s linear 10ms infinite;
  transform: scale(1.2);
`

export const BackgroundThree = styled(Background)`
  background-image: url(${props => props.bgImage});
  animation: ${increase} 25s linear 10ms infinite;
`

export const BackgroundFour = styled(Background)`
  background-image: url(${props => props.bgImage});
  animation: ${decrease} 25s linear 10ms infinite;
  transform: scale(1.2);
`

export const Fade = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: ${props => props.isMd ? 'calc(100vh - 150px - 27px)' : 'calc(100vh - 82px - 27px)'};
  background-image: linear-gradient(transparent 0%, rgba(0, 0, 0, 0.8) 50%);
`
