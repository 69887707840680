import React from 'react'
import { number } from 'prop-types'
import { Icon } from '..'
import ICONS from '../../ui/ICONS'
import { cam } from '../../../assets'
import { GlobalMediaPicture } from './styles'

const MediaPicture = (props) => {
  const { media, size } = props

  return (
    <GlobalMediaPicture
      src={media && media.pictures ? media.pictures[0] : cam}
      hasMedia={!!media}
      size={size}
      {...props}
    >
      {!media && (
        <Icon icon={ICONS.LIBRARY} color="#0C96B6" size={size * 0.7} />
      )}
    </GlobalMediaPicture>
  )
}

MediaPicture.propTypes = {
  size: number,
}

MediaPicture.defaultProps = {
  size: 60,
}

export default MediaPicture
