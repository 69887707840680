import React from 'react'
import {
  oneOf, bool, string, array,
} from 'prop-types'
import { Loader, Icon } from '..'
import { GlobalButton, StyledButton, GlobalLoader } from './styles'

const Button = (props) => {
  const {
    variation,
    size,
    disabled,
    fluid,
    children,
    isLoading,
    type,
    icon,
    onClick
  } = props
  const isDisabled = isLoading || disabled

  return (
    <GlobalButton {...props}>
      <StyledButton
        type={type}
        variation={variation}
        size={size}
        disabled={isDisabled}
        fluid={fluid}
        icon={icon}
        onClick={onClick}
      >
        {icon && <Icon icon={icon} />}

        {children}

        {isLoading && (
          <GlobalLoader>
            <Loader />
          </GlobalLoader>
        )}
      </StyledButton>
    </GlobalButton>
  )
}

Button.propTypes = {
  type: string,
  variation: oneOf([
    'primary',
    'secondary',
    'success',
    'danger',
    'warning',
    'info',
    'ghost',
    'change',
  ]),
  size: oneOf(['small', 'medium', 'large']),
  disabled: bool,
  fluid: bool,
  isLoading: bool,
  icon: array,
}

Button.defaultProps = {
  type: 'button',
  variation: 'primary',
  size: 'medium',
  disabled: false,
  fluid: false,
  isLoading: false,
}

export default Button
