import React from 'react'
import styled from 'styled-components'
import ICONS from '../../ui/ICONS'
import { IconButton } from '..'

const GlobalViewMode = styled.div`
  display: flex;
  justify-content: center;

  > button {
    margin: 0 4px;
  }
`

const ViewMode = (props) => {
  const { active, onChange } = props

  return (
    <GlobalViewMode {...props}>
      <IconButton
        icon={ICONS.GRID_LESS}
        style={{ opacity: active === 'less' ? '1' : '0.2' }}
        onClick={() => onChange('less')}
      />

      <IconButton
        icon={ICONS.GRID_MORE}
        style={{ opacity: active === 'more' ? '1' : '0.2' }}
        onClick={() => onChange('more')}
      />
    </GlobalViewMode>
  )
}

export default ViewMode
