import styled, { keyframes } from 'styled-components'
import { DefaultPageWrapper } from '../../templates/DefaultPage'

const SHOW_ITEM_ACTION = keyframes`
  from {
    opacity: 0;
    transform: translateY(100%);
  }

  to {
    opacity: 1;
    transform: translateY(0%);
  }
`

const itemVariations = {
  request: {
    bg: 'linear-gradient(#00CBFF,#006E9D)',
    border: '#08A4DE',
  },
  library: {
    bg: 'linear-gradient(#F9C040,#E69814)',
    border: '#F9C040',
  },
  edit: {
    bg: 'linear-gradient(#EE773B,#BA4F1A)',
    border: '#EE773B',
  },
  delete: {
    bg: 'linear-gradient(#FF0606,#9F080D)',
    border: '#FF0606',
  },
}
export const GlobalIcon = styled.div`
  display: ${props => (props.cardIsTooSmall ? 'none' : 'block')};
  width: 30px;
  height: 30px;
  box-sizing: initial;
  border-radius: 50%;
  border: ${props => `2px solid ${itemVariations["library"].border}`};
  padding: 2px;
  margin-right: ${props => `${props.theme.spacing}px`};
  background: ${props => itemVariations["library"].bg};

  :nth-child(2) {
    margin-left: ${props => `${props.theme.spacing}px`};
  }
`
export const GlobalItemAction = styled.button`
  display: none;
  align-items: center;
  color: #fff;
  cursor: pointer;
  margin-top: ${props => `${props.theme.spacing}px`};
  opacity: 0;
  animation: ${SHOW_ITEM_ACTION} 160ms ease-out;
  animation-fill-mode: forwards;
  font-size: 14px;

  ${props => props.cardIsTooSmall
    && `
    border-radius: 4px;
    padding: 0 4px;
    background: ${itemVariations[props.variation].bg};
  `};

  :nth-last-child(2) {
    animation-delay: 80ms;
  }

  :hover ${GlobalIcon} {
    background: transparent;
  }
`

export const PageWrapper = styled(DefaultPageWrapper)`
  padding: 0;
`

export const PageBackground = styled.div`
  width: 100%;
  height: 326px;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  background: url(${props => props.src}) no-repeat top center;
  background-position-y: 20%;
  background-size: cover;
  overflow: hidden;

  @media (max-width: 599px) {
    width: 100vw;
    height: 200px;
    background-size: 150% 150%;
    background-position: center;
    background-position-y: 25%;
    background-size: 700px;
  }
`

export const Fade = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 326px;
  background-image: linear-gradient(transparent, rgba(0, 0, 0, 0.8));
  @media (max-width: 599px) {
    width: 100vw;
    height: 200px;
  }
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 347px;
  width: 100%;
  max-width: 1000px;
  background-color: #0d2032;
  border-radius: 8px;
  box-shadow: inset 0px 0px 20px rgba(0, 0, 0, 0.25);
  margin: 50px auto 50px auto;

  @media (max-width: 599px) {
    width: 100%;
    height: 520px;
    margin-top: 80px;
    margin-bottom: 80px;
    border-radius: 0;
  }
`

export const PageTitle = styled.h1`
  width: 100%;
  position: relative;
  left: 12%;
  top: 40px;

  font-family: Titillium Web;
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 43px;
  color: #ffffff;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  @media (max-width: 599px) {
    left: 20px;
    top: 75px;
    font-size: 1.6rem;
  }
`

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 47px;
  width: 100%;
  max-width: 1000px;
  background-color: #0b1625;
  border-radius: 8px 8px 0 0;

  @media (max-width: 599px) {
    border-radius: 0;
  }
`

export const StatusFilter = styled.li`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  flex: 1;
  font-size: 0.6rem;

  padding: 10px;

  #center-item {
    border-right: 1px solid #253f58;
    border-left: 1px solid #253f58;
    width: 70px;
  }

  .activeTab {
    color: #00cbff;
  }

  @media (max-width: 599px) {
    padding-left: 30px;
  }
`

export const StatusItem = styled.span`
  font-size: 0.7rem;
  width: 50px;
  text-align: center;
  cursor: pointer;
`

export const HistoryList = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  padding: 5px;
  width: 100%;
  max-width: 959px;
  height: 280px;
  overflow: auto;

  .selected {
    background-color: #003049 !important;
  }

  @media (max-width: 599px) {
    height: 460px;
    padding: 5px 10px;
  }
`
const getSelectors = props => (props.isTouchable ? '&, :hover' : ':hover')

export const HistoryItem = styled.li`
  background-color: #0b1625;
  display: grid;
  grid-template-rows: 83px;
  grid-template-columns: 53px 1.5fr 180px 1fr 1fr 1fr 1fr;
  grid-template-areas: 'avatar tradeInfo titlesFlow tradeStared tradeCompleted tradeType tradeActions';
  height: 83px;
  width: 100%;
  max-width: 959px;
  margin-bottom: 2px;
  .avatar {
    width: 53px;
    height: 83px;
    grid-area: avatar;

    @media (max-width: 599px) {
      height: 150px;
      border-radius: 5px 0 0 5px;
    }
  }
  .accepted {
    background-color: #4cd964;
  }

  .pending {
    background-color: #00cbff;
  }
  .declined {
    background-color: #ff0606;
  }
  .avatar-expired {
    background-color: #0a1c2e !important;
  }

  .cell-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
    font-size: 0.8rem;
    span {
      color: #979797;
      margin-left: 5px;
      text-align: center;
    }
  }

  .trade-info {
    height: 83px;
    grid-area: tradeInfo;
    @media (max-width: 599px) {
      height: 100%;
      flex-direction: row;
      justify-content: flex-start;
      padding-left: 15px;
      margin: 0 auto;
    }
    ${props => getSelectors(props)} {

    ${GlobalItemAction} {
      display: inline-flex;
      transform: translateY(1%);
      opacity: 1;
    }
    .trade-status {
      display: none
    }
  }
  }

  .titles-flow {
    height: 83px;
    padding: 0;
    grid-area: titlesFlow;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding: 10px;
    width: 100%;
    text-align: center;
    div {
      padding: 10px;
    }
    @media (max-width: 599px) {
      margin: 0 auto;
      height: 65px;
      justify-content: center;
    }
  }

  .trade-started {
    height: 83px;
    grid-area: tradeStared;
    span {
      font-size: 0.7rem;
    }
    @media (max-width: 599px) {
      height: 42px;
      margin: 0 auto;
      font-size: 0.7rem;
    }
  }

  .trade-completed {
    height: 83px;
    border-right: 1px solid #253f58;
    border-left: 1px solid #253f58;
    grid-area: tradeCompleted;
    span {
      font-size: 0.7rem;
    }
  }

  .trade-type {
    height: 83px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    grid-area: tradeType;
  }

  .trade-actions {
    grid-area: tradeActions;
    text-align: center;
    display: flex;
    align-items: center;
    padding: 5px;
    button {
      width: 100px;
      height: 29px;
      font-size: 0.7rem;
      font-weight: bold;
      text-transform: uppercase;
    }
    .proceed {
      background-color: #4cd964;
    }
    .details {
      background-color: #0ba0be;
    }

    @media (max-width: 599px) {
      padding: 5px;
      margin: 0 auto;
      button {
        height: 26px;
        font-size: 0.6rem;
        font-weight: bold;
      }
    }
  }

  .denied {
    button {
      display: none;
    }
  }

  .expired {
    button {
      color: white;
    }
  }

  .success {
    button {
      color: white;
      background-color: transparent;
      border: 1px solid #4cd964;
    }
  }

  @media (max-width: 599px) {
    height: 150px;
    width: 100%;
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-columns: 53px 40% 40%;
    grid-template-areas:
      'avatar tradeInfo tradeInfo'
      'avatar titlesFlow titlesFlow'
      'avatar tradeStared tradeActions';
    border-radius: 5px;

    .hide-sm {
      display: none;
    }
  }
`

export const AvatarBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 55px;
  height: 83px;
  padding: 5px;
  font-size: 0.7rem;

  border-top-left-radius: ${props => props.isFirst && '5px'};
  border-bottom-left-radius: ${props => props.isLast && '5px'};

  .avatar {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    box-shadow: 0 6px 8px 0 rgba(0, 0, 0, 0.3);

    @media (max-width: 599px) {
      margin: 10px 0;
    }
  }

  @media (max-width: 599px) {
    height: 100%;
    justify-content: flex-start;
    border-radius: 0;
  }
`

export const TitleThumbnail = styled.img`
  width: 47px;
  height: 47px;
  border-radius: 5px;

  @media (max-width: 599px) {
    width: 47px;
    height: 47px;
  }
`

export const StatusIcon = styled.div`
  background-image: url(${props => props.icon});
  background-repeat: no-repeat;
  background-position: center;
  width: 45px !important;
  z-index: 10;
`

export const Actions = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 105px;
  padding: 0 15px;
`
export const StatusTrade = styled.span`

`