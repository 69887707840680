import styled from 'styled-components'

export const Container = styled.div`
  background: #0b1625;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 348px;
  flex-direction: column;
  padding: 8px;
  & > div {
    display: flex;
    align-items: center;
    width: 100%;
    height: 80px;
    background: #253f58;
    margin-bottom: 2px;
    &:nth-child(even) {
      background: #152f48;
    }
    & > div {
      flex: 1;
      display: flex;
      height: 100%;
      align-items: center;
      &:not(:nth-child(2)) {
        justify-content: center;
      }
      & > img {
        max-height: 48px;
        max-width: 48px;
        border-radius: 5px;
      }
    }
  }
`

export const Platform = styled.div`
  width: 70px;
  height: 20px;
  border-radius: 20px;
  background: ${props => props.color};
  display: flex;
  align-items: center;
  justify-content: center;
  & > img {
    max-width: 38px;
    max-height: 15px;
  }
`
