import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify'

import { reviewTrade, reportTrade, getReview } from '../../../../../services/trade'
import { goBack, updateTrade } from '../../store'
import { useStore } from '../../../../../store'
import { Grid, Rating, Avatar, Button } from '../../../../commons'
import { Title } from '../../../../ui'
import { euaFlag } from '../../../../../assets'
import { TransactionResume } from '../components'
import {
  ButtonsGroup,
  ReviewInfos,
  UserInfo,
  Description,
  ReportButton,
} from '../styles'
import ReportModal from './ReportModal'
import { useRouter } from '../../../../../hooks'

const TRADE_REVIEWED = 'reviewed'
const TRADE_REPORTED = 'reported'

const Review = ({ state, dispatch }) => {
  useEffect(() => {
    if (isReviewDisabled) {
      fetchReview()
    }
  }, [])
  const { from, to, _id } = state
  const { user: { data: { id } } } = useStore()
  const { history } = useRouter()
  const myTrade = from.user.id === id ? from : to
  const hisTrade = from.user.id !== id ? from : to
  const [myMedia] = myTrade.trading
  const [hisMedia] = hisTrade.trading
  const userToReview = hisTrade.user
  const { avatar, displayName, cityLocation } = userToReview
  const [rating, setRating] = useState(0)
  const [description, setDescription] = useState('')
  const [isReportOpen, setIsReportOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const isReviewDisabled = myTrade.status === TRADE_REVIEWED
    ||myTrade.status === TRADE_REPORTED
  const fetchReview = async () => {
    try {
      const { description, rating } = await getReview(_id)
      setDescription(description)
      setRating(rating)
    } catch (err) {
      console.log(err)
    }
  }
  const handleSubmit = async (event) => {
    event.preventDefault()
    try {
      setIsLoading(true)
      const { trade } = await reviewTrade(_id, rating, description.trim())
      dispatch(updateTrade(trade))
      history.push('/')
    } catch (error) {
      toast.dismiss()
      if (error.response.data.errors){
        error.response.data.errors.map(item => toast.error(item))
      } else {
        toast.error(
          error.response && error.response.data.message
            ? error.response.data.message
            : 'An error occurred. Try again later.',
        )
      }
    } finally {
      setIsLoading(false)
    }
  }
  const handleReport = async (reason, description, image) => {
    try {
      return await reportTrade(_id, reason, description.trim(), image)
    } catch (error) {
      toast.dismiss()
      if (error.response.data.errors){
        error.response.data.errors.map(item => toast.error(item))
      } else {
        toast.error(
          error.response && error.response.data.message
            ? error.response.data.message
            : 'An error occurred. Try again later.',
        )
      }
    }
  }

  const handleBack = (event) => {
    event.stopPropagation()
    dispatch(goBack())
  }

  return (
    <Grid spacing={0} container justify="center">
      <UserInfo item md={12} lg={12}>
        <Avatar src={avatar} alt="avatar" size="large" />
        <div>
          <h2>{displayName}</h2>
          <div>
            <img alt="flag" src={euaFlag} />
            <span>
              {cityLocation ? cityLocation.state : ''},
              {' '}
              {cityLocation ? cityLocation.city : ''}
              </span>
            <ReportButton
              onClick={() => setIsReportOpen(true)}
              disabled={isReviewDisabled}
            >
              report
            </ReportButton>
          </div>
        </div>
      </UserInfo>
      <ReviewInfos item md={6} lg={6}>
        <Title>Rate User</Title>
        <Rating
          setRate={setRating}
          isClickable={!isReviewDisabled}
          spacing={2}
          size={22}
          rate={rating}
        />
        <form onSubmit={handleSubmit}>
          <Title>Description</Title>
          <Description
            required
            type="text"
            placeholder="Describe user..."
            value={description}
            onChange={event => setDescription(event.target.value)}
            disabled={isReviewDisabled}
          />
          <TransactionResume myMedia={myMedia} hisMedia={hisMedia} />
          <ButtonsGroup>
            <Button
              onClick={event => handleBack(event)}
              disabled={isLoading}
              variation="info"
            >
              BACK
            </Button>
            <Button
              disabled={isReviewDisabled || isLoading}
              type="submit"
              variation="success"
            >
              COMPLETE
            </Button>
          </ButtonsGroup>
        </form>
      </ReviewInfos>
      <ReportModal
        isOpen={isReportOpen}
        handleClose={() => setIsReportOpen(false)}
        user={userToReview}
        handleReport={handleReport}
        dispatch={dispatch}
      />
    </Grid>
  )
}

export default Review
