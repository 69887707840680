import { defaultTheme } from './_default-theme'

export default {
  ...defaultTheme,
  colorInputBackground: '#253F58',
  colorInputPlaceholder: '#A5A5A5',
  colorPrimaryDark2: '#003049' /* color-palete-15 */,
  colorPrimaryDark3: '#0C1F32' /* color-palete-14 */,
  colorPrimaryDark5: '#0B1625' /* color-palete-17 */,
  colorPrimaryFeatured: '#08A4DE' /* color-palete-08 */,
  colorPrimaryComplementary: '#547384' /* color-palete-09 */,
  colorSecondary: '#FFFFFF' /* PS: precisa adicionar */,
  colorTertiary: '#EE773B' /* color-palete-28 / Orange */,
  colorGray2: '#8E949A' /* color-palete-21 */,
  colorGrayDark:
    '#191919' /* color-palete-17 / (PS: Parecido com o 2 / É gray) */,
  colorGrayDark2: '#313131' /* color-palete-22 */,
  colorBadge: '#EE302A',
  colorMediaTypes: {
    game: ['#0BA0BE', '#006E9D'],
    movie: ['#EE773B', '#BA4F1A'],
    book: ['#147E14', '#0C5E0C'],
    music: ['#A11D21', '#58070A'],
  },
  colorPositive: '#29AE4A',
  colorNegative: '#EE302A',
  colorInput: '#253F58',
  colorPlaceholder: '#A5A5A5'
}
