import styled from "styled-components";
import { Grid } from "../../../commons";

export const Form = styled.form`
    & > label {
    margin-top: 12px;
  }
  
  & > label:nth-child(1){
    margin-bottom: 12px;
  }
`

export const MapContainer = styled(Grid)`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-top: 42px;
`

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 12px;
`

export const NextButton = styled.button`
  background: linear-gradient(180deg, #4CD964 0%, #0C9D25 93.75%);
  color: #FFF;
  padding: 6px 60px;
  font-size: 14px;
  line-height: 22px;
  border-radius: 6px;
  text-align: center;
  font-weight: 600;
  margin-top: 36px;
  &:not(:disabled) {
    cursor: pointer;
  }
  &:disabled {
    opacity: 0.65;
    background: ${props => props.theme.colorGray2};
  }
`

export const RowWithInputs = styled.div`
   margin-top: 12px;
   display: flex;
   & > label:nth-child(2) {
      margin-right: 4px;
      margin-left: 4px;
    }
`

export const styles = {
  base: {
    iconColor: '#FFF',
    color: '#08A4DE',
    fontSize: 16,
    fontFamily:'Titillium Web',
    '::placeholder': {
      color: '#A5A5A5',
    },
  }
}
