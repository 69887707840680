import { userInitialState, userReducer } from '../components/UserInfo'
import {
  hiddenContentInitialState,
  searchResultsInitialState,
  searchBarReducer,
  hiddenContentReducer,
} from '../components/templates/DefaultTemplate/Header/HiddenContent'
import {
  searchReducer,
  searchInitialState,
} from '../components/commons/SearchBar/reducer'
import {
  notificationsInitialState,
  notificationsReducer,
} from '../components/templates/DefaultTemplate/Header/HiddenContent/Notifications'

export const initialState = {
  user: userInitialState,
  search: searchInitialState,
  searchResults: searchResultsInitialState,
  hiddenContent: hiddenContentInitialState,
  notifications: notificationsInitialState,
}

export default (
  {
    user, searchResults, search, hiddenContent, notifications,
  },
  action,
) => ({
  user: userReducer(user, action),
  search: searchReducer(search, action),
  searchResults: searchBarReducer(searchResults, action),
  hiddenContent: hiddenContentReducer(hiddenContent, action),
  notifications: notificationsReducer(notifications, action),
})
