import React, { useState, useEffect } from "react";
import { compose, withProps } from "recompose";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  OverlayView,
} from "react-google-maps";
import api from "../../../../../services/api";
import { Avatar, Rating } from "../../../../commons";
import { useStore } from "../../../../../store";
import { GlobalMedia } from "./styles";

const apiKey = process.env.REACT_APP_GOOGLE_MAP_API_KEY;

const Map = compose(
  withProps({
    googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${apiKey}`,
    loadingElement: <div style={{ height: "100%" }} />,
    containerElement: <div style={{ height: "400px" }} />,
    mapElement: <div style={{ height: "100%" }} />,
  }),
  withScriptjs,
  withGoogleMap
)((props) => {
  const { callbackSelectMedia, coordinates, searchTerm } = props;
  const { user } = useStore();
  const [selectedMedia, setSelectedMedia] = useState("");
  const [medias, setMedias] = useState({});

  const onSelectMedia = (id) => {
    setSelectedMedia(id);
    callbackSelectMedia(medias[id]);
  };

  const fetchSearch = async () => {
    try {
      const response = await api.post("/user/closest", {
        ...coordinates,
        miles: 50,
        query: searchTerm,
      });

      setMedias(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchSearch();
  }, []);

  return (
    <GoogleMap
      defaultZoom={14}
      defaultCenter={coordinates}
      options={{
        mapTypeControl: false,
        streetViewControl: false,
      }}
    >
      <OverlayView
        position={coordinates}
        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
      >
        <Avatar
          size="medium"
          src={user.data.avatar}
          style={{ border: "4px solid #033753", boxSizing: "initial" }}
        />
      </OverlayView>

      {Object.keys(medias).map((key) => (
        <OverlayView
          key={key}
          position={{
            lat: medias[key].cityLocation.coordinates[1],
            lng: medias[key].cityLocation.coordinates[0],
          }}
          mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
        >
          <GlobalMedia
            onClick={() => onSelectMedia(key)}
            isSelected={selectedMedia === key}
          >
            <Avatar src={medias[key].avatar} size="extrasmall" />
            <Rating rate={medias[key].rating} size={3} />
          </GlobalMedia>
        </OverlayView>
      ))}
    </GoogleMap>
  );
});

export default Map;
