import React from 'react'
import { Dialog, Button } from "evergreen-ui";
import { DialogContent, DialogFooter, DialogHeader} from "./styles";

export const WarningDialog = ({ isOpen, handleClose, handleSubmit, isLoading }) => (
  <Dialog
    isShown={isOpen}
    onCloseComplete={handleClose}
    hasFooter={false}
    hasHeader={false}
    width={566}
    contentContainerProps={{padding: 0}}
    topOffset="30vh"
  >
    <DialogHeader>
      <h1>Warning</h1>
    </DialogHeader>
    <DialogContent>
      <p>Your might lose your progress if you do this</p>
    </DialogContent>
    <DialogFooter>
      <Button
        marginRight={12}
        intent="danger"
        appearance="primary"
        onClick={handleClose}
      >
        Cancel
      </Button>
      <Button
        intent="success"
        appearance="primary"
        onClick={handleSubmit}
        isLoading={isLoading}
      >
        Agree
      </Button>
    </DialogFooter>
  </Dialog>
)
