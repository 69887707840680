import styled from 'styled-components'

export const GlobalInput = styled.label`
  width: 100%;
  display: inline-block;
  margin-bottom: ${props => props.spacing}px;
`

export const GlobalStyledInput = styled.div`
  position: relative;
`

export const StyledInput = styled.input`
  background-color: ${props => props.theme.colorInputBackground};
  color: ${props => props.theme.colorPrimaryFeatured};
  border: none;
  padding: ${props => props.theme.spacing}px;
  ${props => props.hasClearButton
    && `
    padding-right: 40px;
  `}
  ${props => props.hasIcon
    && `
    padding-left: ${props.theme.spacing * 2 + 30}px;
  `}
  border-radius: 8px;
  font-size: 16px;
  width: 100%;
  border: ${props => (props.error.status ? '2px solid red' : '2px solid transparent')};
  box-sizing: border-box;

  &:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
  }

  &::placeholder {
    color: ${props => props.theme.colorInputPlaceholder};
  }

  &:focus {
    outline: none;
    border: 2px solid ${props => props.theme.colorPrimaryFeatured};
    background-color: #ffffff;
    font-weight:600;
    color:black;
  }

  ::-webkit-search-cancel-button {
    display: none;
  }
`

export const GlobalButton = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: ${props => (props.right ? 0 : 'auto')};
  left: ${props => (props.left ? `${props.theme.spacing}px` : 'auto')};
  height: ${props => (props.left ? '30px' : 'auto')};
`

export const Legend = styled.span`
  color: ${props => props.theme.colorNegative};
  font-size: ${props => props.theme.fontSizes.default2};
`
