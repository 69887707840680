import React from 'react'
import Tour from 'reactour'
import { useTranslation } from 'react-i18next'
import { GlobalStyleTour, styleSteps } from './styles'

export default function TradeTour({ isOpen, onRequestClose }) {
  const { t } = useTranslation()
  const steps = [
    {
      selector: '#rightSide .trade-section',
      content: t('tradeTour1'),
      style: styleSteps,
    },
    {
      selector: '#leftSide .trade-section',
      content: t('tradeTour2'),
      style: styleSteps,
    },
    {
      selector: '#leftSide .library-section',
      content: t('tradeTour3'),
      style: styleSteps,
    },
    {
      selector: '#leftSide .library-section .sc-jWBwVP.dHkThy',
      content: t('tradeTour4'),
      style: styleSteps,
    },
    {
      selector: '#rightSide .library-section',
      content: t('tradeTour5'),
      style: styleSteps,
    },
    {
      selector: '.ready-to-trade',
      content: t('tradeTour6'),
      style: styleSteps,
    },
    {
      selector: '.cancel-trade',
      content: t('tradeTour7'),
      style: styleSteps,
    },
    {
      selector: '.open-tour',
      content: t('tradeTour8'),
      style: styleSteps,
    },
  ]

  const options = {
    isOpen,
    steps,
    maskSpace: 8,
    badgeContent: (curr, tot) => `${curr} of ${tot}`,
    onRequestClose,
    onAfterOpen: () => {
      document.getElementById('appBackgroundOverflow').style.overflowX = 'unset'
    },
    onBeforeClose: () => {
      document.getElementById('appBackgroundOverflow').style.overflowX = 'hidden'
    },
    accentColor: '#08A4DE',
    className: 'itemReactTour',
    maskClassName: 'maskReactTour',
    disableInteraction: true,
    prevButton: 'Prev',
    nextButton: 'Next',
    startAt: 0,
  }

  return (
    <>
      <GlobalStyleTour />
      <Tour {...options} />
    </>
  )
}
