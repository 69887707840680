import React from 'react'
import { Popover, Badge} from 'evergreen-ui'
import { PopoverContent } from './styles'

const InputPopover = ({ children }) => (
  <Popover
    content={
      <PopoverContent>
        {children}
      </PopoverContent>
    }
  >
    <Badge
      position="absolute"
      bottom={0}
      right={0}
      color="teal"
      isSolid
      marginBottom={-4}
      marginRight={-4}
      cursor="pointer"
      borderRadius={100}
      textTransform="normal"
      textAlign="center"
    >
      i
    </Badge>
  </Popover>
)

export default InputPopover
