import React from 'react'

import { Grid, Button } from '../../../../../commons'
import {
  Column,
  ShippingStatus,
  ButtonsGroup,
} from '../../styles'
import { TransactionResume } from '../../components'
import { goBack, navigateToNext } from '../../../store'

const ShippingSelected = ({
  dispatch,
  myTrade,
  hisTrade,
  shippingIcon,
}) =>{
  const [myMedia] = myTrade.trading
  const [hisMedia] = hisTrade.trading
  const handleNext = (event) => {
    event.stopPropagation()
    dispatch(navigateToNext())
  }

  const handleBack = (event) => {
    event.stopPropagation()
    dispatch(goBack())
  }

  return (
    <Grid container justify="center">
      <Column item md={12} lg={12}>
        <img src={shippingIcon} alt="pickup" />
      </Column>
      <ShippingStatus item md={12} lg={12}>
        <h1>
          You both selected
          <span>
          {' '}
            {myTrade.shipping.method === 'pickup' ? myTrade.shipping.method : 'Ship it'}
        </span>
        </h1>
      </ShippingStatus>
      <TransactionResume myMedia={myMedia} hisMedia={hisMedia} />
      <ButtonsGroup>
        <Button variation="info" onClick={event => handleBack(event)}>BACK</Button>
        <Button variation="success" onClick={event => handleNext(event)}>NEXT</Button>
      </ButtonsGroup>
    </Grid>
  )
}

export default ShippingSelected
