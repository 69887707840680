import api from './api'

const COOKIE_KEY = 'new-used-media-logged'
const COOKIE_FIRSTLOGIN_KEY = 'new-used-media-first-login'

export const isAuthenticated = () => {
  const match = document.cookie.match(new RegExp(`(^| )${COOKIE_KEY}=([^;]+)`))

  if (match) {
    return match[2]
  }

  return false
}

export const isFirstLogin = () => {
  const match = document.cookie.match(
    new RegExp(`(^| )${COOKIE_FIRSTLOGIN_KEY}=([^;]+)`),
  )

  if (match) {
    return match[2] === 'true'
  }

  return false
}

export const signIn = signInForm => api.post('auth/signin', signInForm)

export const signUp = signUpForm => api.post('auth/signup', signUpForm)

export const signUpWizard = signUpWizardForm => api.post('user/wizard', signUpWizardForm)

export const getUserAddress = () => api.get('user/address').then(res => res.data)

export const createAddress = address => api.post('user/address', address).then(res => res.data)

export const deleteAddress = addressId => api.delete(`user/address/${addressId}`).then(res => res.data)

export const updateAddress = (address, addressId) => api.put(`user/address/${addressId}`, address).then(res => res.data)

export const validateAddress = address => api.post('user/address/validate', address).then(res => res.data)
