import React, { useEffect, useState } from 'react'
import { Spinner } from 'evergreen-ui'
import { toast } from 'react-toastify'
import Iframe from 'react-iframe'
import { useMoment } from '../../../../../../hooks'

import { TrackingTable, Button } from '../../../../../commons'
import { getTracking, recoverLabel } from '../../../../../../services/trade'
import { goBack, navigateToNext } from '../../../store'
import { TransactionResume } from '../../components'
import {
  ButtonsGroup,
  TableContainer,
  TableSection,
  TrackingStatus,
  Status,
  Title,
  ShippingLabel,
  TrackingSection,
  TrackingHistory,
  ShippingAlert,
} from '../../styles'

const PROCESSED = 'PRE_TRANSIT'
const IN_TRANSIT = 'TRANSIT'
const DELIVERED = 'DELIVERED'
const RETURNED = 'RETURNED'
const FAILURE = 'FAILURE'

const Tracking = ({
  dispatch, hisTrade, myTrade, tradeId, lastUpdate,
}) => {
  const [myMedia] = myTrade.trading
  const [hisMedia] = hisTrade.trading
  const moment = useMoment()
  const dateAfter = moment(lastUpdate).add(2, 'days')
  const currentDate = moment()
  const [shippingLabel, setShippingLabel] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [isFetchingLabel, setIsFetchingLabel] = useState(false)
  const [tracking, setTracking] = useState({})
  const [trackingHistory, setTrackingHistory] = useState([])
  const [eta, setEta] = useState('')
  const defaultTracking = [
    {
      object_id: tradeId,
      status_date: lastUpdate,
      status_details: `Waiting for ${hisTrade.user.displayName} to create his shipping label`,
      location: {
        city: hisTrade.shipping.address.city,
        state: hisTrade.shipping.address.state,
      },
    },
  ]
  useEffect(() => {
    if (hisTrade.shipping.transactionId) {
      fetchTracking()
    }
  }, [lastUpdate])
  useEffect(() => {
    fetchShippingLabel()
  }, [])
  const fetchShippingLabel = async () => {
    try {
      setIsFetchingLabel(true)
      const { label_url } = await recoverLabel(tradeId)
      setShippingLabel(label_url)
    } catch (error) {
      toast.dismiss()
      if (error.response.data.errors) {
        error.response.data.errors.map(item => toast.error(item))
      } else {
        toast.error(
          error.response && error.response.data.message
            ? error.response.data.message
            : 'An error occurred. Try again later.',
        )
      }
    } finally {
      setIsFetchingLabel(false)
    }
  }
  const fetchTracking = async () => {
    try {
      setIsLoading(true)
      const { tracking_history, tracking_status } = await getTracking(
        hisTrade.shipping.transactionId,
      )
      setEta(eta)
      setTracking(tracking_status ? tracking_status : "")
      setTrackingHistory(tracking_history ? tracking_history : "")
    } catch (error) {
      toast.dismiss()
      if (error.response.data.errors) {
        error.response.data.errors.map(item => toast.error(item))
      } else {
        toast.error(
          error.response && error.response.data.message
            ? error.response.data.message
            : 'An error occurred. Try again later.',
        )
      }
    } finally {
      setIsLoading(false)
    }
  }
  const onClick = (event) => {
    event.stopPropagation()
    event.preventDefault()
    return dispatch(navigateToNext())
  }




  const onClick2 = (event) => {
    event.stopPropagation()
    event.preventDefault()
    return dispatch(navigateToNext())
  }
  const { status } = tracking
  const getDeliveryLabel = (status) => {
    if (status === FAILURE) {
      return 'failure'
    }
    if (status === RETURNED) {
      return 'returned'
    }
    return 'delivery'
  }

  const handleBack = (event) => {
    event.stopPropagation()
    dispatch(goBack())
  }

  return (
    <div>
      <Title>Tracking Updates</Title>
      <TrackingStatus>
        <Status
          isActualState={
            status === IN_TRANSIT
            || status === PROCESSED
            || status === DELIVERED
            || status === FAILURE
            || status === RETURNED
          }
        >
          processed
        </Status>
        <Status
          isActualState={
            status === IN_TRANSIT
            || status === DELIVERED
            || status === FAILURE
            || status === RETURNED
          }
        >
          in transit
        </Status>
        <Status
          isActualState={
            status === DELIVERED || status === FAILURE || status === RETURNED
          }
          isReturned={status === RETURNED}
          isFailure={status === FAILURE}
        >
          {getDeliveryLabel(status)}
        </Status>
      </TrackingStatus>
      <TrackingSection justify="center" container>
        <TrackingHistory item xs={12} lg={6} xlg={6} md={6}>
          <TrackingUpdates
            trackingHistory={trackingHistory}
            isLoading={isLoading}
            eta={eta}
            defaultTracking={defaultTracking}
            transactionId={hisTrade.shipping.transactionId}
          />
        </TrackingHistory>
        <ShippingLabel item xs={12} lg={6} xlg={6} md={6}>
          <Iframe
            url={shippingLabel}
            loading={isFetchingLabel}
            width="100%"
            height="100%"
          />

          <ShippingAlert>
            You need to
            {' '}
            <strong>print this shipping label</strong>
            {' '}
to complete
            the transaction.
          </ShippingAlert>
        </ShippingLabel>
      </TrackingSection>
      <TransactionResume myMedia={myMedia} hisMedia={hisMedia} />
      <ButtonsGroup>
        <Button variation="info" onClick={event => handleBack(event)}>
          BACK
        </Button>
        <Button
          disabled={tracking.status !== DELIVERED}
          onClick={onClick}
          variation="success"
        >
          NEXT
        </Button>

        {moment(currentDate).isAfter(dateAfter)
          ? (
            <Button
              onClick={onClick2}
              variation="success"
            >
              I sent the package
            </Button>
          )
        : null }

      </ButtonsGroup>
    </div>
  )
}

const TrackingUpdates = ({
  isLoading,
  trackingHistory,
  defaultTracking,
  transactionId,
}) => {
  if (isLoading) {
    return <Spinner marginX="auto" marginY="auto" />
  }
  return (
    <TableContainer>
      <TableSection centered>
        <TrackingTable
          trackingHistory={transactionId ? trackingHistory : defaultTracking}
        />
      </TableSection>
    </TableContainer>
  )
}

export default Tracking
