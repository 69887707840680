import React from 'react'

import { Grid } from '../../../../../commons'
import { Column, ShippingStatus } from '../../styles'
import { TransactionResume } from '../../components'

const WaitingOtherUser = ({ myTrade, hisTrade, shippingIcon }) => {
  const [myMedia] = myTrade.trading
  const [hisMedia] = hisTrade.trading
  return (
    <Grid container justify="center">
      <Column item md={12} lg={12}>
        <img src={shippingIcon} alt="pickup" />
      </Column>
      <ShippingStatus item md={12} lg={12}>
        <h1>
          You selected
          <span>{` ${myTrade.shipping.method}`}</span>
          <span>{`, please wait for ${hisTrade.user.displayName} to Confirm`}</span>
        </h1>
      </ShippingStatus>
      <TransactionResume myMedia={myMedia} hisMedia={hisMedia} />
    </Grid>
  )
}

export default WaitingOtherUser
