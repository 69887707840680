import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const StyledSvg = styled.svg`
  display: inline-block;
  vertical-align: middle;
`

const StyledPath = styled.path`
  fill: ${props => props.color};
`

const Icon = ({ icon, size, color }) => (
  <StyledSvg width={`${size}px`} height={`${size}px`} viewBox="0 0 24 24">
    {icon.map(path => (
      <StyledPath key={path} color={color} d={path} />
    ))}
  </StyledSvg>
)

Icon.propTypes = {
  icon: PropTypes.arrayOf(PropTypes.string).isRequired,
  size: PropTypes.number,
  color: PropTypes.string,
}

Icon.defaultProps = {
  size: 30,
  color: '#FFF',
}

export default Icon
