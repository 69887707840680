import styled from 'styled-components'
import { Grid } from '../../../commons'

export const GlobalSubmit = styled.div`
  margin-top: ${props => props.theme.spacing * 2}px;
  display: flex;
  justify-content: flex-end;
  position: absolute;
  bottom: 0;
  right: 0;
`

export const Container = styled(Grid)`
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 578px;
  align-items: flex-start;
`
