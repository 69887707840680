import api from '../../services/api'
import * as searchBarActions from '../templates/DefaultTemplate/Header/HiddenContent/actions'
import { fetchData, fetchSuccess, fetchError, logout } from './actions'

export const getUserInfo = async (dispatch) => {
  dispatch(fetchData())
  try {
    const response = await api.get('user/profile')
    dispatch(fetchSuccess(response.data))
  } catch (error) {
    dispatch(fetchError(error))
  }
}

export const logoutUser = async (dispatch) => {
  try {
    await api.get('auth/logout')
    dispatch(logout())
    dispatch(searchBarActions.hideTheHiddenContent())
  } catch (error) {
    console.log(error)
  }
}
