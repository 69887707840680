import styled, { keyframes } from 'styled-components'
import { Wrapper } from '../../../../../ui'
import { getZindex } from '../../../../../../helpers'

const SHOW_GLOBAL_NAVIGATION = keyframes`
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
`

export const StyledWrapper = styled(Wrapper)`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  padding: ${props => (props.isSm ? `0 ${props => props.theme.spacing * 2}px` : '0')};
`

export const NotificationHeader = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  background-color: #08658e;
  padding: ${props => `${props.theme.spacing}px ${props.theme.spacing * 2}px`};
  font-size: ${props => props.theme.fontSizes.legend};
  border-bottom: 1px solid #0c7eb0;

  &:before {
    display: block;
    content: '';
    width: 10px;
    height: 10px;
    position: absolute;
    top: -5px;
    right: 30px;
    transform: rotate(45deg);
    background-color: #08658e;
  }
  button {
    color: #fff;
    cursor: pointer;
  }
`

export const GlobalNavigation = styled.div`
  display: flex;
  flex-direction: column;
  animation: ${SHOW_GLOBAL_NAVIGATION} 150ms ease-out;
  width: ${props => (props.isSm ? '430px' : '100%')};
  max-height: 100%;
  margin-right: 50px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
  max-height: 400px;
`

export const LimitNavigation = styled.div`
  z-index: ${getZindex('default')};
  max-height: 80%;
`

export const NotificationFooter = styled.div`
  width: 100%;
  height: 16px;
  background-color: #0b1625;
  border-radius: 0 0 5px 5px;
`

export const GlobalNotifications = styled.div`
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  scroll-behavior: smooth;
`

export const LoadMore = styled.div`
  display: flex;
  justify-content: center;
  background-color: #08658e;
  padding: ${props => `${props.theme.spacing}px ${props.theme.spacing * 2}px`};
  font-size: ${props => props.theme.fontSizes.legend};
  color: #fff;
  cursor: pointer;
  text-align: center;

  span {
    opacity: ${props => (props.isLoading ? '0.4' : '1')};
  }
`
