import React, { useState } from 'react'
import { Dialog, Button } from 'evergreen-ui'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import api from '../../../services/api'
import { useRouter } from '../../../hooks'
import { DialogContent, DialogFooter, DialogHeader } from './styles'

const TradeDialog = (props) => {
  const {
    isOpen, handleClose, userId, mediaId,
  } = props
  const [isLoading, setIsLoading] = useState(false)
  const { history } = useRouter()
  const { t } = useTranslation()

  const onWay = (route) => {
    history.push(route)
    toast.dismiss()
  }

  const handleSubmit = async () => {
    setIsLoading(true)

    try {
      const response = await api.post('/trade', {
        to: userId,
        requestedItems: [mediaId],
      })
      handleClose()
      toast.success(
        <>
          <div style={{ marginBottom: '8px' }}>
            {t('Request sent successfully')}
          </div>
          <Button
            onClick={() => history.push(`/trade/${response.data.id}`)}
            style={{ marginRight: '8px' }}
          >
            {t('Go to trade')}
          </Button>
          <Button onClick={() => toast.dismiss()}>{t('Keep surfing')}</Button>
        </>,
        { autoClose: 12000 },
      )
    } catch (error) {
      setIsLoading(false)

      toast.dismiss()

      if (error.response.data.errors) {
        return toast.error(
          <>
            <div style={{ marginBottom: '8px' }}>{t('Please, finish setting up payment method')}</div>

            <Button onClick={() => onWay('/settings')}>
              {t('Set on your account')}
            </Button>
          </>,
          { autoClose: 12000 },
        )
      }
      if (
        error.response.data.message
        === 'You cant start a trade without medias in your library'
      ) {
        return toast.error(
          <>
            <div style={{ marginBottom: '8px' }}>
              {t('You can`t start a trade without medias in your library')}
            </div>

            <Button onClick={() => onWay('/add-media')}>
              {t('Add a media')}
            </Button>
          </>,
          { autoClose: 12000 },
        )
      }

      return toast.error(
        error.response && error.response.data.message
          ? error.response.data.message
          : t('An error occurred. Try again later.'),
      )
    }
  }

  return (
    <Dialog
      isShown={isOpen}
      onCloseComplete={handleClose}
      hasFooter={false}
      hasHeader={false}
      width={566}
      contentContainerProps={{ padding: 0 }}
      topOffset="30vh"
    >
      <DialogHeader>
        <h1>{t('Request item')}</h1>
      </DialogHeader>
      <DialogContent>
        <p>{t('Do you want request this item?')}</p>
      </DialogContent>
      <DialogFooter>
        <Button
          marginRight={12}
          intent="danger"
          appearance="primary"
          onClick={handleClose}
        >
          {t('Cancel')}
        </Button>
        <Button
          intent="success"
          appearance="primary"
          onClick={handleSubmit}
          isLoading={isLoading}
        >
          {t('Request')}
        </Button>
      </DialogFooter>
    </Dialog>
  )
}

export default TradeDialog
