import React, { Fragment } from 'react'
import NavigationMobile from './NavigationMobile'
import NavigationDesktop from './NavigationDesktop'
import { useMedia } from '../../../../hooks'

const Navigation = () => {
  const isLg = useMedia('(min-width: 1024px)')

  return (
    <Fragment>{isLg ? <NavigationDesktop /> : <NavigationMobile />}</Fragment>
  )
}

export default Navigation
