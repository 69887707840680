import React from 'react'
import { useTranslation } from 'react-i18next'
import MyAccount from './MyAccount'
import { useStore } from '../../../../../store'
import { MenuItem, MenuItemSignIn, GlobalMenu } from './styles'

const NavigationDesktop = () => {
  const { user } = useStore()
  const { t } = useTranslation()

  return (
    <GlobalMenu>
      <MenuItem to="/how-it-works">{t('How it works')}</MenuItem>

      {Object.keys(user.data).length === 0 && (
        <MenuItemSignIn to="/signin">Sign In</MenuItemSignIn>
      )}

      {Object.keys(user.data).length > 0 && <MyAccount user={user.data} />}
    </GlobalMenu>
  )
}

export default NavigationDesktop
