import React, { useState, useEffect, useRef } from 'react'
import { number, string, bool } from 'prop-types'

import { useTranslation } from 'react-i18next'
import { useStore } from '../../../store'
import { useDebounce, useRouter, useOutsideClick } from '../../../hooks'
import { Loader, IconButton } from '..'
import { bookColor } from '../../../assets'
import { mediaSearch } from './services'
import * as searchActions from './actions'
import { SELECT_OPTIONS, FILTER_IMAGES } from './constants'
import ICONS from '../../ui/ICONS'
import {
  OutsideContainer,
  Container,
  Select,
  SearchInput,
  Filters,
  CloseIcon,
} from './styles'
import SearchResult from './SearchResult'

function SearchBar({
  inputId,
  maxWidth,
  backgroundColor,
  spacing,
  maxWidthResult,
  maxHeightResult,
  zIndex,
  withTransition,
  withClickOutside,
  hasAnimationOnFocus,
}) {
  const { history } = useRouter()
  const { search, dispatch } = useStore()
  const debouncedSearchTerm = useDebounce(search.searchTerm, 400)
  const [animate, setAnimate] = useState(withTransition)
  const clickOutsideRef = useRef(undefined)
  const { t } = useTranslation()

  function clearResults() {
    dispatch(searchActions.clearSearchResults())
    dispatch(searchActions.closeSearchResults())
  }

  function onSearchSubmit() {
    dispatch(searchActions.populateHomeSearch(search.searchResults))
    dispatch(searchActions.clearSearchResults())
    if (inputId) {
      document.getElementById(inputId).blur()
    }
    history.push('/')
    setTimeout(() => {
      document.getElementById('wrapperGoToTop').scroll({
        top: window.innerHeight - 170,
        behavior: 'smooth',
      })
    }, 600)
  }

  function handleKeyPress(e) {
    if (e.key === 'Enter' && search.searchResults.length > 0) {
      onSearchSubmit()
    }
  }

  async function searchTerm(term, filter) {
    try {
      dispatch(searchActions.fetchSearchResults())
      const res = await mediaSearch(term, filter)
      dispatch(searchActions.successSearchResults(res.data.docs))
      dispatch(searchActions.openSearchResults())
    } catch (error) {
      dispatch(searchActions.errorSearchResults())
    }
  }

  function onFocus() {
    if (search.searchResults && search.searchResults.length) {
      dispatch(searchActions.openSearchResults())
    }
  }

  useOutsideClick(clickOutsideRef, () => {
    if (withClickOutside) {
      dispatch(searchActions.closeSearchResults())
      dispatch(searchActions.clearSearchResults())
    }
  })

  useEffect(() => {
    if (animate) {
      setAnimate(false)
    }
  }, [])

  useEffect(() => {
    if (debouncedSearchTerm) {
      searchTerm(debouncedSearchTerm, search.activeFilter)
    } else {
      clearResults()
    }
  }, [debouncedSearchTerm, search.activeFilter])

  return (
    <OutsideContainer
      animate={animate}
      backgroundColor={backgroundColor}
      spacing={spacing}
      zIndex={zIndex}
      ref={clickOutsideRef}
    >
      <Container maxWidth={maxWidth} hasAnimationOnFocus={hasAnimationOnFocus}>
        <Select activeFilter={search.activeFilter}>
          <select
            value={search.activeFilter}
            onChange={e => dispatch(searchActions.changeActiveFilter(e.target.value))
            }
          >
            {SELECT_OPTIONS.map((option) => {
              const { key, label } = option
              return (
                <option key={key} value={key}>
                  {t(label)}
                </option>
              )
            })}
          </select>
        </Select>
        <SearchInput
          id={inputId}
          value={search.searchTerm}
          onChange={e => dispatch(searchActions.changeSearchTerm(e.target.value))
          }
          onFocus={onFocus}
          onKeyPress={handleKeyPress}
          autoComplete="off"
          placeholder={t('searchPlaceholder')}
        />
        <Filters>
          {search.loadingResults && <Loader color="#000" />}
          {search.searchTerm && !search.loadingResults && (
            <CloseIcon title="Clear" onClick={clearResults} />
          )}

          {FILTER_IMAGES[search.activeFilter].map(filterItem => (
            <React.Fragment key={filterItem.key}>
              {filterItem.key === 'BOOK' && (
                <svg
                  width="40px"
                  height="40px"
                  viewBox="0 0 24 24"
                  onClick={() => dispatch(searchActions.changeActiveFilter(filterItem.key))
                  }
                  style={{ cursor: 'pointer' }}
                >
                  <image xlinkHref={bookColor} />
                </svg>
              )}

              {filterItem.key !== 'BOOK' && (
                <IconButton
                  key={filterItem.key}
                  icon={ICONS[filterItem.key]}
                  size={40}
                  color={filterItem.color}
                  onClick={() => dispatch(searchActions.changeActiveFilter(filterItem.key))
                  }
                />
              )}
            </React.Fragment>
          ))}
        </Filters>
      </Container>

      {search.searchResultsOpen && (
        <SearchResult
          spacing={spacing}
          maxWidthResult={maxWidthResult}
          maxHeightResult={maxHeightResult}
          searchCallback={onSearchSubmit}
        />
      )}
    </OutsideContainer>
  )
}

SearchBar.propTypes = {
  inputId: string,
  maxWidth: number,
  backgroundColor: string,
  spacing: number,
  maxWidthResult: number,
  maxHeightResult: number,
  zIndex: number,
  withTransition: bool,
  withClickOutside: bool,
  hasAnimationOnFocus: bool,
}

SearchBar.defaultProps = {
  inputId: '',
  maxWidth: undefined,
  backgroundColor: 'transparent',
  spacing: undefined,
  maxWidthResult: undefined,
  maxHeightResult: 300,
  zIndex: 0,
  withTransition: false,
  withClickOutside: false,
  hasAnimationOnFocus: false,
}

export default SearchBar
