import styled from 'styled-components'

export const Title = styled.h2`
  font-size: ${props => props.theme.fontSizes.heading1};
  margin-bottom: ${props => props.theme.spacing * 2}px;
  text-align: center;
`

export const GlobalUser = styled.div`
  display: flex;
  align-items: center;
  margin-left: ${props => `-${props.theme.spacing}px`};
  margin-top: ${props => `-${props.theme.spacing}px`};
  margin-bottom: ${props => `-${props.theme.spacing}px`};
`

export const Td = styled.td`
  & > div {
    margin: 0;
  }
`
