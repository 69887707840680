import React from 'react'
import { array, func, bool } from 'prop-types'

import { SearchItem, EmptyResults } from '../../../commons'
import { GlobalResults } from './styles'

const ResultsSearchByTitle = (props) => {
  const {
    items, isLoading, searchTerm, callbackOnClickItem,
  } = props

  return (
    <GlobalResults>
      {!isLoading && items.length > 0 && searchTerm && (
        <>
          {items.map(item => (
            <SearchItem
              key={Math.random()}
              data={item}
              onClick={() => callbackOnClickItem(item)}
            />
          ))}
        </>
      )}

      {!isLoading && items.length === 0 && searchTerm && (
        <EmptyResults searchTerm={searchTerm} />
      )}
    </GlobalResults>
  )
}

ResultsSearchByTitle.propTypes = {
  items: array,
  callbackOnClickItem: func,
  isLoading: bool,
}

ResultsSearchByTitle.defaultProps = {
  callbackOnClickItem: null,
  isLoading: false,
}

export default ResultsSearchByTitle
