import styled from 'styled-components'

export const Title = styled.h2`
  font-size: ${props => props.theme.fontSizes.heading1};
  margin-bottom: ${props => props.theme.spacing * 2}px;
  text-align: center;
`

export const GlobalButton = styled.div`
  display: flex;
  justify-content: center;
`

export const GlobalUser = styled.div`
  display: flex;
  align-items: center;
  margin-left: ${props => `-${props.theme.spacing}px`};
  margin-top: ${props => `-${props.theme.spacing}px`};
  margin-bottom: ${props => `-${props.theme.spacing}px`};

  > strong {
    margin-right: ${props => `${props.theme.spacing / 2}px`};
  }
`

export const Selected = styled.div`
  text-align: right;
`
