import styled from 'styled-components'

export const Title = styled.h1`
  font-weight: 600;
  font-size: 28px;
  text-align: center;
  margin-bottom: ${props => props.theme.spacing * 2}px;
`

export const GlobalAction = styled.div`
  display: flex;
  justify-content: flex-end;
`
