import React, { useState } from 'react'
import { Dialog, Button } from 'evergreen-ui'
import { useTranslation } from 'react-i18next'
import { DialogContent, DialogFooter, DialogHeader } from './styles'
import { monthly } from '../../../../assets'
import api from '../../../../services/api'

const TradeDialog = (props) => {
  const { isOpen, handleClose } = props
  const [isLoading, setIsLoading] = useState(false)
  const [confirmState, setConfirmState] = useState(1)
  const { t } = useTranslation()

  const onSubAccept = async () => {
    if (confirmState === 1) setConfirmState(2)
    if (confirmState === 2) {
      try {
        setIsLoading(true)
        await api.post('/payment/subscribe')
      } catch (error) {
        console.log(error)
      }
      setConfirmState(1)
      setIsLoading(false)
      handleClose()
    }
  }

  return (
    <Dialog
      isShown={isOpen}
      onCloseComplete={handleClose}
      hasFooter={false}
      hasHeader={false}
      width={566}
      minHeightContent={600}
      contentContainerProps={{ padding: 0 }}
      topOffset="30vh"
    >
      <DialogHeader>
        <h1>{t('Would you like to subscribe for a monthly plan?')}</h1>
      </DialogHeader>
      <DialogContent>

        <div>
          <h1>{t('Monthly Subscription')}</h1>
          <img src={monthly} height="70" width="92" alt="calendar" />
          <div>
            <p>{t('On this option you will pay')}</p>
            <p>{t('6.99 U$D per month + Shipping')}</p>

          </div>
        </div>
      </DialogContent>
      <DialogFooter>
        <Button
          marginRight={12}
          intent="danger"
          appearance="primary"
          onClick={handleClose}
          disabled={confirmState === 2}
        >
          {t('Keep my current plan')}
        </Button>
        <Button
          intent={confirmState === 2 ? 'warning' : 'success'}
          appearance="primary"
          onClick={onSubAccept}
          isLoading={isLoading && confirmState === 2}
          confirm={confirmState}
        >
          {confirmState === 1 ? t('Upgrade my plan') : t('Confirm')}
        </Button>
      </DialogFooter>
    </Dialog>
  )
}

export default TradeDialog
