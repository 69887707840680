import styled from 'styled-components'

const variations = [
  {
    key: 'primary',
    bgColor: '#0BA0BE',
    bgColor2: '#006E9D',
    hover: '#0069d9',
    border: '#0062cc',
    color: '#fff',
  },
  {
    key: 'secondary',
    bgColor: '#6c757d',
    hover: '#5a6268',
    border: '#545b62',
    color: '#fff',
  },
  {
    key: 'success',
    bgColor: '#4CD964',
    bgColor2: '#0C9D25',
    hover: '#218838',
    border: '#1e7e34',
    color: '#fff',
  },
  {
    key: 'danger',
    bgColor: '#FF0606',
    bgColor2: '#9F080D',
    hover: '#c82333',
    border: '#bd2130',
    color: '#fff',
  },
  {
    key: 'warning',
    bgColor: '#E69814',
    bgColor2: '#F9C040',
    hover: '#e0a800',
    border: '#d39e00',
    color: '#212529',
  },
  {
    key: 'info',
    bgColor: '#00CBFF',
    bgColor2: '#006E9D',
    hover: '#138496',
    border: '#117a8b',
    color: '#fff',
  },
  {
    key: 'change',
    bgColor: '#EE773B',
    bgColor2: '#BA4F1A',
    hover: '#EE773B',
    border: '#EE773B',
    color: '#fff',
  },
  {
    key: 'ghost',
    bgColor: 'transparent',
    hover: 'transparent',
    border: 'transparent',
    color: '#fff',
  },
]

const getWidth = (props) => {
  if (props.fluid) {
    return '100%'
  }

  if (props.icon && !props.label) {
    return '40px'
  }

  return 'auto'
}

export const StyledButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  outline: none;
  font-family: ${props => props.theme.fontFamily};
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  font-size: ${props => props.theme.fontSizes.default};
  border-radius: 4px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out;
  background-color: ${props => variations.find(type => type.key === props.variation).bgColor};
  background-image: ${props => `linear-gradient(${
    variations.find(type => type.key === props.variation).bgColor
  }, ${variations.find(type => type.key === props.variation).bgColor2})`};
  color: ${props => variations.find(type => type.key === props.variation).color};
  width: ${props => getWidth(props)};

  :hover {
    background-image: ${props => `linear-gradient(${
    variations.find(type => type.key === props.variation).bgColor2
  }, ${variations.find(type => type.key === props.variation).bgColor})`};
  }

  ${props => props.size === 'small'
    && `
    height: 30px;
    padding: 0 ${props.icon && !props.label ? 0 : `${props.theme.spacing}px`};
  `}

  ${props => props.size === 'medium'
    && `
    height: 40px;
    padding: 0 ${
  props.icon && !props.label ? 0 : `${props.theme.spacing * 2}px`
};
    `}

${props => props.size === 'large'
  && `
  height: 50px;
  padding: 0 ${props.icon && !props.label ? 0 : `${props.theme.spacing * 2}px`};
  `}

  &:not(:disabled) {
    cursor: pointer;
  }
  &:disabled {
    opacity: 0.65;
  }
  &:focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
  }
`

export const GlobalLoader = styled.div`
  margin-left: ${props => props.theme.spacing}px;
`

export const GlobalButton = styled.div`
  display: inline-block;
`
