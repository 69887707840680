import styled from 'styled-components'
import { Grid } from '../../../../commons'

export const Container = styled(Grid)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  margin-bottom: ${props => props.marginBottom}px;
  margin-top: ${props => props.marginTop}px;
    @media(max-width: 430px) {
    flex-direction: column;
    }
`

export const MediaSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${props => props.justify};
    @media(max-width: 430px) {
      flex-direction: column;
    }
    & > p:first-child {
      margin-right: 16px;
      word-break: normal;
      width: 120px;
      text-align: right;
    }
    & > p:last-child {
      margin-left: 16px;
      word-break: normal;
      width: 120px;
    }
    & > img {
      border-radius: 5px;
    }
`

export const ArrowsSmall = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
  margin-right: 8px;
   @media(max-width: 430px) {
    margin: 0 auto;
   }
    }
    & > img:first-child {
    margin-right: 10px;
  }
    & > img:last-child {
    margin-left: 10px;
`

export const PopoverContent = styled.div`
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
`
