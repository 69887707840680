import React, { lazy, Suspense } from 'react'
import { useTranslation } from 'react-i18next'
// import { MediaCard, Grid, Button } from '../../../commons'
import { Title } from '../../../ui'
import { Container, GlobalSeeMore } from './styles'

const MediaCard = lazy(() => import('../../../commons/MediaCard'))
const Grid = lazy(() => import('../../../commons/Grid'))
const Button = lazy(() => import('../../../commons/Button'))

const styles = {
  title: {
    textTransform: 'uppercase',
    fontWeight: 600,
    fontSize: 22,
  },
}

export default function HomeSlider({
  items = [],
  title,
  viewMode,
  callbackSeeMore,
  nextPage,
  isLoading,
}) {
  const { t } = useTranslation()
  const grid = {
    less: {
      xs: 12,
      sm: 6,
      md: 4,
      lg: 3,
    },
    more: {
      xs: 4,
      sm: 3,
      md: 2,
      lg: 2,
    },
  }

  const seeMore = async (e) => {
    e.preventDefault()
    e.stopPropagation()
    callbackSeeMore()
  }

  return (
    <Container>
      <Title style={styles.title}>{title}</Title>
      <Suspense fallback={<div>Loading...</div>}>
      <Grid container>
        {items.map(item => (
          <Grid
            key={item.id}
            item
            xs={grid[viewMode].xs}
            sm={grid[viewMode].sm}
            md={grid[viewMode].md}
            lg={grid[viewMode].lg}
          >
            <MediaCard
              data={{
                ...item,
                thumb: item.thumb,
              }}
              showUser
              actions="default"
            />
          </Grid>
        ))}
      </Grid>
      </Suspense>

      <GlobalSeeMore>
        {nextPage && (
        <Suspense fallback={<div>Loading...</div>}>
          <Button
            variation="info"
            onClick={e => seeMore(e)}
            isLoading={isLoading}
          >
            {t('See more')}
          </Button>
        </Suspense>
        )}
      </GlobalSeeMore>
    </Container>
  )
}
