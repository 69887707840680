import styled from 'styled-components'
import SocialButton from '../styles'

export default styled(SocialButton)`
  width: 100%;
  background: #6441a5;
  color: #fff;
  &:hover {
    background: linear-gradient(#6441a5 10%, #412475);
  }
`
