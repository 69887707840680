import React, { useState, useEffect } from 'react'
import { bool, array } from 'prop-types'
import { withTheme } from 'styled-components'
import { Icon } from '..'
import { LabelForm } from '../../ui'
import ICONS from '../../ui/ICONS'
import { GlobalDelivery, StyledDelivery } from './styles'

const Delivery = (props) => {
  const {
    theme,
    label,
    callbackChange,
    noMargin,
    colorLabel,
    options,
    uniqueSelection,
  } = props

  const [optionsState, setOptionsState] = useState({})

  const handleChange = (option) => {
    if (!callbackChange) return

    setOptionsState((prev) => {
      const prevArray = JSON.parse(JSON.stringify(prev))

      if (uniqueSelection) {
        Object.keys(prevArray).map(
          key => (prevArray[key] = key === option.key ? !prevArray[option.key] : false),
        )
      } else {
        prevArray[option.key] = !prevArray[option.key]
      }
      return prevArray
    })
  }

  useEffect(() => {
    const newOptionsState = {}

    options.map(option => (newOptionsState[option.key] = option.status))

    setOptionsState(newOptionsState)
  }, [options.length])

  useEffect(() => {
    if (!Object.keys(optionsState).length || !callbackChange) return

    callbackChange(optionsState)
  }, [optionsState])

  return (
    <GlobalDelivery noMargin={noMargin}>
      {label && <LabelForm color={colorLabel}>{label}</LabelForm>}

      {options.map(option => (
        <StyledDelivery
          key={option.title}
          status={optionsState[option.key]}
          onClick={() => handleChange(option)}
          hasCallback={!!callbackChange}
        >
          <Icon
            icon={optionsState[option.key] ? ICONS.YES : ICONS.NO}
            color={
              optionsState[option.key]
                ? theme.colorPositive
                : theme.colorNegative
            }
          />
          {option.title}
        </StyledDelivery>
      ))}
    </GlobalDelivery>
  )
}

Delivery.propTypes = {
  noMargin: bool,
  options: array,
  uniqueSelection: bool,
}

Delivery.defaultProps = {
  noMargin: false,
  options: [],
  uniqueSelection: false,
}

export default withTheme(Delivery)
