import React from 'react'
import './Index.css'

import { useStore } from '../../../../../store'
import { Grid, MediaCard, MediasPopover, Button } from '../../../../commons'
import {
  Arrows,
  ButtonsGroup,
  MediaContainer,
} from '../styles'
import { leftArrow, rightArrow } from '../../../../../assets'
import { goBack, navigateToNext } from '../../store'



const Transaction = ({ dispatch, state, isFinished }) => {
  const { from, to } = state
  const {
    user: {
      data: { id },
    },
  } = useStore()
  const myTrade = from.user.id === id ? from : to
  const hisTrade = from.user.id !== id ? from : to
  const [myMedia] = myTrade.trading
  const [hisMedia] = hisTrade.trading
  const hisMediaData = {
    ...hisMedia,
    user: hisTrade.user,
  }
  const myMediaData = {
    ...myMedia,
    user: myTrade.user,
  }

  const handleNext = (event) => {
    event.stopPropagation()
    dispatch(navigateToNext())
  }

  const handleBack = (event) => {
    event.stopPropagation()
    dispatch(goBack())
  }

  return (
    <Grid container justify="center">
      <MediaContainer align="flex-end" item md={5} lg={5}>
        <div className="pulsate-fwd">
          <MediaCard actions="none" data={myMediaData} />
          <p>Your item</p>
          <MediasPopover mediasArray={myTrade.trading} />
        </div>
      </MediaContainer>
      <Arrows item md={2} lg={2}>
        <img src={leftArrow} alt="left-arrow" />
        <img src={rightArrow} alt="right-arrow" />
      </Arrows>
      <MediaContainer align="flex-start" item md={5} lg={5}>
        <div className="pulsate-fwd">
          <MediaCard actions="none" data={hisMediaData} showUser />
          <MediasPopover mediasArray={hisTrade.trading} />
        </div>
      </MediaContainer>
      <ButtonsGroup>
        {isFinished && (
          <Button variation="info" onClick={event => handleBack(event)}>BACK</Button>
        )}
        <Button variation="success" onClick={event => handleNext(event)}>NEXT</Button>
      </ButtonsGroup>
    </Grid>
  )
}

export default Transaction
