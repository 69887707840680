import React from 'react'
import api from '../../../../../../services/api'
import { BrandBadge } from '../../../../../commons'
import Icon from '../../../../../commons/Icon'
import ICONS from '../../../../../ui/ICONS'
import {
  Container, MediaImage, MediaName, MediaPlatform, Actions, Refuse,
} from './styles'

export default function MediaSelected(props) {
  const { data, tradeId, readyToTrade } = props

  function removeMedia() {
    api.post('trade/media/remove', { tradeId, mediaId: data.id })
  }
  return (
    <Container>
      <MediaImage src={data.pictures ? data.pictures[0] : null} />
      <MediaName>
        <span>{data.title}</span>
      </MediaName>
      <MediaPlatform>
        <BrandBadge platform={data.platform} size="small" />
      </MediaPlatform>

      <Actions>
        <Refuse onClick={removeMedia} disabled={readyToTrade}>
          <Icon icon={ICONS.NO} size={18} />
        </Refuse>
      </Actions>
    </Container>
  )
}
