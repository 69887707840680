import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { logoWhite } from '../../../assets'

const GlobalLogo = styled(Link)`
  display: inline-block;
  height: ${props => props.height || 'auto'};

  > img {
    height: ${props => (props.height ? '100%' : 'auto')};
  }
`

const Header = ({ height }) => (
  <GlobalLogo to="/" height={height}>
    <img src={logoWhite} alt="New Used Media" />
  </GlobalLogo>
)

export default Header
