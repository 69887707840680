import React, { useState } from 'react'
import { bool } from 'prop-types'
import { GlobalSwipe } from './styles'

export const SwipeCheckbox = (props) => {
  const { callback, defaultIsChecked } = props
  const [status, setStatus] = useState(!!defaultIsChecked)

  const onChange = () => {
    const newStatus = !status
    setStatus(newStatus)
    callback(newStatus)
  }

  return <GlobalSwipe status={status} onClick={onChange} />
}

SwipeCheckbox.propTypes = {
  defaultIsChecked: bool,
}

SwipeCheckbox.defaultProps = {
  defaultIsChecked: false,
}

export default SwipeCheckbox
