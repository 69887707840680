import React from 'react'
import { useTranslation } from 'react-i18next'
import { userServices } from '../../../../../UserInfo'
import { useStore } from '../../../../../../store'
import * as searBarActions from '../actions'
import { useRouter } from '../../../../../../hooks'
import { StyledWrapper, GlobalNavigation, NavigationLink } from './styles'

const MyAccountNavigation = (props) => {
  const { userId } = props
  const { dispatch } = useStore()
  const { history } = useRouter()
  const { t } = useTranslation()

  const onClickLink = (route) => {
    history.push(route)
    dispatch(searBarActions.hideTheHiddenContent())
  }

  const onClickLogout = () => {
    history.push('/')
    dispatch(userServices.logoutUser(dispatch))
  }

  return (
    <StyledWrapper>
      <GlobalNavigation>
        <NavigationLink onClick={() => onClickLink('/settings')}>
          {t('Account')}
        </NavigationLink>
        <NavigationLink onClick={() => onClickLink('/history')}>
          {t('Transaction History')}
        </NavigationLink>
        <NavigationLink onClick={() => onClickLink(`/library/${userId}`)}>
          {t('My media')}
        </NavigationLink>
        <NavigationLink onClick={() => onClickLink('/create-notification-for-media')}>
          {t('My wish list')}
        </NavigationLink>
        <NavigationLink onClick={() => onClickLogout()}>{t('Logout')}</NavigationLink>
      </GlobalNavigation>
    </StyledWrapper>
  )
}

export default MyAccountNavigation
