import styled from 'styled-components'

export default styled.span`
  display: flex;
  align-items: baseline;
  margin-bottom: ${props => props.theme.spacing / 2}px;

  :before {
    content: '';
    display: block;
    width: 4px;
    height: 14px;
    border-top-right-radius: 45px;
    overflow: hidden;
    background-color: ${props => (props.color ? props.color : props.theme.colorPrimaryFeatured)};
    margin-right: ${props => props.theme.spacing - 2}px;
    margin-left: 2px;
    transition: background-color 300ms;
  }
`
