import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify'

import { useStore } from '../../../../../store'
import { useRouter } from '../../../../../hooks'
import { selectShippingMethod, declineTrade } from '../../../../../services/trade'
import { updateTrade } from '../../store'
import { deliveryOn, pickupOn } from '../../../../../assets'
import {
  ShippingRevoked,
  ShippingSelected,
  WaitingMyShipping,
  WaitingOtherUser,
  RequestShipping,
  ChooseAddress,
  ShippingDimensions,
} from './components'

const PENDING_SHIPPING = 'pending-shipping-method'
const SHIPPING_METHOD_SELECTED = 'shipping-method-selected'
const SHIPPING_METHOD_REVOKED = 'shipping-method-changed'

const Shipping = ({ state, dispatch }) => {
  useEffect(() => {
    setShippingProcess(getInitialScreen())
  }, [state.from.status, state.to.status])
  const { from, to, _id } = state
  const { user: { data: { id } } } = useStore()
  const { history } = useRouter()
  const myTrade = from.user.id === id ? from : to
  const hisTrade = from.user.id !== id ? from : to
  const [isLoading, setIsLoading] = useState(false)
  const [isCanceling, setIsCanceling] = useState(false)
  const getShippingMethod = () => {
    if (myTrade.status === PENDING_SHIPPING && hisTrade.status === PENDING_SHIPPING) {
      return null
    }
    if (hisTrade.status === SHIPPING_METHOD_SELECTED
      && myTrade.status === SHIPPING_METHOD_REVOKED) {
      return myTrade.shipping.method
    }
    if (myTrade.status === SHIPPING_METHOD_SELECTED
      && hisTrade.status === SHIPPING_METHOD_REVOKED) {
      return hisTrade.shipping.method
    }
    return hisTrade.shipping ? hisTrade.shipping.method : myTrade.shipping.method
  }
  const shippingIcon = getShippingMethod() === 'pickup' ? pickupOn : deliveryOn
  const getInitialScreen = () => {
    if (myTrade.status === PENDING_SHIPPING && hisTrade.status === PENDING_SHIPPING) {
      return 'requestShipping'
    }
    if ((myTrade.status === SHIPPING_METHOD_SELECTED && hisTrade.status === PENDING_SHIPPING)
        || myTrade.status === SHIPPING_METHOD_REVOKED
    ) {
      return 'waitingOtherUser'
    }
    if (myTrade.status === SHIPPING_METHOD_SELECTED && hisTrade.status === SHIPPING_METHOD_REVOKED) {
      return 'shippingRevoked'
    }
    if (hisTrade.status === SHIPPING_METHOD_SELECTED && myTrade.status === PENDING_SHIPPING) {
      return 'waitingMyShipping'
    }
    if ((myTrade.status === SHIPPING_METHOD_SELECTED || myTrade.status === SHIPPING_METHOD_REVOKED)
          && myTrade.shipping.method === 'delivery'
          && !myTrade.shipping.transactionId
    ) {
      return 'shippingDimensions'
    }
    return 'shippingSelected'
  }
  const [shippingProcess, setShippingProcess] = useState(getInitialScreen())
  const handleSelectPickUp = async () => {
    try {
      setIsLoading(true)
      const trade = await selectShippingMethod({
        tradeId: _id,
        shippingMethod: 'pickup',
      })
     return dispatch(updateTrade(trade))
    } catch (error) {
      toast.dismiss()
      if (error.response.data.errors){
        error.response.data.errors.map(item => toast.error(item))
      } else {
        toast.error(
          error.response && error.response.data.message
            ? error.response.data.message
            : 'An error occurred. Try again later.',
        )
      }
    } finally {
      setIsLoading(false)
    }
  }
  const handleCancelTrade = async event => {
    event.preventDefault()
    try {
      setIsCanceling(true)
      await declineTrade(_id)
      return history.push('/')
    } catch (error) {
      toast.dismiss()
      if (error.response.data.errors){
        error.response.data.errors.map(item => toast.error(item))
      } else {
        toast.error(
          error.response && error.response.data.message
            ? error.response.data.message
            : 'An error occurred. Try again later.',
        )
      }
    } finally {
      setIsCanceling(false)
    }
  }
  const screens = {
    waitingOtherUser: (
      <WaitingOtherUser
        shippingIcon={shippingIcon}
        myTrade={myTrade}
        hisTrade={hisTrade}
      />
    ),
    waitingMyShipping: (
      <WaitingMyShipping
        shippingIcon={shippingIcon}
        myTrade={myTrade}
        hisTrade={hisTrade}
        shippingMethod={getShippingMethod()}
        handleSelectPickUp={handleSelectPickUp}
        setShippingProcess={setShippingProcess}
        dispatch={dispatch}
        handleCancelTrade={handleCancelTrade}
        isLoading={isLoading}
        isCanceling={isCanceling}
      />
    ),
    shippingRevoked: (
      <ShippingRevoked
        setShippingProcess={setShippingProcess}
        shippingMethod={getShippingMethod()}
        shippingIcon={shippingIcon}
        myTrade={myTrade}
        hisTrade={hisTrade}
        handleSelectPickUp={handleSelectPickUp}
        dispatch={dispatch}
        handleCancelTrade={handleCancelTrade}
        isCanceling={isCanceling}
      />
    ),
    shippingSelected: (
      <ShippingSelected
        hisTrade={hisTrade}
        myTrade={myTrade}
        dispatch={dispatch}
        shippingIcon={shippingIcon}
      />
    ),
    requestShipping: (
      <RequestShipping
        dispatch={dispatch}
        setShippingProcess={setShippingProcess}
        handleSelectPickUp={handleSelectPickUp}
        myTrade={myTrade}
        hisTrade={hisTrade}
        isLoading={isLoading}
      />
    ),
    chooseAddress: (
      <ChooseAddress
        myTrade={myTrade}
        hisTrade={hisTrade}
        dispatch={dispatch}
        setShippingProcess={setShippingProcess}
        tradeId={_id}
      />
    ),
    shippingDimensions: (
      <ShippingDimensions
        myTrade={myTrade}
        hisTrade={hisTrade}
        dispatch={dispatch}
        tradeId={_id}
      />
    ),
  }

  return screens[shippingProcess]
}

export default Shipping
