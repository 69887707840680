import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const StyledModalFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  background-color: #0d2c45;
  border-bottom-right-radius: 0.4em;
  border-bottom-left-radius: 0.4em;
  padding: 0.75rem;
  > * {
    margin-right: 0.375rem;
  }
  > *:last-child {
    margin: 0;
  }
`

const ModalFooter = ({ children }) => (
  <StyledModalFooter>{children}</StyledModalFooter>
)

ModalFooter.propTypes = {
  children: PropTypes.node,
}

ModalFooter.defaultProps = {
  children: '',
}

export default ModalFooter
