import styled from 'styled-components'
import { arrowExchange } from '../../../assets'

export const GlobalVisualTrade = styled.div`
  width: ${props => (props.size === 'small' ? '120px' : '240px')};
  height: ${props => (props.size === 'small' ? '120px' : '240px')};
  position: relative;

  :before,
  :after {
    display: block;
    background-image: url(${arrowExchange});
    background-size: contain;
    background-repeat: no-repeat;
    content: '';
    width: ${props => (props.size === 'small' ? '50px' : '100px')};
    height: ${props => (props.size === 'small' ? '50px' : '100px')};
    position: absolute;
  }

  :before {
    bottom: 0;
    left: 0;
  }

  :after {
    top: 0;
    right: 0;
    opacity: 0.5;
    transform: rotate(180deg);
  }
`
