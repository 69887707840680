import styled from 'styled-components'
import { library } from '../../../assets'

export const GlobalMedia = styled.div`
  display: flex;
  align-items: center;
`

export const GlobalImage = styled.div`
  width: 50px;
  height: 50px;
  background-image: ${props => (props.src ? 'linear-gradient(180deg, #0E1C2E 0%, #070C14 100%)' : 'linear-gradient(0, #0A1C2E, #0879A6)')};
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  overflow: hidden;
`

export const Image = styled.div`
  width: 100%;
  height: 100%;
  background-image: url(${props => props.src ? props.src : library});
  background-size: cover;
  background-position: center;
`

export const Text = styled.div`
  margin-left: ${props => `${props.theme.spacing}px`};
`
