import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`

export const ChatHeader = styled.div`
  display: flex;
  margin-bottom: 12px;
  border-radius: 4px;
  overflow: hidden;
  background-color: #132a3f;

  & > div.avatar {
    background-color: #0ba0be;
    padding: 24px 6px;
    & > div.image {
      width: 48px;
      height: 48px;
      border-radius: 50%;
      background-color: red;
      box-shadow: 0 2px 6px 0px rgba(0, 0, 0, 0.6);
    }
  }
  & > div.row {
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;

    & > div.rating {
      display: flex;
      flex-direction: column;
      margin: 0 24px;
      text-transform: capitalize;
    }

    & > div.medias {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-right: 24px;
      & > div {
        justify-content: flex-end;
      }
    }
  }
`

export const ChatBody = styled.div`
  flex: 1;
  display: flex;
  background-color: #132a3f;
  border-radius: 4px;
  flex-direction: column;
  min-height: 300px;
  max-height: 300px;
  overflow: auto;
  padding: 8px 16px;
  padding-bottom: 0;
`

export const InputContainer = styled.div`
  margin-top: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #0b1625;
  padding: 12px;
  border-radius: 4px;

  & > input {
    flex: 1;
    background-color: #253f58;
    padding: 6px 12px;
    margin-right: 12px;
    border-radius: 4px;
    color: #fff;
  }

  & > button {
    background: linear-gradient(#00c1f5, #0076a6);
    border-radius: 4px;
    color: #fff;
    padding: 6px 12px;
    cursor: pointer;
    &:disabled {
      opacity: 0.55;
    }
  }
`

export const SystemMessage = styled.div`
  font-size: 12px;
  color: #000;
  background-color: rgba(253, 244, 197, 1);
  padding: 4px 8px;
  border-radius: 4px;
  margin-bottom: 8px;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.6);
`
