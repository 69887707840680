import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import api from '../../../../../../../services/api'
import { useRouter, useMoment } from '../../../../../../../hooks'
import { User, Media, Icon } from '../../../../../../commons'
import ICONS from '../../../../../../ui/ICONS'
import { searchBarActions } from '../..'
import { useStore } from '../../../../../../../store'
import { rightArrow as arrow } from '../../../../../../../assets'
import {
  GlobalNotificationItem,
  Time,
  GlobalMedias,
  GlobalArrows,
  Arrow,
  HistoryToggle,
  GlobalShowcase,
  Badge,
  GlobalTitle,
  GlobalHistory,
  HistoryItem,
  ShowHistory,
  GlobalMedia,
  BadgeMedia,
  NudgeButton,
} from './styles'

const NotificationItem = (props) => {
  const { data, openHistory, callbackOpenHistory } = props
  const { history } = useRouter()
  const { t } = useTranslation()
  const moment = useMoment()
  const { dispatch } = useStore()
  const [qtUnread, setQtUnread] = useState(0)
  const [read, setRead] = useState(false)
  const [showNudge, setShowNudge] = useState(false)
  const [nudging, setNudging] = useState(false)
  const [lastUpdate, setLastUpdate] = useState('')

  useEffect(() => {
    const newQtUnread = Object.keys(data.notifications).filter(
      key => !data.notifications[key].read,
    ).length
    setQtUnread(newQtUnread)
    setRead(newQtUnread === 0)
  }, [data])

  const onClickNotification = () => {
    history.push(`/trade/${data.id}`)
    dispatch(searchBarActions.hideTheHiddenContent())
  }

  const onToggleHistory = (e) => {
    e.preventDefault()
    e.stopPropagation()
    callbackOpenHistory(!openHistory ? data.id : '')
  }

  const onNudgeUser = async (e) => {
    e.preventDefault()
    e.stopPropagation()

    setNudging(true)

    try {
      await api.get(`/trade/nudge/${data.id}`)
      setShowNudge(false)
      toast.dismiss()
      toast.success('You nugded the user.')
    } catch (error) {
      toast.dismiss()
      toast.error('An error occurred. Try again later.')
    }

    setNudging(false)
  }

  useEffect(() => {
    if (!openHistory) return

    const keyLastNotification = Object.keys(data.notifications)[0]
    const lastNotification = data.notifications[keyLastNotification]
    const lastUpdateN = lastNotification.createdAt

    const diff = moment.utc(moment().diff(moment.utc(lastUpdateN)))
    const diffSplitted = diff.format('DD-HH').split('-')
    const diffDays = parseInt(diffSplitted[0]) - 1 // because diff returns 1 hour more
    const diffHours = parseInt(diffSplitted[1])

    if ((diffDays >= 1 || diffHours >= 6) && data.status !== 'declined') {
      setShowNudge(true)
      setLastUpdate(lastUpdateN)
    }
  }, [openHistory])

  return (

    <GlobalNotificationItem
      {...props}
      onClick={onClickNotification}
      read={read}
    >
      <GlobalShowcase>
        <GlobalTitle>
          {t('Trade with')}
          {' '}
          <User
            data={{
              id: data.otherUser.user.id,
              avatar: data.otherUser.user.avatar,
              username: data.otherUser.user.username,
            }}
          />
          <Time>
            { data.createdAt === data.updatedAt
              ? `${t('created at')} ${moment(data.createdAt).fromNow()}`
              : `${t('updated')} ${moment(data.updatedAt).fromNow()}`}
          </Time>
          <Time>{`${t('Trade Status')}: ${t(data.status)}`}</Time>
        </GlobalTitle>

        <GlobalMedias>
          <GlobalMedia>
            <Media
              data={{
                pictures: data.otherUser.trading[0]
                  ? data.otherUser.trading[0].pictures
                  : null,
              }}
              size={60}
            />

            {data.otherUser.trading.length > 1 && (
              <BadgeMedia>{`+${data.otherUser.trading.length - 1}`}</BadgeMedia>
            )}
          </GlobalMedia>

          <GlobalArrows read={read}>
            <Arrow src={arrow} />
            <Arrow src={arrow} />
          </GlobalArrows>

          <GlobalMedia>
            <Media
              data={{
                pictures: data.user.trading[0]
                  ? data.user.trading[0].pictures
                  : null,
              }}
              size={60}
            />

            {data.user.trading.length > 1 && (
              <BadgeMedia>{`+${data.user.trading.length - 1}`}</BadgeMedia>
            )}
          </GlobalMedia>
        </GlobalMedias>

        <div>
          <HistoryToggle onClick={onToggleHistory} read={read}>
            <Icon icon={ICONS.HISTORY} size={24} />

            <Icon icon={openHistory ? ICONS.UP : ICONS.DOWN} size={24} />

            {qtUnread > 0 && <Badge>{qtUnread}</Badge>}
          </HistoryToggle>
        </div>
      </GlobalShowcase>

      <ShowHistory open={openHistory}>
        {openHistory && (
          <GlobalHistory read={read}>
            {showNudge && (
              <HistoryItem itemRead>
                <div>
                  There&apos;s been no response on this trade
                  {' '}
                  <NudgeButton
                    onClick={onNudgeUser}
                    disabled={nudging}
                    nudging={nudging}
                  >
                    {t('Nudge user')}
                  </NudgeButton>
                </div>

                <span>{moment(lastUpdate).fromNow()}</span>
              </HistoryItem>
            )}

            {Object.keys(data.notifications).map(key => (
              <HistoryItem key={key} itemRead={data.notifications[key].read}>
                <div>{data.notifications[key].title}</div>

                <span>
                  {moment(data.notifications[key].createdAt).fromNow()}
                </span>
              </HistoryItem>
            ))}
          </GlobalHistory>
        )}
      </ShowHistory>
    </GlobalNotificationItem>
  )
}

export default NotificationItem
