import styled from 'styled-components'
import { Grid } from '../../../commons'

export const Addresses = styled(Grid)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 84px;
`

export const Address = styled.div`
  padding: 12px;
  background: #003049;
  position: relative;
  border: ${props => (props.isSelected ? '2px solid #4CD964' : '')};
  border-radius: 5px;
  width: 32%;
  min-width: 220px;
  height: 88px;
  margin-top: 8px;
  margin-left: 8px;
  & > p {
    font-size: 13px;
    line-height: 20px;
    margin-top: -14px;
  }
  & > div  {
      font-size: 14px;
    & > span {
      text-transform: uppercase;
    }
  }
`

export const NewAddress = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 2px solid #003049;
  width: 32%;
  min-width: 220px;
  height: 88px;
  margin-top: 8px;
  margin-left: 8px;
  border-radius: 5px;
  font-size: 13px;
  cursor: pointer;
`

export const Form = styled.form`
  & > div:last-child {
    margin-top: 10px;
  }
`

export const Container = styled(Grid)`
  width: 100%;
  height: 100%;
  min-height: 578px;
  align-items: flex-start;
`

export const IconContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`
