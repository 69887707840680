import React from 'react'
import { withTheme } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Title } from '../../ui'
import {
  GerneList, Genre, CategoryList, Category,
} from './styles'

const GenreFilter = (props) => {
  const { theme } = props
  const { t } = useTranslation()

  return (
    <div>
      <header>
        <Title color={theme.colorTertiary}>{t('Browse by genre')}</Title>

        <CategoryList>
          <Category>{t('GAMES')}</Category>
          <Category>{t('MOVIES')}</Category>
          <Category>{t('BOOKS')}</Category>
        </CategoryList>
      </header>

      <GerneList>
        <Genre>{t('Action')}</Genre>
        <Genre>{t('Family')}</Genre>
        <Genre>{t('Animated')}</Genre>
        <Genre>{t('Strategy')}</Genre>
      </GerneList>
    </div>
  )
}

export default withTheme(GenreFilter)
