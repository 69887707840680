import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import ICONS from '../../../../ui/ICONS'
import { Icon } from '../../../../commons'
import { brazil, spain, unitedStates } from '../../../../../assets'
import { GlobalChangeLanguage } from './styles'

const ChangeLanguage = (props) => {
  const { style, onClick, active } = props
  const [imageLanguage, setImageLanguage] = useState('')
  const { i18n } = useTranslation()

  useEffect(() => {
    switch (i18n.language) {
      case 'es-US':
        return setImageLanguage(spain)
      case 'pt-BR':
        return setImageLanguage(brazil)
      default:
        return setImageLanguage(unitedStates)
    }
  }, [i18n.language])

  if (imageLanguage) {
    return (
      <GlobalChangeLanguage style={style} onClick={onClick}>
        <img src={imageLanguage} alt={i18n.language} width="30" height="30" />

        <Icon icon={active ? ICONS.UP : ICONS.DOWN} size={20} />
      </GlobalChangeLanguage>
    )
  }

  return <div />
}

export default ChangeLanguage
