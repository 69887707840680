import React from 'react'

import { Icon } from 'evergreen-ui'
import { TabsWrapper, TabHeading, TabsHeadings } from './styles'
import { useMedia } from '../../../hooks'

const Tabs = ({ value, children }) => {
  const [activeTab, setActiveTab] = React.useState(
    value
      || (children.constructor === Array
        ? children[0].props.label
        : children.props.label),
  )
  const shouldIconApper = useMedia('(max-width: 640px)')
  const getIcon = {
    Profile: 'people',
    Addresses: 'compass',
    Password: 'key',
    Subscription: 'credit-card',
  }
  return (
    <TabsWrapper>
      <TabsHeadings>
        {children.constructor === Array ? (
          children.map(child => (
            <TabHeading
              onClick={() => setActiveTab(child.props.label)}
              key={child.props.label}
              activeTab={activeTab === child.props.label}
            >
              {!shouldIconApper ? (
                child.props.label
              ) : (
                <Icon icon={getIcon[child.props.label]} size={20} />
              )}
            </TabHeading>
          ))
        ) : (
          <TabHeading
            onClick={() => setActiveTab(children.props.label)}
            key={children.props.label}
          >
            {children.props.label}
          </TabHeading>
        )}
      </TabsHeadings>
      {children.constructor === Array
        ? children.map(child => (child.props.label !== activeTab ? undefined : child))
        : children}
    </TabsWrapper>
  )
}

export default Tabs
