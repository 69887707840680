export const setNotifications = payload => ({
  type: 'SET_NOTIFICATIONS',
  payload,
})

export const setNotificationFromSocket = payload => ({
  type: 'SET_NOTIFICATION_FROM_SOCKET',
  payload,
})

export const setAllNotificationsAsRead = () => ({
  type: 'SET_ALL_NOTIFICATIONS_AS_READ',
})

export const setAllTradeNotificationsAsRead = (tradeId, unread) => ({
  type: 'SET_ALL_TRADE_NOTIFICATIONS_AS_READ',
  tradeId,
  unread,
})

export const fetchError = () => ({
  type: 'NOTIFICATIONS_FETCH_ERROR',
})
