import styled from 'styled-components'
import {
  DefaultPageBox,
  DefaultPageBoxContent,
} from '../../templates/DefaultPage'

export const Container = styled(DefaultPageBox)`
  margin: 0 auto;
  max-width: 640px;
`

export const Content = styled(DefaultPageBoxContent)`
  padding: 48px;
`

export const Header = styled.div`
  height: 20px;
  background-color: #0b1625;
`
