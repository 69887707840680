export const clearData = () => ({
  type: 'SEARCH_RESULTS_CLEAR_DATA',
})

export const fetchData = searchTerm => ({
  type: 'SEARCH_RESULTS_FETCH',
  searchTerm,
})

export const fetchSuccess = payload => ({
  type: 'SEARCH_RESULTS_FETCH_SUCCESS',
  payload,
})

export const fetchError = payload => ({
  type: 'SEARCH_RESULTS_FETCH_ERROR',
  payload,
})

export const showAHiddenContent = contentToShow => ({
  type: 'SHOW_A_HIDDEN_CONTENT',
  contentToShow,
})

export const hideTheHiddenContent = () => ({
  type: 'HIDE_THE_HIDDEN_CONTENT',
})
