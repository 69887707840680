import React, { useState } from 'react'

import SubscriptionPopover from './Popover'
import { Grid } from '../../../commons'
import {
  Subscription,
  SubscriptionCard,
  Description,
  Plus,
  CenteredContent,
  BestValue,
} from './styles'
import { monthly, perTransaction } from '../../../../assets'
import { WarningDialog } from './Dialog'
import { setSubscriptionType } from '../store/wizardsReducer'
import { changeSubscriptionType } from '../store/formReducer'

export const SubscriptionType = ({
  dispatch,
  form,
  changeForm,
  handleFinish,
  isLoading,
}) => {
  const { subscriptionType } = form
  const [isMonthly, setIsMonthly] = useState(false)
  const [isBasic, setIsBasic] = useState(false)
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const handleSelectMonthly = (event) => {
    event.preventDefault()
    if (isBasic) {
      setIsBasic(false)
    }
    if (!isMonthly) {
      changeForm(changeSubscriptionType('monthly'))
      return setIsMonthly(true)
    }
    dispatch(setSubscriptionType(subscriptionType))
    return setIsDialogOpen(true)
  }
  const handleSelectBasic = (event) => {
    event.preventDefault()
    if (isMonthly) {
      setIsMonthly(false)
    }
    if (!isBasic) {
      changeForm(changeSubscriptionType(undefined))
      return setIsBasic(true)
    }
    dispatch(setSubscriptionType(subscriptionType))
    return setIsDialogOpen(true)
  }
  const handleCloseDialog = () => {
    dispatch(setSubscriptionType(undefined))
    changeForm(changeSubscriptionType(undefined))
    setIsMonthly(false)
    setIsDialogOpen(false)
  }
  return (
    <Grid container justify="center">
      <Subscription align="flex-end" item md={4} lg={4} xs={12}>
        <SubscriptionCard isSelected={isBasic}>
          <h1>basic</h1>
          <img src={perTransaction} height="60" width="93" alt="shake hands" />
          <div>
            <div>
              <span>$</span>
              <span>2</span>
              <span>.</span>
              <span>99</span>
            </div>
            <br />
            <Description>Per Transaction</Description>
            <Plus>or</Plus>
            <Description>Per Item</Description>
          </div>
          <button
            onClick={handleSelectBasic}
          >
            {isBasic ? 'Finish' : 'Choose Basic'}
          </button>
          <SubscriptionPopover>
            <div>
              <p>With this option <span>you will pay $2.99 per</span></p>
              <p><span>transaction + applicable taxes</span> and shipping</p>
              <p>if shipping is required</p>
            </div>
            <div>
              <p>Please note that when you initiate a transaction</p>
              <p>we will place a temporary hold on your funds for</p>
              <p>the transaction costs.</p>
              <p>In the event that the other user rejects your</p>
              <p>request, we will return the funds to you as soon</p>
              <p>as possible.</p>
            </div>
          </SubscriptionPopover>
        </SubscriptionCard>
      </Subscription>
      <Subscription align="center" item md={4} lg={4} xs={12}>
        <SubscriptionCard isPremium isSelected={isMonthly}>
          <h1>premium</h1>
          <img src={monthly} height="70" width="92" alt="calendar" />
          <div>
            <div>
              <span>$</span>
              <span>6</span>
              <span>.</span>
              <span>99</span>
              <span> / </span>
              <span>month</span>
            </div>
            <br />
            <Description>Unlimited Transactions</Description>
            <Plus>+</Plus>
            <Description>Unlimited Items Trade</Description>
          </div>
          <button
            onClick={handleSelectMonthly}
          >
            {isMonthly ? 'Finish' : 'Choose Premium'}
          </button>
          <BestValue>
            best value
          </BestValue>
          <SubscriptionPopover>
            <div>
              <p>With this option <span>you will pay</span></p>
              <p><span>$6.99 per month for unlimited</span></p>
              <p><span>transactions</span> + shipping if shipping</p>
              <p>is required</p>
            </div>
          </SubscriptionPopover>
        </SubscriptionCard>
      </Subscription>
      <Subscription align="flex-start" item md={4} lg={4} xs={12}>
        <SubscriptionCard >
          <h1>Shipping</h1>
          <CenteredContent>
            <p>If shipping is</p>
            <p>required it is standard</p>
            <p>shipping <span>NOT</span> inflated</p>
            <p>pricing to compensate</p>
            <p>for low transaction</p>
            <p>costs.</p>
          </CenteredContent>
        </SubscriptionCard>
      </Subscription>
      <WarningDialog
        isOpen={isDialogOpen}
        handleClose={handleCloseDialog}
        handleSubmit={handleFinish}
        isLoading={isLoading}
      />
    </Grid>
  )
}
