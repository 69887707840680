import styled from 'styled-components'

export default styled.button.attrs({ type: 'button' })`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: #6441a5;
  color: #fff;
  border: none;
  border-radius: 0.2em;
  font-size: 1rem;
  padding: 0.2em 0;
  font-weight: 200;
  margin-top: 0.8em;
  outline: none;
  height: 36px;
  & > img {
    position: absolute;
    left: 0.8em;
    height: 20px;
  }
  &::before {
    content: '';
    width: 2px;
    height: 30px;
    background-color: rgba(0, 0, 0, 0.1);
    position: absolute;
    left: 2.8em;
  }
  & > strong {
    font-weight: 400;
  }
  &:hover {
    background: linear-gradient(#6441a5 10%, #412475);
  }
`
