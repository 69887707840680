import styled from 'styled-components'

import { Icon } from '..'
import ICONS from '../../ui/ICONS'

export const GlobalEmptyResults = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #18283b;
  padding: 48px 12px;

  @media (max-width: 670px) {
    flex-direction: column;
    padding: 12px;
    padding-bottom: 24px;
    text-align: center;
  }
`

export const CloseIcon = styled(Icon).attrs({
  icon: ICONS.NO,
  size: 96,
  color: '#87909A',
})``

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const MainText = styled.span`
  color: #fff;
  font-size: 24px;
  font-weight: bold;
`

export const Term = styled.span`
  color: #1692d6;
  font-style: italic;
`

export const Button = styled.button.attrs({ type: 'button' })`
  color: #fff;
  margin: 12px 0px;
  padding: 4px 16px;
  border-radius: 50px;
  background: linear-gradient(180deg, #4cd964 0%, #0c9d25 93.75%);
  cursor: pointer;
  width: 50%;
  min-width: 300px;
  align-self: center;

  &:hover {
    opacity: 0.8;
  }
`
