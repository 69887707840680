import React, { useState } from 'react'
import { Switch } from 'evergreen-ui'

import { goBack } from '../../../store'
import { Grid, Button } from '../../../../../commons'
import {
  Column,
  Option,
  ShippingOption,
  Header,
  ButtonsGroup,
} from '../../styles'
import {
  deliveryOn,
  deliveryOff,
  pickupOn,
  pickupOff,
} from '../../../../../../assets'
import { TransactionResume } from '../../components'

const RequestShipping = ({
  dispatch,
  setShippingProcess,
  handleSelectPickUp,
  myTrade,
  hisTrade,
  isLoading,
}) => {
  const [myMedia] = myTrade.trading
  const [hisMedia] = hisTrade.trading
  const [isDelivery, setIsDelivery] = useState(true)
  const handleClickOnNext = async (event) => {
    event.preventDefault()
    event.stopPropagation()
    if (isDelivery) {
      return setShippingProcess('chooseAddress')
    }
    await handleSelectPickUp()
    return setShippingProcess('waitingOtherUser')
  }
  const handleBack = (event) => {
    event.stopPropagation()
    dispatch(goBack())
  }
  return (
    <Grid container justify="center">
      <Header item md={12} lg={12}>
        <h1>
          Choose one of the
          {' '}
          <span>shipping options:</span>
        </h1>
      </Header>
      <Column item md={5} lg={5}>
        <img src={!isDelivery ? pickupOn : pickupOff} alt="pickup" />
      </Column>
      <Column item md={2} lg={2}>
        <ShippingOption>
          <Option isChecked={!isDelivery}>Pickup</Option>
          <Switch
            hasCheckIcon={false}
            marginRight={12}
            marginLeft={12}
            height={32}
            checked={isDelivery}
            onChange={() => setIsDelivery(!isDelivery)}
            color="#4CD964"
          />
          <Option isChecked={isDelivery}>Ship it</Option>
        </ShippingOption>
      </Column>
      <Column item md={5} lg={5}>
        <img src={isDelivery ? deliveryOn : deliveryOff} alt="delivery" />
      </Column>
      <TransactionResume myMedia={myMedia} hisMedia={hisMedia} />
      <ButtonsGroup>
        <Button
          variation="info"
          disabled={isLoading}
          onClick={event => handleBack(event)}
        >
          BACK
        </Button>
        <Button
          isLoading={isLoading}
          variation="success"
          onClick={handleClickOnNext}
        >
          NEXT
        </Button>
      </ButtonsGroup>
    </Grid>
  )
}

export default RequestShipping
