import React, { useEffect, useState } from 'react'
import { oneOf, string } from 'prop-types'
import { withTheme } from 'styled-components'
import Slider from 'react-slick'
import { mediaTypes } from '../../../helpers'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { useRouter } from '../../../hooks'
import { MediaCard, Arrow } from '..'
import {
  GlobalSectionLibrary,
  Band,
  GlobalSlider,
  GlobalMediaCard,
  GlobalArrowSectionLibrary,
  Title,
} from './styles'

const ArrowSectionLibrary = (props) => {
  const { next } = props

  return (
    <GlobalArrowSectionLibrary next={!!next}>
      <Arrow {...props} />
    </GlobalArrowSectionLibrary>
  )
}

const SectionLibrary = (props) => {
  const {
    theme,
    actionsOnMediaCard,
    mediaType,
    items,
    itemsConfig,
    filter,
    setDeletedMediaId,
  } = props
  const { history } = useRouter()
  const [itemsToShow, setItemsToShow] = useState([])

  const sliderItemsConfig = {
    largest: {
      slidesToShow: 6,
      slidesToScroll: 6,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 5,
            slidesToScroll: 5,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 4,
          },
        },
        {
          breakpoint: 576,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        },
      ],
    },
    large: {
      slidesToShow: 4,
      slidesToScroll: 4,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
          },
        },
        {
          breakpoint: 576,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    },
  }
  const sliderSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    prevArrow: <ArrowSectionLibrary />,
    nextArrow: <ArrowSectionLibrary next />,
    ...sliderItemsConfig[itemsConfig],
  }

  const mediaTypeDictionary = mediaTypes.filter(
    item => item.value === mediaType,
  )[0]

  useEffect(() => {
    setItemsToShow(
      filter ? items.filter(item => item.condition === filter) : items,
    )
  }, [filter])

  return (
    <GlobalSectionLibrary>
      <Band id={mediaType} color={theme.colorMediaTypes[mediaType][0]} />

      <GlobalSlider>
        <Title>{mediaTypeDictionary.label}</Title>

        <Slider {...sliderSettings}>
          {itemsToShow.map(item => (
            <GlobalMediaCard key={item.id}>
              <MediaCard
                data={{
                  ...item,
                  thumb: item.pictures ? item.pictures[0] : null,
                }}
                onClick={() => history.push(`/media/${item.id}`)}
                actions={actionsOnMediaCard}
                callbackDelete={() => setDeletedMediaId(item.id)}
              />
            </GlobalMediaCard>
          ))}
        </Slider>
      </GlobalSlider>
    </GlobalSectionLibrary>
  )
}

SectionLibrary.propTypes = {
  itemsConfig: oneOf(['large', 'largest']),
  filter: string,
}

SectionLibrary.defaultProps = {
  itemsConfig: 'largest',
  filter: '',
}

export default withTheme(SectionLibrary)
