import styled from 'styled-components'
import { Grid } from '../../../commons'

export const GlobalSubmit = styled.div`
  margin-top: ${props => props.theme.spacing * 2}px;
  display: flex;
  justify-content: flex-end;
`

export const EditButton = styled.button`
  background: linear-gradient(180deg, #EE773B 0%, #BA4F1A 100%);
  color: #FFF;
  padding: 6px 15px;
  font-size: 14px;
  line-height: 22px;
  border-radius: 4px;
  text-align: center;
  font-weight: 600;
  text-transform: capitalize;
  &:not(:disabled) {
    cursor: pointer;
  }
  &:disabled {
    opacity: 0.65;
    background: ${props => props.theme.colorGray2};
  }
`

export const CopyButton = styled.button`
  background: linear-gradient(180deg, #0BA0BE 25.86%, #006E9D 100%);
  color: #FFF;
  padding: 3px 6px;
  font-size: 14px;
  line-height: 22px;
  border-radius: 4px;
  text-align: center;
  font-weight: 600;
  text-transform: capitalize;
  margin-left: 24px;
  &:not(:disabled) {
    cursor: pointer;
  }
  &:disabled {
    opacity: 0.65;
    background: ${props => props.theme.colorGray2};
  }
`

export const Container = styled(Grid)`
  width: 100%;
  height: 100%;
  min-height: 578px;
`

export const RegularText = styled.span`
  font-weight: 300;
  font-size: 14px;
  color: #7AA6B8;
`

export const Picture = styled.div`
  height: 110px;
  width: 100px;
  background-color: ${props => (props.error ? props.theme.colorNegative : '#0b1625')};
  border-radius: 5px;
  display: inline-flex;
  font-size: 12px;
  text-align: center;
  color: #547384;
  line-height: 6px;
  font-weight: bold;
  background-image: url(${props => props.src});
  background-size: cover;
  background-position: center;
`
