import React, { useState, useEffect } from 'react'
import { withTheme } from 'styled-components'
import { useTranslation } from 'react-i18next'
import {
  Modal,
  ModalContent,
  Button,
  UserShowcase,
  Icon,
} from '../../../commons'
import ICONS from '../../../ui/ICONS'
import { Table, Tr, Th } from '../../../ui'
import { convertTimezoneForYearAndMonth } from '../../../../helpers'
import { useRouter, useMedia } from '../../../../hooks'
import SearchInMap from './SearchInMap'
import { useStore } from '../../../../store'
import * as searchActions from '../../../commons/SearchBar/actions'
import { Title, GlobalUser, Td } from './styles'

const ModalIncreaseMyChances = (props) => {
  const { t, i18n } = useTranslation()
  const {
    open, handleClose, theme, searchTerm,
  } = props
  const [selectedMedia, setSelectedMedia] = useState('')
  const { user, dispatch } = useStore()
  const [coordinates, setCoordinates] = useState({})
  const { history } = useRouter()
  const isSm = useMedia('(min-width: 576px)')

  const getPositionFromBrowser = () => {
    if (!navigator.geolocation) return

    navigator.geolocation.getCurrentPosition((position) => {
      setCoordinates({
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      })
    })
  }

  function handleClick(id) {
    dispatch(searchActions.closeSearchLocal())
    history.push(`/library/${id}`)
  }

  useEffect(() => {
    if (!open) return

    if (Object.keys(user.data).length > 0 && user.data.preciseLocation) {
      setCoordinates({
        lat: user.data.preciseLocation.coordinates[1],
        lng: user.data.preciseLocation.coordinates[0],
      })
    } else {
      getPositionFromBrowser()
    }
  }, [open])

  return (
    <Modal open={open} handleClose={handleClose}>
      <ModalContent>
        <Title>{t('Search Local')}</Title>

        {Object.keys(coordinates).length > 0 && (
          <SearchInMap
            callbackSelectMedia={media => setSelectedMedia(media)}
            coordinates={coordinates}
            searchTerm={searchTerm}
          />
        )}

        {!Object.keys(coordinates).length && (
          <div>
            {t(
              'For a local search you must create an account and log in so that we can help you locate people in your area.',
            )}
          </div>
        )}

        {selectedMedia && (
          <Table style={{ marginTop: `${theme.spacing * 2}px` }}>
            <thead>
              <Tr>
                <Th>{t('User')}</Th>
                {isSm && <Th>{t('Member since')}</Th>}
                <Th>{t('Trades')}</Th>
                <Th>{t('Added items')}</Th>
                <Th />
              </Tr>
            </thead>
            <tbody>
              <Tr>
                <Td>
                  <GlobalUser>
                    <UserShowcase
                      data={{
                        avatar: selectedMedia.avatar,
                        rating: selectedMedia.rating,
                      }}
                      status="selected"
                      style={{ marginRight: `${theme.spacing}px` }}
                    />

                    <div>
                      <strong>{selectedMedia.username}</strong>
                      <br />
                      {selectedMedia.distance}
                    </div>
                  </GlobalUser>
                </Td>
                {isSm && (
                  <Td>
                    {convertTimezoneForYearAndMonth(selectedMedia.createdAt, i18n.language)}
                  </Td>
                )}
                <Td>{selectedMedia.tradeCount}</Td>
                <Td>{selectedMedia.mediaCount}</Td>
                <Td>
                  <Button onClick={() => handleClick(selectedMedia.id)}>
                    {isSm ? t('See library') : <Icon icon={ICONS.BOOK} />}
                  </Button>
                </Td>
              </Tr>
            </tbody>
          </Table>
        )}
      </ModalContent>
    </Modal>
  )
}

export default withTheme(ModalIncreaseMyChances)
