import React from 'react'
import styled from 'styled-components'

const StyledVideo = styled.div`
  position: relative;
  padding-bottom: 56.25%;
  height: 0;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
  }
`

const Video = ({ src }) => (
  <StyledVideo>
    <iframe
      title="video"
      width="560"
      height="315"
      src={`${src}?rel=0`}
      frameBorder="0"
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    />
  </StyledVideo>
)

export default Video
