import styled, { keyframes } from 'styled-components'
import { getZindex } from '../../../../../helpers'

const AnimationGlobalNavigation = keyframes`
  from {
    opacity: 0;
    transform: translateX(-100%);
  }

  to {
    opacity: 1;
    transform: translateX(0%);
  }
`

export const GlobalChangeLanguageNavigation = styled.div`
  overflow: hidden;
`

export const GlobalNavigation = styled.div`
  position: fixed;
  top: calc(50px + ${props => props.theme.spacing * 4}px);
  left: 0;
  width: 100%;
  height: 100%;
  animation: ${AnimationGlobalNavigation} 150ms;
  transform: translateX(0%);
  overflow-y: hidden;
`

export const GlobalMenu = styled.div`
  position: relative;
  z-index: ${getZindex('default')};
  width: calc(100% - 50px);
  height: calc(100% - (50px + ${props => props.theme.spacing * 4}px));
  overflow: auto;
  background-color: ${props => props.theme.colorGrayDark};
  padding: ${props => props.theme.spacing * 4}px;
  box-shadow: 0 0 10px #000;
`

export const Menu = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  color: ${props => props.theme.colorSecondary};

  :nth-child(2) {
    border-top: 1px solid rgba(255, 255, 255, 0.2);
  }
`

export const MenuColumn = styled.div`
  display: flex;
  flex-direction: column;
`

export const MenuItem = styled.div`
  text-align: center;
  height: 50px;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: ${props => props.theme.spacing * 2}px;
  text-decoration: none;

  :hover {
    opacity: 0.7;
  }
`

export const MenuItemSignIn = styled(MenuItem)`
  border: 2px solid ${props => props.theme.colorPrimaryFeatured};
  padding: 0 ${props => props.theme.spacing * 4}px;
  border-radius: 25px;
  margin-bottom: 0;
`

export const UserInfo = styled.div`
  display: flex;
  align-items: center;
  height: 50px;
  margin-top: ${props => props.theme.spacing * 4}px;
`

export const UserName = styled.div`
  font-size: ${props => props.theme.fontSizes.heading2};
  font-weight: bold;
  margin-left: ${props => `${props.theme.spacing}px`};
`

export const Overlay = styled.div`
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
`
