import React, { useState, useEffect, useRef } from 'react'
import socket from 'socket.io-client'
import Message from './Message'
import api from './tempService'
import {
  GlobalChatBox,
  GlobalSendMessage,
  SendMessage,
  GlobalMessages,
} from './styles'

const Chat = () => {
  const room = '5cc186a48110a9001769f32c'
  const myUserId = 'cde456'
  const [messages, setMessages] = useState([])
  const [sendingMessages, setSendingMessages] = useState(false)
  const refGlobalSendMessage = useRef(null)

  const fetchData = async () => {
    const response = await api.get(`chat/${room}`)
    setMessages(prev => [...prev, ...response.data.messages])
  }

  useEffect(() => {
    const io = socket('https://omni-stack-week-6-backend.herokuapp.com')
    io.emit('connectRoom', room)
    io.on('message', (data) => {
      setMessages(prev => [...prev, data])
      setSendingMessages(false)
    })

    return () => {
      io.off('message')
    }
  }, [])

  useEffect(() => {
    fetchData()
  }, [])

  useEffect(() => {
    refGlobalSendMessage.current.scrollTop = refGlobalSendMessage.current.scrollHeight
  }, [sendingMessages, messages])

  const sendMessage = (message) => {
    setSendingMessages(true)

    api.post(`chat/${room}/message`, {
      message,
      userId: myUserId,
    })
  }

  return (
    <GlobalChatBox>
      <GlobalMessages ref={refGlobalSendMessage}>
        {messages.length > 0
          && messages.map(message => (
            <Message key={message._id} data={message} myUserId={myUserId} />
          ))}

        {sendingMessages && <Message typing />}
      </GlobalMessages>

      <GlobalSendMessage>
        <SendMessage onClick={() => sendMessage('Good too :)')}>
          Good too :)
        </SendMessage>

        <SendMessage onClick={() => sendMessage('I am bored -.-')}>
          I am bored -.-
        </SendMessage>

        <SendMessage onClick={() => sendMessage('I am hungry O.O')}>
          I am hungry O.O
        </SendMessage>
      </GlobalSendMessage>
    </GlobalChatBox>
  )
}

export default Chat
