import styled from 'styled-components'
import { DefaultPageBoxHeader } from '../../../templates/DefaultPage'

export const GlobalTransactions = styled.div`
  background: #0b1625;
  box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.25);
  padding: ${props => `${props.theme.spacing * 4}px ${props.theme.spacing * 6}px`};
  margin: ${props => `0 -${props.theme.spacing * 6}px`};
`

export const UserTitle = styled.h1`
  font-size: ${props => props.theme.fontSizes.heading1};
  font-weight: bold;
`

export const BoxHeader = styled(DefaultPageBoxHeader)`
  display: flex;
  padding-left: calc(128px + ${props => props.theme.spacing * 4}px);

  @media (min-width: ${props => props.theme.breakpoints.sm}px) {
    justify-content: flex-end;
  }
`

export const Header = styled.header`
  display: flex;
  align-items: flex-end;
  padding: 0 16px;
`

export const HeaderColumn = styled.div`
  :first-child {
    margin-right: 16px;
    margin-bottom: -60px;
    padding-bottom: 0;
  }

  :last-child {
    flex-grow: 1;

    @media (min-width: ${props => props.theme.breakpoints.sm}px) {
      display: flex;
      align-items: flex-end;
    }
  }
`

export const HeaderColumnChildren = styled.div`
  margin-bottom: ${props => props.theme.spacing * 2}px;
`

export const HeaderLibrary = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: ${props => props.theme.spacing * 5}px;
  margin-bottom: ${props => props.theme.spacing * 2}px;
`

export const RatingLabel = styled.span`
  color: rgba(255, 255, 255, 0.6);
  font-size: ${props => props.theme.fontSizes.legend};
  margin-right: ${props => props.theme.spacing * 2}px;
`

export const GlobalProfile = styled.div`
  display: flex;
  flex-direction: ${props => (props.isSm ? 'row' : 'column')};
  justify-content: space-between;
  margin-bottom: ${props => props.theme.spacing * 4}px;
`

export const ProfileSection = styled.div`
  text-align: ${props => (props.isSm ? 'center' : 'left')};
  font-size: ${props => props.theme.fontSizes.legend};
  padding: ${props => (props.isSm
    ? `0 ${props.theme.spacing}px`
    : `${props.theme.spacing * 2}px 0`)};

  :last-child {
    padding-right: 0;
  }
`

export const MediaContainer = styled.div`
  position: relative;
`
