import React from 'react'
import {
  GlobalMessage,
  MessageText,
  TypingMessage,
  GlobalTypingMessage,
  Disk,
} from './styles'

const Message = ({ data, myUserId, typing }) => (
  <>
    {!typing && (
      <GlobalMessage data={data} myUserId={myUserId}>
        <MessageText data={data} myUserId={myUserId}>
          {data ? data.message : 'Loading'}
        </MessageText>
      </GlobalMessage>
    )}
    {typing && (
      <GlobalTypingMessage>
        <TypingMessage>
          <Disk />
          <Disk />
          <Disk />
        </TypingMessage>
      </GlobalTypingMessage>
    )}
  </>
)

export default Message
