import React, { useState } from 'react'
import { CardElement, injectStripe } from 'react-stripe-elements'
import { toast } from 'react-toastify'

import { validateAddress } from '../../../../../services/auth'
import {
  ButtonContainer,
  Form,
  styles,
  RowWithInputs,
} from '../styles'
import { Input, Button } from '../../../../commons'
import { setBillingAddress, setCardToken } from '../../store/wizardsReducer'
import { changeBillingAddress, changeCardToken} from '../../store/formReducer'

const StripeCardContainer = ({
  stripe,
  isSameAddress,
  dispatch,
  changeForm,
  navigateToNext,
  streetAddress1,
  streetAddress2,
  state,
  city,
  zipcode,
}) => {
  const [holdersName, setCardHoldersName] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const handleChangeHoldersName = char => setCardHoldersName(char.toUpperCase())
  const handleSubmit = async (event) => {
    event.preventDefault()
    setIsLoading(true)
    try {
      if(!isSameAddress) {
        const address = { streetAddress1, streetAddress2, state, city, zipcode }
        await validateAddress(address)
        dispatch(setBillingAddress(address))
      }
      await handleCreateToken()
      navigateToNext()
    } catch (error) {
      toast.dismiss()
      if (error.response.data.errors){
        error.response.data.errors.map(item => toast.error(item))
      } else {
        toast.error(
          error.response && error.response.data.message
            ? error.response.data.message
            : 'An error occurred. Try again later.',
        )
      }
    } finally {
      setIsLoading(false)
    }
  }
  const handleCreateToken = async () => {
    try {
      const { token } = await stripe.createToken()
      changeForm(changeCardToken(token))
      dispatch(setCardToken(token))
    } catch (error) {
      toast.dismiss()
      if (error.response.data.errors){
        error.response.data.errors.map(item => toast.error(item))
      } else {
        toast.error(
          error.response && error.response.data.message
            ? error.response.data.message
            : 'An error occurred. Try again later.',
        )
      }
    }
  }
  return (
    <Form onSubmit={handleSubmit}>
      <Input
        label="Credit Card"
        placeholder="Card Holder's Name"
        id="Card Holder's Name"
        type="text"
        value={holdersName}
        onChange={event => handleChangeHoldersName(event.target.value)}
        required
      />
      <CardElement style={styles} hidePostalCode />
      <Input
        label="Billing Address"
        id="Billing Address"
        placeholder="Street Address"
        type="text"
        value={streetAddress1}
        onChange={event => changeForm(changeBillingAddress({ streetAddress1: event.target.value}))}
        required
        readOnly={isSameAddress}
      />
      <Input
        placeholder="Apt., ste., bldg. "
        id="address line two"
        type="text"
        readOnly={isSameAddress}
        value={streetAddress2}
        onChange={event => changeForm(changeBillingAddress({ streetAddress2: event.target.value}))}
      />
      <RowWithInputs>
        <Input
          placeholder="State"
          id="shipping address line two"
          type="text"
          value={state}
          onChange={event => changeForm(changeBillingAddress({
            state: event.target.value})
          )}
          required
          readOnly={isSameAddress}
        />
        <Input
          placeholder="City"
          id="shipping address line two"
          type="text"
          value={city}
          onChange={event => changeForm(changeBillingAddress({
            city: event.target.value})
          )}
          required
          readOnly={isSameAddress}
        />
        <Input
          placeholder="Zipcode"
          id="shipping address line two"
          type="text"
          value={zipcode}
          onChange={event => changeForm(changeBillingAddress({
            zipcode: event.target.value})
          )}
          required
          readOnly={isSameAddress}
        />
      </RowWithInputs>
      <ButtonContainer>
        <Button
          isLoading={isLoading}
          type="submit"
          variation="success"
        >
          Next
        </Button>
      </ButtonContainer>
    </Form>
  )
}

export const StripeCard = injectStripe(StripeCardContainer)
