import styled, { keyframes } from 'styled-components'
import { getZindex } from '../../../../../../helpers'

const SHOW_GLOBAL_NAVIGATION = keyframes`
  from {
    transform: translateY(-100%);
  }

  to {
    transform: translateY(0);
  }
`

export const GlobalLanguageNavigation = styled.div`
  animation: ${SHOW_GLOBAL_NAVIGATION} 150ms ease-out;
  z-index: ${getZindex('default')};
  width: ${props => (props.onNavigationMobile ? '100%' : '200px')};
  margin-right: 8px;
`

export const NavigationLink = styled.button`
  display: flex;
  width: 100%;
  text-decoration: none;
  padding: ${props => (props.onNavigationMobile
    ? `${props.theme.spacing}px ${props.theme.spacing * 2}px ${
      props.theme.spacing
    }px 0`
    : `${props.theme.spacing}px ${props.theme.spacing * 2}px`)};
  background-color: ${props => (props.onNavigationMobile ? 'transparent' : '#045374')};
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  color: #fff;
  text-align: left;
  cursor: pointer;

  img {
    margin-right: 8px;
  }

  :hover {
    ${props => (props.onNavigationMobile
    ? `
      opacity: 0.7;
    `
    : `
      background-color: #064661;
    `)}
  }

  last-child {
    border-bottom: 0;
  }
`
