import styled from 'styled-components'
import { getZindex } from '../../../helpers'

export const GlobalScrollUpButton = styled.div`
  position: fixed;
  bottom: ${props => props.theme.spacing * 2}px;
  right: ${props => props.theme.spacing * 4}px;
  z-index: ${getZindex('goToTop')};
  cursor: pointer;
`

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    opacity: 0.8;
  }
  & > button:hover {
    opacity: 1;
  }
`

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  user-select: none;

  & > span {
    color: ${props => props.theme.colorSecondary};
    font-size: ${props => props.theme.fontSizes.heading2};
    font-weight: 200;
    position: relative;
    top: 5px;
    &:last-child {
      top: -5px;
    }
  }
`
