import React from 'react'
import { oneOf } from 'prop-types'
import { MediaPicture } from '..'
import { GlobalVisualTrade } from './styles'

const VisualTrade = (props) => {
  const { from, to, size } = props

  return (
    <GlobalVisualTrade size={size}>
      <MediaPicture
        media={from}
        style={{ position: 'absolute' }}
        size={size === 'small' ? 60 : 120}
      />
      <MediaPicture
        media={to}
        style={{ position: 'absolute' }}
        size={size === 'small' ? 60 : 120}
      />
    </GlobalVisualTrade>
  )
}

VisualTrade.propTypes = {
  size: oneOf(['small', 'large']),
}

VisualTrade.defaultProps = {
  size: 'small',
}

export default VisualTrade
