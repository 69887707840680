import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  align-items: center;
  max-width: ${props => (props.size === 'small' ? '60px' : '100px')};
  height: ${props => (props.size === 'small' ? '20px' : '30px')};
  background-color: ${props => props.color};
  border-radius: 50px;

  & > img {
    padding: ${props => (props.size === 'small' ? '4px' : '4px 16px')};
    max-width: 100%;
    max-height: 100%;
    margin: auto;
  }
`
