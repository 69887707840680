import React from 'react'
import { useTranslation } from 'react-i18next'
import { Title } from './styles'

const Completed = (props) => {
  const { computedMatch } = props
  const { t } = useTranslation()

  return (
    <>
      <Title>{t('Check you email')}</Title>

      <p>
        {t(
          'To create the new password, click the link that we sent to your email',
        )}
        {' '}
        <strong>{computedMatch.params.email}</strong>
      </p>
    </>
  )
}

export default Completed
