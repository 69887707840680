import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import api from "../../../../services/api";
import HomeSlider from "../HomeSlider";
import { ViewMode } from "../../../commons";

export default function HomeSliders({ activeFilter }) {
  const initialStateLines = {
    items: [],
    nextPage: 1,
    isLoading: false,
  };
  const [filterActive, setFilterActive] = useState("all");
  const [lineOne, setlineOne] = useState(initialStateLines);
  const [lineTwo, setlineTwo] = useState(initialStateLines);
  const [lineThree, setlineThree] = useState(initialStateLines);
  const [lineFour, setlineFour] = useState(initialStateLines);
  const [recentlyAdded, setRecentlyAdded] = useState(initialStateLines);
  const [viewMode, setViewMode] = useState("more");
  const [platforms, setPlatforms] = useState({
    game: [],
  });
  const { t } = useTranslation();

  const getMediaPlatforms = () => {
    api.get("/media/platforms").then((response) => {
      setPlatforms(response.data); 
    })};

  

  const fetchLineOne = (filters, page) => {
    if (!filters.platform) {
      setFilterActive("all");

    }
    setlineOne((prev) => ({
      ...(page === 1 ? initialStateLines : prev),
      isLoading: true,
    }));

    api
      .post("media/search", {
        ...filters,
        search: "",
        limit: page > 1 ? 48 : 12,
        page,
      })
      .then((res) => {
        setlineOne((prev) => ({
          items: prev.items.concat(res.data.docs),
          nextPage: res.data.nextPage,
          isLoading: false,
        }));
      });
  };

  const fetchLineTwo = (filters, page) => {
    setlineTwo((prev) => ({
      ...(page === 1 ? initialStateLines : prev),
      isLoading: true,
    }));

    api
      .post("media/search", {
        ...filters,
        search: "",
        limit: page > 1 ? 48 : 12,
        page,
      })
      .then((res) => {
        setlineTwo((prev) => ({
          items: prev.items.concat(res.data.docs),
          nextPage: res.data.nextPage,
          isLoading: false,
        }));
      });
  };

  const fetchLineThree = (filters, page) => {
    setlineThree((prev) => ({
      ...(page === 1 ? initialStateLines : prev),
      isLoading: true,
    }));

    api
      .post("media/search", {
        ...filters,
        search: "",
        limit: page > 1 ? 48 : 12,
        page,
      })
      .then((res) => {
        setlineThree((prev) => ({
          items: prev.items.concat(res.data.docs),
          nextPage: res.data.nextPage,
          isLoading: false,
        }));
      });
  };

  const fetchLineFour = (filters, page) => {
    setlineFour((prev) => ({
      ...(page === 1 ? initialStateLines : prev),
      isLoading: true,
    }));

    api
      .post("media/search", {
        ...filters,
        search: "",
        limit: page > 1 ? 48 : 12,
        page,
      })
      .then((res) => {
        setlineFour((prev) => ({
          items: prev.items.concat(res.data.docs),
          nextPage: res.data.nextPage,
          isLoading: false,
        }));
      });
  };

  const fetchRecentlyAdded = (filters, page) => {
    setRecentlyAdded((prev) => ({
      ...(page === 1 ? initialStateLines : prev),
      isLoading: true,
    }));

    api
      .post("media/search", {
        ...filters,
        search: "",
        limit: page > 1 ? 48 : 12,
        sort: {
          releaseDate: -1,
        },
        page,
      })
      .then((res) => {
        setRecentlyAdded((prev) => ({
          items: prev.items.concat(res.data.docs),
          nextPage: res.data.nextPage,
          isLoading: false,
        }));
      });
  };

  function fetchALL(page) {
    fetchLineOne({ mediaType: "GAME" }, page);
    fetchLineTwo({ mediaType: "MOVIE" }, page);
    fetchLineThree({ mediaType: "BOOK" }, page);
    fetchLineFour({ mediaType: "MUSIC" }, page);
    fetchRecentlyAdded({}, page);
  }

  function fetchGAME(page) {
    fetchLineOne({ mediaType: "GAME" }, page);
    fetchRecentlyAdded({ mediaType: "GAME" }, page);
  }

  function fetchBOOK(page) {
    fetchLineOne({ mediaType: "BOOK" }, page);
    fetchRecentlyAdded({ mediaType: "BOOK" }, page);
  }

  function fetchMUSIC(page) {
    fetchLineOne({ mediaType: "MUSIC" }, page);
    fetchRecentlyAdded({ mediaType: "MUSIC" }, page);
  }

  function fetchMOVIE(page) {
    fetchLineOne({ mediaType: "MOVIE" }, page);
    fetchRecentlyAdded({ mediaType: "MOVIE" }, page);
  }

  function nice(type) {
    return (
      platforms && platforms[type] && <Select>
        <select
          onChange={(event) => {
            const { value } = event.target;
            setFilterActive(value);
            fetchLineOne(
              {
                platform: value === "all" ? undefined : value,
                mediaType: type.toUpperCase(),
              },
              1
            );
          }}
        >
          <option defaultValue value="all">
            All
          </option>
          {Object.keys(platforms[type]).map((key) => (
            <option key={key} value={key}>
              {platforms[type][key]}
            </option>
          ))}
        </select>
      </Select>
    );
  }

  useEffect(() => {
    getMediaPlatforms();
  },[]);

  useEffect(() => {
    const filtersObj = {
      ALL: fetchALL,
      GAME: fetchGAME,
      MOVIE: fetchMOVIE,
      BOOK: fetchBOOK,
      MUSIC: fetchMUSIC,
    };

    filtersObj[activeFilter](1);
  }, [activeFilter]);

  const filters = {
    ALL: () => (
      <>
        {lineOne.items.length > 0 && (
          <HomeSlider
            viewMode={viewMode}
            title={t("Games")}
            items={lineOne.items}
            nextPage={lineOne.nextPage}
            isLoading={lineOne.isLoading}
            callbackSeeMore={() =>
              fetchLineOne({ mediaType: "GAME" }, lineOne.nextPage)
            }
          />
        )}
        {lineTwo.items.length > 0 && (
          <HomeSlider
            title={t("Movies")}
            viewMode={viewMode}
            items={lineTwo.items}
            nextPage={lineTwo.nextPage}
            isLoading={lineTwo.isLoading}
            callbackSeeMore={() =>
              fetchLineTwo({ mediaType: "MOVIE" }, lineTwo.nextPage)
            }
          />
        )}
        {lineThree.items.length > 0 && (
          <HomeSlider
            title={t("Books")}
            viewMode={viewMode}
            items={lineThree.items}
            nextPage={lineThree.nextPage}
            isLoading={lineThree.isLoading}
            callbackSeeMore={() =>
              fetchLineThree({ mediaType: "BOOK" }, lineThree.nextPage)
            }
          />
        )}
        {lineFour.items.length > 0 && (
          <HomeSlider
            title={t("Music")}
            viewMode={viewMode}
            items={lineFour.items}
            nextPage={lineFour.nextPage}
            isLoading={lineFour.isLoading}
            callbackSeeMore={() =>
              fetchLineFour({ mediaType: "MUSIC" }, lineFour.nextPage)
            }
          />
        )}
        {recentlyAdded.items.length > 0 && (
          <HomeSlider
            title={t("Recently Added")}
            viewMode={viewMode}
            items={recentlyAdded.items}
            nextPage={recentlyAdded.nextPage}
            isLoading={recentlyAdded.isLoading}
            callbackSeeMore={() =>
              fetchRecentlyAdded({}, recentlyAdded.nextPage)
            }
          />
        )}
      </>
    ),
    GAME: () => (
      <>
        {nice("game")}
        {lineOne.items.length > 0 && (
          <HomeSlider
            viewMode={viewMode}
            title={t(
              filterActive === "all" ? "all" : platforms.game[filterActive]
            )}
            items={lineOne.items}
            nextPage={lineOne.nextPage}
            isLoading={lineOne.isLoading}
            callbackSeeMore={() =>
              fetchLineOne(
                {
                  platform: filterActive === "all" ? undefined : filterActive,
                  mediaType: "GAME",
                },
                lineOne.nextPage
              )
            }
          />
        )}
        {recentlyAdded.items.length > 0 && (
          <HomeSlider
            title={t("Recently Added")}
            viewMode={viewMode}
            items={recentlyAdded.items}
            nextPage={recentlyAdded.nextPage}
            isLoading={recentlyAdded.isLoading}
            callbackSeeMore={() =>
              fetchRecentlyAdded({ mediaType: "GAME" }, recentlyAdded.nextPage)
            }
          />
        )}
      </>
    ),
    MOVIE: () => (
      <>
        {nice("movie")}
        {lineOne.items.length > 0 && (
          <HomeSlider
            viewMode={viewMode}
            title={t(
              filterActive === "all" ? "all" : platforms.movie[filterActive]
            )}
            items={lineOne.items}
            nextPage={lineOne.nextPage}
            isLoading={lineOne.isLoading}
            callbackSeeMore={() =>
              fetchLineOne(
                {
                  platform: filterActive === "all" ? undefined : filterActive,
                  mediaType: "MOVIE",
                },
                lineOne.nextPage
              )
            }
          />
        )}
        {recentlyAdded.items.length > 0 && (
          <HomeSlider
            title={t("Recently Added")}
            viewMode={viewMode}
            items={recentlyAdded.items}
            nextPage={recentlyAdded.nextPage}
            isLoading={recentlyAdded.isLoading}
            callbackSeeMore={() =>
              fetchRecentlyAdded({ mediaType: "MOVIE" }, recentlyAdded.nextPage)
            }
          />
        )}
      </>
    ),
    BOOK: () => (
      <>
        {nice("book")}
        {lineOne.items.length > 0 && (
          <HomeSlider
            viewMode={viewMode}
            title={t(
              filterActive === "all" ? "all" : platforms.book[filterActive]
            )}
            items={lineOne.items}
            nextPage={lineOne.nextPage}
            isLoading={lineOne.isLoading}
            callbackSeeMore={() =>
              fetchLineOne(
                {
                  platform: filterActive === "all" ? undefined : filterActive,
                  mediaType: "BOOK",
                },
                lineOne.nextPage
              )
            }
          />
        )}
        {recentlyAdded.items.length > 0 && (
          <HomeSlider
            title={t("Recently Added")}
            viewMode={viewMode}
            items={recentlyAdded.items}
            nextPage={recentlyAdded.nextPage}
            isLoading={recentlyAdded.isLoading}
            callbackSeeMore={() =>
              fetchRecentlyAdded({ mediaType: "BOOK" }, recentlyAdded.nextPage)
            }
          />
        )}
      </>
    ),
    MUSIC: () => (
      <>
        {nice("music")}
        {lineOne.items.length > 0 && (
          <HomeSlider
            viewMode={viewMode}
            title={t(
              filterActive === "all" ? "all" : platforms.music[filterActive]
            )}
            items={lineOne.items}
            nextPage={lineOne.nextPage}
            isLoading={lineOne.isLoading}
            callbackSeeMore={() =>
              fetchLineOne(
                {
                  platform: filterActive === "all" ? undefined : filterActive,
                  mediaType: "MUSIC",
                },
                lineOne.nextPage
              )
            }
          />
        )}
        {recentlyAdded.items.length > 0 && (
          <HomeSlider
            title={t("Recently Added")}
            viewMode={viewMode}
            items={recentlyAdded.items}
            nextPage={recentlyAdded.nextPage}
            isLoading={recentlyAdded.isLoading}
            callbackSeeMore={() =>
              fetchRecentlyAdded({ mediaType: "MUSIC" }, recentlyAdded.nextPage)
            }
          />
        )}
      </>
    ),
  };
  return (
    <>
      <ViewMode
        style={{ margin: "10px 20px 0" }}
        onChange={(mode) => setViewMode(mode)}
        active={viewMode}
      />

      {filters[activeFilter]()}
    </>
  );
}

const Select = styled.div`
  background: linear-gradient(#1690b1, #0c5b8d);
  width: fit-content;
  overflow: hidden;
  border-radius: 50px;
  padding: 4px 12px;
  float: right;
  & > select {
    background: transparent;
    color: #fff;
    cursor: pointer;
  }
`;
