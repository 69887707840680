import styled from 'styled-components'

export const GlobalNotificationButton = styled.div`
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  path {
    fill: ${props => (props.notifications > 0 ? '#ffe400' : '#fff497')};
  }

  :hover svg {
    opacity: 0.8;
  }
`

export const GlobalBadge = styled.div`
  position: relative;
`

const SIZE_BADGE = 18

export const Badge = styled.div`
  min-width: ${SIZE_BADGE}px;
  height: ${SIZE_BADGE}px;
  line-height: ${SIZE_BADGE}px;
  background-color: ${props => props.theme.colorBadge};
  border-radius: ${SIZE_BADGE / 2}px;
  font-size: ${props => props.theme.fontSizes.default2};
  text-align: center;
  padding: 0 4px;
  position: absolute;
  top: -${SIZE_BADGE / 4}px;
  right: -${SIZE_BADGE / 4}px;
`
