import styled from 'styled-components'

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 250px;
  margin: 0 auto;
  
  .username {
    font-weight: bold;
    font-size: 1.2rem;
  }

  .location {
    span {
      padding: 5px;
    }
  }

  .column {
    display: flex;
    flex-direction: column;
  }

  .row {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
`

export const Body = styled.div`
  padding: 50px 20px 10px 20px;

  .remove-image {
    position: absolute;
    right: 55px;
  }
  
  @media (max-width: 599px) {
    padding: 20px 0 0 0;
  }
`

export const Form = styled.form`
  width: 100%;
  display: grid;
  justify-content: center;
  align-items: center;
  grid-gap: 15px;
  grid-template-columns: 1fr 120px;
  grid-template-rows: 1fr 1fr 1fr;
  grid-template-areas:
    'top top'
    'left right'
    'footer footer';
  margin: 0 auto;
  @media (max-width: 599px) {
    grid-template-columns: 1fr 80px;
    grid-template-rows: 1fr 1fr 1fr;
    height: 400px;
    width: 100%;
    padding: 0;
  }

  select,
  textarea {
    padding-left: 10px;
    font-weight: bold;
    font-size: 0.9rem;
  }

  .gridtop {
    grid-area: top;
  }

  .gridleft {
    grid-area: left;
  }

  .gridright {
    display: flex;
    justify-content: center;
    align-items: center;
    grid-area: right;
    div {
      @media (max-width: 599px) {
        max-width: 100px;
      }
    }
  }

  .gridfooter {
    grid-area: footer;
    margin: 0 auto;
  }
`
