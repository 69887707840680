import styled from 'styled-components'
import React, { useEffect, useState } from 'react'
import socket from 'socket.io-client'
import { useTranslation } from 'react-i18next'
import api from '../../../../services/api'
import HiddenContent, { searchBarActions } from './HiddenContent'
import * as searchActions from '../../../commons/SearchBar/actions'
import { notificationsActions } from './HiddenContent/Notifications'
import { useStore } from '../../../../store'
import { useRouter, useMedia } from '../../../../hooks'
import { Logo, IconButton, Icon } from '../../../commons'
import ICONS from '../../../ui/ICONS'
import NotificationButton from './NotificationButton'
import { DefaultTemplateNavigation } from '..'
import ChangeLanguage from './ChangeLanguage'
import {
  GlobalHeader,
  HeaderBand,
  StyledWrapper,
  HeaderColumn,
  AddMedia,
} from './styles'

let loading = false
const Header = (props) => {
  const { dontUseDefaultSearch } = props
  const { user } = useStore()
  const { hiddenContent, notifications, dispatch } = useStore()
  const { history } = useRouter()
  const isSm = useMedia('(min-width: 576px)')
  const isGreaterThan1370 = useMedia('(min-width: 1370px)')
  const { t } = useTranslation()
  const [messages, setMessages] = useState([])

  const getInitialExchanges = async () => {
    const response = await api.get('/notification/trade')
    dispatch(notificationsActions.setNotifications(response.data))
  }

  const subscribeToNewExchanges = async () => {
    const io = socket(process.env.REACT_APP_API_URL, {
      transports: ['websocket'],
    })

    io.on('notification', (data) => {
      dispatch(notificationsActions.setNotificationFromSocket(data))
    })

    io.on('refresh-messages', async () => {
      if (loading) return
      loading = true
      const { data } = await api.get('/user/messages')
      loading = false
      setMessages(data)
    })

    const { data } = await api.get('/user/messages')
    setMessages(data)
  }

  useEffect(() => {
    if (!Object.keys(user.data).length) return

    getInitialExchanges()
    subscribeToNewExchanges()
  }, [user.data])

  useEffect(() => {
    dispatch(searchBarActions.hideTheHiddenContent())
  }, [history.location.pathname])

  const toggleSearchBar = () => {
    if (dontUseDefaultSearch) {
      dispatch(searchBarActions.hideTheHiddenContent())
      document.getElementById('wrapperGoToTop').scroll({
        top: 0,
        behavior: 'smooth',
      })
      setTimeout(() => {
        document.getElementById('page-search').focus()
      }, 800)
      return
    }
    if (hiddenContent === 'searchBar') {
      dispatch(searchActions.clearSearchResults())
      dispatch(searchActions.closeSearchResults())
      dispatch(searchBarActions.hideTheHiddenContent())
    } else {
      dispatch(searchBarActions.showAHiddenContent('searchBar'))
    }
  }

  const toggleChangeLanguage = () => {
    if (hiddenContent === 'changeLanguage') return dispatch(searchBarActions.hideTheHiddenContent())

    return dispatch(searchBarActions.showAHiddenContent('changeLanguage'))
  }

  const toggleNotifications = (event) => {
    event.stopPropagation()
    if (hiddenContent === 'notifications') {
      dispatch(searchBarActions.hideTheHiddenContent())
    } else if (
      notifications.data
      && Object.keys(notifications.data).length > 0
    ) {
      dispatch(searchBarActions.showAHiddenContent('notifications'))
    }
  }

  const Button = styled.button`
  background: linear-gradient(180deg, #4cd964 0%, #0c9d25 93.75%);
  color: #fff;
  padding: 6px 70px;
  font-size: 14px;
  line-height: 22px;
  border-radius: 6px;
  width: 100%;
  text-align: center;
  font-weight: 600;
  margin-top: 36px;
  &:not(:disabled) {
    cursor: pointer;
  }
`

  return (
    <GlobalHeader>
      <HeaderBand>

        { messages.length > 0 && (
          <div style={{
            position: 'absolute',
            top: 0,
            left: 0,
            zIndex: 99,
            width: '100vw',
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            background: 'rgba(0,0,0, .5)',
          }}

          >
            <div style={{ maxWidth: '360px', background: '#112539', padding: '20px' }}>
              <h1 style={{ textAlign: 'center', fontSize: '24pt' }}>{t('Notification')}</h1>
              { messages.map(item => (
                <div
                  style={{
                    marginTop: '10px', wordBreak: 'break-word', textAlign: 'center', whiteSpace: 'break-spaces',
                  }}
                  key={Math.random()}
                >
                  { item.message }
                </div>
              ))}
              <Button
                type="button"
                onClick={() => setMessages([])}
              >
                {t('Close')}
              </Button>
            </div>
          </div>
        )
        }
        <StyledWrapper>
          <HeaderColumn>
            <DefaultTemplateNavigation />
          </HeaderColumn>


          <HeaderColumn>
            <Logo height="50px" />
          </HeaderColumn>

          <HeaderColumn>
            {Object.keys(user.data).length > 0 && (
            <>
              <NotificationButton
                onClick={event => toggleNotifications(event)}
                notifications={notifications.unread}
              />
            </>
            )}

            {history.location.pathname !== '/' && (
            <IconButton
              icon={
                  hiddenContent === 'searchBar' && !dontUseDefaultSearch
                    ? ICONS.CLOSE
                    : ICONS.SEARCH
                }
              onClick={() => toggleSearchBar()}
              minClickable
            />
            )}

            {isSm && (
            <>
              {user.data.id && (
              <AddMedia
                onClick={() => history.push('/add-media')}
                isSm={isSm}
              >
                <Icon icon={ICONS.MORE} />

                <span>{t('Add Media')}</span>
              </AddMedia>
              )}

              <ChangeLanguage
                style={
                    isGreaterThan1370
                      ? {
                        position: 'fixed',
                        right: '8px',
                        top: '26px',
                      }
                      : {}
                  }
                onClick={() => toggleChangeLanguage()}
                active={hiddenContent === 'changeLanguage'}
              />
            </>
            )}
          </HeaderColumn>
        </StyledWrapper>
      </HeaderBand>

      <HiddenContent dontUseDefaultSearch={dontUseDefaultSearch} />

      {/* <DefaultTemplateSearchBar active={hiddenContent} /> */}
    </GlobalHeader>
  )
}

export default Header
