import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import api from '../../../../../services/api'
import { useRouter } from '../../../../../hooks'
import { useStore } from '../../../../../store'
import {
  Grid, MediaCard, Loader, MediasFilter,
} from '../../../../commons'
import EmptySpace from './EmptySpace'
import MediaSelected from './MediaSelected'
import { Container } from './styles'

export default function TradeSection({
  userInfo,
  data,
  tradeId,
  readyToTrade,
  fromUser,
  setMediaCount,
}) {
  const { t } = useTranslation()
  const [library, setLibrary] = useState({ items: [] })
  const [libraryToShow, setLibraryToShow] = useState([])
  const [trading, setTrading] = useState([])
  const [loading, setLoading] = useState(false)
  const [accepted, setAccepted] = useState(false)
  const { user } = useStore()
  const { history } = useRouter()
  const [mediaTypesFromLibrary, setMediaTypesFromLibrary] = useState({})
  const [filters, setFilters] = useState({})

  useEffect(() => {
    if (!Object.keys(filters).length && !library.items.length) return

    setLibraryToShow(() => {
      let newItemsToShow = []

      if (Object.keys(filters).length > 0) {
        const mediaTypes = Object.keys(filters)
        const platforms = Object.keys(filters).map(key => filters[key])
        newItemsToShow = library.items.filter(
          item => mediaTypes.includes(item.mediaType)
            && platforms.includes(item.platform.id),
        )
      } else {
        newItemsToShow = JSON.parse(JSON.stringify(library.items))
      }

      return newItemsToShow
    })
  }, [filters])

  async function fetchLibrary() {
    setLoading(true)

    try {
      const res = await api.post(`media/user/${userInfo.id}`, {
        limit: '*',
      })
      const {
        data: { docs, mediaTypes },
      } = res
      const newItems = docs
      setLibrary({ items: newItems })
      setLibraryToShow(newItems)
      setMediaTypesFromLibrary(mediaTypes)
    } catch (err) {
      history.push('/')
    }

    setLoading(false)
  }

  function populateArrays() {
    setTrading(data.trading)
  }

  function renderEmpty(size) {
    const array = Array(Math.max(size, 0)).fill('')
    return array.map(() => (
      <Grid key={Math.random()} item noMargin lg={6} spacing={4}>
        <EmptySpace />
      </Grid>
    ))
  }

  function renderItems(size = 4) {
    let groupedValues = [...trading]
    if (fromUser) setMediaCount(groupedValues.length)

    groupedValues = groupedValues.map(trade => (
      <Grid key={trade.id} item noMargin lg={6} spacing={4}>
        <MediaSelected
          data={trade}
          tradeId={tradeId}
          readyToTrade={readyToTrade}
        />
      </Grid>
    ))
    const array = [
      ...groupedValues,
      ...renderEmpty(size - groupedValues.length),
    ]
    return array
  }

  function requestOtherUserMedia(id) {
    return api.post('trade/media/request', { tradeId, mediaId: id })
  }

  async function offerMyMedia(id) {
    return api.post('trade/media/offer', { tradeId, mediaId: id })
  }

  async function handleCallbackSelect(item) {
    if (item.user.id === user.data.id) {
      try {
        await offerMyMedia(item.id)
      } catch (error) {
        toast.dismiss()
        toast.error(
          t('You can only offer a media if you have`nt accepted the trade'),
        )
      }
    } else {
      try {
        await requestOtherUserMedia(item.id)
      } catch (error) {
        toast.dismiss()
        toast.error(
          t('You can only request a media if you have`nt accepted the trade'),
        )
      }
    }
  }

  function renderLibrary() {
    return (
      <>
        {libraryToShow && libraryToShow.length > 0 && (
          <>
            {libraryToShow.map(item => (
              <Grid
                key={item.id}
                item
                noMargin
                lg={3}
                md={4}
                xs={6}
                spacing={4}
              >
                <MediaCard
                  marginAuto
                  actions="select"
                  showTitle={false}
                  data={item}
                  selected={!!trading.find(trade => item.id === trade.id)}
                  callbackSelect={() => handleCallbackSelect(item)}
                />
              </Grid>
            ))}

            {loading && <Loader />}
          </>
        )}

        {!loading && libraryToShow && !libraryToShow.length && (
          <Grid item noMargin>
            {t('User media library is currently empty')}
          </Grid>
        )}
      </>
    )
  }

  useEffect(() => {
    populateArrays()
    if (data.status === 'accepted') {
      setAccepted(true)
    } else {
      setAccepted(false)
    }
  }, [data])

  useEffect(() => {
    if (userInfo) {
      fetchLibrary()
      populateArrays()
    }
  }, [userInfo])

  const changeMediaFilter = (mediaType, platform) => {
    setFilters((prev) => {
      let newFilters = JSON.parse(JSON.stringify(prev))

      if (!mediaType) {
        newFilters = {}
      } else if (!platform) {
        delete newFilters[mediaType]
      } else {
        newFilters[mediaType] = platform
      }

      return newFilters
    })
  }

  return (
    <Container accepted={accepted}>
      <Grid container noMargin className="trade-section">
        {renderItems()}
      </Grid>
      <div>
        <MediasFilter
          mediaTypesFromLibrary={mediaTypesFromLibrary}
          callbackChangeFilter={changeMediaFilter}
          active={filters}
        />

        <Grid container noMargin className="library-section">
          {renderLibrary()}
        </Grid>
      </div>
    </Container>
  )
}
