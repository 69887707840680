import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import {
  string, func, number, bool,
} from 'prop-types'
import Cropper from 'react-easy-crop'
import { useTranslation } from 'react-i18next'
import getCroppedImg from './cropImage'
import { Modal, ModalContent, Button } from '../..'
import { Title, GlobalCropper, GlobalAction } from './styles'

const ModalCrop = (props) => {
  const {
    open, handleClose, src, aspectToCrop, onCrop, isLoading,
  } = props
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)
  const [croppedAreaPixelsFromImage, setCroppedAreaPixelsFromImage] = useState(
    {},
  )
  const { t } = useTranslation()

  useEffect(() => {
    if (open) return

    setCrop({ x: 0, y: 0 })
    setZoom(1)
    setCroppedAreaPixelsFromImage({})
  }, [open])

  const onSave = async () => {
    try {
      const croppedImage = await getCroppedImg(src, croppedAreaPixelsFromImage)
      onCrop(croppedImage)
    } catch (error) {
      toast.error(t('An error occurred. Try again later.'))
    }
  }

  return (
    <Modal open={open} handleClose={handleClose}>
      <ModalContent>
        <Title>{t('Crop image to upload')}</Title>

        <GlobalCropper>
          <Cropper
            image={src}
            crop={crop}
            zoom={zoom}
            aspect={aspectToCrop}
            onCropChange={data => setCrop(data)}
            onCropComplete={(_, croppedAreaPixels) => setCroppedAreaPixelsFromImage(croppedAreaPixels)
            }
            onZoomChange={data => setZoom(data)}
          />
        </GlobalCropper>

        <GlobalAction>
          <Button variation="success" onClick={onSave} isLoading={isLoading}>
            {t('Save photo')}
          </Button>
        </GlobalAction>
      </ModalContent>
    </Modal>
  )
}

ModalCrop.propTypes = {
  open: bool.isRequired,
  handleClose: func.isRequired,
  src: string.isRequired,
  aspectToCrop: number.isRequired,
  onCrop: func.isRequired,
  isLoading: bool,
}

ModalCrop.defaultProps = {
  isLoading: false,
}

export default ModalCrop
