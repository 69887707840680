export default [
  {
    label: 'Games',
    value: 'game',
  },
  {
    label: 'Movies',
    value: 'movie',
  },
  {
    label: 'Music',
    value: 'music',
  },
  {
    label: 'Books',
    value: 'book',
  },
]
