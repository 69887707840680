import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify'

import { WarningDialog } from '../../../Wizard/Dialog'
import { goBack, navigateToNext, updateTrade } from '../../../store'
import {
  createDimension,
  createLabel,
  createRate,
} from '../../../../../../services/trade'
import { Title } from '../../../../../ui'
import {
  Grid, Input, Button,
} from '../../../../../commons'
import {
  heightIcon,
  lengthIcon,
  weightIcon,
  widthIcon,
  boxHeight,
  boxWidth,
  boxLength,
} from '../../../../../../assets'
import {
  ButtonsGroup,
  Dimension,
  Dimensions,
  Header,
  ShippingInfos,
  InputContainer,
  InputIcon,
} from '../../styles'
import { TransactionResume, InputPopover } from '../../components'

const ShippingDimensions = ({
  dispatch, myTrade, hisTrade, tradeId,
}) => {
  const [myMedia] = myTrade.trading
  const [hisMedia] = hisTrade.trading
  const [isCustom, setIsCustom] = useState(false)
  const [isAutomatized, setIsAutomatized] = useState(true)
  const [timeToSend, setTimeToSend] = useState(3)
  const [height, setHeight] = useState('')
  const [width, setWidth] = useState('')
  const [length, setLength] = useState('')
  const [weight, setWeight] = useState('')
  const [automatedDimensions, setAutomatedDimensions] = useState({})
  const [shippingPrice, setShippingPrice] = useState(0)
  const [rateId, setRateId] = useState(undefined)
  const [shippingLabel, setShippingLabel] = useState(undefined)
  const [isLoading, setIsLoading] = useState(false)
  const [isCreatingLabel, setIsCreatingLabel] = useState(false)
  const [isOnlyRead, setIsOnlyRead] = useState(true)
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  useEffect(() => {
    if (isAutomatized) {
      createAutomatizedDimensions()
    }
  }, [isAutomatized])
  useEffect(() => {
    if (height && width && length && weight) {
      setTimeout(fetchRate, 2000)
    }
  }, [height, width, length, weight])
  const createAutomatizedDimensions = async () => {
    try {
      setIsLoading(true)
      const {
        height, length, weight, width,
      } = await createDimension(tradeId)
      setAutomatedDimensions({
        height,
        length,
        weight,
        width,
      })
      setHeight(height.toFixed(2))
      setWidth(width.toFixed(2))
      setLength(length.toFixed(2))
      setWeight(weight.toFixed(2))
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }
  const fetchRate = async () => {
    const dimensions = {
      height,
      width,
      weight,
      length,
    }
    setShippingPrice(0)
    try {
      const rates = await createRate(dimensions, tradeId)
      const byLowestAmount = (current, next) => (Number(current.amount) > Number(next.amount) ? 1 : -1)
      const [rate] = rates.sort(byLowestAmount)
      const { object_id, amount } = rate
      setShippingPrice(Number(amount))
      setRateId(object_id)
    } catch (err) {
      console.log(err)
    }
  }
  const handleSelectCustom = (event) => {
    event.preventDefault()
    setIsAutomatized(false)
    setIsOnlyRead(false)
    setIsCustom(true)
  }
  const handleSelectAutomatized = (event) => {
    event.preventDefault()
    setIsCustom(false)
    setIsOnlyRead(true)
    setIsAutomatized(true)
    setHeight(automatedDimensions.height.toFixed(2))
    setWidth(automatedDimensions.width.toFixed(2))
    setLength(automatedDimensions.length.toFixed(2))
    setWeight(automatedDimensions.weight.toFixed(2))
  }
  const handleCreateLabel = async (event) => {
    event.preventDefault()
    try {
      setIsCreatingLabel(true)
      const {
        trade,
        transaction: { label_url },
      } = await createLabel(rateId, tradeId)
      dispatch(updateTrade(trade))
      setShippingLabel(label_url)
      return dispatch(navigateToNext())
    } catch (error) {
      if (error.response && error.response.data.errors) {
        error.response.data.errors.map(item => toast.error(item))
      } else {
        toast.error(
          error.response && error.response.data.message
            ? error.response.data.message
            : 'An error occurred. Try again later.',
        )
      }
    } finally {
      setIsCreatingLabel(false)
      setIsDialogOpen(false)
    }
  }
  const handleNext = (event) => {
    event.preventDefault()
    event.stopPropagation()
    if (!shippingLabel) {
      return setIsDialogOpen(true)
    }
    return dispatch(navigateToNext())
  }
  const handleBack = (event) => {
    event.stopPropagation()
    dispatch(goBack())
  }
  return (
    <Grid container justify="center">
      <Header item md={12} lg={12}>
        <h1>What are the dimensions of the package?</h1>
      </Header>
      <Dimensions>
        <Dimension onClick={handleSelectAutomatized} isSelected={isAutomatized}>
          Automated
        </Dimension>
        <Dimension onClick={handleSelectCustom} isSelected={isCustom}>
          Custom
        </Dimension>
      </Dimensions>
      <Grid item md={2} lg={2}>
        <InputContainer>
          <Input
            id="height"
            label="Height (in)"
            placeholder="Height"
            value={height}
            onChange={event => setHeight(event.target.value)}
            type="text"
            readOnly={isOnlyRead}
          />
          <InputIcon translate={5} src={heightIcon} />
          <InputPopover>
            <img src={boxHeight} alt="box-height" />
          </InputPopover>
        </InputContainer>
      </Grid>
      <Grid item md={2} lg={2}>
        <InputContainer>
          <Input
            id="width"
            label="Width (in)"
            placeholder="Width"
            value={width}
            onChange={event => setWidth(event.target.value)}
            type="text"
            readOnly={isOnlyRead}
          />
          <InputIcon translate={10} src={widthIcon} />
          <InputPopover>
            <img src={boxWidth} alt="box-width" />
          </InputPopover>
        </InputContainer>
      </Grid>
      <Grid item md={2} lg={2}>
        <InputContainer>
          <Input
            id="length"
            label="Length (in)"
            placeholder="Length"
            value={length}
            onChange={event => setLength(event.target.value)}
            type="text"
            readOnly={isOnlyRead}
          />
          <InputIcon translate={5} src={lengthIcon} />
          <InputPopover>
            <img src={boxLength} alt="box-length" />
          </InputPopover>
        </InputContainer>
      </Grid>
      <Grid item md={2} lg={2}>
        <InputContainer>
          <Input
            id="weight"
            label="Weight (oz)"
            placeholder="Weight"
            value={weight}
            onChange={event => setWeight(event.target.value)}
            type="text"
            readOnly={isOnlyRead}
          />
          <InputIcon translate={10} src={weightIcon} />
        </InputContainer>
      </Grid>
      <Header item md={12} lg={12}>
        <h1>How long will it take you to send the media?</h1>
      </Header>
      <Dimensions>
        <Dimension
          onClick={() => setTimeToSend(3)}
          isSelected={timeToSend <= 3}
        >
          1-3 days
        </Dimension>
        <Dimension onClick={() => setTimeToSend(6)} isSelected={timeToSend > 3}>
          4-6 days
        </Dimension>
      </Dimensions>
      <ShippingInfos align="center" item md={12} lg={12} xlg={12}>
        <Title>Shipping Price</Title>
        <p>{shippingPrice ? `$ ${shippingPrice.toFixed(2)}` : 'Evaluating'}</p>
        <span>We recommend you to get insurance for this shipping!</span>
      </ShippingInfos>

      <TransactionResume myMedia={myMedia} hisMedia={hisMedia} />
      <ButtonsGroup>
        <Button
          disabled={isLoading}
          onClick={event => handleBack(event)}
          variation="info"
        >
          BACK
        </Button>
        <Button
          disabled={!shippingPrice}
          onClick={handleNext}
          variation="success"
          isLoading={isLoading}
        >
          NEXT
        </Button>
      </ButtonsGroup>
      <WarningDialog
        isOpen={isDialogOpen}
        isLoading={isCreatingLabel}
        handleClose={() => setIsDialogOpen(false)}
        handleSubmit={handleCreateLabel}
      />
    </Grid>
  )
}

export default ShippingDimensions
