import React from 'react'

import { useTranslation } from 'react-i18next'
import {
  DefaultPageBox,
  DefaultPageFadeBackground,
  DefaultPageNewBackground,
  DefaultPageTitle,
  DefaultPageNewBoxHeader,
  DefaultPageWrapper,
  DefaultPageBoxContent,
} from '../../templates/DefaultPage'
import { DefaultTemplateBackground } from '../../templates/DefaultTemplate'
import { Grid, Meta } from '../../commons'
import { PageSection, Title } from './styles'

const FAQ = () => {
  const { t } = useTranslation()

  const metaDescription = 'NUM is the world’s first fully-dedicated trading' +
    'platform. It is a place for people who are no longer playing, watching, reading or listening to certain media they own.'

  return (
    <DefaultTemplateBackground bottom>
      <Meta metaDescription={metaDescription} />
      <DefaultPageNewBackground />
      <DefaultPageFadeBackground />

      <DefaultPageWrapper>
        <DefaultPageTitle>{t('FAQ')}</DefaultPageTitle>

        <DefaultPageBox>
          <DefaultPageNewBoxHeader />

          <DefaultPageBoxContent>
            <Grid container justify="flex-start">
              <Grid item md={9} lg={9} xlg={9} xs={9}>
                <PageSection>
                  <Title>{t('WHAT IS NEW USED MEDIA (NUM) ?')}</Title>
                  <p>
                    {t(
                      'faq1',
                    )}
                  </p>
                </PageSection>
                <PageSection>
                  <Title>{t('HOW DOES IT WORK ?')}</Title>
                  <p>
                    {t(
                      'faq2',
                    )}
                  </p>
                </PageSection>
                <PageSection>
                  <Title>{t('WHAT ARE THE COSTS ?')}</Title>
                  <p>{t('faq3')}</p>
                  <p>
                    {t('faq4')}
                  </p>
                  <p>{t('faq5')}</p>
                </PageSection>
                <PageSection>
                  <Title>{t('HOW DOES TRADING WORK?')}</Title>
                  <p>
                    {t(
                      'faq6',
                    )}
                  </p>
                </PageSection>
                <PageSection>
                  <Title>{t('HOW DOES THE SHIPPING WORK ?')}</Title>
                  <p>
                    {t(
                      'faq7',
                    )}
                  </p>
                </PageSection>
                <PageSection>
                  <Title>{t('CAN I TRADE DIGITAL ITEMS ?')}</Title>
                  <p>
                    {t(
                      'faq8',
                    )}
                  </p>
                </PageSection>
                <PageSection>
                  <Title>
                    {t('faq9')}
                  </Title>
                  <p>
                    {t(
                      'faq10',
                    )}
                  </p>
                </PageSection>
                <PageSection>
                  <Title>
                    {t('CAN I TRADE ITEMS THAT HAVE BEEN ILLEGALLY COPIED?')}
                  </Title>
                  <p>
                    {t(
                      'faq11',
                    )}
                  </p>
                </PageSection>
                <PageSection>
                  <Title>{t('WHAT KINDS OF GAMES CAN BE TRADED ?')}</Title>
                  <p>
                    {t(
                      'faq12',
                    )}
                  </p>
                </PageSection>
                <PageSection>
                  <Title>
                    {t(
                      'HOW ARE TRADES REGULATED AND/OR VERIFIED SO I KNOW I`M NOT GETTING SCAMMED ?',
                    )}
                  </Title>
                  <p>
                    {t(
                      'faq13',
                    )}
                  </p>
                  <p>
                    {t(
                      'faq14',
                    )}
                  </p>
                </PageSection>
                <PageSection>
                  <Title>{t('CAN I CANCEL MY SUBSCRIPTION?')}</Title>
                  <p>
                    {t(
                      'faq15',
                    )}
                  </p>
                </PageSection>
                <PageSection>
                  <Title>{t('ARE THERE MEMBERSHIP BENEFITS ?')}</Title>
                  <p>{t('faq16')}</p>
                </PageSection>
                <PageSection>
                  <Title>
                    {t('HOW DOES A USER UPLOAD THEIR GAME LIBRARY ?')}
                  </Title>
                  <p>
                    {t(
                      'faq17',
                    )}
                  </p>
                </PageSection>
              </Grid>
            </Grid>
          </DefaultPageBoxContent>
        </DefaultPageBox>
      </DefaultPageWrapper>
    </DefaultTemplateBackground>
  )
}

export default FAQ
