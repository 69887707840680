import React, { useState, useReducer } from 'react'
import { toast } from 'react-toastify'

import { useTranslation } from 'react-i18next'
import { signUpWizard } from '../../../services/auth'
import { DefaultTemplateBackground } from '../../templates/DefaultTemplate'
import { DefaultPageWrapper } from '../../templates/DefaultPage'
import { Wizard } from './Wizard'
import { BillingAddress } from './BillingAddress'
import { ShippingAddress } from './ShippingAddress'
import { SubscriptionType } from './SubscriptionType'
import { useRouter } from '../../../hooks'
import { INITIAL_STATE, wizardsReducer } from './store/wizardsReducer'
import { FORM_INITIAL_STATE, formReducer } from './store/formReducer'

const SignUpWizard = () => {
  const [state, dispatch] = useReducer(wizardsReducer, INITIAL_STATE)
  const [form, changeForm] = useReducer(formReducer, FORM_INITIAL_STATE)
  const [isLoading, setIsLoading] = useState(false)
  const [hasSecondaryAddress, setHasSecondaryAddress] = useState(false)
  const [currentPage, handleChangeCurrentPage] = useState(0)
  const { history } = useRouter()
  const { t } = useTranslation()

  const navigateToNext = () => {
    handleChangeCurrentPage(currentPage + 1)
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    try {
      setIsLoading(true)
      await signUpWizard(state)
      history.push('/')
      toast.success(t('Done! Add your media!'))
    } catch (error) {
      toast.dismiss()
      if (error.response.data.errors) {
        error.response.data.errors.map(item => toast.error(item))
      } else {
        toast.error(
          error.response && error.response.data.message
            ? error.response.data.message
            : t('An error occurred. Try again later.'),
        )
      }
    } finally {
      setIsLoading(false)
    }
  }
  const entries = [
    {
      title: t('Contact Information'),
      label: 'address',
      component: (
        <ShippingAddress
          navigateToNext={navigateToNext}
          hasSecondaryAddress={hasSecondaryAddress}
          setHasSecondaryAddress={setHasSecondaryAddress}
          dispatch={dispatch}
          form={form}
          changeForm={changeForm}
          state={state}
        />
      ),
    },
    {
      title: t('input billing address'),
      label: 'payment',
      component: (
        <BillingAddress
          navigateToNext={navigateToNext}
          dispatch={dispatch}
          form={form}
          state={state}
          changeForm={changeForm}
        />
      ),
    },
    {
      title: t('subscription type'),
      label: 'subscription ',
      component: (
        <SubscriptionType
          dispatch={dispatch}
          form={form}
          changeForm={changeForm}
          handleFinish={handleSubmit}
          isLoading={isLoading}
        />
      ),
    },
  ]
  return (
    <DefaultTemplateBackground top bottom>
      <DefaultPageWrapper
        topXs="10px"
        topSm="30px"
        topLg="40px"
        topXlg="50px"
        botXs="10px"
        botSm="30px"
        botLg="40px"
        botXlg="50px"
      >
        <Wizard
          handleSubmit={handleSubmit}
          entries={entries}
          currentPage={currentPage}
          handleChangeCurrentPage={handleChangeCurrentPage}
          isLoading={isLoading}
          state={state}
          form={form}
        />
      </DefaultPageWrapper>
    </DefaultTemplateBackground>
  )
}

export default SignUpWizard
