import styled from 'styled-components'

export default styled.div`
  flex: 1 1 auto;
  background: linear-gradient(
    to bottom,
    rgba(13, 32, 50, 1) 0%,
    rgba(13, 32, 50, 1) 50%,
    rgba(13, 32, 50, 0.6) 100%
  );
  box-shadow: 0px 0px 25px 0px #000e1b;
  border-radius: 10px 10px 0 0;
  overflow: hidden;
`
