import styled from 'styled-components'

export const NewBackground = styled.div`
  width: 100%;
  height: 326px;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  overflow: hidden;

  :before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background: url(${props => props.src}) no-repeat top center;
    background-position-y: ${props => (props.withBlur ? '20%' : '50%')};
    background-size: cover;
    filter: ${props => (props.withBlur ? 'blur(10px)' : 'none')};
  }

  @media (max-width: 599px) {
    width: 100vw;
    height: 140px;

    &:before {
      background-position: center;
      background-position-y: 25%;
      background-size: 700px;
    }
  }
`

export const FadeBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 326px;
  background-image: linear-gradient(transparent, rgba(0, 0, 0, 0.8));
  @media (max-width: 599px) {
    width: 100vw;
    height: 140px;
  }
`

export const Header = styled.div`
  height: 48px;
  width: 100%;
  background-color: #0b1625;
`
