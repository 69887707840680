import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMoment } from '../../../hooks'
import handshake from '../../../assets/handshake-bg.jpg'

import { DefaultTemplateBackground } from '../../templates/DefaultTemplate'
import { getUserTrades } from '../../../services/trade'
import ItemHistory from './ItemHistory'

import {
  PageWrapper,
  Container,
  PageBackground,
  Fade,
  PageTitle,
  Header,
  StatusFilter,
  StatusItem,
  HistoryList
} from './styles'

const History = () => {
  const [loading, setLoading] = useState(true)
  const [listType, selectListType] = useState('active')
  const [tradesHistory, setTradesHistory] = useState([])
  const { t } = useTranslation()
  const moment = useMoment()

  async function fetchTradeHistory() {
    const trades = await getUserTrades()
    setTradesHistory(Object.values(trades))
  }

  useEffect(() => {
    fetchTradeHistory()
    setLoading(false)
  }, [])

  // Trade status filters
  const isFinished = trade => trade.status === 'declined'
    || trade.status === 'finalized'
    || trade.status === 'completed'
  const tradesHistoryFinished = tradesHistory.filter(isFinished)
  const tradesHistoryActive = tradesHistory.filter(
    trade => trade.status === 'accepted' || trade.status === 'pending',
  )

  const filteredHistory = () => {
    let list
    if (listType === 'active') list = tradesHistoryActive
    if (listType === 'finished') list = tradesHistoryFinished
    if (listType === 'all') list = tradesHistory
    return list.map((trade, i) => (
      <ItemHistory key={trade.id} trade={trade} i={i}/>
    ))
  }

  return (
    <DefaultTemplateBackground bottom>
      <PageBackground src={handshake} />
      <Fade />
      <PageWrapper topXs="50px" topMd="100px">
        <PageTitle>{t('History')}</PageTitle>
        <Container>
          <Header>
            <StatusFilter>
              <StatusItem
                name="active"
                onClick={() => selectListType('active')}
                className={listType === 'active' && 'activeTab'}
              >
                {t('ACTIVE')}
              </StatusItem>
              <StatusItem
                name="finished"
                onClick={() => selectListType('finished')}
                className={listType === 'finished' && 'activeTab'}
                id="center-item"
              >
                {t('FINISHED')}
              </StatusItem>
              <StatusItem
                name="all"
                onClick={() => selectListType('all')}
                className={listType === 'all' && 'activeTab'}
              >
                {t('ALL')}
              </StatusItem>
            </StatusFilter>
          </Header>
          <HistoryList>
            {loading ? <p>Loading...</p> : filteredHistory()}
          </HistoryList>
        </Container>
      </PageWrapper>
    </DefaultTemplateBackground>
  )
}

export default History
