import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { withTheme } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useRouter, useMedia } from '../../../../hooks'
import api from '../../../../services/api'
import { useStore } from '../../../../store'

import {
  Modal,
  ModalContent,
  Media,
  Button,
  UserShowcase,
  BrandBadge,
  SwipeCheckbox,
  Loader,
} from '../../../commons'
import {
  Table, Thead, Tbody, Tr, Th, Td,
} from '../../../ui'
import {
  Title, GlobalButton, GlobalUser, Selected,
} from './styles'

const ModalIncreaseMyChances = (props) => {
  const { t } = useTranslation()
  const {
    open, handleClose, theme, searchTerm,
  } = props
  const { user } = useStore()
  const { history } = useRouter()
  const [coordinates, setCoordinates] = useState({})
  const defaultCoordinates = {
    lat: 37.275476,
    lng: -104.658508,
  }
  const [medias, setMedias] = useState({})
  const [isLoading, setIsLoading] = useState(true)
  const [selectedMedia, setSelectedMedia] = useState([])
  const [requesting, setRequesting] = useState(false)
  const isMd = useMedia('(min-width: 768px)')

  const getPositionFromBrowser = () => {
    if (!navigator.geolocation) return

    const error = () => {
      setCoordinates(defaultCoordinates)
    }

    const showPosition = (position) => {
      setCoordinates({
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      })
    }

    navigator.geolocation.getCurrentPosition(showPosition, error)
  }

  useEffect(() => {
    if (!open) return

    if (Object.keys(user.data).length > 0 && user.data.preciseLocation) {
      setCoordinates({
        lat: user.data.preciseLocation.coordinates[1],
        lng: user.data.preciseLocation.coordinates[0],
      })
    } else {
      getPositionFromBrowser()
    }
  }, [open])

  const fetchSearch = async () => {
    setIsLoading(true)

    try {
      const response = await api.post('/media/closest', {
        ...coordinates,
        miles: 999999,
        query: searchTerm,
      })

      setMedias(response.data)
      setSelectedMedia(Object.keys(response.data).map(key => key))
    } catch (error) {
      console.log(error)
    }

    setIsLoading(false)
  }

  useEffect(() => {
    if (!Object.keys(coordinates).length) return

    fetchSearch()
  }, [coordinates])

  const onSendRequest = async () => {
    if (Object.keys(user.data).length === 0) {
      history.push('/signin')
      handleClose()
      return
    }

    if (!selectedMedia.length) {
      toast.dismiss()
      toast.error(t('Select a media'))
      return
    }

    setRequesting(true)

    try {
      await api.post('/trade/multiple', {
        medias: selectedMedia,
      })

      toast.dismiss()
      toast.success(t('Successful request!'))
    } catch (error) {
      toast.dismiss()

      if (error.response.data.errors) {
        error.response.data.errors.map(item => toast.error(item))
      } else {
        toast.error(
          error.response && error.response.data.message
            ? error.response.data.message
            : t('An error occurred. Try again later.'),
        )
      }
    }

    setRequesting(false)
  }

  const changeSelectedMedia = (key, status) => {
    if (status) {
      setSelectedMedia((prev) => {
        const newSelectedMedia = JSON.parse(JSON.stringify(prev))
        newSelectedMedia[newSelectedMedia.length] = key
        return newSelectedMedia
      })
    } else {
      setSelectedMedia(prev => prev.filter(value => value !== key))
    }
  }

  function formattedText() {
    if (selectedMedia.length === Object.keys(medias).length) {
      return t('Send Request for All')
    }
    return `${t('Send Request to')} ${selectedMedia.length} ${t('of')} ${
      Object.keys(medias).length
    }`
  }

  return (
    <Modal open={open} handleClose={handleClose}>
      <ModalContent>
        <Title>{t('Increase my Chances')}</Title>

        {isLoading && <Loader />}

        {!isLoading && !Object.keys(medias).length && (
          <div>{t('No media found.')}</div>
        )}

        {!isLoading && Object.keys(medias).length > 0 && (
          <>
            <Selected>
              {selectedMedia.length}
/
              {Object.keys(medias).length}
              {' '}
              {t('selected')}
            </Selected>
            <Table style={{ marginBottom: `${theme.spacing * 2}px` }}>
              <Thead isMd={isMd}>
                <Tr>
                  <Th />
                  <Th>{t('User')}</Th>
                  <Th>{t('Media')}</Th>
                  <Th>{t('Platform')}</Th>
                  {defaultCoordinates.lat !== coordinates.lat
                    && defaultCoordinates.lng !== coordinates.lng && (
                      <Th>{t('Distance')}</Th>
                  )}
                  <Th>{t('Trades')}</Th>
                  <Th>{t('Added items')}</Th>
                  <Th>{t('Location')}</Th>
                </Tr>
              </Thead>

              <Tbody>
                {Object.keys(medias).map(key => (
                  <Tr key={key}>
                    <Td legend="Select" isMd={isMd}>
                      <SwipeCheckbox
                        callback={status => changeSelectedMedia(key, status)}
                        defaultIsChecked
                      />
                    </Td>
                    <Td legend="User" isMd={isMd}>
                      <GlobalUser>
                        <UserShowcase
                          data={{
                            avatar: medias[key].user.avatar,
                            rating: medias[key].user.rating,
                          }}
                          status="ghost"
                          style={{ marginRight: `${theme.spacing}px` }}
                        />
                        <strong>{medias[key].user.username}</strong>
                        {t('has')}
                      </GlobalUser>
                    </Td>
                    <Td legend="Media" isMd={isMd}>
                      <Media data={medias[key]} />
                    </Td>

                    <Td legend="Platform" isMd={isMd}>
                      <BrandBadge
                        platform={medias[key].platform}
                        size="small"
                      />
                    </Td>

                    {defaultCoordinates.lat !== coordinates.lat
                      && defaultCoordinates.lng !== coordinates.lng && (
                        <Td legend="Distance" isMd={isMd} align="center">
                          {medias[key].distance}
                        </Td>
                    )}

                    <Td legend="Trades" isMd={isMd}>
                      {medias[key].user.tradeCount}
                    </Td>

                    <Td legend="Added items" isMd={isMd}>
                      {medias[key].user.mediaCount}
                    </Td>

                    <Td legend="Location" isMd={isMd}>
                      {medias[key].cityLocation
                      && medias[key].cityLocation.formattedAddress
                        ? medias[key].cityLocation.formattedAddress
                        : '-'}
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>

            <GlobalButton>
              <Button
                variation="success"
                onClick={onSendRequest}
                isLoading={requesting}
                disabled={selectedMedia.length === 0}
              >
                {formattedText()}
              </Button>
            </GlobalButton>
          </>
        )}
      </ModalContent>
    </Modal>
  )
}

export default withTheme(ModalIncreaseMyChances)
