export const ALL_IMAGES = [
  require('../../../../assets/home_wallpapers/ALL/wallpaper-1.jpg'),
  require('../../../../assets/home_wallpapers/ALL/wallpaper-2.jpg'),
]

export const GAMES_IMAGES = [
  require('../../../../assets/home_wallpapers/GAME/wallpaper-2.jpg'),
  require('../../../../assets/home_wallpapers/GAME/wallpaper-1.jpg'),
]

export const MOVIES_IMAGES = [
  require('../../../../assets/home_wallpapers/MOVIE/wallpaper-1.jpg'),
  require('../../../../assets/home_wallpapers/MOVIE/wallpaper-2.jpg'),
]

export const BOOKS_IMAGES = [
  require('../../../../assets/home_wallpapers/BOOK/wallpaper-2.jpg'),
  require('../../../../assets/home_wallpapers/BOOK/wallpaper-1.jpg'),
]

export const MUSICS_IMAGES = [
  require('../../../../assets/home_wallpapers/MUSIC/wallpaper-1.jpg'),
  require('../../../../assets/home_wallpapers/MUSIC/wallpaper-2.jpg'),
]
