import React from 'react'
import { useTranslation } from 'react-i18next'
import { DefaultTemplateBackground } from '../../templates/DefaultTemplate'
import {
  DefaultPageBox,
  DefaultPageFadeBackground,
  DefaultPageNewBackground,
  DefaultPageTitle,
  DefaultPageNewBoxHeader,
  DefaultPageWrapper,
  DefaultPageBoxContent,
} from '../../templates/DefaultPage'
import { Title, PageSection } from './styles'

const UserAgreement = () => {
  const { t } = useTranslation()

  return (
    <DefaultTemplateBackground bottom>
      <DefaultPageNewBackground />
      <DefaultPageFadeBackground />

      <DefaultPageWrapper>
        <DefaultPageTitle>{t('User Agreement')}</DefaultPageTitle>

        <DefaultPageBox>
          <DefaultPageNewBoxHeader />

          <DefaultPageBoxContent>
            <PageSection>
              <p>
                {t(
                  'Please note adherence to this agreement is essential as violation of such may lead to suspension or a permanent ban from utilizing our services.',
                )}
              </p>
            </PageSection>
            <PageSection>
              <Title>{t('1.0 Transactions')}</Title>

              <p style={{ marginBottom: '10px' }}>
                {t(
                  '1.1 When you click on the notification of interest (NOI) button, you are indicating that you, as a user do understand that you are being bound to complete the transaction if the user you sent the NOI to reciprocates the action, meaning if they accept your NOI.',
                )}
              </p>

              <p style={{ marginBottom: '10px' }}>
                {t(
                  '1.2 If you accept an incoming NOI, that is if someone is interested in your item and you visit their profile and click or tap on the NOI button you must complete the transaction by providing payment for the transaction fee.',
                )}
              </p>

              <p style={{ marginBottom: '10px' }}>
                {t(
                  '1.3 You are responsible for shipping your item in the time-frame you committed to. After a completed transaction the website will ask you when you intend to ship the item and this information will be relayed to the other party in the transaction.',
                )}
              </p>

              <p>
                {t(
                  '1.4 You may cancel a transaction with a particular user if such user has not accepted your transaction request, if the user has accepted your transaction request section 1.1 of this agreement comes into effect.',
                )}
              </p>
            </PageSection>
            <PageSection>
              <Title>{t('2.0 Resolution')}</Title>

              <p style={{ marginBottom: '10px' }}>
                {t(
                  '2.1 First things first, we act as an intermediary between you and other users, we connect users from around the web who are searching for items they hope to find at lower prices on our website. You should know that we are not responsible for lost, damaged or stolen items, but we know these things can happen so we HIGHLY encourage you to please obtain postal insurance on your items, it is relatively inexpensive and should ensure you will not be out the money in the event of your item getting lost, damaged or stolen.',
                )}
              </p>

              <p style={{ marginBottom: '10px' }}>
                {t(
                  '2.2 It is our official position that you should, whenever possible, obtain shipping insurance through the United States Postal Service®. As of 1 January 2018, the price to insure an article of up to $50.00 in value is $1.65. visit their link for more information',
                )}
                {' '}
                <a
                  href="https://about.usps.com/postal-bulletin/2007/html/pb22218/kit1_011.html"
                  title="More information"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://about.usps.com/postal-bulletin/2007/html/pb22218/kit1_011.html
                </a>
              </p>

              <p style={{ marginBottom: '10px' }}>
                {t(
                  '2.3 In the event of a lost, damaged or stolen item, we reserve the right to refund or to not refund whomever we choose, but only for the transaction fee, a “refund” would not cover shipping or damaged or stolen items as stated in paragraph 2.1.',
                )}
              </p>

              <p style={{ marginBottom: '10px' }}>
                {t(
                  '2.4 In the event that we approve your refund and you are a pay as you go (PAG) customer, we would refund the transaction fee.',
                )}
              </p>

              <p style={{ marginBottom: '10px' }}>
                {t(
                  '2.5 In the event that we approve your refund and you are a monthly subscriber (MS) we may refund either half or the entirety of your current month’s subscription costs.',
                )}
              </p>

              <p>
                {t(
                  '2.6 Your refund claim must be submitted within 7 calendar days of the date of the transaction, we reserve the right to extend it past the 7 days if we choose to, but 7 calendar days is the cut-off time to file a claim.',
                )}
              </p>
            </PageSection>
            <PageSection>
              <Title>{t('3.0 Infringing Content')}</Title>

              <p style={{ marginBottom: '10px' }}>
                {t(
                  '3.1 You may only transact, exchange, sell, original items. An original item is one you purchased or was gifted to you and which was obtained from a legal retailer and you or someone you know has not illegally copied the item in violation of the copyright laws of the city or country in which you reside.',
                )}
              </p>

              <p style={{ marginBottom: '10px' }}>
                {t(
                  '3.2 You are responsible for obeying and adhering to all copyright and any applicable laws in your state or country.',
                )}
              </p>

              <p>
                {t(
                  '3.3 Don`t post content or interact with other members of the Community in a way that infringes the intellectual property or other proprietary rights of others. Only post your own content or content that you have permission to use.',
                )}
              </p>
            </PageSection>
            <PageSection>
              <Title>{t('4.0 Rating system')}</Title>

              <p style={{ marginBottom: '10px' }}>
                {t(
                  '4.1 In order to help maintain a community of trust-worthy individuals we highly encourage you to rate your completed transactions on a regular basis.',
                )}
              </p>

              <p style={{ marginBottom: '10px' }}>
                {t(
                  '4.2 You must ship the item within the time-frame you committed to either 1-3 days or 3-6 days, not doing so may affect your rating negatively.',
                )}
              </p>

              <p style={{ marginBottom: '10px' }}>
                {t(
                  '4.3 Comments left on the rating system will be limited to 15 words or less',
                )}
              </p>

              <p style={{ marginBottom: '10px' }}>
                {t(
                  '4.4 Comments will be respectful in nature and will refrain from personal attacks to gender, sexual orientation, political orientation and or ethnicity.',
                )}
              </p>

              <p style={{ marginBottom: '10px' }}>
                {t('4.5 Rating will be limited to')}
                <span
                  style={{
                    marginTop: '10px',
                    marginBottom: '10px',
                    marginLeft: '20px',
                    display: 'block',
                  }}
                >
                  {t(
                    '4.5.1 Item condition and description (did they send what they said they would)',
                  )}
                </span>
                <span
                  style={{
                    marginTop: '10px',
                    marginBottom: '10px',
                    marginLeft: '20px',
                    display: 'block',
                  }}
                >
                  {t(
                    '4.5.2 Was the user easy to deal with, easy to reach, kept their word.',
                  )}
                </span>
              </p>

              <p style={{ marginBottom: '10px' }}>
                {t(
                  '4.6 No personal identifying information (PII) will be posted in the rating comments',
                )}
              </p>

              <p>
                {t(
                  '4.7 Run-on words may not be used in the comments section for example: thisdoesnotcountasoneword',
                )}
              </p>
            </PageSection>
            <PageSection>
              <Title>{t('5.0 User names')}</Title>

              <p>
                {t(
                  '5.1 User names will be respectful and will not include derogatory terms actual or implied',
                )}
              </p>
            </PageSection>
          </DefaultPageBoxContent>
        </DefaultPageBox>
      </DefaultPageWrapper>
    </DefaultTemplateBackground>
  )
}

export default UserAgreement
