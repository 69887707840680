import styled from 'styled-components'
import React from 'react'

const MEDIA_BANNER_BORDER_ACTIVE = {
  ALL: '#106594',
  GAME: '#168fb0',
  MOVIE: '#E35F2C',
  MUSIC: '#8E0E19',
  BOOK: '#166C10',
}

const MEDIA_BANNER_INFOS = {
  ALL: {
    color: 'linear-gradient(#106594,#0B1F30)',
  },
  GAME: {
    color: 'linear-gradient(#168fb0, #0c5e8e)',
    image: require('../../../assets/games-mediabanner.jpg'),
  },
  MOVIE: {
    color: 'linear-gradient(#E35F2C, #AD3D16)',
    image: require('../../../assets/movies-mediabanner.jpg'),
  },
  MUSIC: {
    color: 'linear-gradient(#8E0E19, #4A030C)',
    image: require('../../../assets/musics-mediabanner.jpg'),
  },
  BOOK: {
    color: 'linear-gradient(#166C10, #104E0C)',
    image: require('../../../assets/books-mediabanner.jpg'),
  },
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`

export const MainSection = styled.section`
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100vh - 82px - 27px);

  & > div.top-section {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 12px;
    text-align: center;
  }

  & > div.footer-section {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`

export const MainText = styled.span`
  font-size: 28px;
  font-weight: 600;
  text-shadow: 0px 0px 10px #000000;
  margin-bottom: -10px;
`

export const SecondaryText = styled.span`
  font-size: 28px;
  text-shadow: 0px 0px 10px #000000;
  font-weight: 500;
  margin-bottom: 12px;
`

export const SearchContainer = styled.div`
  display: flex;
  background-color: #fff;
  width: 100%;
  min-height: 20px;
  max-width: 720px;
  margin: 0 12px;
  padding: 4px;
  border-radius: 50px;
  overflow: hidden;

  & > div.select {
    border-radius: 50px;
    cursor: pointer;
    background-image: ${props => MEDIA_BANNER_INFOS[props.activeFilter].color};
    select {
      background-color: transparent;
      color: #fff;
      margin: 4px 12px;
      font-size: 14px;
    }
  }

  & > input {
    flex: 1;
    padding: 0 12px;
  }

  & > div.active-filters {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100px;

    & > img {
      margin-right: 8px;
    }
  }
`

export const ResultsContainer = styled.div`
  position: relative;
  width: 100%;
  max-width: 720px;

  & > div.main-result {
    z-index: 2;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 18px;
    background-color: #0b111b;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;

    & > div {
      max-height: 300px;
      overflow: auto;

      & > div.results {
        & > div.result {
          display: flex;
          background-color: #0c1726;
          padding: 12px 16px;
          cursor: pointer;

          & > div.media-info {
            flex: 1;

            & > div.media-name {
              text-align: left;

              & > span {
                &:first-child {
                  font-size: 16px;
                  font-weight: 300;
                }
                &:last-child {
                  font-size: 10px;
                  color: #767c83;
                  margin-left: 8px;
                  font-weight: 300;
                }
              }
            }

            & > div.other-media-info {
              display: flex;
              align-items: center;

              & > div.avatar {
                width: 16px;
                height: 16px;
                border-radius: 50%;
                background-color: yellow;
                margin-right: 8px;
              }

              & > span {
                margin-right: 8px;
                font-weight: 100;
              }
            }
          }

          & > div.media-brand {
            display: flex;
            align-items: center;
          }

          &:hover {
            background-color: #032338;
          }
        }
      }
    }

    & > div.footer {
      padding: 12px 0;
      box-shadow: 0 -5px 5px -5px rgba(0, 0, 0, 0.8);
    }
  }
`

export const MediaImage = styled.div`
  margin-right: 12px;
  height: 48px;
  width: 48px;
  background-color: black;
  background-image: url(${props => props.image});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 4px;
`

export const ButtonToggle = styled.button.attrs({
  type: 'button',
})`
  background-image: ${props => MEDIA_BANNER_INFOS[props.activeFilter].color};
  border-radius: 50px;
  color: #fff;
  margin: 0 6px;
  padding: 6px 18px;
  cursor: pointer;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.4);
`

export const Links = React.memo(styled.div`
  display: flex;
  bottom: 12px;
  margin-bottom: 12px;

  & > a {
    text-decoration: none;
    padding: ${props => (props.isSm ? '0 12px' : '0 6px')};
    text-align: center;
    position: relative;
    font-size: ${props => (props.isSm ? '14px' : '12px')};

    &:hover {
      text-decoration: underline;
    }

    &:after {
      content: '';
      display: block;
      width: 1px;
      height: 80%;
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      background-color: #fff;
    }

    :last-child {
      :after {
        display: none;
      }
    }
  }
`)

export const MediaTypesBanner = React.memo(styled.div`
  position: relative;
  width: 100%;
  max-width: 920px;
  padding: 16px 8px;
  display: ${props => (props.isMd ? 'flex' : 'none')};
  justify-content: space-around;
  align-items: center;
  margin-bottom: 24px;

  & > div.spacer {
    position: absolute;
    height: 60%;
    border-radius: 20px;
    box-shadow: 0px 0px 25px 0px #000e1b;
    z-index: -1;
  }

  & > div.back-space {
    width: calc(100% - 96px);
    background: linear-gradient(#0c1726, #072941);
    top: 0;
    left: 48px;
  }

  & > div.front-space {
    width: 100%;
    bottom: 0;
    background: #072941;
  }

  & > div.wrapper {
    flex: 1;
    display: flex;
    justify-content: center;
    margin: 0 12px;
  }
`)

export const MediaBanner = React.memo(styled.div`
  flex: 1;
  height: 120px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  border-radius: 20px;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  border: ${props => (props.active ? `4px solid ${MEDIA_BANNER_BORDER_ACTIVE[props.type]}` : 0)};

  &:before {
    display: block;
    content: '';
    background-image: ${props => `url(${MEDIA_BANNER_INFOS[props.type].image})`};
    width: 100%;
    height: 100%;
    position: absolute;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    transform: ${props => (props.active ? 'scale(1.1)' : 'scale(1)')};
    transition: transform 300ms;
  }

  &:hover,
  &:active {
    &:before {
      transform: scale(1.1);
    }
  }

  & > div.fade {
    height: 40px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(transparent, rgba(0, 0, 0, 0.4));
  }

  & > span {
    padding: 6px 32px 2px 32px;
    background: ${props => MEDIA_BANNER_INFOS[props.type].color};
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    overflow: hidden;
    z-index: 1;
  }
`)

export const ContentSection = styled.section`
  min-height: calc(100vh - 60px - 40px);
  max-width: 1280px;
  width: 100%;
  margin: 0 auto;
  border-radius: 16px;
  padding-bottom: 32px;
  margin-bottom: 32px;
`

export const MediaLine = styled.div`
  display: flex;
  flex-direction: column;
`

export const MediaTitle = styled.h1`
  position: relative;
  font-size: 20px;
  margin-left: 24px;

  &:before {
    content: '';
    position: absolute;
    border-top-right-radius: 45px;
    overflow: hidden;
    left: -12px;
    bottom: 7px;
    width: 4px;
    height: 18px;
    background-color: #1592d6;
  }
`

export const MediaRow = styled.div`
  display: flex;
  width: 100%;
  overflow: hidden;
`

export const MediaItem = styled.div`
  width: 152px;
  min-width: 152px;
  height: 180px;
  margin: 0 6px;
  background-color: rgba(200, 200, 200, 0.4);
`

export const SearchResultContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  padding: 0 20px;

  & > div.filter {
    width: 100%;
    margin: ${props => props.boxMargin}px;
    margin-top: 48px;
    margin-bottom: 24px;

    & > div.row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      & > div.filters {
        & > select {
          background-color: #072941;
          cursor: pointer;
          color: #fff;
          height: 40px;
          width: 100px;
          margin-right: 6px;
        }
      }

      & > div.actions {
        & > button {
          background-image: linear-gradient(#0c5b8d, #19befe);
          color: #fff;
          padding: 6px 12px;
          border-radius: 6px;
          cursor: pointer;
        }
      }
    }
  }
`

export const Title = styled.h1`
  position: relative;
  font-size: 20px;
  margin-left: 12px;
  margin-bottom: 28px;
  text-transform: uppercase;

  :before {
    content: '';
    position: absolute;
    left: -12px;
    top: 4px;
    width: 4px;
    height: 20px;
    border-top-right-radius: 4px;
    background-color: ${props => (props.color ? props.color : props.theme.colorPrimaryFeatured)};
    margin-right: ${props => props.theme.spacing}px;
  }
`

export const GlobalViewMode = styled.div`
  display: flex;
  /* justify-content: space-between; */
`
