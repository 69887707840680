import styled from 'styled-components'

export const GlobalSelect = styled.label`
  width: 100%;
  display: inline-block;
  margin-bottom: ${props => props.spacing}px;
`

export const StyledSelect = styled.select`
  background-color: ${props => props.theme.colorInput};
  color: ${props => props.theme.colorPlaceholder};
  border: none;
  width: 100%;
  height: 44px;
  border: ${props => (props.error.status ? '2px solid red' : '2px solid transparent')};
  box-sizing: border-box;

  &:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
  }

  &:focus {
    outline: none;
    border: 2px solid ${props => props.theme.colorPrimaryFeatured};
    background-color: #fff;
  }
`
