import React from 'react'
import { number, bool, func } from 'prop-types'
import { starOff, starBright } from '../../../assets'
import { Container, Star } from './styles'

const Rating = (props) => {
  const {
    rate, size, spacing, isClickable, setRate,
  } = props
  const stars = [1, 2, 3, 4, 5]

  const handleClick = (start) => {
    if (isClickable) {
      return setRate(start)
    }
  }

  return (
    <Container>
      {stars.map(star => (rate >= star ? (
        <Star
          isClickable={isClickable}
          key={star}
          size={size}
          spacing={spacing}
          src={starBright}
          alt="star bright"
          onClick={() => handleClick(star)}
        />
      ) : (
        <Star
          isClickable={isClickable}
          key={star}
          size={size}
          spacing={spacing}
          src={starOff}
          alt="star off"
          onClick={() => handleClick(star)}
        />
      )))}
    </Container>
  )
}

Rating.propTypes = {
  size: number,
  spacing: number,
  isClickable: bool,
  setRate: func,
}

Rating.defaultProps = {
  size: 12,
  spacing: 0,
  isClickable: false,
  setRate: undefined,
}

export default Rating
