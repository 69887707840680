import React from 'react'
import {
  string, func, bool, any, number,
} from 'prop-types'
import { LabelForm } from '../../ui'
import { StyledTextArea, GlobalTextArea, Legend } from './styles'

const TextArea = (props) => {
  const {
    value,
    label,
    id,
    type,
    cols,
    rows,
    onChange,
    pattern,
    readOnly,
    required,
    error,
    colorLabel,
    spacing,
    placeholder,
  } = props

  return (
    <GlobalTextArea htmlFor={id} spacing={spacing}>
      {label && <LabelForm color={colorLabel}>{label}</LabelForm>}
      <StyledTextArea
        type={type}
        id={id}
        cols={cols}
        rows={rows}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        pattern={pattern}
        readOnly={readOnly}
        required={required}
        error={error}
      />
      {error.status && <Legend>{error.message}</Legend>}
    </GlobalTextArea>
  )
}

TextArea.propTypes = {
  id: string.isRequired,
  label: string,
  placeholder: string,
  cols: string,
  rows: string,
  value: string,
  type: string,
  onChange: func,
  pattern: string,
  readOnly: bool,
  required: bool,
  error: any,
  spacing: number,
}

TextArea.defaultProps = {
  label: '',
  placeholder: '',
  value: '',
  type: 'text',
  onChange: null,
  pattern: null,
  readOnly: false,
  required: false,
  error: { status: false, message: '' },
  spacing: 0,
}

export default TextArea
