import styled, { keyframes } from 'styled-components'

const rotate = keyframes`
  100% { transform: rotate(360deg); }
`

const dash = keyframes`
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }

  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
`

export const SvgLoader = styled.svg`
  display: block;
  margin: ${props => (props.centralized ? '0 auto' : '0')};
  animation: ${rotate} 2s linear infinite;
  padding: ${props => props.width * 0.16}px;
  filter: drop-shadow(
    ${props => (props.withShadow ? '3px 3px 2px rgba(0, 0, 0, .7)' : '0')}
  );

  > circle {
    animation: ${dash} 1.5s ease-in-out infinite;
  }
`
