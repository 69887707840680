import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  height: 72px;
  max-height: 72px;
  padding: 12px;
  background: linear-gradient(180deg, #0ba0be 25.86%, #006e9d 100%);
  border-radius: 4px;
`

export const MediaImage = styled.div`
  width: 46px;
  height: 46px;
  border-radius: 4px;
  margin-right: 12px;
  background-image: url(${props => props.src});
  background-color: ${props => (props.src ? 'transparent' : 'rgba(0, 0, 0, 0.4)')};
  background-size: cover;
  background-position: center;
`

export const MediaName = styled.div`
  flex: 1;
  margin: auto;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const MediaPlatform = styled.div`
  display: flex;
  align-items: center;
  margin-left: 12px;
`

export const PlatformBadge = styled.div`
  width: 68px;
  height: 18px;
  border-radius: 12px;
  background-color: rgba(0, 0, 0, 0.4);
`

export const Actions = styled.div`
  position: absolute;
  bottom: 12px;
  right: 12px;
  display: flex;

  ${Container}:hover & {
  }
`

const Button = styled.button.attrs({ type: 'button' })`
  /* display: ${props => (props.disabled ? 'none' : '#flex')}; */
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: gray;
  padding: 0px 8px;
  margin: -6px 0;
  border-radius: 12px;
  color: #fff;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.6);
  cursor: pointer;

  &:hover {
    box-shadow: none;
  }
  &:disabled {
    opacity: 0.7;
    cursor: inherit;
    background-color: #333;
  }
`

export const Refuse = styled(Button)`
  background-color: #fb000c;
`
