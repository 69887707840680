import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { Spinner } from 'evergreen-ui'
import { useTranslation } from 'react-i18next'
import api from '../../../../services/api'
import {
  DefaultPageWrapper,
  DefaultPageBox,
  DefaultPageBoxContent,
  DefaultPageTitle,
  DefaultPageNewBackground,
  DefaultPageFadeBackground,
} from '../../../templates/DefaultPage'
import { DefaultTemplateBackground } from '../../../templates/DefaultTemplate'
import {
  Button,
  NoMedia,
  Grid,
  MediaCard,
  ViewMode,
  MediasFilter,
} from '../../../commons'
import { useStore } from '../../../../store'
import { useRouter } from '../../../../hooks'
import { BoxHeader } from './styles'

const MyLibrary = () => {
  const { t } = useTranslation()
  const { user } = useStore()
  const { history } = useRouter()
  const [items, setItems] = useState({ all: [], toShow: [] })
  const [isFetchingItems, setIsFetchingItems] = useState(false)
  const [deletedMediaId, setDeletedMediaId] = useState('')
  const cardGrid = {
    less: {
      xs: 12,
      sm: 6,
      md: 4,
      lg: 3,
    },
    more: {
      xs: 6,
      sm: 3,
      md: 2,
      lg: 2,
    },
  }
  const [viewMode, setViewMode] = useState('more')
  const [mediaTypesFromLibrary, setMediaTypesFromLibrary] = useState({})
  const [filters, setFilters] = useState({})

  const fetchLibrary = async (userId) => {
    try {
      setIsFetchingItems(true)
      const response = await api.post(`/media/user/${userId}`, { limit: '*' })

      setItems({ all: response.data.docs, toShow: response.data.docs })
      setMediaTypesFromLibrary(response.data.mediaTypes)
    } catch (error) {
      toast.dismiss()
      toast.error(
        error.response
          ? error.response.data.error
          : t('An error occurred. Try again later.'),
      )
    } finally {
      setIsFetchingItems(false)
    }
  }

  useEffect(() => {
    if (!user.loaded) return

    fetchLibrary(user.data.id)
  }, [user.loaded])

  useEffect(() => {
    if (!deletedMediaId) return

    const allItems = items.all.filter(item => item.id !== deletedMediaId)
    const allItemsToShow = items.toShow.filter(
      item => item.id !== deletedMediaId,
    )
    setItems({ all: allItems, toShow: allItemsToShow })
  }, [deletedMediaId])

  const LibrarySection = () => {
    if (isFetchingItems) {
      return <Spinner marginX="auto" marginY="auto" size={58} />
    }

    if (!items.toShow.length) {
      return <NoMedia />
    }

    return (
      <Grid container>
        {items.toShow.length > 0
          && items.toShow.map(item => (
            <Grid
              key={item.id}
              item
              xs={cardGrid[viewMode].xs}
              sm={cardGrid[viewMode].sm}
              md={cardGrid[viewMode].md}
              lg={cardGrid[viewMode].lg}
            >
              <MediaCard
                data={item}
                showUser
                actions="editor"
                callbackDelete={mediaId => setDeletedMediaId(mediaId)}
              />
            </Grid>
          ))}
      </Grid>
    )
  }

  useEffect(() => {
    if (!Object.keys(filters).length && !items.all.length) return

    setItems((prev) => {
      let newItemsToShow = []

      if (Object.keys(filters).length > 0) {
        const mediaTypes = Object.keys(filters)
        const platforms = Object.keys(filters).map(key => filters[key])
        newItemsToShow = prev.all.filter(
          item => mediaTypes.includes(item.mediaType)
            && platforms.includes(item.platform.id),
        )
      } else {
        newItemsToShow = JSON.parse(JSON.stringify(prev.all))
      }

      return {
        all: prev.all,
        toShow: newItemsToShow,
      }
    })
  }, [filters])

  const changeMediaFilter = (mediaType, platform) => {
    setFilters((prev) => {
      let newFilters = JSON.parse(JSON.stringify(prev))

      if (!mediaType) {
        newFilters = {}
      } else if (!platform) {
        delete newFilters[mediaType]
      } else {
        newFilters[mediaType] = platform
      }

      return newFilters
    })
  }

  return (
    <DefaultTemplateBackground bottom>
      <DefaultPageNewBackground src={user.data.avatar} withBlur />
      <DefaultPageFadeBackground />

      <DefaultPageWrapper topXs="50px" topMd="100px">
        <DefaultPageTitle>{t('My media')}</DefaultPageTitle>

        <DefaultPageBox>
          <BoxHeader>
            <Button
              variation="success"
              onClick={() => history.push('/add-media')}
            >
              {t('Add media')}
            </Button>
          </BoxHeader>

          <DefaultPageBoxContent>
            {items.all && items.all.length > 0 && (
              <>
                <MediasFilter
                  mediaTypesFromLibrary={mediaTypesFromLibrary}
                  callbackChangeFilter={changeMediaFilter}
                  active={filters}
                />

                <ViewMode
                  style={{ marginBottom: '16px' }}
                  onChange={mode => setViewMode(mode)}
                  active={viewMode}
                />
              </>
            )}

            <LibrarySection />
          </DefaultPageBoxContent>
        </DefaultPageBox>
      </DefaultPageWrapper>
    </DefaultTemplateBackground>
  )
}

export default MyLibrary
