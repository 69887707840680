import styled from 'styled-components'

export const Container = styled.div`
  & > div:first-child {
    padding: 12px;
    border-radius: 6px;
    background-color: ${props => (props.accepted ? '#1C6E28' : '#0b111b')};
    margin-bottom: 24px;
  }
  & > div:last-child {
    padding: 12px;
    background-color: #0b111b;

    > div:last-child {
      min-height: 400px;
      max-height: 400px;
      overflow: auto;
      border-radius: 6px;
    }
  }
`
