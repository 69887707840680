import React from 'react'

import TradeHeading from './TradeHeading'
import TradeSection from './TradeSection'
import { Container } from './styles'

export default function TradeComponent({
  id,
  user,
  tradeId,
  vip,
  readyToTrade,
  setMediaCount,
  fromUser,
}) {
  return (
    <Container id={id}>
      <TradeHeading
        name={user.user.displayName}
        rating={user.user.rating}
        avatar={user.user.avatar}
        vip={vip}
      />
      <TradeSection
        tradeId={tradeId}
        userInfo={user.user}
        data={user}
        readyToTrade={readyToTrade}
        setMediaCount={setMediaCount}
        fromUser={fromUser}
      />
    </Container>
  )
}
