import styled from 'styled-components'
import { getZindex } from '../../../../helpers'

const getBg = (props) => {
  let bg = 'linear-gradient(#147E14,#0C5E0C)'

  if (props.condition === 'used') {
    bg = 'linear-gradient(#F9C040, #E69814)'
  } else if (props.condition === 'likeNew') {
    bg = 'linear-gradient(#00CBFF, #006E9D)'
  }

  return bg
}

export const FooterInfo = styled.div`
  height: 20px;
  position: absolute;
  padding: 0 8px;
  bottom: ${props => `${props.theme.spacing}px`};
  right: ${props => `${props.theme.spacing}px`};
  background-image: ${props => getBg(props)};
  text-transform: uppercase;
  font-size: ${props => props.theme.fontSizes.legend};
  border-radius: 10px;
  text-align: center;
  z-index: ${getZindex('default')};
`

export const GlobalMediaImage = styled.div`
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;
  background-image: linear-gradient(180deg, #0e1c2e 0%, #070c14 100%);
  ${props => !props.src
    && `
    background-color: ${props.theme.colorPrimaryDark5};
  `}
  transition: background-color 200ms;
  box-shadow: 0px 0px 25px 0px #000e1b;
`

export const GlobalUpload = styled.div`
  text-align: center;
`

export const TextUpload = styled.div`
  color: ${props => props.theme.colorPrimaryComplementary};
  font-weight: bold;
  font-size: ${props => props.theme.fontSizes.heading2};
  line-height: 1.2;
  margin-top: -10px;
`

export const Image = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url(${props => props.src});
  background-size: cover;
  background-position: center;
  transition: transform 300ms;
`

export const GhostImage = styled.img`
  width: 100%;
  height: auto;
`

export const MediaImageStyle = {
  position: 'absolute',
  width: '100%',
  height: '100%',
  backgroundImage: `url(${props => props.src})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  transition: 'transform 300ms',
}

export const LdsCircle = styled.div`
    display: inline-block;
    transform: translateZ(1px);
    div {
    display: inline-block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    background: #fff;
    animation: LdsCircle 2.4s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  }
  @keyframes LdsCircle {
    0%, 100% {
      animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5);
    }
    0% {
      transform: rotateY(0deg);
    }
    50% {
      transform: rotateY(1800deg);
      animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1);
    }
    100% {
      transform: rotateY(3600deg);
    }
  }

`
