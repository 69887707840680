import React from 'react'
import { useTranslation } from 'react-i18next'
import { Meta } from '../../commons'
import { DefaultTemplateBackground } from '../../templates/DefaultTemplate'
import {
  PageBoxContent,
  ContentWrapper,
  PageBackground,
  Fade,
  PageSection,
  PageTitleRectangle,
  PageBox,
  PageTitle,
  Title,
} from './styles'

const UserAgreement = () => {
  const { t } = useTranslation()
  return (
    <DefaultTemplateBackground bottom>
      <Meta />
      <PageBackground />
      <Fade />

      <ContentWrapper
        topXs="10px"
        topSm="50px"
        topLg="100px"
        topXlg="160px"
        botXs="10px"
        botSm="30px"
        botLg="70x"
        botXlg="90px"
      >
        <PageTitle>{t('New Used Media Corp. Privacy Policy')}</PageTitle>
        <PageTitleRectangle />
        <PageBox>
          <PageBoxContent>
            <PageSection>
              <p style={{ marginBottom: '10px' }}>
                {t('Know that your privacy is very important to us and that we abide by the following agreement concerning your privacy. In order to provide the services we do at New Used Media Corp., we must process information about you. The types of information collected depends on how you use our services.',)}
              </p>

              <p>
                {t('We collect certain information through our website, located at https://www.newusedmedia.com/ (our “Website”). This page (this “Privacy Policy”) lays out our policies and procedures surrounding the collection and handling of any such information that identifies an individual user or that could be used to contact or locate him or her (“Personally Identifiable Information” or “PII”).',)}
              </p>
              <p>
                {t('This Privacy Policy applies only to our Website (which includes our App). It does not apply to any third-party site or service linked to our Website or recommended or referred by our Website or by our staff. Furthermore, it does not apply to any other website or online service operated by our company, or to any of our offline activities.',)}
              </p>
            </PageSection>

            <PageSection>
              <Title>{t('I. PII We Collect')}</Title>

              <p style={{ marginBottom: '10px' }}>
                {t(
                  'Some products containing sexual content and some sex and sensuality products are',
                )}
                {' '}
                {t('We collect the following Personally Identifiable Information from users who set up a user profile and participate in our services: name, email address, birthdate, shipping address, billing address, credit card information, a profile picture (if you choose to post one), any PII you choose to post in a profile description, and any PII posted in the chat boxes for completing transactions (solely to monitor compliance with the User Agreement. It should be noted that the chat boxes for completing transactions are meant only for communication directly related to completing the relevant transaction as described in our User Agreement and that it is a violation of the User Agreement to post any other type of information or communication in the chat boxes.',)}
              </p>

              <p>
                {t('We also use “cookies” to collect certain information from all users, including website visitors who don’t buy anything through our Website. A cookie is a string of data our system sends to your computer and then uses to identify your computer when you return to our Website. Cookies give us usage data, like how often you visit, where you go at the site, and what you do.',)}
              </p>
            </PageSection>

            <PageSection>
              <Title>{t('II. Our Use of PII')}</Title>

              <p>
                {t('We use your Personally Identifiable Information to create your account, to communicate with you about any products and/or services you’ve purchased, to offer you additional products and/or services, and to bill you. We also use that information to the extent necessary to enforce our Website User Agreement and to prevent imminent harm to persons or property.',)}
              </p>
               <p>
                {t('The following Personally Identifiable Information will be visible to other users of our services: your profile picture (if you have one posted) and the city and state in which you live. Because of the nature of our services, this information is necessary to facilitate efficient trades and or purchases from and between our users.',)}
              </p>
               <p>
                {t('We use cookies so that our Website can remember you and provide you with the information you’re most likely to need. For instance, when you return to our Website, cookies identify you and prompt the site to provide your user name (not your password), so you can sign in more quickly. Cookies also allow our Website to remind you of your past purchases and to suggest similar products and services. Finally, we use information gained through cookies to compile statistical information about use of our Website, such as the time users spend at the site and the pages they visit most often. Those statistics do not include PII.',)}
              </p>
               <p>
                {t('DNT (Do Not Track) is a concept created to allow Internet users to limit or control tracking of their online activities across the Internet. Generally, DNT can be set up and changed by using browser settings. DNT has been promoted by various regulatory agencies such as the U.S. Federal Trade Commission (FTC). Our Website does not generally respond to “do not track” signals.',)}
              </p>
            </PageSection>

            <PageSection>
              <Title>{t('III. Protection of PII')}</Title>

                <p>
                {t('We employ the following data security measures to protect Personally Identifiable Information: our databases storing PII are subject to a high-level encryption system such that your PII is very secure. Furthermore, any payment PII such as credit cards are not stored by us but by a third- party payment processing company (Stripe – more detail below), which has their own high levels of security. Unfortunately, even with these extensive measures, we cannot guarantee the security of PII. By using our Website, you acknowledge and agree that we make no such guarantee, and that you use our Website at your own risk.',)}
              </p>


            </PageSection>

            <PageSection>
              <Title>{t('IV. Contractor and Other Third-Party Access to PII')}</Title>

                 <p>
                {t('We give certain independent contractors access to Personally Identifiable Information. Those contractors assist us with payment processing and administrative accounts. Those contractors are required to have high levels of security and encryption to reasonably protect your PII. (Users are not third-party beneficiaries of our contracts with those independent contractors) We may also disclose PII to attorneys, collection agencies, or law enforcement authorities to address potential User Agreement violations, other contract violations, or illegal behavior. Furthermore, we disclose any information demanded in a court order or otherwise required by law or to prevent imminent harm to persons or property.',)}
              </p>
              <p>
                {t('As noted above, we compile Website usage statistics from data collected through cookies. We may publish those statistics or share them with third parties, but they don’t include PII.',)}
              </p>

            </PageSection>
            <PageSection>
              <Title>{t('V. Accessing and Correcting Your PII')}</Title>

              <p>
                {t('You can access and change or delete any Personally Identifiable Information we store through your “Account” page at any time. Once you have deleted PII, we do not continue to store that PII. It should be noted that if certain PII is deleted and not replaced with new PII, then our services may not be able to work as intended with your account.',)}
              </p>
            </PageSection>

             <PageSection>
              <Title>{t('VI. Contacting Us with Questions')}</Title>
               <p>
                {t('As stated above, your privacy is very important to us. Thus, if you have any questions or concerns about our Privacy Policy, please contact us online through the following email: admin@newusedmedia.com. Please place the words “Privacy Policy” in the subject line of your email.',)}
              </p>
            </PageSection>

                        <PageSection>
              <Title>{t('VII. Amendment of This Privacy Policy')}</Title>
               <p>
                {t('We may change this Privacy Policy at any time by posting a new version on this page or on a successor page. We will notify you before we update or amend this Privacy Policy. If you do not agree with our updates or amendments, you will be able to close your account with us beforehand. The new version will become effective on the date it is posted, which will be listed at the bottom of the page as the Date of Last Revision.',)}
              </p>
            </PageSection>

            <PageSection>
              <Title>{t('VIII. California Residents and the CCPA')}</Title>
               <p>
                {t('If you are a California resident, please see our Privacy Notice for California Residents for further information about your privacy and rights under the CCPA.',)}
              </p>
               <p>
                {t('Date of Last Revision: January 24, 2020.',)}
              </p>
            </PageSection>


            <PageSection>
              <Title>{t('Privacy Notice for California Consumers Under the California Consumer Privacy Act')}</Title>
               <p>
                {t('This Privacy Notice (the “Notice”) explains how New Used Media Corp. collects, uses, and shares any such information that identifies an individual user or that could be used to contact or locate him or her (“Personally Identifiable Information” or “PII”). This Notice is for Californians and explains their rights under the California Consumer Privacy Act (the “CCPA”). Please note that we fully describe how we collect and handle PII in our Privacy Policy and that this Notice acts as a supplement thereof.',)}
              </p>
            </PageSection>

             <PageSection>
              <Title>{t('I. How We Collect, Use, and Share PII')}</Title>
               <p>
                {t('As noted above, our Privacy Policy fully describes how we collect, use and maintain Personally Identifiable Information. This Notice organizes that information, in the table below, according to the categories given in the CCPA.',)}
              </p>
                  <p>
                {t('a. PII Categories We May Collect',)}
              </p>
              <p>
                {t('The following is a list of categories and examples of Personally Identifiable Information we collect: 1. Identifiers and Personal Information: your name, your email address, your IP address, your postal address, your billing address, credit card information, or other similar identifiers. 2. Data with Special Protections: your birthdate or your age. 3. Commercial Information: your billing address, your credit card information, your library of products, trades or purchases you have made from other users. 4. Internet or Other Electronic Activity Information: your browsing history on our Website, your search history on our Website, and other information regarding your interactions with our services. 5. Location-Related Services: the geolocation associated with your IP address. 6. Other (If you Choose to Provide it): visual information, such as a profile picture; educational information, employment information, or any other information you choose to post in your profile description box.',)}
              </p>
                <p>
                {t('b. Source of the PII We May Collect',)}
              </p>
              <p>
                {t('All Personally Identifiable Information we collect is given directly by you. You have access to edit or change the information you provide as described in our Privacy Policy. Our services may not work properly if certain information is not given, e.g., payment information.',)}
              </p>
              <p>
                {t('c. Examples of How PII May be Used.',)}
              </p>
              <p>
                {t('Personally Identifiable Information is used as described in our Privacy Policy.',)}
              </p>
               <p>
                {t(
                  'd. PII We May Share for a Business Purpose',
                )}
              </p>
               <p>
                {t('We share PII as described in our Privacy Policy to further our business goals and ensure we are providing you the best service we can offer. The following is a specific list of how we may share PII: 1. Security Purposes: We may disclose PII to attorneys, collection agencies, or law enforcement authorities to address potential User Agreement violations, other contract violations, or illegal behavior. Furthermore, we disclose any information demanded in a court order or otherwise required by law or to prevent imminent harm to persons or property. 2. Service Providers: We give certain independent contractors access to Personally Identifiable Information. Those contractors assist us with payment processing and administrative accounts.',)}
              </p>

              <p>
                {t('II. We Do Not Sell Your PII',)}
              </p>

              <p>
                {t('The CCPA requires use to disclose to you whether or not we sell your Personally Identifiable Information. We do not at any time sell your PII.',)}
              </p>
                <p>
                {t('III. Your Rights Under the CCPA',)}
              </p>
                <p>
                {t('The CCPA gives residents of California the following rights: a. Your Right to Opt Out of Sales As stated above, we do not at any time sell any of your Personally Identifiable Information and thus we do not provide a section to opt out of sales of PII. b. Your Right to Notification You have a right to know when we update or change our Privacy Policy. Under our Privacy Policy, we will notify you before we update or amend our Privacy Policy. If you do not agree with our updates, you will be able to close your account with us beforehand. c. Your Right to Delete PII You may log into your account at any time and delete or modify any PII. Further, when you close your account, all PII will be deleted. d. Requests for Information You have the right to request a copy of your Personally Identifiable Information. You also have the right to request disclosure of how we have collected, used, and shared your PII over the past 12 months, including the categories of PII we collected and why we did so. You may also request the categories of sources for that information, the categories of third parties with whom we shared it for a business purpose, and our purposes for doing so. If you have an account with us, you can make a request through the following email: admin@newusedmedia.com. Please put “Privacy Policy Request” in the subject line. e. Nondiscrimination for Exercising Your CCPA Rights You have a right not to be discriminated against for exercising any of your CCPA rights. Discrimination may include different price rates than others, different levels of quality for services, denying services, etc. We at no time will discriminate against you for exercising your CCPA rights.',)}
              </p>
                <p>
                {t('Date of Last Revision: January 24, 2020.')}
              </p>

            </PageSection>



          </PageBoxContent>
        </PageBox>
      </ContentWrapper>
    </DefaultTemplateBackground>
  )
}

export default UserAgreement
