import React, { useState } from 'react'
import { toast } from 'react-toastify'
import { withTheme } from 'styled-components'
import { useTranslation } from 'react-i18next'
import api from '../../../../services/api'
import { Input, Button } from '../../../commons'
import { useRouter } from '../../../../hooks'
import { Title, GlobalAction } from './styles'

const Init = (props) => {
  const { theme } = props
  const { t } = useTranslation()
  const [email, setEmail] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const { history } = useRouter()

  const onSend = async (e) => {
    e.stopPropagation()
    setIsLoading(true)
    
    try {
      await api.post('/auth/password/forgot', {
        email,
      })

      history.push(`/recover-password/${email}`)
    } catch (error) {
      toast.dismiss()
      toast.error(
        error.response
          ? error.response.data.error
          : t('An error occurred. Try again later.'),
      )
    }

    setIsLoading(false)
  }

  return (
    <>
      <Title>{t('Forgot password')}</Title>

      <p style={{ marginBottom: `${theme.spacing * 2}px` }}>
        {t('Type your email to recover the password.')}
      </p>

      <Input
        label={t('Email')}
        id="email"
        type="email"
        value={email}
        onChange={e => setEmail(e.target.value)}
        onClear={() => setEmail('')}
        spacing={theme.spacing * 2}
        required
      />

      <GlobalAction>
        <Button variation="success" onClick={e => onSend(e)} isLoading={isLoading}>
          {t('Forgot password')}
        </Button>
      </GlobalAction>
    </>
  )
}

export default withTheme(Init)
