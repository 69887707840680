import React, { useState, useEffect } from 'react'
import axios from 'axios'
import {
  number, shape, string, bool,
} from 'prop-types'
import { compose, withProps } from 'recompose'
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from 'react-google-maps'

import { useTranslation } from 'react-i18next'
import Icon from '../Icon'
import ICONS from '../../ui/ICONS'
import { useStore } from '../../../store'
import { useDebounce } from '../../../hooks'
import { isEmpty } from '../../../helpers'
import { SearchContainer, SearchInput } from './styles'

const { REACT_APP_GOOGLE_MAP_API_KEY } = process.env

const MapWithSearch = compose(
  withProps({
    googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${REACT_APP_GOOGLE_MAP_API_KEY}`,
    loadingElement: <div style={{ height: '100%' }} />,
    containerElement: <div style={{ height: '100%' }} />,
    mapElement: <div style={{ height: '100%', borderRadius: 5 }} />,
  }),
  withScriptjs,
  withGoogleMap,
)(({ coordinates, defaultZoom }) => {
  const { user } = useStore()
  const [inputValue, setInputValue] = useState('')
  const [searchCoords, setSearchCoords] = useState({})
  const debouncedSearchTerm = useDebounce(inputValue, 400)
  const { t } = useTranslation()

  async function searchLocation(address) {
    const res = await axios.get(
      `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${REACT_APP_GOOGLE_MAP_API_KEY}`,
    )
    const {
      data: { results },
    } = res
    if (results.length) {
      setSearchCoords(results[0].geometry.location)
    }
  }

  const getPositionFromBrowser = () => {
    if (!navigator.geolocation) return

    navigator.geolocation.getCurrentPosition((position) => {
      setSearchCoords({
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      })
    })
  }

  useEffect(() => {
    if (debouncedSearchTerm) {
      searchLocation(debouncedSearchTerm)
    }
  }, [debouncedSearchTerm])

  useEffect(() => {
    if (!user.loaded) {
      return
    }
    if (Object.keys(user.data).length > 0 && user.data.preciseLocation) {
      setSearchCoords({
        lat: user.data.preciseLocation.coordinates[1],
        lng: user.data.preciseLocation.coordinates[0],
      })
    } else {
      getPositionFromBrowser()
    }
  }, [user.loaded])

  return (
    <GoogleMap
      defaultZoom={defaultZoom}
      defaultCenter={coordinates}
      center={!isEmpty(searchCoords) ? searchCoords : coordinates}
      options={{
        mapTypeControl: false,
        streetViewControl: false,
        zoomControl: false,
        fullscreenControl: false,
      }}
    >
      <SearchContainer>
        <SearchInput
          value={inputValue}
          onChange={e => setInputValue(e.target.value)}
          placeholder={t('Search on Google Maps')}
        />
        <Icon icon={ICONS.SEARCH} size={32} color="#ccc" />
      </SearchContainer>
      {!isEmpty(searchCoords) && <Marker position={searchCoords} />}
    </GoogleMap>
  )
})

MapWithSearch.propTypes = {
  coordinates: shape({
    lat: number,
    lng: number,
  }),
  colorCircle: string,
  defaultZoom: number,
  radius: number,
  hasMarker: bool,
  hasCircle: bool,
}

MapWithSearch.defaultProps = {
  coordinates: { lat: 45, lng: 45 },
  defaultZoom: 15,
  radius: 0,
  hasMarker: false,
  hasCircle: false,
}

export default MapWithSearch
