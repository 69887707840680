import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const Label = styled.div`
  width: 40%;
  position: absolute;
  bottom: 0;
  background-color: ${props => props.theme.colorTertiary};
  padding: 0.325em 0.75em;
  font-size: ${props => props.theme.fontSizes.default};
  color: white;
  border-radius: 0px 45px 0px 0px;
  transition: all 0.2s ease;
`

export const GlobalCategoryCard = styled(Link)`
  display: block;
  position: relative;
  width: 100%;
  height: 150px;
  background: url(${props => props.src});
  background-size: cover;
  background-position: center;
  border-radius: 0px 45px 0px 0px;

  &:hover > ${Label} {
    width: 55%;
    font-size: ${props => props.theme.fontSizes.heading2};
  }
`
