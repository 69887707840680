import styled from 'styled-components'

export const Title = styled.p`
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
    padding-top: 4px;
    color: white;
`

export const Content = styled.p`
  font-weight: 300;
  font-size: 18px;
  line-height: 28px;
  color: white;
`

export const DialogFooter = styled.div`
  background: #0B1625;
  width: 100%;
  height: 55px;
  padding: 14px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-radius: 0 0 5px 5px;
`
