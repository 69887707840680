import React from 'react'
import {
  number, shape, string, bool,
} from 'prop-types'
import { compose, withProps } from 'recompose'
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Circle,
  Marker,
} from 'react-google-maps'

import { convertMilesToMeters } from '../../../helpers'

const Map = compose(
  withProps({
    googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${
      process.env.REACT_APP_GOOGLE_MAP_API_KEY
    }`,
    loadingElement: <div style={{ height: '100%' }} />,
    containerElement: <div style={{ height: '400px' }} />,
    mapElement: <div style={{ height: '100%', borderRadius: 5 }} />,
  }),
  withScriptjs,
  withGoogleMap,
)(({
  coordinates, colorCircle, radius, defaultZoom, hasMarker, hasCircle,
}) => (
  <GoogleMap
    defaultZoom={defaultZoom}
    defaultCenter={coordinates}
    center={coordinates}
    options={{
      mapTypeControl: false,
      streetViewControl: false,
      zoomControl: false,
      fullscreenControl: false,
    }}
  >
    {hasCircle && (
      <Circle
        center={coordinates}
        radius={convertMilesToMeters(radius)}
        label="blabla"
        options={{
          fillColor: colorCircle,
          strokeColor: colorCircle,
        }}
      />
    )}
    {hasMarker && <Marker position={coordinates} />}
  </GoogleMap>
))

Map.propTypes = {
  coordinates: shape({}).isRequired,
  colorCircle: string,
  defaultZoom: number,
  radius: number,
  hasMarker: bool,
  hasCircle: bool,
}

Map.defaultProps = {
  defaultZoom: 8,
  radius: 0,
  hasMarker: false,
  hasCircle: false,
}

export default Map
