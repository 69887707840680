import React from 'react'
import { oneOf } from 'prop-types'
import { Rating, Avatar } from '..'
import { GlobalUserShowcase, GlobalAvatar } from './styles'

const UserShowcase = (props) => {
  const { data, status } = props

  return (
    <GlobalUserShowcase status={status} {...props}>
      <GlobalAvatar>
        <Avatar src={data.avatar} size="medium" />
      </GlobalAvatar>

      {data.rating >= 0 && <Rating rate={data.rating} size={8} />}
    </GlobalUserShowcase>
  )
}

UserShowcase.propTypes = {
  status: oneOf(['success', 'danger', 'selected', 'default', 'ghost']),
}

UserShowcase.defaultProps = {
  status: 'default',
}

export default UserShowcase
