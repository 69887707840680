import styled from 'styled-components'

const Background = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  background: url(${props => props.src});
  background-repeat: no-repeat;
  background-position: ${props => props.backgroundPosition};
  background-size: ${props => props.backgroundSize};
  filter: blur(${props => props.blur}px);
  opacity: ${props => props.opacity};

  ${props => props.width
    && `
    max-width: ${props.width};
  `}

  ${props => props.height
    && `
    height: ${props.height};
  `}

  /* width: 100%;
  opacity: 0.5; */
  /* height: 300px; */

  /* @media (min-width: ${props => props.theme.breakpoints.lg}px) {
    height: 500px;
  } */

  /* img {
    width: 100%;
  } */

  /* :after {
    display: block;
    content: '';
    width: 100%;
    height: 100%;
    background-image: linear-gradient(
      to bottom,
      rgba(13, 32, 50, 0),
      rgba(13, 32, 50, 1)
    );
  } */
`

export default Background
