import React from 'react'
import { oneOf, string, bool } from 'prop-types'
import { Loader, Icon } from '..'
import ICONS from '../../ui/ICONS'
import { GlobalAvatar, StyledAvatar, GlobalLoader } from './styles'

export const Avatar = (props) => {
  const {
    error, isLoadingThumb, size, src,
  } = props

  return (
    <GlobalAvatar error={error} size={size} {...props}>
      {!error && <StyledAvatar isLoadingThumb={isLoadingThumb} src={src} title="image user" />}
      {isLoadingThumb && (
        <GlobalLoader>
          <Loader size={40} withShadow />
        </GlobalLoader>
      )}
      {error && !isLoadingThumb && <Icon icon={ICONS.NO} size={40} />}
    </GlobalAvatar>
  )
}

Avatar.propTypes = {
  size: oneOf([
    'small',
    'extrasmall',
    'notSoSmall',
    'medium',
    'notSolarge',
    'large',
    'largest',
  ]),
  src: string,
  isAvatar: bool,
  error: bool,
}

Avatar.defaultProps = {
  size: 'medium',
  src: '',
  isAvatar: true,
  error: false,
}

export default Avatar
