import { createGlobalStyle } from 'styled-components'

export const GlobalStyleTour = createGlobalStyle`
  .itemReactTour {
    /* Steps */
    > span {
      font-family: inherit;
    }

    /* Close button */
    > button {
      color: #8e949a !important;

      :hover {
        color: #55595e !important;
      }
    }

    /* Next/prev */
    > div > button {
      color: #003049;

      > span {
        font-size: 16px;
        font-weight: bold;
      }

      &:disabled {
        color: #BBBCBB;
      }
    }
  }

  .maskReactTour {
    color: #FFFFFF !important;
  }
`

export const styleSteps = {
  background:
    'radial-gradient(516.00px at 50.09% -20.56%, #E2E2E2 0%, #FFFFFF 100%)',
  borderRadius: '16px',
  color: '#003049',
  boxShadow: '0 0.5em 3em rgba(0,0,0,0.5)',
}
