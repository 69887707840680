import { createReducer, createAction } from 'redux-starter-kit'

export const INITIAL_STATE = {
  address: undefined,
  secondaryAddress: undefined,
  billingAddress: undefined,
  billingSameAsShipping: undefined,
  cardToken: undefined,
  subscriptionType: undefined,
}

export const setAddress = createAction('CHANGE_ADDRESS')
export const setBillingAddress = createAction('CHANGE_BILLING_ADDRESS')
export const setSecondaryAddress = createAction('CHANGE_SECONDARY_ADDRESS')
export const setBillingAddressSameAsShipping = createAction('SET_BILLING_SAME')
export const setCardToken = createAction('SET_CARD_TOKEN')
export const setSubscriptionType = createAction('SET_SUBSCRIPTION_TYPE')

const handleSetAddress = (state, { payload }) => ({
  ...state,
  address: {
    streetAddress1: payload.streetAddress1,
    streetAddress2: payload.streetAddress2,
    zipcode: payload.zipcode,
    state: payload.state,
    city: payload.city,
  }
})

const handleSetBillingAddress = (state, { payload }) => ({
  ...state,
  billingAddress: {
    streetAddress1: payload.streetAddress1,
    streetAddress2: payload.streetAddress2,
    zipcode: payload.zipcode,
    state: payload.state,
    city: payload.city,
  }
})

const handleSetSecondaryAddress = (state, { payload }) => ({
  ...state,
  secondaryAddress: {
    streetAddress1: payload.streetAddress1,
    streetAddress2: payload.streetAddress2,
    zipcode: payload.zipcode,
    state: payload.state,
    city: payload.city,
  }
})

const handleSetBillingAddressSameAsShipping = state => ({
  ...state,
  billingSameAsShipping: true,
  billingAddress: undefined,
})

const handleSetCardToken = (state, { payload }) => ({
  ...state,
  cardToken: payload,
})

const handleSubscriptionType = (state, { payload }) => ({
  ...state,
  subscriptionType: payload,
})

export const wizardsReducer = createReducer(INITIAL_STATE,{
  [setAddress]: handleSetAddress,
  [setBillingAddress]: handleSetBillingAddress,
  [setSecondaryAddress]: handleSetSecondaryAddress,
  [setBillingAddressSameAsShipping]: handleSetBillingAddressSameAsShipping,
  [setCardToken]: handleSetCardToken,
  [setSubscriptionType]: handleSubscriptionType,
})
