import React, { useEffect, useState } from 'react'
import { Checkbox } from 'evergreen-ui'
import { toast } from 'react-toastify'
import queryString from 'query-string'
import { useTranslation } from 'react-i18next'
import { signUp } from '../../../services/auth'
import { DefaultPageWrapper } from '../../templates/DefaultPage'
import { DefaultTemplateBackground } from '../../templates/DefaultTemplate'
import { useRouter } from '../../../hooks'
import { Input, Grid, Meta } from '../../commons'
import {
  GoogleButton,
  TwitchButton,
  FacebookButton,
} from '../../commons/SocialButtons'
import {
  Container,
  Content,
  Header,
  Title,
  Subtitle,
  SocialSignUpSection,
  SocialSignUp,
  Form,
  SignUpButton,
  ButtonContainer,
  Label,
  ValidationCheckBox,
} from './styles'

const SignUp = () => {
  const { t } = useTranslation()
  const [isConditionsRead, setIsConditionRead] = useState(false)
  const [submittingForm, setSubmittingForm] = useState(false)
  const [name, handleChangeName] = useState('')
  const [email, handleChangeEmail] = useState('')
  const [username, handleChangeUsername] = useState('')
  const [password, handleChangePassword] = useState('')
  const [confirmPassword, handleChangeConfirmPassword] = useState('')
  const [birthdate, handleChangeBirthdate] = useState('')
  const [social, handleChangeSocial] = useState('')
  const [socialId, handleChangeSocialId] = useState('')
  const [showValidationCheckBox, setShowValidationCheckBox] = useState(false)
  const { history, location } = useRouter()
  const initialStateErrors = {
    name: {
      status: false,
      message: '',
    },
    username: {
      status: false,
      message: '',
    },
    email: {
      status: false,
      message: '',
    },
    password: {
      status: false,
      message: '',
    },
    birthdate: {
      status: false,
      message: '',
    },
  }
  const [errors, setErrors] = useState(initialStateErrors)
  useEffect(() => {
    const {
      email, name, social, socialId, username,
    } = queryString.parse(
      location.search,
    )
    handleChangeEmail(email)
    handleChangeName(name)
    handleChangeUsername(username)
    handleChangeSocial(social)
    handleChangeSocialId(socialId)
  }, [location])

  const submitForm = async (e) => {
    e.stopPropagation()
    e.preventDefault()
    console.log("form submit")

    if (!isConditionsRead) {
      setShowValidationCheckBox(true)
      return
    }

    if (confirmPassword !== password) {
      const newErrors = JSON.parse(JSON.stringify(initialStateErrors))
      newErrors.password = {
        status: true,
        message: t('Password does not match'),
      }
      return setErrors(newErrors)
    }

    try {
      setSubmittingForm(true)
      await signUp({
        email,
        name,
        username,
        birthdate,
        password,
        socialId,
        social,
      })
      history.push(`/signup-confirmation/${email}`)
    } catch (err) {
      if (err.response && err.response.data.errors) {
        const newErrors = JSON.parse(JSON.stringify(initialStateErrors))
        err.response.data.errors.map(
          error => (newErrors[error.field] = {
            status: true,
            message: error.message,
          }),
        )
        setErrors(newErrors)
      } else {
        toast.error(t('An error occurred. Try again later.'))
      }
    } finally {
      setSubmittingForm(false)
    }
  }

  const onCheckTerms = (e) => {
    setIsConditionRead(e.target.checked)
    setShowValidationCheckBox(!e.target.checked)
  }

  const openLink = (e, link) => {
    e.preventDefault()
    e.stopPropagation()
    window.open(link)
  }

  const metaDescription = 'Sign in or sign up for New Used Media and start' +
    ' exchanging with users across the country'

  return (
    <DefaultTemplateBackground top bottom>
      <Meta metaDescription={metaDescription} />
      <DefaultPageWrapper
        topXs="10px"
        topSm="50px"
        topLg="100px"
        topXlg="160px"
        botXs="10px"
        botSm="50px"
        botLg="100px"
        botXlg="160px"
      >
        <Container>
          <Header />
          <Content>
            <Grid container justify="center">
              <Grid item md={6} lg={6}>
                <Title>Create Account</Title>
                <Form onSubmit={submitForm}>
                  <Input
                    label={t('Name')}
                    id="name"
                    value={name}
                    onChange={event => handleChangeName(event.target.value)}
                    error={errors.name}
                    required
                  />
                  <Input
                    label={t('Username')}
                    id="username"
                    value={username}
                    onChange={event => handleChangeUsername(event.target.value)}
                    error={errors.username}
                    required
                  />
                  <Input
                    label={t('Email')}
                    id="email"
                    type="email"
                    value={email}
                    onChange={event => handleChangeEmail(event.target.value)}
                    error={errors.email}
                    required
                  />
                  <Input
                    label={t('Birthday')}
                    id="birthdate"
                    type="date"
                    value={birthdate}
                    onChange={event => handleChangeBirthdate(event.target.value)
                    }
                    error={errors.birthdate}
                    required
                  />
                  <Input
                    label={t('Password')}
                    id="password"
                    type="password"
                    value={password}
                    onChange={event => handleChangePassword(event.target.value)}
                    required
                  />
                  <Input
                    label={t('Confirm Password')}
                    id="confirmPassword"
                    type="password"
                    value={confirmPassword}
                    onChange={event => handleChangeConfirmPassword(event.target.value)
                    }
                    error={errors.password}
                    required
                  />
                  <ButtonContainer>
                    <Checkbox
                      checked={isConditionsRead}
                      onChange={onCheckTerms}
                      label={(
                        <Label>
                          {t('I`ve read and accept the')}
                          {' '}
                          <button
                            type="button"
                            onClick={e => openLink(e, '/user-agreement')}
                          >
                            {t('User Agreement')}
                            {' '}
                          </button>
                          {' '}
                          {t('and')}
                          {' '}
                          <button
                            type="button"
                            onClick={e => openLink(e, '/privacy-policy')}
                          >
                            {t('Privacy policy')}
                            {' '}
                          </button>
                        </Label>
                      )}
                    />

                    {showValidationCheckBox && (
                      <ValidationCheckBox>
                        {t('You need to accept the')}
                        {' '}
                        <strong>{t('User Agreement')}</strong>
                        {' '}
                        {t('and')}
                        {' '}
                        <strong>{t('Privacy policy')}</strong>
                        {' '}
                        {t('above.')}
                      </ValidationCheckBox>
                    )}

                    <SignUpButton type="submit" disabled={submittingForm}>
                      {t('Sign Up')}
                    </SignUpButton>
                  </ButtonContainer>
                </Form>
              </Grid>
              <SocialSignUp item md={6} lg={6}>
                <SocialSignUpSection item md={6} lg={6}>
                  <Subtitle>{t('or Sign Up with')}</Subtitle>
                  <FacebookButton>{t('Sign Up with Facebook')}</FacebookButton>
                  <GoogleButton>{t('Sign Up with Google')}</GoogleButton>
                </SocialSignUpSection>
              </SocialSignUp>
            </Grid>
          </Content>
        </Container>
      </DefaultPageWrapper>
    </DefaultTemplateBackground>
  )
}

export default SignUp
