import styled from 'styled-components'

export const Title = styled.h2`
  font-size: ${props => props.theme.fontSizes.heading1};
  margin-bottom: ${props => props.theme.spacing * 2}px;
  text-align: center;
`

export const GlobalCropper = styled.div`
  position: relative;
  width: 100%;
  height: 400px;
  margin-bottom: ${props => `${props.theme.spacing * 2}px`};
`

export const GlobalAction = styled.div`
  display: flex;
  justify-content: flex-end;
`
