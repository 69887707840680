import React, { useState, Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { IconButton, Avatar } from '../../../../commons'
import ICONS from '../../../../ui/ICONS'
import { useRouter } from '../../../../../hooks'
import { useStore } from '../../../../../store'
import ChangeLanguage from '../../Header/ChangeLanguage'
import ChangeLanguageNavigation from '../../Header/HiddenContent/ChangeLanguageNavigation'
import { userServices } from '../../../../UserInfo'
import {
  GlobalNavigation,
  GlobalMenu,
  Menu,
  MenuColumn,
  MenuItem,
  MenuItemSignIn,
  Overlay,
  UserInfo,
  UserName,
  GlobalChangeLanguageNavigation,
} from './styles'

const NavigationMobile = () => {
  const { user, dispatch } = useStore()
  const [activeMenu, setActiveMenu] = useState(false)
  const [openChangeLanguage, setOpenChangeLanguage] = useState(false)
  const { history } = useRouter()
  const { t } = useTranslation()

  const goTo = (route) => {
    history.push(route)
    setActiveMenu(false)
  }

  return (
    <Fragment>
      <IconButton
        icon={activeMenu ? ICONS.CLOSE : ICONS.MENU}
        onClick={() => setActiveMenu(!activeMenu)}
        minClickable
      />

      {activeMenu && (
        <GlobalNavigation>
          <GlobalMenu>
            {Object.keys(user.data).length > 0 && (
              <Menu>
                <MenuColumn>
                  <UserInfo>
                    <Avatar src={user.data.avatar} size="notSoSmall" />

                    <UserName>{user.data.username}</UserName>
                  </UserInfo>

                  <MenuItem onClick={() => goTo('/settings')}>
                    {t('Account')}
                  </MenuItem>
                  <MenuItem onClick={() => goTo('/history')}>
                    {t('Transaction History')}
                  </MenuItem>
                  <MenuItem onClick={() => goTo(`/library/${user.data.id}`)}>
                    {t('My media')}
                  </MenuItem>
                  <MenuItem onClick={() => goTo('/create-notification-for-media')}>
                    {t('My wish list')}
                  </MenuItem>
                  <MenuItem
                    onClick={() => dispatch(userServices.logoutUser(dispatch))}
                  >
                    {t('Logout')}
                  </MenuItem>
                </MenuColumn>
              </Menu>
            )}

            <Menu>
              <MenuColumn>
                <MenuItem onClick={() => goTo('/how-it-works')}>
                  {t('How it works')}
                </MenuItem>
              </MenuColumn>

              {Object.keys(user.data).length === 0 && (
                <MenuColumn>
                  <MenuItemSignIn onClick={() => goTo('/signin')}>
                    Sign In
                  </MenuItemSignIn>
                </MenuColumn>
              )}
            </Menu>

            <ChangeLanguage
              onClick={() => setOpenChangeLanguage(!openChangeLanguage)}
              active={openChangeLanguage}
            />

            {openChangeLanguage && (
              <GlobalChangeLanguageNavigation>
                <ChangeLanguageNavigation onNavigationMobile />
              </GlobalChangeLanguageNavigation>
            )}
          </GlobalMenu>

          <Overlay onClick={() => setActiveMenu(false)} />
        </GlobalNavigation>
      )}
    </Fragment>
  )
}

export default NavigationMobile
