import React, { useEffect, useState } from 'react'
import Geocode from 'react-geocode'

import { Grid, Map } from '../../../commons'
import {
  MapContainer,
} from './styles'
import { StripeCard } from './StripeCard'
import { changeBillingAddressSameAsShipping } from '../store/formReducer'

export const BillingAddress = ({
  dispatch,
  form,
  state,
  changeForm,
  navigateToNext,
}) => {
  useEffect(() => {
    if(state.billingSameAsShipping){
      changeForm(changeBillingAddressSameAsShipping(state.billingSameAsShipping))
    }
  },[])
  const { billingSameAsShipping, address, billingAddress } = form
  const [latitude, handleChangeLatitude] = useState(0)
  const [longitude, handleChangeLongitude] = useState(0)
  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation
        .getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords
            handleChangeLatitude(latitude)
            handleChangeLongitude(longitude)
          },
        )
    }
  }, [])
  useEffect(() => {
    const getLongitudeAndLatitudeFromAddress = async () => {
      try {
        const { results } = await Geocode.fromAddress(address.streetAddress1)
        const { lat, lng } = results[0].geometry.location
        handleChangeLatitude(lat)
        handleChangeLongitude(lng)
      } catch (err) {
        console.log(err)
      }
    }
    getLongitudeAndLatitudeFromAddress()
  }, [address.streetAddress1])
  return (
    <Grid container justify="center">
      <Grid item md={6} lg={6}>
        <StripeCard
          isSameAddress={billingSameAsShipping}
          navigateToNext={navigateToNext}
          dispatch={dispatch}
          changeForm={changeForm}
          streetAddress1={billingSameAsShipping ? address.streetAddress1 : billingAddress.streetAddress1}
          streetAddress2={billingSameAsShipping ? address.streetAddress2 : billingAddress.streetAddress2}
          state={billingSameAsShipping ? address.state : billingAddress.state}
          city={billingSameAsShipping ? address.city : billingAddress.city}
          zipcode={billingSameAsShipping ? address.zipcode : billingAddress.zipcode}
        />
      </Grid>
      <MapContainer item md={6} lg={6}>
        <Map
          hasMarker
          defaultZoom={15}
          coordinates={{ lat: latitude, lng: longitude }}
        />
      </MapContainer>
    </Grid>
  )
}
