import React, { useState, useEffect } from 'react'
import { withTheme } from 'styled-components'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import { useMoment } from '../../../../hooks'
import { fetchSuccess } from '../../../UserInfo/actions'
import Icon from '../../../commons/Icon'
import ICONS from '../../../ui/ICONS'
import { LabelForm } from '../../../ui'
import { useStore } from '../../../../store'
import api from '../../../../services/api'
import { copyToClipboard } from '../../../../helpers'
import {
  Input, TextArea, Grid, UploadPicture, Button,
} from '../../../commons'
import {
  GlobalSubmit,
  EditButton,
  CopyButton,
  Container,
  RegularText,
  Picture,
} from './styles'

const TabProfile = () => {
  const {
    user: { data },
  } = useStore()
  const moment = useMoment()
  const { t } = useTranslation()
  const [isEditing, setIsEditing] = useState(false)
  const {
    name, username, email, birthdate, profile, avatar,
  } = data

  if (isEditing) {
    return <EditProfile user={data} setIsEditing={setIsEditing} />
  }

  const handleCopyToClipboard = (targetId) => {
    copyToClipboard(targetId)
    toast.success(t('Link copied with success'))
  }

  return (
    <Container container>
      <Grid item sm={12} md={8} lg={8}>
        <Grid container>
          <Grid item sm={4} md={4} lg={4}>
            <LabelForm>{t('Name')}</LabelForm>
            <RegularText>{name}</RegularText>
          </Grid>
          <Grid item sm={4} md={4} lg={4}>
            <LabelForm>{t('Username')}</LabelForm>
            <RegularText>{username}</RegularText>
          </Grid>
          <Grid item sm={8} md={8} lg={8}>
            <LabelForm>{t('Profile Link')}</LabelForm>
            <RegularText id="profileLink">
              {`${window.location.origin}/library/${username}`}
            </RegularText>
            <CopyButton onClick={() => handleCopyToClipboard('profileLink')}>
              {t('Copy Link')}
            </CopyButton>
          </Grid>
          <Grid item sm={4} md={4} lg={4}>
            <LabelForm>{t('E-mail Address')}</LabelForm>
            <RegularText>{email}</RegularText>
          </Grid>
          <Grid item sm={8} md={8} lg={8}>
            <LabelForm>{t('Birthdate')}</LabelForm>
            <RegularText>{moment(birthdate).format('MM/DD/YYYY')}</RegularText>
          </Grid>
          <Grid item sm={12} md={12} lg={12}>
            <LabelForm>{t('Profile')}</LabelForm>
            <RegularText>{profile}</RegularText>
          </Grid>
          <Grid item sm={12} md={12} lg={12}>
            <Picture src={avatar}>
              {!avatar && (
                <div>
                  <Icon icon={ICONS.PHOTO} size={80} color="#547384" />
                  {t('upload photo')}
                </div>
              )}
            </Picture>
          </Grid>
        </Grid>
      </Grid>
      <Grid item sm={12} md={4} lg={4}>
        <GlobalSubmit>
          <EditButton onClick={() => setIsEditing(true)}>
            {t('Edit Profile')}
          </EditButton>
        </GlobalSubmit>
      </Grid>
    </Container>
  )
}

const EditProfile = ({ user, setIsEditing }) => {
  useEffect(() => {
    const {
      name, username, email, birthdate, profile, avatar,
    } = user
    handleChangeName(name)
    handleChangeUsername(username)
    handleChangeEmail(email)
    handleChangeBirthdate(birthdate)
    handleChangeAvatar(avatar)
    handleChangeProfile(profile)
  }, [user])
  const { t } = useTranslation()
  const moment = useMoment()
  const [name, handleChangeName] = useState('')
  const [username, handleChangeUsername] = useState('')
  const [email, handleChangeEmail] = useState('')
  const [birthdate, handleChangeBirthdate] = useState('')
  const [avatar, handleChangeAvatar] = useState('')
  const [profile, handleChangeProfile] = useState('')
  const [submittingSettings, setSubmittingSettings] = useState(false)
  const handleCopyToClipboard = (targetId) => {
    copyToClipboard(targetId)
    toast.success(t('Link copied with success'))
  }

  const { dispatch } = useStore()

  const initialStateFieldsSettingsErrors = {
    name: {
      status: false,
      message: '',
    },
    username: {
      status: false,
      message: '',
    },
    email: {
      status: false,
      message: '',
    },
    birthdate: {
      status: false,
      message: '',
    },
    avatar: {
      status: false,
      message: '',
    },
    profile: {
      status: false,
      message: '',
    },
  }

  const [errorsFieldsSettings, setErrorsFieldsSettings] = useState(
    initialStateFieldsSettingsErrors,
  )

  const onSubmitSettings = async () => {
    setSubmittingSettings(true)
    try {
      const response = await api.put('user/profile', {
        name,
        username,
        email,
        birthdate,
        profile,
        avatar,
      })

      if (response) {
        setErrorsFieldsSettings(initialStateFieldsSettingsErrors)
        dispatch(fetchSuccess(response.data.user))
        toast.success(response.data.message)
      }
    } catch (err) {
      const newErrors = JSON.parse(
        JSON.stringify(initialStateFieldsSettingsErrors),
      )

      if (err.response.data.errors) {
        err.response.data.errors.map(
          error => (newErrors[error.field] = {
            status: true,
            message: error.message,
          }),
        )
      }
      setErrorsFieldsSettings(newErrors)

      if (err.response.data.error) {
        toast.error(err.response.data.error)
      }
    } finally {
      setSubmittingSettings(false)
      setIsEditing(false)
    }
  }

  return (
    <>
      <Container container>
        <Grid item sm={6}>
          <Input
            id="name"
            label={t('Name')}
            value={name}
            error={errorsFieldsSettings.name}
            onChange={event => handleChangeName(event.target.value)}
          />
        </Grid>

        <Grid item sm={6}>
          <Input
            id="username"
            label={t('Username')}
            value={username}
            error={errorsFieldsSettings.username}
            onChange={event => handleChangeUsername(event.target.value)}
          />
        </Grid>

        <Grid item sm={6}>
          <LabelForm>{t('Profile Link')}</LabelForm>
          <RegularText id="profileLink">
            {`${window.location.origin}/library/${username}`}
          </RegularText>
          <CopyButton onClick={() => handleCopyToClipboard('profileLink')}>
            {t('Copy Link')}
          </CopyButton>
        </Grid>

        <Grid item sm={6}>
          <Input
            id="email"
            label={t('E-mail Address')}
            type="email"
            value={email}
            error={errorsFieldsSettings.email}
            onChange={event => handleChangeEmail(event.target.value)}
            readOnly
          />
        </Grid>

        <Grid item sm={6}>
          <Input
            id="birthdate"
            label={t('Birthdate')}
            type="date"
            value={moment(birthdate).format('YYYY-MM-DD')}
            error={errorsFieldsSettings.birthdate_iso}
            onChange={event => handleChangeBirthdate(event.target.value)}
          />
        </Grid>

        <Grid item sm={6}>
          <TextArea
            label={t('Profile Description')}
            id="profile"
            value={profile}
            error={errorsFieldsSettings.profile}
            onChange={event => handleChangeProfile(event.target.value)}
          />
        </Grid>

        <Grid item sm={5} md={4} lg={3} xlg={2}>
          <UploadPicture
            id="avatar"
            label={t('Change Profile Image')}
            onChange={image => handleChangeAvatar(image)}
            src={avatar}
            enableCrop
            aspectToCrop={4 / 4}
          />
        </Grid>
      </Container>

      <GlobalSubmit>
        <Button
          variation="ghost"
          onClick={() => setIsEditing(false)}
          isLoading={submittingSettings}
        >
          {t('Cancel')}
        </Button>
        <Button
          variation="success"
          onClick={() => onSubmitSettings()}
          isLoading={submittingSettings}
        >
          {t('Save')}
        </Button>
      </GlobalSubmit>
    </>
  )
}

export default withTheme(TabProfile)
