import React from 'react'
import {
  string, func, bool, any, number,
} from 'prop-types'
import { LabelForm } from '../../ui'
import { StyledSelect, GlobalSelect } from './styles'

const Select = (props) => {
  const {
    label,
    id,
    type,
    onChange,
    pattern,
    readOnly,
    required,
    error,
    options,
    colorLabel,
    value,
    spacing,
    emptyOption,
  } = props

  return (
    <GlobalSelect htmlFor={id} spacing={spacing}>
      {label && <LabelForm color={colorLabel}>{label}</LabelForm>}
      <StyledSelect
        type={type}
        id={id}
        onChange={onChange}
        pattern={pattern}
        readOnly={readOnly}
        required={required}
        value={value}
        error={error}
      >
        {emptyOption && <option value="" />}
        {options
          && options.length
          && options.map(option => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
      </StyledSelect>
    </GlobalSelect>
  )
}

Select.propTypes = {
  id: string.isRequired,
  label: string,
  type: string,
  onChange: func,
  pattern: string,
  readOnly: bool,
  required: bool,
  error: any,
  value: string,
  spacing: number,
  emptyOption: bool,
}

Select.defaultProps = {
  label: '',
  type: 'text',
  onChange: null,
  pattern: null,
  readOnly: false,
  required: false,
  error: { status: false, message: '' },
  value: '',
  spacing: 0,
  emptyOption: false,
}

export default Select
