import React from 'react'
import {
  GlobalMedia, Image, Text, GlobalImage,
} from './styles'

const Media = (props) => {
  const { data } = props

  return (
    <GlobalMedia>
      <GlobalImage src={data && data.pictures ? data.pictures[0] : null}>
        <Image src={data && data.pictures ? data.pictures[0] : null} />
      </GlobalImage>
      {data && data.title && (
        <Text>
          {data.title}
          {' '}
(
          {data.releaseDate}
)
        </Text>
      )}
    </GlobalMedia>
  )
}

export default Media
