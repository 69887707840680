import React, { useEffect, useState } from 'react'

import { getUserAddress } from '../../../../../../services/auth'
import { Grid, Icon, Button } from '../../../../../commons'
import { Title } from '../../../../../ui'
import {
  Header,
  ButtonsGroup,
  Address,
  Addresses,
  NewAddress,
} from '../../styles'
import ICONS from '../../../../../ui/ICONS'
import { TransactionResume } from '../../components'
import { goBack, updateTrade } from '../../../store'
import { selectShippingMethod } from '../../../../../../services/trade'
import { useRouter } from '../../../../../../hooks'

const PENDING_SHIPPING = 'pending-shipping-method'
const SHIPPING_METHOD_REVOKED = 'shipping-method-changed'

const ChooseAddress = ({
  myTrade,
  hisTrade,
  dispatch,
  setShippingProcess,
  tradeId,
}) => {
  const { history } = useRouter()
  const [myMedia] = myTrade.trading
  const [hisMedia] = hisTrade.trading
  const [addresses, setAddresses] = useState([])
  const [addressSelected, setAddressSelected] = useState(undefined)
  const [isLoading, setIsLoading] = useState(false)
  useEffect(() => {
    const fetchAddresses = async () => {
      try {
        const fetchedAddresses = await getUserAddress()
        setAddresses(fetchedAddresses)
        if (fetchedAddresses && fetchedAddresses.length > 0) {
          setAddressSelected(0)
        }
      } catch (err) {
        console.log(err)
      }
    }
    fetchAddresses()
  }, [])
  const handleSelectShipping = async () => {
    try {
      setIsLoading(true)
      const trade = await selectShippingMethod({
        tradeId,
        shippingMethod: 'delivery',
        addressId: addresses[addressSelected]._id,
      })
      await dispatch(updateTrade(trade))
      const myChangedTrade = trade.from.user.id === myTrade.user.id ? trade.from : trade.to
      return { trade: myChangedTrade }
    } catch (err) {
      console.log(err)
    } finally {
      setIsLoading(false)
    }
  }
  const handleClick = async (event) => {
    event.stopPropagation()
    event.preventDefault()
    try {
      const { trade } = await handleSelectShipping()
      if (
        hisTrade.status === PENDING_SHIPPING
        || trade.status === SHIPPING_METHOD_REVOKED
      ) {
        return setShippingProcess('waitingOtherUser')
      }
      return setShippingProcess('shippingDimensions')
    } catch (error) {
      console.log(error)
    }
  }
  const handleBack = (event) => {
    event.stopPropagation()
    dispatch(goBack())
  }
  return (
    <Grid container justify="center">
      <Header item md={12} lg={12}>
        <h1>
          Select a
          {' '}
          <span>Shipping Address</span>
        </h1>
      </Header>
      <Addresses item md={12} lg={12}>
        {addresses.map((address, index) => (
          <Address
            key={address._id}
            isSelected={addressSelected === index}
            onClick={() => setAddressSelected(index)}
          >
            <Title>
              Address
              {' '}
              {index + 1}
            </Title>
            <p>{address.streetAddress1}</p>
          </Address>
        ))}
        <NewAddress onClick={() => history.push('/settings')}>
          <Icon icon={ICONS.ADD} size={20} color="#0BA0B5" />
          <p>Add New Address</p>
        </NewAddress>
      </Addresses>
      <TransactionResume myMedia={myMedia} hisMedia={hisMedia} />
      <ButtonsGroup>
        <Button
          disabled={isLoading}
          onClick={event => handleBack(event)}
          variation="info"
        >
          BACK
        </Button>
        <Button isLoading={isLoading} onClick={handleClick} variation="success">
          NEXT
        </Button>
      </ButtonsGroup>
    </Grid>
  )
}

export default ChooseAddress
