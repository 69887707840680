import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { Grid } from '../../../commons'

export const GridContainer = styled(Grid)``
export const GridItem = styled(Grid)`
  width: 80px;
  text-align: left;
  padding-left: 50px;
`

export const FooterBrands = styled.div`
  display: flex;
  justify-content: center;
  background: linear-gradient(
    to right,
    #1252a2,
    #176e10,
    #fb000b,
    #168fb0,
    #000000,
    #39277e,
    #1252a2,
    #176e10,
    #fb000b,
    #168fb0,
    #000000,
    #39277e
  );
  height: 4px;
`

export const FooterBand = styled.div`
  background-color: #03060D;
  padding: ${props => props.isLg ? `${props.theme.spacing * 6}px 0` : `${props.theme.spacing * 6}px 0 65px`};
  text-align: center;

  @media (min-width: ${props => props.theme.breakpoints.lg}px) {
    text-align: left;
  }
`

export const FooterTitle = styled.h3`
  color: ${props => props.theme.colorPrimaryFeatured};
  font-weight: bold;
  font-size: ${props => props.theme.fontSizes.heading2};
`

export const FooterItem = styled(Link)`
  display: block;
  text-decoration: none;

  color: ${props => props.theme.colorSecondary};

  &:hover {
    text-decoration: underline;
  }
`

export const SubFooter = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1.2rem 0;

  @media (min-width: ${props => props.theme.breakpoints.lg}px) {
    border-bottom: 2px solid #313131;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`

export const SubFooterColumn = styled.div`
  margin-bottom: ${props => props.theme.spacing * 6}px;

  :last-child {
    margin-bottom: 0;
  }

  /* Follow us */
  :first-child {
    order: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 30px 0 -30px 0;
  }

  @media (min-width: ${props => props.theme.breakpoints.lg}px) {
    margin-bottom: 0;

    /* Follow us */
    :first-child {
      margin: 0;
    }

    /* Logo */
    :nth-child(2) {
      order: 0;
    }

    /* Menu horz */
    :last-child {
      order: 1;
    }
  }
`

export const FollowUs = styled.div`
  display: inline-flex;

  button {
    width: 25px;
  }
`

export const MenuHorzItem = styled(Link)`
  position: relative;
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  color: ${props => props.theme.colorSecondary};
  padding: 0 ${props => props.theme.spacing * 2}px;
  height: 30px;

  :hover {
    opacity: 0.7;
  }

  :after {
    display: block;
    content: '';
    width: 2px;
    height: 15px;
    background-color: ${props => props.theme.colorSecondary};
    position: absolute;
    right: -1px;
  }

  :last-child::after {
    display: none;
  }
`
