import styled from 'styled-components'

import IconButton from '../IconButton'
import ICONS from '../../ui/ICONS'
import { SELECT_OPTIONS } from './constants'

export const OutsideContainer = styled.div`
  background-color: ${props => props.backgroundColor};
  padding: ${props => (props.spacing ? `${props.spacing}px 12px` : '12px')};
  z-index: ${props => props.zIndex};
  width: 100%;
  transform: ${props => (props.animate ? 'translateY(-100px)' : 'translateY(0)')};
  transition: all 0.2s;
`

export const Container = styled.div`
  display: flex;
  width: 100%;
  max-width: ${props => (props.maxWidth ? `${props.maxWidth}px` : '100%')};
  margin: ${props => (props.maxWidth ? '0 auto' : '0')};
  background-color: #fff;
  border-radius: 50px;
  border: 4px solid transparent;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.6);

  &:focus-within {
    animation: ${props => (props.hasAnimationOnFocus ? 'pulsing 2s 1s infinite' : 'none')};
  }

  @keyframes pulsing {
    0% {
      border-color: transparent;
    }
    50% {
      border-color: green;
    }
    100% {
      border-color: transparent;
    }
  }
`

export const Select = styled.div`
  background: ${props => SELECT_OPTIONS.find(option => option.key === props.activeFilter).color};
  overflow: hidden;
  border-radius: 50px;
  padding: 4px 12px;

  & > select {
    background: transparent;
    color: #fff;
    cursor: pointer;
  }
`

export const SearchInput = styled.input`
  flex: 1;
  margin: 0 10px;
`

export const Filters = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 4px;

  & > img {
    margin: 4px;
  }

  @media (max-width: 670px) {
    display: none;
  }
`

export const CloseIcon = styled(IconButton).attrs({
  icon: ICONS.CLOSE,
  color: '#999',
})`
  cursor: pointer;
`
