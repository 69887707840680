import styled from 'styled-components'
import { Wrapper } from '../../../ui'
import { getZindex } from '../../../../helpers'

export const GlobalHeader = styled.header`
  z-index: ${getZindex('header')};
`

export const HeaderBand = styled.div`
  z-index: ${getZindex('default')};
  background: ${props => `linear-gradient(135deg, ${props.theme.colorPrimaryDark2} 0%, ${props.theme.colorPrimaryFeatured} 100%)`};
  padding: ${props => props.theme.spacing * 2}px 0;
  box-shadow: 0 -2px 6px 0 rgba(0, 0, 0, 0.8);
`

export const StyledWrapper = styled(Wrapper)`
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  min-height: 50px;

  @media (min-width: ${props => props.theme.breakpoints.lg}px) {
    justify-content: flex-end;
  }
`

export const HeaderColumn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: ${props => props.theme.breakpoints.lg}px) {
    /* Logo */
    :nth-child(2) {
      position: absolute;
      left: ${props => props.theme.spacing * 2}px;
    }

    :last-child {
      margin-left: ${props => props.theme.spacing * 2}px;
    }
  }
`

export const AddMedia = styled.button`
  color: #fff;
  display: inline-flex;
  align-items: center;
  height: 32px;
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-radius: 30px;
  cursor: pointer;
  transition: all 300ms;
  overflow: hidden;
  margin: 0 16px 0 8px;
  padding-right: 8px;

  span {
    color: #fff;
    white-space: nowrap;
    transition: color 300ms, transform 300ms, opacity 300ms;
  }

  svg {
    path {
      transition: fill 300ms;
    }
  }

  :hover {
    background-color: #fff;

    span {
      color: #08a4de;
    }

    svg {
      path {
        fill: #08a4de;
      }
    }
  }
`
