import React from 'react'
import { bool } from 'prop-types'
import { Icon } from '..'
import ICONS from '../../ui/ICONS'
import { GlobalArrow } from './styles'

export const Arrow = (props) => {
  const { next, onClick } = props

  return (
    <GlobalArrow next={next} onClick={onClick}>
      <Icon
        icon={next ? ICONS.ARROW_RIGHT : ICONS.ARROW_LEFT}
        size={50}
        color="#0d2032"
      />
    </GlobalArrow>
  )
}

Arrow.propTypes = {
  next: bool,
}

Arrow.defaultProps = {
  next: false,
}

export default Arrow
