import styled from 'styled-components'

export const GlobalArrow = styled.div`
  display: ${props => (props.onClick ? 'inline-block' : 'none')};
  background-color: #253f58;
  border-radius: ${props => (props.next ? '50% 0 0 50%' : '0 50% 50% 0')};
  box-shadow: ${props => (props.next
    ? '4px 4px 10px rgba(0,0,0,.5)'
    : '-4px 4px 10px rgba(0,0,0,.5)')};
  cursor: pointer;
`
