import React, { useState, useEffect } from 'react'
import { withTheme } from 'styled-components'
import api from '../../../services/api'
import { Title } from '../../ui'
import { MediaCard, Grid } from '..'

const MediaShowcase = (props) => {
  const { theme, title } = props
  const [loadingItems, setLoadingItems] = useState(true)
  const [items, setItems] = useState([])

  useEffect(() => {
    api.get('/media/recents').then((response) => {
      setItems(response.data.library)
      setLoadingItems(false)
    })
  }, [])

  return (
    <div>
      <Title color={theme.colorTertiary}>{title}</Title>

      {loadingItems && <div>Loading...</div>}

      {!loadingItems && items.length > 0 && (
        <Grid container>
          {items.map(item => (
            <Grid key={Math.random()} item xs={6} sm={4} md={3} xlg={2}>
              <MediaCard
                data={{
                  ...item,
                  thumb: item.pictures[0],
                }}
                onClick={() => console.log('modal')}
                showUser
                action="default"
              />
            </Grid>
          ))}
        </Grid>
      )}
    </div>
  )
}

export default withTheme(MediaShowcase)
