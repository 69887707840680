import styled from 'styled-components'

export const CategoryList = styled.ul``

export const Category = styled.li`
  position: relative;
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  color: ${props => props.theme.secondary};
  padding: 0 ${props => props.theme.spacing * 2}px;
  height: 30px;

  :hover {
    opacity: 0.7;
  }

  :after {
    display: block;
    content: '';
    width: 2px;
    height: 15px;
    background-color: ${props => props.theme.secondary};
    position: absolute;
    right: -1px;
  }

  :last-child::after {
    display: none;
  }
`

export const GerneList = styled.ul`
  display: flex;
  align-items: center;
  overflow-x: auto;
  padding-bottom: ${props => props.theme.spacing * 1}px;

  &::-webkit-scrollbar {
    height: 6px;
  }

  &::-webkit-scrollbar-track {
    background-color: #0C1726;
    border-radius: 3px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #09577A;
    border-radius: 3px;
  }
`

export const Genre = styled.li`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 100px;
  height: 60px;
  padding: 0 ${props => props.theme.spacing * 3}px;
  margin-right: ${props => props.theme.spacing * 2}px;
  border: 2px solid ${props => props.theme.colorPrimaryFeatured};
  border-radius: 30px;
`
