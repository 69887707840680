import React from 'react'
import { Switch } from 'react-router-dom'
import {
  SignIn,
  SignUp,
  SignUpConfirmation,
  Settings,
  Library,
  Home,
  HowItWorks,
  Media,
  MediaForm,
  Trade,
  SignUpWizard,
  History,
  RecoverPassword,
  NewPassword,
  Transactions,
  NotFound,
  FAQ,
  UserAgreement,
  PrivacyPolicy,
  CreateNotificationForMedia,
} from './components/routes'
import { DefaultTemplate } from './components/templates/DefaultTemplate'

const Routes = () => (
  <Switch>
    <DefaultTemplate dontUseDefaultSearch exact path="/" component={Home} />
    <DefaultTemplate path="/how-it-works" component={HowItWorks} />
    <DefaultTemplate path="/faq" component={FAQ} />
    <DefaultTemplate path="/user-agreement" component={UserAgreement} />
    <DefaultTemplate path="/privacy-policy" component={PrivacyPolicy} />
    <DefaultTemplate path="/media/:id" component={Media} />
    <DefaultTemplate path="/signin/:verified?" component={SignIn} />
    <DefaultTemplate path="/signup" component={SignUp} />
    <DefaultTemplate
      privateRoute
      firstLoginOnly
      path="/signup-wizard"
      component={SignUpWizard}
    />
    <DefaultTemplate
      path="/signup-confirmation/:email/:notVerified?"
      component={SignUpConfirmation}
    />
    <DefaultTemplate
      path="/recover-password/:email?"
      component={RecoverPassword}
    />
    <DefaultTemplate path="/new-password/:token" component={NewPassword} />
    <DefaultTemplate path="/library/:id" component={Library} />
    <DefaultTemplate path="/transaction" component={Transactions} />
    <DefaultTemplate
      path="/create-notification-for-media"
      component={CreateNotificationForMedia}
    />
    <DefaultTemplate privateRoute path="/settings" component={Settings} />
    <DefaultTemplate privateRoute path="/add-media" component={MediaForm} />
    <DefaultTemplate
      privateRoute
      path="/edit-media/:id"
      component={MediaForm}
    />
    <DefaultTemplate privateRoute path="/trade/:id" component={Trade} />
    <DefaultTemplate privateRoute path="/history" component={History} />
    <DefaultTemplate path="*" component={NotFound} />
  </Switch>
)

export default Routes
