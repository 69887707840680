import React from 'react'
import PropTypes from 'prop-types'
import { fbLogo } from '../../../../assets'
import StyledFacebookButton from './styles'

const navigateTo = () => {
  window.location = `${process.env.REACT_APP_API_URL}/auth/facebook`
}

const FacebookButton = ({ children }) => (
  <StyledFacebookButton onClick={navigateTo}>
    <img src={fbLogo} alt="Facebook" />
    {children}
  </StyledFacebookButton>
)

FacebookButton.propTypes = {
  children: PropTypes.node.isRequired,
}

export default FacebookButton
