import React, { useState } from 'react'
import { withTheme } from 'styled-components'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import api from '../../../../services/api'
import { Input, Button } from '../../../commons'
import { GlobalSubmit, Container } from './styles'
import Grid from '../../../commons/Grid'

const TabPassword = (props) => {
  const { theme } = props
  const { t } = useTranslation()

  const [fieldsPassword, setFieldsPassword] = useState({
    oldPassword: '',
    password: '',
    repeatPassword: '',
  })

  const initialStateFieldsPasswordErrors = {
    oldPassword: {
      status: false,
      message: '',
    },
    password: {
      status: false,
      message: '',
    },
    repeatPassword: {
      status: false,
      message: '',
    },
  }

  const [errorsFieldsPassword, setErrorsFieldsPassword] = useState(
    initialStateFieldsPasswordErrors,
  )

  const [submittingPassword, setSubmittingPassword] = useState(false)

  const onSubmitPassword = async () => {
    if (fieldsPassword.password !== fieldsPassword.repeatPassword) {
      setErrorsFieldsPassword({
        ...initialStateFieldsPasswordErrors,
        repeatPassword: {
          status: true,
          message: t('Password repeat is incorrect'),
        },
      })

      return
    }

    setSubmittingPassword(true)

    const req = Object.keys(fieldsPassword)
      .filter(key => key !== 'repeatPassword')
      .reduce((obj, key) => {
        obj[key] = fieldsPassword[key]
        return obj
      }, {})

    try {
      const response = await api.post('user/change-password', req)

      setErrorsFieldsPassword(initialStateFieldsPasswordErrors)
      toast.success(response.data)
    } catch (err) {
      const newErrors = JSON.parse(
        JSON.stringify(initialStateFieldsPasswordErrors),
      )

      if (err.response.data.errors) {
        err.response.data.errors.map(
          error => (newErrors[error.field] = {
            status: true,
            message: error.message,
          }),
        )
      }
      setErrorsFieldsPassword(newErrors)

      if (err.response.data) {
        toast.error(
          err.response.data.error ? err.response.data.error : err.response.data,
        )
      }
    }

    setSubmittingPassword(false)
  }

  const handleChangeFieldsPassword = (e) => {
    const { id, value } = e.target
    setFieldsPassword((prev) => {
      const returnObj = { ...prev }
      returnObj[id] = value
      return returnObj
    })
  }

  return (
    <Container container>
      <Grid item lg={12} md={12} xlg={12} xs={12}>
        <Input
          id="oldPassword"
          label={t('Old Password')}
          placeholder={t('Old password')}
          type="password"
          value={fieldsPassword.oldPassword}
          error={errorsFieldsPassword.oldPassword}
          onChange={handleChangeFieldsPassword}
          spacing={theme.spacing}
        />
        <Input
          id="password"
          label={t('New Password')}
          type="password"
          placeholder={t('New password')}
          value={fieldsPassword.password}
          error={errorsFieldsPassword.password}
          onChange={handleChangeFieldsPassword}
          spacing={theme.spacing * 2}
        />
        <Input
          id="repeatPassword"
          placeholder={t('Confirm new password')}
          type="password"
          value={fieldsPassword.repeatPassword}
          error={errorsFieldsPassword.repeatPassword}
          onChange={handleChangeFieldsPassword}
          spacing={theme.spacing * 2}
        />
        <GlobalSubmit>
          <Button
            variation="success"
            onClick={() => onSubmitPassword()}
            isLoading={submittingPassword}
          >
            {t('Save')}
          </Button>
        </GlobalSubmit>
      </Grid>
    </Container>
  )
}

export default withTheme(TabPassword)
