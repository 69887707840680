import styled from 'styled-components'
import { avatarDefault } from '../../../assets'

const sizes = [
  {
    key: 'small',
    size: 24,
  },
  { key: 'extrasmall', size: 12 },
  { key: 'notSoSmall', size: 30 },
  { key: 'medium', size: 40 },
  { key: 'notSolarge', size: 54 },
  { key: 'large', size: 96 },
  {
    key: 'largest',
    size: 150,
  },
]

export const GlobalAvatar = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: ${props => `${sizes.find(type => type.key === props.size).size}px`};
  height: ${props => `${sizes.find(type => type.key === props.size).size}px`};
  border-radius: 50%;
  box-sizing: border-box;
  position: relative;
  background-color: ${props => (props.error ? props.theme.colorNegative : props.theme.colorDefault)};
  overflow: hidden;
`

export const GlobalLoader = styled.div`
  position: absolute;
`

export const StyledAvatar = styled.div`
  width: 100%;
  height: 100%;
  filter: blur(${props => (props.isLoadingThumb ? '4px' : '0')});
  border-radius: 50%;
  background-image: url(${props => (props.src ? props.src : avatarDefault)});
  background-position: center;
  background-size: cover;
`
