import React from 'react'
import styled from 'styled-components'
import { bool } from 'prop-types'
import { getZindex } from '../../../../helpers'
import { appBackground } from '../../../../assets'

const AppBackground = styled.div`
  flex: 1 1 auto;
  position: relative;
  overflow-x: hidden;
`

const TopSide = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 300px;
  max-height: 40%;
  width: 100%;
  opacity: 0.1;
  background-image: url(${appBackground});
  & > div {
    height: 100%;
    width: 100%;
    background-image: linear-gradient(transparent, #0d2032 100%);
    position: absolute;
    bottom: 0;
  }
`

const BotSide = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  height: 700px;
  max-height: 100%;
  width: 100%;
  opacity: 0.1;
  background-image: url(${appBackground});
  & > div {
    height: 100%;
    width: 100%;
    background-image: linear-gradient(#0d2032, transparent 100%);
    position: absolute;
    top: 0;
  }
`

const GlobalChildren = styled.div`
  position: relative;
  z-index: ${getZindex('default')};
`

const Background = ({ top, bottom, children }) => (
  <AppBackground id="appBackgroundOverflow">
    {top && (
      <TopSide>
        <div />
      </TopSide>
    )}

    <GlobalChildren>{children}</GlobalChildren>

    {bottom && (
      <BotSide>
        <div />
      </BotSide>
    )}
  </AppBackground>
)

Background.propTypes = {
  top: bool,
  bottom: bool,
}

Background.defaultProps = {
  top: false,
  bottom: false,
}

export default Background
