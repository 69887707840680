import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

import { en, es, br } from './locales'

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  'en-US': en,
  'es-US': es,
  'pt-BR': br,
}

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'en-US',
  })

export default i18n
