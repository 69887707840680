import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const GlobalMediaResult = styled(Link)`
  padding: ${props => props.theme.spacing * 2}px;
  color: ${props => props.theme.colorSecondary};
  border-bottom: 1px solid rgba(255, 255, 255, 0.08);
  display: block;
  text-decoration: none;
  transition: background-color 150ms;

  :last-child {
    border-bottom: 0;
  }

  :hover {
    background-color: #29425d;
  }

  img {
    width: 70px;
    height: 70px;
    margin-left: ${props => props.theme.spacing}px;
    border-radius: 50%;
  }

  span {
    font-size: ${props => props.theme.fontSizes.legend};
  }
`

export const GlobalTitle = styled.h2`
  display: flex;
  align-items: baseline;
  margin-top: ${props => props.theme.spacing * 1}px;

  h2 {
    font-weight: bold;
    margin-right: ${props => props.theme.spacing}px;
  }

  span {
    opacity: 0.6;
  }
`

export const GlobalColumns = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const Column = styled.div`
  :first-child {
    flex-grow: 1;
  }
`

export const GlobalMediaHeader = styled.div`
  margin-left: -${props => props.theme.spacing * 2}px;
`
