import React, { useState } from 'react'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import {
  DefaultPageWrapper,
  DefaultPageTitle,
  DefaultPageBox,
  DefaultPageBoxContent,
} from '../../templates/DefaultPage'
import { DefaultTemplateBackground } from '../../templates/DefaultTemplate'
import { Grid, Button } from '../../commons'
import api from '../../../services/api'
import { useRouter } from '../../../hooks'

const SignUpConfirmation = (props) => {
  const { computedMatch } = props
  const [resending, setResending] = useState(false)
  const { history } = useRouter()
  const { t } = useTranslation()

  const onResendInstructions = async () => {
    setResending(true)

    try {
      await api.post('/auth/email/resend', {
        email: computedMatch.params.email,
      })
      history.push(`/signup-confirmation/${computedMatch.params.email}`)
    } catch (err) {
      toast.dismiss()
      toast.error(t('An error occurred. Try again later.'))
    }

    setResending(false)
  }

  const ToVerify = () => (
    <>
      <DefaultPageTitle>{t('Check your email')}</DefaultPageTitle>

      <DefaultPageBox>
        <DefaultPageBoxContent>
          {t('Please follow the instructions we sent to')}
          {' '}
          <strong>{computedMatch.params.email}</strong>
          {' '}
          {t('to complete the Signup.')}
          <br/>
          <strong>{t('Please check your spam filter')}</strong>

        </DefaultPageBoxContent>
      </DefaultPageBox>
    </>
  )

  const NotVerified = () => (
    <>
      <DefaultPageTitle>{t('User email not verified')}</DefaultPageTitle>

      <DefaultPageBox>
        <DefaultPageBoxContent>
          <p style={{ marginBottom: '16px' }}>
            {t('Hello ')}
            {' '}
            <strong>{computedMatch.params.email}</strong>
            {' '}
            {t('we have already sent you the instructions so that you can verify your email, ')}
            {t('if it has not yet arrived you can click on the button below to resend it.')}
          </p>

          <p style={{ marginBottom: '16px' }}>
            {t('Do you want to resend this instructions?')}
          </p>

          <Button
            variation="success"
            onClick={onResendInstructions}
            isLoading={resending}
          >
            {t('Resend instructions to Signup')}
          </Button>
        </DefaultPageBoxContent>
      </DefaultPageBox>
    </>
  )

  return (
    <DefaultTemplateBackground top bottom>
      <DefaultPageWrapper
        topXs="10px"
        topSm="50px"
        topLg="100px"
        topXlg="160px"
      >
        <Grid container justify="center">
          <Grid item md={6} lg={4}>
            {computedMatch.params.notVerified ? <NotVerified /> : <ToVerify />}
          </Grid>
        </Grid>
      </DefaultPageWrapper>
    </DefaultTemplateBackground>
  )
}

export default SignUpConfirmation
