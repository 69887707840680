import styled from 'styled-components'
import SocialButton from '../styles'

export default styled(SocialButton)`
  width: 100%;
  background: #fff;
  color: #757575;
  &:hover {
    background: linear-gradient(#fff 10%, #ccc);
  }
`
