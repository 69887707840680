import styled from 'styled-components'
import {
  DefaultPageBox,
  DefaultPageBoxContent,
} from '../../templates/DefaultPage'
import { Grid } from '../../commons'

export const Container = styled(DefaultPageBox)`
  margin: 0 auto;
  max-width: 921px;
`

export const Content = styled(DefaultPageBoxContent)`
  padding: 48px;
`

export const Header = styled.div`
  height: 20px;
  background-color: #0b1625;
`

export const Title = styled.h1`
  font-weight: 600;
  font-size: 28px;
  text-align: center;
`

export const SignInSection = styled(Grid)``

export const Form = styled.form`
  & > label {
    margin-top: 24px;
  }
  & > p {
    font-weight: 300;
    font-size: 12px;
    color: #00cbff;
    line-height: 18px;
    margin-top: 4px;
  }
`

export const SocialSignInSection = styled(Grid)`
  background-color: #0b1b2a;
  border-radius: 10px;
  padding: 52px 48px;
  @media (max-width: 400px) {
    padding: 10px;
  }
`

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
`

export const SignInButton = styled.button`
  background: linear-gradient(180deg, #4cd964 0%, #0c9d25 93.75%);
  color: #fff;
  padding: 6px 70px;
  font-size: 14px;
  line-height: 22px;
  border-radius: 6px;
  text-align: center;
  font-weight: 600;
  margin-top: 36px;
  &:not(:disabled) {
    cursor: pointer;
  }
  &:disabled {
    opacity: 0.65;
    background: ${props => props.theme.colorGray2};
  }
`

export const SignUpButton = styled.button`
  background: linear-gradient(180deg, #00cbff 0%, #006e9d 100%);
  color: #fff;
  padding: 6px 32px;
  font-size: 14px;
  line-height: 22px;
  border-radius: 6px;
  text-align: center;
  font-weight: 600;
  &:not(:disabled) {
    cursor: pointer;
  }
  &:disabled {
    opacity: 0.65;
    background: ${props => props.theme.colorGray2};
  }
`

export const Paragraph = styled.p`
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  font-family: inherit;
  margin-bottom: 10px;
  margin-top: 54px;
  & > span {
    font-weight: 600;
  }

  @media (max-width: 400px) {
    margin-bottom: 10px;
    margin-top: 10px;
  }
`

export const Forget = styled.button.attrs({ type: 'button' })`
  color: #08a4de;
  cursor: pointer;
  font-size: ${props => props.theme.fontSizes.legend};

  &:hover {
    text-decoration: underline;
  }
`
