import styled from 'styled-components'

export const GlobalOptions = styled.div`
  display: flex;
  margin-bottom: ${props => props.theme.spacing * 4}px;
  width: 100%;
  overflow-x: auto;
  padding-bottom: ${props => props.theme.spacing * 1}px;

  &::-webkit-scrollbar {
    height: 6px;
  }

  &::-webkit-scrollbar-track {
    background-color: #0c1726;
    border-radius: 3px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #09577a;
    border-radius: 3px;
  }
`

export const Option = styled.div`
  display: inline-flex;
  align-items: center;
  border: 2px solid;
  border-color: ${props => props.theme.colorMediaTypes[props.id][1]};
  background-image: ${props => (props.active === props.id
    ? `linear-gradient(${props.theme.colorMediaTypes[props.id][0]}, ${
      props.theme.colorMediaTypes[props.id][1]
    })`
    : 'transparent')};
  height: 40px;
  border-radius: 5px;
  padding: 0 ${props => props.theme.spacing}px;
  margin: 0 ${props => props.theme.spacing / 2}px;
  cursor: pointer;
  transition: background-color 200ms;
`
