import styled from 'styled-components'

export const GlobalChatBox = styled.div`
  width: 60%;
  margin: 0 auto;
  background-color: #ffebb9;
`

export const GlobalSendMessage = styled.div`
  padding: ${props => props.theme.spacing * 2}px 0;
  background-color: #08a4de;
  display: flex;
  justify-content: center;
`

export const SendMessage = styled.span`
  display: inline-block;
  color: #fff;
  border: 1px solid #fff;
  border-radius: 10px;
  padding: ${props => props.theme.spacing}px;
  margin: 0 ${props => props.theme.spacing}px;
  cursor: pointer;
  transition: background-color 150ms, color 150ms;

  :hover {
    background-color: #fff;
    color: #08a4de;
  }
`

export const GlobalMessages = styled.div`
  height: 400px;
  margin-bottom: ${props => props.theme.spacing * 2}px;
  overflow-y: auto;
  padding: ${props => props.theme.spacing * 2}px
    ${props => props.theme.spacing}px;
`
