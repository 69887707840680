import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import socket from 'socket.io-client'
import { useTranslation } from 'react-i18next'
import { useStore } from '../../../store'
import { notificationsActions } from '../../templates/DefaultTemplate/Header/HiddenContent/Notifications'
import { useRouter } from '../../../hooks'
import { isEmpty } from '../../../helpers'
import api from '../../../services/api'
import { Grid, Icon, Loader, Button } from '../../commons'
import TradeComponent from './TradeComponent'
import TradeTour from './TradeTour'
import {
  DefaultPageBackground,
  DefaultPageWrapper,
  DefaultPageBox,
  DefaultPageBoxHeader,
} from '../../templates/DefaultPage'
import { DefaultTemplateBackground } from '../../templates/DefaultTemplate'
import ICONS from '../../ui/ICONS'
import GiveUpTradeDialog from './GiveUpTradeDialog'
import SubOfferDialog from './SubOfferDialog'
import {
  StyledDefaultPageBoxContent,
  OutlineButton,
  GlobalActions,
  StyledIconButton,
  CustomTitle,
} from './styles'

const { REACT_APP_API_URL } = process.env

export default function Trade({ computedMatch }) {
  const { t } = useTranslation()
  const [trade, setTrade] = useState({})
  const [readyToTrade, setReadyToTrade] = useState(false)
  const [giveUpTrade, setGiveUpTrade] = useState(false)
  const [tourOpened, setOpenTour] = useState(false)
  const [loadingReadyToTrade, setLoadingReadyToTrade] = useState(false)
  const [qtNotifications, setQtNotifications] = useState(0)
  const [userHasSub, setUserHasSub] = useState(false)
  const [showSubOfferModal, setShowSubOfferModal] = useState(false)
  const [mediaCount, setMediaCount] = useState(0)
  const { user } = useStore()
  const { history } = useRouter()
  const { notifications, dispatch } = useStore()

  function checkIfIsDeclined(data) {
    if (data.status === 'declined') {
      toast.dismiss()
      toast.error(t('This trade was declined, please, start another one.'))
      history.push('/')
    }
  }

  function checkIfIsAccepted(data) {
    if (
      data.status === 'accepted'
      || data.status === 'reviewed'
      || data.status === 'reported'
      || data.status === 'completed'
    ) {
      history.push(`/transaction/#id=${data.id}`)
    }
  }

  async function fetchTrade(id) {
    try {
      const res = await api.get(`trade/${id}`)
      const { data } = res
      checkIfIsDeclined(data)
      checkIfIsAccepted(data)
      setTrade(data)
      setReadyToTrade(data.user.status === 'accepted')
    } catch (err) {
      history.push('/')
    }
  }

  const markAllNotificaftionsAsRead = async (id) => {
    try {
      const response = await api.post(`/notification/read/trade/${id}`)
      dispatch(
        notificationsActions.setAllTradeNotificationsAsRead(
          id,
          response.data.count,
        ),
      )
    } catch (error) {
      toast.dismiss()
      toast.error(
        error.response && error.response.data.error
          ? error.response.data.error
          : t('An error occurred. Try again later.'),
      )
    }
  }

  async function fetchSubscription() {
    try {
      const subscription = await api.get('payment/subscription')
      if (subscription.data) {
        setUserHasSub(true)
      }
    } catch (err) {
      console.log(err.message)
    }
  }

  useEffect(() => {
    if (
      !Object.keys(notifications).length
      || qtNotifications === Object.keys(notifications.data).length
    ) return

    markAllNotificaftionsAsRead(computedMatch.params.id)
    setQtNotifications(Object.keys(notifications.data).length)
  }, [notifications.data])

  useEffect(() => {
    const io = socket(REACT_APP_API_URL, {
      transports: ['websocket'],
    })

    io.on('trade', (res) => {
      if (res.id === computedMatch.params.id) {
        checkIfIsDeclined(res)
        checkIfIsAccepted(res)
        setTrade(res)
        setReadyToTrade(res.user.status === 'accepted')
      }
    })

    return () => {
      io.off('trade')
    }
  }, [])

  useEffect(() => {
    fetchTrade(computedMatch.params.id)
  }, [computedMatch])

  useEffect(() => {
    fetchSubscription()
  }, [user])

  const unacceptTrade = async () => {
    try {
      await api.post('trade/unaccept', { tradeId: computedMatch.params.id })
      setReadyToTrade(false)
    } catch (error) {
      toast.dismiss()
      toast.error(
        error.response
          ? error.response.data.error
          : t('An error occurred. Try again later.'),
      )
    }
  }

  const acceptTrade = async () => {
    try {
      await api.post('trade/accept', { tradeId: computedMatch.params.id })
      setReadyToTrade(true)
    } catch (error) {
      toast.dismiss()

      if (error.response.data.errors) {
        toast.error(
          <>
            <div style={{ marginBottom: '8px' }}>
              {t('You have some missing info, please finish setting up your account.')}
            </div>
            <Button
              onClick={() => history.push('/settings')}
              style={{ marginRight: '8px' }}
            >
              {t('Set on your account')}
            </Button>
          </>,
          { autoClose: 12000 },
        )
      } else {
        toast.error(
          error.response && error.response.data.message
            ? error.response.data.message
            : t('An error occurred. Try again later.'),
        )
      }
    }
  }

  const onReadyToTrade = async () => {
    setLoadingReadyToTrade(true)
    if (mediaCount > 2 && !userHasSub) setShowSubOfferModal(true)
    if (readyToTrade) {
      await unacceptTrade()
    } else {
      await acceptTrade()
    }
    setLoadingReadyToTrade(false)
  }

  return (
    <DefaultTemplateBackground bottom>
      <DefaultPageBackground
        src={require('../../../assets/home_wallpapers/GAME/wallpaper-2.jpg')}
        blur={10}
        height="250px"
        backgroundSize="cover"
        backgroundPosition="center center"
        opacity={0.4}
      />

      <DefaultPageWrapper topXs="50px" topMd="100px">
        <CustomTitle>trade hub™</CustomTitle>

        <DefaultPageBox>
          <DefaultPageBoxHeader />

          <StyledDefaultPageBoxContent>
            <StyledIconButton
              type="button"
              onClick={() => setOpenTour(true)}
              className="open-tour"
              icon={ICONS.HELP}
            />

            <TradeTour
              isOpen={tourOpened}
              onRequestClose={() => setOpenTour(false)}
            />
            {isEmpty(trade) ? (
              <h1>{t('Loading...')}</h1>
            ) : (
              <Grid container noMargin>
                <Grid item noMargin sm={6}>
                  <TradeComponent
                    id="leftSide"
                    user={trade.user}
                    tradeId={computedMatch.params.id}
                    vip={userHasSub}
                    readyToTrade={readyToTrade}
                    setMediaCount={setMediaCount}
                    fromUser
                  />
                </Grid>
                <Grid item noMargin sm={6}>
                  <TradeComponent
                    id="rightSide"
                    user={trade.otherUser}
                    tradeId={computedMatch.params.id}
                    readyToTrade={readyToTrade}
                  />
                </Grid>
              </Grid>
            )}

            <GlobalActions>
              <OutlineButton
                danger
                onClick={() => setGiveUpTrade(true)}
                className="cancel-trade"
              >
                {t('Cancel Trade')}
              </OutlineButton>

              <OutlineButton
                filled={!!readyToTrade}
                onClick={onReadyToTrade}
                className="ready-to-trade"
              >
                {loadingReadyToTrade && <Loader size={40} />}
                {!loadingReadyToTrade && (
                  <Icon
                    icon={readyToTrade ? ICONS.YES : ICONS.CIRCLE}
                    size={40}
                  />
                )}
                {t('Ready to Trade')}
              </OutlineButton>
            </GlobalActions>

            {giveUpTrade && (
              <GiveUpTradeDialog
                isOpen={giveUpTrade}
                handleClose={() => setGiveUpTrade(false)}
                tradeId={computedMatch.params.id}
              />
            )}
            {!loadingReadyToTrade && readyToTrade && showSubOfferModal && (
              <SubOfferDialog
                isOpen={showSubOfferModal}
                handleClose={() => setShowSubOfferModal(false)}
              />
            )}
          </StyledDefaultPageBoxContent>
        </DefaultPageBox>
      </DefaultPageWrapper>
    </DefaultTemplateBackground>
  )
}
