import React, { useState } from 'react'
import { withTheme } from 'styled-components'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import api from '../../../services/api'
import { DefaultPageWrapper } from '../../templates/DefaultPage'
import { Input, Button } from '../../commons'
import { useRouter } from '../../../hooks'
import { DefaultTemplateBackground } from '../../templates/DefaultTemplate'
import {
  Container, Content, Header, GlobalAction, Title,
} from './styles'

const NewPassword = (props) => {
  const { computedMatch, theme } = props
  const { t } = useTranslation()
  const { history } = useRouter()
  const [password, setPassword] = useState('')
  const [repeatPassword, setRepeatPassword] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const onSend = async () => {
    setIsLoading(true)

    if (password !== repeatPassword) {
      toast.dismiss()
      toast.error(t('You must retype the password correctly.'))
    } else {
      try {
        await api.post('/auth/password/reset', {
          token: computedMatch.params.token,
          newPassword: password,
        })

        toast.dismiss()
        toast.success(
          t('Password created successfully. Now you can log in with it.'),
        )
        history.push('/signin')
      } catch (error) {
        toast.dismiss()
        toast.error(
          error.response
            ? error.response.data.error
            : t('An error occurred. Try again later.'),
        )
      }
    }

    setIsLoading(false)
  }

  return (
    <DefaultTemplateBackground top bottom>
      <DefaultPageWrapper
        topXs="10px"
        topSm="50px"
        topLg="100px"
        topXlg="160px"
        botXs="10px"
        botSm="50px"
        botLg="100px"
        botXlg="160px"
      >
        <Container>
          <Header />
          <Content>
            <Title>{t('Create your new password')}</Title>

            <Input
              label={t('New password')}
              id="password"
              type="password"
              value={password}
              onChange={e => setPassword(e.target.value)}
              onClear={() => setPassword('')}
              spacing={theme.spacing * 2}
              required
            />

            <Input
              label={t('Repeat the new password')}
              id="repeatPassword"
              type="password"
              value={repeatPassword}
              onChange={e => setRepeatPassword(e.target.value)}
              onClear={() => setRepeatPassword('')}
              spacing={theme.spacing * 2}
              required
            />

            <GlobalAction>
              <Button
                variation="success"
                onClick={onSend}
                isLoading={isLoading}
              >
                {t('Create new password')}
              </Button>
            </GlobalAction>
          </Content>
        </Container>
      </DefaultPageWrapper>
    </DefaultTemplateBackground>
  )
}

export default withTheme(NewPassword)
