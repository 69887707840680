import React from 'react'
import { string } from 'prop-types'

import { useTranslation } from 'react-i18next'
import { useRouter } from '../../../hooks'
import {
  GlobalEmptyResults,
  CloseIcon,
  TextContainer,
  MainText,
  Term,
  Button,
} from './styles'

const EmptyResults = ({ searchTerm }) => {
  const { history } = useRouter()
  const { t } = useTranslation()

  function goToNotifyMePage() {
    history.push('/create-notification-for-media')
  }

  return (
    <GlobalEmptyResults>
      <CloseIcon />
      <TextContainer>
        <MainText>
          {t('Sorry we couldn&apos;t find')}
          {' '}
          <Term>{searchTerm}</Term>
        </MainText>
        <h3>
          {t(
            'Maybe your search was too specific, please try searching with another term',
          )}
        </h3>
        <Button onClick={goToNotifyMePage}>
          {t('Notify me when it becomes available')}
        </Button>
      </TextContainer>
    </GlobalEmptyResults>
  )
}

EmptyResults.propTypes = {
  searchTerm: string,
}

EmptyResults.defaultProps = {
  searchTerm: '',
}

export default EmptyResults
