import React, { useState, useEffect } from 'react'
import { useStore } from '../../../../../store'
import { useRouter } from '../../../../../hooks'
import { Overlay } from '../../../../ui'
import { SearchBar } from '../../../../commons'
import MyAccountNavigation from './MyAccountNavigation'
import Notifications from './Notifications'
import ChangeLanguageNavigation from './ChangeLanguageNavigation'
import * as searchActions from '../../../../commons/SearchBar/actions'
import * as searBarActions from './actions'
import { GlobalSearchBar } from './styles'
import { getZindex } from '../../../../../helpers'

const HiddenContent = () => {
  const [canShowOverlay, setCanShowOverlay] = useState(false)
  const {
    searchResults, hiddenContent, dispatch, user,
  } = useStore()
  const { history } = useRouter()

  function closeContent() {
    dispatch(searchActions.clearSearchResults())
    dispatch(searchActions.closeSearchResults())
    dispatch(searBarActions.hideTheHiddenContent())
  }

  useEffect(() => {
    if (
      (history.location.pathname === '/' && hiddenContent === 'searchBar')
      || !hiddenContent
    ) {
      setCanShowOverlay(false)
    } else {
      setCanShowOverlay(true)
    }
  }, [history.location.pathname, hiddenContent])

  return hiddenContent.length > 0 ? (
    <GlobalSearchBar
      showSearchResults={!!searchResults.searchTerm}
      active={hiddenContent}
    >
      {hiddenContent === 'searchBar' && (
        <SearchBar
          maxWidth={720}
          maxWidthResult={860}
          backgroundColor="#0C3956"
          spacing={16}
          zIndex={getZindex('header')}
          withTransition
          withClickOutside
        />
      )}
      {hiddenContent === 'myAccountNavigation' && (
        <MyAccountNavigation userId={user.data.id} />
      )}
      {hiddenContent === 'notifications' && <Notifications />}

      {hiddenContent === 'changeLanguage' && <ChangeLanguageNavigation />}

      {canShowOverlay && <Overlay onClick={closeContent} />}
    </GlobalSearchBar>
  ) : (
    <div />
  )
}

export default HiddenContent
