import React from 'react'
import { Popover, Badge} from 'evergreen-ui'
import { PopoverContent } from './styles'

const SubscriptionPopover = ({ children }) => (
    <Popover
      content={
        <PopoverContent>
          {children}
        </PopoverContent>
      }
    >
      <Badge
        position="absolute"
        top={0}
        right={0}
        color="teal"
        isSolid
        marginTop={8}
        marginRight={8}
        cursor="pointer"
        borderRadius={100}
        textTransform="normal"
        textAlign="center"
      >
        i
      </Badge>
    </Popover>
)

export default SubscriptionPopover
