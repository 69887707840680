import React from 'react'
import { useTranslation } from 'react-i18next'
import { mediaTypes } from '../../../helpers'
import { GlobalOptions, Option } from './styles'
import { Icon } from '..'
import ICONS from '../../ui/ICONS'
import { bookWhite } from '../../../assets'

const MediaTypeOptions = ({ active, callbackChangeMediaType, style }) => {
  const { t } = useTranslation()

  return (
    <GlobalOptions style={style}>
      {mediaTypes.map(option => (
        <Option
          key={option.value}
          id={option.value}
          active={active}
          onClick={() => callbackChangeMediaType(option.value)}
        >
          {option.value === 'book' && (
            <svg width="40px" height="40px" viewBox="0 0 24 24">
              <image xlinkHref={bookWhite} />
            </svg>
          )}

          {option.value !== 'book' && (
            <Icon icon={ICONS[option.value.toUpperCase()]} size={40} />
          )}
          {t(option.label)}
        </Option>
      ))}
    </GlobalOptions>
  )
}

export default MediaTypeOptions
