import React, { useState } from 'react'
import { Dialog, Button } from 'evergreen-ui'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import api from '../../../../services/api'
import { DialogContent, DialogFooter, DialogHeader } from './styles'

const TradeDialog = (props) => {
  const { isOpen, handleClose, tradeId } = props
  const [loadingGiveUpTrade, setLoadingGiveUpTrade] = useState(false)
  const { t } = useTranslation()

  const onGiveUpToTrade = async () => {
    setLoadingGiveUpTrade(true)

    try {
      await api.post('trade/decline', { tradeId })
    } catch (error) {
      toast.dismiss()
      toast.error(
        error.response
          ? error.response.data.error
          : t('An error occurred. Try again later.'),
      )
    }

    setLoadingGiveUpTrade(false)
    handleClose()
  }

  return (
    <Dialog
      isShown={isOpen}
      onCloseComplete={handleClose}
      hasFooter={false}
      hasHeader={false}
      width={566}
      contentContainerProps={{ padding: 0 }}
      topOffset="30vh"
    >
      <DialogHeader>
        <h1>{t('Cancel trade')}</h1>
      </DialogHeader>
      <DialogContent>
        <p>{t('Do you want to cancel trade?')}</p>
      </DialogContent>
      <DialogFooter>
        <Button
          marginRight={12}
          intent="danger"
          appearance="primary"
          onClick={handleClose}
        >
          {t('Cancel')}
        </Button>
        <Button
          intent="success"
          appearance="primary"
          onClick={onGiveUpToTrade}
          isLoading={loadingGiveUpTrade}
        >
          {t('Confirm')}
        </Button>
      </DialogFooter>
    </Dialog>
  )
}

export default TradeDialog
