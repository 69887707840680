import api from './api'

export const getCards = () =>
  api.get('payment/card').then(res => res.data)

export const getDefaultCard = () =>
  api.get('payment/card/default').then(res => res.data)

export const changeDefaultCard = cardId =>
  api.put(`payment/card/default/${cardId}`).then(res => res.data)

export const deleteCard = cardId =>
  api.delete(`payment/card/${cardId}`).then(res => res.data)

export const createCard = token =>
  api.post('payment/card', token).then(res => res.data)

export const getSubscription = () =>
  api.get('payment/subscription').then(res => res.data)

export const subscribe = () =>
  api.post('payment/subscribe').then(res => res.data)

export const unsubscribe = () =>
  api.post('payment/unsubscribe').then(res => res.data)
