import styled from 'styled-components'
import { Title as UiTitle } from '../../ui'
import {
  DefaultPageWrapper,
  DefaultPageBoxContent,
} from '../../templates/DefaultPage'

export const ContentWrapper = styled(DefaultPageWrapper)`
  @media (max-width: 599px) {
    position: relative;
    top: 80px;
    width: 100vw;
    padding: 0;
    -webkit-scrollbar: none;
    overflow-y: hidden;
  }
`

export const PageBoxContent = styled(DefaultPageBoxContent)`
  @media (max-width: 599px) {
    padding: 25px;
    padding-top: 50px;
    padding-bottom: 80px;
  }
`

export const PageBackground = styled.div`
  width: 100%;
  height: 376px;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  background: url(${props => props.src});
  background-repeat: no-repeat;
  background-position: top center;
  background-position-y: 30%;
  background-size: cover;
  overflow: hidden;

  @media (max-width: 599px) {
    width: 100vw;
    height: 200px;
    background-size: 150% 150%;
    background-position-y: 30%;
  }
`

export const Fade = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 376px;
  background-image: linear-gradient(transparent, rgba(0, 0, 0, 0.8));
  @media (max-width: 599px) {
    width: 100vw;
    height: 200px;
  }
`

export const PageTitle = styled.h1`
  width: 100%;
  position: relative;
  left: 156px;
  top: -10px;

  font-family: Titillium Web;
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 43px;

  color: #ffffff;

  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  @media (max-width: 599px) {
    left: 20px;
    font-size: 1.6rem;
  }
`

export const PageTitleRectangle = styled.div`
  width: 1000px;
  height: 46px;
  background: #0b1625;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
  border-radius: 8px 8px 0 0;
  overflow: hidden;
  margin: 0 auto;

  @media (max-width: 599px) {
    margin-top: 100px;
    width: 100vw;
    height: 46px;
    border-radius: 8px 8px 0 0;
    overflow: hidden;
    margin: 0 auto;
  }
`

export const PageBox = styled.div`
  width: 1000px;
  background: linear-gradient(
    to bottom,
    rgba(13, 32, 50, 1) 0%,
    rgba(13, 32, 50, 1) 50%,
    rgba(13, 32, 50, 0.6) 100%
  );
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
  border-radius: 0 0 10px 10px;
  overflow: hidden;
  margin: 0 auto;

  @media (max-width: 599px) {
    left: 20px;
    font-size: 1.6rem;
    width: 100vw;
    border-radius: 0px;
  }
`

export const Title = styled(UiTitle)`
  margin-bottom: 22px;
`

export const SubTitle = styled.h3`
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  margin-top: 30px;
  margin-bottom: 5px;

  first-child {
    margin-top: 0;
  }

  @media (max-width: 599px) {
    font-size: 0.8rem;
  }
`

export const PageSection = styled.div`
  margin-bottom: ${props => props.theme.spacing * 6}px;

  p {
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
  }
  @media (min-width: ${props => props.theme.breakpoints.md}px) {
    :last-child {
      margin-bottom: 0;
    }
  }

  @media (max-width: 599px) {
    p {
      font-size: 1rem;
    }

    .bellow-player {
      margin-top: 22px;
    }
  }
`
