import styled from "styled-components";
import { Grid } from "../../../commons";

export const Form = styled.form`
  position: relative;
  & > label {
    margin-top: 12px;
  }
  & > button {
    position: absolute;
    bottom: 50%;
    right: 0;
    cursor: pointer;
  }
  & > div {
    margin-top: 12px;
    display: flex;
    & > label:nth-child(2) {
      margin-right: 4px;
      margin-left: 4px;
    }
  }
`

export const RowWithInputs = styled.div`
   margin-top: 12px;
   display: flex;
   & > label:nth-child(2) {
      margin-right: 4px;
      margin-left: 4px;
    }
`

export const MapContainer = styled(Grid)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 42px;
`

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
`

export const NextButton = styled.button`
  background: linear-gradient(180deg, #4CD964 0%, #0C9D25 93.75%);
  color: #FFF;
  padding: 6px 60px;
  font-size: 14px;
  line-height: 22px;
  border-radius: 6px;
  text-align: center;
  font-weight: 600;
  margin-top: 36px;
  cursor: pointer;
`

export const SecondaryAddress = styled.div`
  padding: 32px 0;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  border: 2px solid #003049;
  font-size: 14px;
  line-height: 20px;
  border-radius: 5px;
  cursor: pointer;
  
  & > p {
    margin-left: 6px;
  }
`

export const Label = styled.span`
  font-size: 14px;
  line-height: 22px;
  color: #FFFFFF;
  & > a {
    color: #00CBFF;
    text-decoration: none;
  }
`
