import { createReducer, createAction } from 'redux-starter-kit'

export const FORM_INITIAL_STATE = {
  address: {
    streetAddress1: '',
    streetAddress2: '',
    zipcode: '',
    city: '',
    state: '',
  },
  secondaryAddress: {
    streetAddress1: '',
    streetAddress2: '',
    zipcode: '',
    city: '',
    state: '',
  },
  billingAddress: {
    streetAddress1: '',
    streetAddress2: '',
    zipcode: '',
    city: '',
    state: '',
  },
  billingSameAsShipping: undefined,
  cardToken: undefined,
  subscriptionType: '',
}

export const changeAddress = createAction('CHANGE_ADDRESS')
export const changeBillingAddress = createAction('CHANGE_BILLING_ADDRESS')
export const changeSecondaryAddress = createAction('CHANGE_SECONDARY_ADDRESS')
export const changeBillingAddressSameAsShipping = createAction('CHANGE_BILLING_SAME')
export const changeCardToken = createAction('CHANGE_CARD_TOKEN')
export const changeSubscriptionType = createAction('CHANGE_SUBSCRIPTION_TYPE')

const handleChangeAddress = (state, { payload }) => ({
  ...state,
  address: {
    ...state.address,
    ...payload
  }
})

const handleChangeBillingAddress = (state, { payload }) => ({
  ...state,
  billingAddress: {
    ...state.billingAddress,
    ...payload,
  }
})

const handleChangeSecondaryAddress = (state, { payload }) => ({
  ...state,
  secondaryAddress: {
    ...state.secondaryAddress,
    ...payload,
  }
})

const handleChangeBillingAddressSameAsShipping = (state, { payload }) => ({
  ...state,
  billingSameAsShipping: payload,
})

const handleChangeCardToken = (state, { payload }) => ({
  ...state,
  cardToken: payload,
})

const handleChangeSubscriptionType = (state, { payload }) => ({
  ...state,
  subscriptionType: payload,
})

export const formReducer = createReducer(FORM_INITIAL_STATE,{
  [changeAddress]: handleChangeAddress,
  [changeBillingAddress]: handleChangeBillingAddress,
  [changeSecondaryAddress]: handleChangeSecondaryAddress,
  [changeBillingAddressSameAsShipping]: handleChangeBillingAddressSameAsShipping,
  [changeCardToken]: handleChangeCardToken,
  [changeSubscriptionType]: handleChangeSubscriptionType,
})
