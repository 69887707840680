import styled from 'styled-components'
import { DefaultPageBox, DefaultPageBoxContent } from "../../templates/DefaultPage";
import { Grid } from "../../commons";

export const Container = styled(DefaultPageBox)`
  margin: 0 auto;
  max-width: 921px;
`

export const Content = styled(DefaultPageBoxContent)`
  padding: 48px;
`

export const Header = styled.div`
  height: 20px;
  background-color: #0B1625;
`

export const Title = styled.h1`
  font-weight: 600;
  font-size: 28px;
  text-align: center;
`

export const Subtitle = styled.h2`
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  line-height: 22px;
`

export const SocialSignUp = styled(Grid)`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const Form = styled.form`
    & > label {
    margin-bottom: 12px;
  }
  & > p {
    font-weight: 300;
    font-size: 12px;
    color: #00CBFF;
    line-height: 18px;
    margin-top: 4px;
  }
`

export const SocialSignUpSection = styled.div`
  background-color: #0B1B2A;
  border-radius: 10px;
  padding: 52px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  @media(max-width: 400px) {
  padding: 10px;
  }
`

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
`

export const SignUpButton = styled.button`
  background: linear-gradient(180deg, #4CD964 0%, #0C9D25 93.75%);
  color: #FFF;
  padding: 6px 70px;
  font-size: 14px;
  line-height: 22px;
  border-radius: 6px;
  text-align: center;
  font-weight: 600;
  margin-top: 22px;
  &:not(:disabled) {
    cursor: pointer;
  }
  &:disabled {
    opacity: 0.65;
    background: ${props => props.theme.colorGray2};
  }
`


export const Label = styled.span`
  font-size: 14px;
  line-height: 22px;
  color: #FFFFFF;
  & > button {
    color: #00CBFF;
    text-decoration: none;
    cursor: pointer;
    text-decoration: underline;
  }
`

export const ValidationCheckBox = styled.span`
  color: #EE302A;
  font-size: 14px;
`