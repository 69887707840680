export const defaultTheme = {
  fontFamily: 'Titillium Web',
  spacing: 8,
  breakpoints: {
    sm: 576,
    md: 768,
    lg: 1024,
    xlg: 1280,
  },
  fontSizes: {
    legend: '12px',
    default: '16px',
    default2: '14px',
    heading1: '24px',
    heading2: '20px',
    display1: '36px',
  },
}
