import React, { Suspense, useEffect, useRef, useState } from 'react'
import { Redirect } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.min.css'
import { isAuthenticated, isFirstLogin } from '../../../../services/auth'
import { useRouter } from '../../../../hooks'
import { useStore } from '../../../../store'
import {
  DefaultTemplateHeader,
  DefaultTemplateSlogan,
} from '..'
import { toastConfig } from '../../../../config'
import {
  GlobalTemplate, GlobalContent, GlobalScroll, Content,
} from './styles'
import * as searchActions from '../../../commons/SearchBar/actions'
import ModalIncreaseMyChances from '../../../routes/Home/ModalIncreaseMyChances'
import ModalSearchLocal from '../../../routes/Home/ModalSearchLocal'
import ModalComeToApp from '../../../routes/Home/ModalComeToApp'

const DefaultTemplateFooter = React.lazy(() => import('../Footer'))

const DefaultTemplate = ({ component: Component, ...props }) => {
  const { privateRoute, dontUseDefaultSearch, location: propsLocation } = props
  const wrapperRef = useRef(undefined)
  const { history, location } = useRouter()
  const [show, showChange] = useState(window.innerWidth <= 600)
  const {
    search: { searchTerm, searchLocalOpen, increaseMyChancesOpen },
    dispatch,
  } = useStore()
  useEffect(() => {
    if (wrapperRef && wrapperRef.current && wrapperRef.current.scroll) {
      wrapperRef.current.scroll({
        top: 0,
      })
    }
  }, [location])

  if (
    isAuthenticated()
    && isFirstLogin()
    && history.location.pathname !== '/signup-wizard'
  ) {
    return <Redirect to="/signup-wizard" />
  }

  if (!isFirstLogin() && history.location.pathname === '/signup-wizard') {
    return <Redirect to="/" />
  }

  return privateRoute && !isAuthenticated() ? (
    <Redirect to={{ pathname: '/signin', state: { from: propsLocation } }} />
  ) : (
    <GlobalTemplate>
      <ModalIncreaseMyChances
        open={increaseMyChancesOpen}
        handleClose={() => dispatch(searchActions.closeIncreaseMyChances())}
        searchTerm={searchTerm}
      />

      <ModalSearchLocal
        open={searchLocalOpen}
        handleClose={() => dispatch(searchActions.closeSearchLocal())}
        searchTerm={searchTerm}
      />

      <ModalComeToApp
        open={show}
        handleClose={() => showChange(false)}
        searchTerm={searchTerm}
      />

      <DefaultTemplateHeader dontUseDefaultSearch={dontUseDefaultSearch} />

      <GlobalScroll ref={wrapperRef} id="wrapperGoToTop">
        <GlobalContent>
          <Content>
            <DefaultTemplateSlogan />
            <Component {...props} />
          </Content>
        </GlobalContent>
        <Suspense fallback={<div>Loading...</div>}>
          <DefaultTemplateFooter />
        </Suspense>
      </GlobalScroll>

      <ToastContainer {...toastConfig} />
    </GlobalTemplate>
  )
}

export default DefaultTemplate
