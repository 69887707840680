import styled from 'styled-components'
import { Title as UiTitle } from '../../ui'

export const Title = styled(UiTitle)`
  margin-bottom: 22px;
`

export const SubTitle = styled.h3`
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  margin-top: 30px;
  margin-bottom: 5px;

  first-child {
    margin-top: 0;
  }

  @media (max-width: 599px) {
    font-size: 0.8rem;
  }
`

export const PageSection = styled.div`
  margin-bottom: ${props => props.theme.spacing * 6}px;

  p {
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
  }
  @media (min-width: ${props => props.theme.breakpoints.md}px) {
    :last-child {
      margin-bottom: 0;
    }
  }

  @media (max-width: 599px) {
    p {
      font-size: 1rem;
    }

    .bellow-player {
      margin-top: 22px;
    }
  }
`
