/* eslint-disable consistent-return */
import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { getZindex } from '../../../helpers'
import { Icon } from '..'
import { Overlay } from '../../ui'
import ICONS from '../../ui/ICONS'

const StyledModal = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: ${props => `${props.width}px`};
  width: 100%;
  height: 90vh;
  border-radius: 0.4em;
  font-family: ${props => props.theme.fontFamily};
  color: ${props => props.theme.bgColor};
  padding: ${props => `0 ${props.theme.spacing * 2}px`};
`

const StyledOverlay = styled(Overlay)`
  z-index: ${getZindex('modal')};
`

const GlobalButton = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

const ButtonAbsolute = styled.button.attrs({ type: 'button' })`
  position: absolute;
  display: flex;
  top: ${props => (props.headerHeight ? '8px' : '-2px')};
  right: ${props => (props.headerHeight ? '5px' : '0')};
  z-index: 1;
  cursor: pointer;
`

export const GlobalContent = styled.div`
  max-height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
`

const Modal = (props) => {
  const {
    open, children, handleClose, width, headerHeight,
  } = props
  const contentRef = useRef(undefined)

  const closeModal = (e) => {
    if (e.keyCode === 27) {
      handleClose()
    }
  }

  const handleCloseOutside = (e) => {
    if (contentRef.current && !contentRef.current.contains(e.target)) {
      handleClose()
    }
  }

  useEffect(() => {
    if (!open) return

    document.addEventListener('click', handleCloseOutside)

    return () => document.removeEventListener('click', handleCloseOutside)
  }, [open])

  useEffect(() => {
    if (!open) return
    document.addEventListener('keyup', closeModal)

    return () => {
      document.removeEventListener('keyup', closeModal)
    }
  }, [open])

  if (!open) {
    return null
  }
  return (
    <StyledOverlay>
      <StyledModal width={width} headerHeight={headerHeight}>
        <GlobalButton>
          <ButtonAbsolute
            onClick={() => handleClose()}
            headerHeight={headerHeight}
          >
            <Icon size={45} icon={ICONS.CLOSE} />
          </ButtonAbsolute>
        </GlobalButton>

        <GlobalContent ref={contentRef}>{children}</GlobalContent>
      </StyledModal>
    </StyledOverlay>
  )
}

Modal.propTypes = {
  open: PropTypes.bool,
  width: PropTypes.string,
  children: PropTypes.node,
}

Modal.defaultProps = {
  open: false,
  width: '960',
  children: '',
}

export default Modal
