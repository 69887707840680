import styled from 'styled-components'

export const GlobalMediaCard = styled.div`
  padding: 0 10px;
  display: flex !important;
  justify-content: center;
  position: relative;
`

export const GlobalSlider = styled.div`
  padding: ${props => `${props.theme.spacing}px 0`};

  .slick-track {
    margin-left: 0;
  }
`

export const GlobalArrowSectionLibrary = styled.div`
  position: absolute;
  top: 40%;
  transform: translateY(-40%);
  left: ${props => (props.next ? 'auto' : '-20px')};
  right: ${props => (props.next ? '-20px' : 'auto')};
  z-index: 1;
`

export const GlobalSectionLibrary = styled.div`
  position: relative;
  padding: 0 20px;
  margin: ${props => `0 -${props.theme.spacing * 6}px ${props.theme.spacing * 8}px`};

  :last-child {
    margin-bottom: 0;
  }
`

export const Band = styled.div`
  background-color: ${props => props.color};
  position: absolute;
  left: 0;
  width: 10px;
  height: 100%;
  border-radius: 0 50px 50px 0;
`

export const Title = styled.h2`
  font-size: ${props => props.theme.fontSizes.heading2};
  font-weight: bold;
  margin-bottom: ${props => `${props.theme.spacing}px`};
  margin-left: 10px;
`
