import React from 'react'
import { Dialog, Button } from 'evergreen-ui'
import { useTranslation } from 'react-i18next'
import { DialogContent, DialogFooter, DialogHeader } from './styles'

export const WarningDialog = ({
  isOpen,
  handleClose,
  handleSubmit,
  isLoading,
}) => {
  const { t } = useTranslation()

  return (
    <Dialog
      isShown={isOpen}
      onCloseComplete={handleClose}
      hasFooter={false}
      hasHeader={false}
      width={566}
      contentContainerProps={{ padding: 0 }}
      topOffset="30vh"
    >
      <DialogHeader>
        <h1>{t('Shipping Label')}</h1>
      </DialogHeader>
      <DialogContent>
        <p style={{ marginBottom: '16px' }}>
          {t(
            'Next, we will help you create a shipping label. You will need to print the shipping label and attach it to the package.',
          )}
        </p>

        <p>{t('Would you like to proceed?')}</p>
      </DialogContent>
      <DialogFooter>
        <Button
          marginRight={12}
          intent="danger"
          appearance="primary"
          onClick={handleClose}
        >
          {t('Cancel')}
        </Button>
        <Button
          intent="success"
          appearance="primary"
          onClick={handleSubmit}
          isLoading={isLoading}
        >
          {t('Yes')}
        </Button>
      </DialogFooter>
    </Dialog>
  )
}
