import React from 'react'
import { Helmet } from "react-helmet";

const Meta = (props) => {
  return (
    <>
      <Helmet>
        <meta name="description" content={props.metaDescription} />
        <link rel="alternate" href={window.location.href} hrefLang="x-default" />
        <link rel="alternate" href={window.location.href} hrefLang="pt-br" />
        <link rel="alternate" href={window.location.href} hrefLang="en" />
        <link rel="alternate" href={window.location.href} hrefLang="es" />
      </Helmet>
    </>
  )
}

export default Meta