import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { shape } from 'prop-types'
import { mediaTypes } from '../../../helpers'
import { Icon } from '..'
import ICONS from '../../ui/ICONS'
import { GlobalOptions, Option, ClearButton } from './styles'

const MediasFilter = (props) => {
  const { mediaTypesFromLibrary, callbackChangeFilter, active } = props
  const { t } = useTranslation()
  const [filters, setFilters] = useState([])
  const optionsRef = []

  useEffect(() => {
    if (!Object.keys(mediaTypesFromLibrary).length) return

    const normalizedFilters = Object.keys(mediaTypesFromLibrary).map(type => ({
      label: mediaTypes.find(item => item.value === type).label,
      value: type,
      options: mediaTypesFromLibrary[type],
    }))

    setFilters(normalizedFilters)
  }, [mediaTypesFromLibrary])

  const setRef = (ref) => {
    if (!ref) return

    optionsRef.push(ref)
  }

  const onChangeOption = (mediaType, platform) => {
    callbackChangeFilter(mediaType, platform)

    if (!mediaType) {
      optionsRef.map(option => (option.value = ''))
    }
  }

  return (
    <GlobalOptions>
      {filters.map(filter => (
        <Option
          ref={setRef}
          key={filter.value}
          id={filter.value}
          onChange={e => onChangeOption(filter.value, e.target.value)}
          active={!!active[filter.value]}
        >
          <option value="">{t(filter.label)}</option>

          {filter.options.map(item => (
            <option key={item.id} value={item.id}>
              {item.name}
            </option>
          ))}
        </Option>
      ))}

      {Object.keys(active).length > 0 && (
        <ClearButton onClick={() => onChangeOption()}>
          <Icon icon={ICONS.CLOSE} size={20} />
          {t('Clear filter')}
        </ClearButton>
      )}
    </GlobalOptions>
  )
}

MediasFilter.propTypes = {
  mediaTypesFromLibrary: shape({}),
}

MediasFilter.defaultProps = {
  mediaTypesFromLibrary: {},
}

export default MediasFilter
