import React, { useState, useEffect, useRef } from 'react'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import { DefaultPageWrapper } from '../../templates/DefaultPage'
import { DefaultTemplateBackground } from '../../templates/DefaultTemplate'
import api from '../../../services/api'
import { useDebounce, useOutsideClick, useRouter } from '../../../hooks'

import {
  Container,
  Header,
  Content,
  Form,
  TypeSelectorContainer,
  FilterButton,
  Input,
  AutoCompleteResult,
  ResultContainer,
  PlatformSelect,
  Button,
  ItemWrapper,
  MediaInfo,
  MediaPicture,
  MediaReleaseYear,
  MediaTitle,
  ClearSelected,
  GoBack,
} from './styles'
import { SearchItem, Icon } from '../../commons'
import ICONS from '../../ui/ICONS'
import { useStore } from '../../../store'

const RecoverPassword = () => {
  const { t } = useTranslation()
  const [activeType, setActiveType] = useState('game')
  const [term, setTerm] = useState('')
  const [results, setResults] = useState([])
  const [platformsList, setPlatformsList] = useState([])
  const [selectedItem, setSelectedItem] = useState(undefined)
  const [platform, setPlatform] = useState('')
  const [email, setEmail] = useState('')
  const [submitedForm, setSubmitedForm] = useState(false)
  const debouncedTerm = useDebounce(term, 400)
  const clickOutsideRef = useRef(undefined)
  const { history } = useRouter()
  const { user } = useStore()

  async function fetchDataByTitle(searchTerm = debouncedTerm) {
    try {
      const res = await api.get(`media/query/${searchTerm}?type=${activeType}`)
      setResults(
        res.data.map(item => ({
          thumb: item.pictures[0],
          title: item.title,
          releaseDate: item.releaseDate,
          externalId: item.id,
        })),
      )
    } catch (error) {
      toast.dismiss()
      toast.error(
        error.response
          ? error.response.data.error
          : t('An error occurred. Try again later.'),
      )
    }
  }

  async function fetchPlatforms() {
    try {
      const response = await api.get('media/platforms/all')
      const platformOptions = {}

      Object.keys(response.data).map(
        key => (platformOptions[key] = Object.keys(response.data[key]).map(
          keyOption => ({
            label: response.data[key][keyOption],
            value: keyOption,
          }),
        )),
      )

      setPlatformsList(platformOptions)
    } catch (error) {
      toast.dismiss()
      toast.error(
        error.response
          ? error.response.data.error
          : t('An error occurred. Try again later.'),
      )
    }
  }

  function checkIfCanFetchData() {
    if (debouncedTerm) {
      fetchDataByTitle(debouncedTerm)
    } else {
      setResults([])
    }
  }

  function handleSelect(item) {
    setResults([])
    setSelectedItem(item)
  }

  function handleSubmit(e) {
    e.preventDefault()
    setSubmitedForm(true)
  }

  async function createNotification(e) {
    e.preventDefault()
    try {
      await api.post('reminder', {
        externalId: selectedItem.externalId,
        platform,
        email,
      })
      history.push('/')
      toast.dismiss()
      toast.success(
        t('Great! We will notify you when someone adds this media.'),
      )
    } catch (error) {
      toast.dismiss()
      toast.error(
        error.response
          ? error.response.data.error
          : t('An error occurred. Try again later.'),
      )
    }
  }

  useEffect(() => {
    if (user.data) {
      setEmail(user.data.email || '')
    }
  }, [user.data])

  useOutsideClick(clickOutsideRef, () => {
    setResults([])
    setTerm('')
  })

  useEffect(() => {
    setTerm('')
    setPlatform('')
    setSelectedItem(undefined)
  }, [activeType])

  useEffect(() => {
    fetchPlatforms()
  }, [])

  useEffect(() => {
    checkIfCanFetchData()
  }, [debouncedTerm])

  return (
    <DefaultTemplateBackground top bottom>
      <DefaultPageWrapper
        topXs="10px"
        topSm="50px"
        topLg="100px"
        topXlg="160px"
        botXs="10px"
        botSm="50px"
        botLg="100px"
        botXlg="160px"
      >
        <Container>
          <Header />
          <Content>
            {submitedForm ? (
              <Form onSubmit={createNotification}>
                <GoBack onClick={() => setSubmitedForm(false)}>
                  {t('Go back')}
                </GoBack>
                <Input
                  value={email}
                  type="email"
                  placeholder={t('Type your e-mail to receive notifications')}
                  required
                  onChange={e => setEmail(e.target.value)}
                />
                <Button type="submit">{t('Create notification')}</Button>
              </Form>
            ) : (
              <Form onSubmit={handleSubmit}>
                <TypeSelectorContainer>
                  <FilterButton
                    onClick={() => setActiveType('game')}
                    selected={activeType === 'game'}
                  >
                    {t('GAMES')}
                  </FilterButton>
                  <FilterButton
                    onClick={() => setActiveType('movie')}
                    selected={activeType === 'movie'}
                  >
                    {t('MOVIES')}
                  </FilterButton>
                  <FilterButton
                    onClick={() => setActiveType('music')}
                    selected={activeType === 'music'}
                  >
                    {t('MUSIC')}
                  </FilterButton>
                  <FilterButton
                    onClick={() => setActiveType('book')}
                    selected={activeType === 'book'}
                  >
                    {t('BOOK')}
                  </FilterButton>
                </TypeSelectorContainer>

                {selectedItem ? (
                  <ItemWrapper>
                    <MediaPicture
                      src={selectedItem.thumb}
                      alt={selectedItem.title}
                    />
                    <MediaInfo>
                      <MediaTitle>{selectedItem.title}</MediaTitle>
                      <MediaReleaseYear>
                        {selectedItem.releaseDate}
                      </MediaReleaseYear>
                    </MediaInfo>
                    <ClearSelected>
                      <button
                        type="button"
                        onClick={() => setSelectedItem(undefined)}
                      >
                        <Icon icon={ICONS.DELETE} />
                      </button>
                    </ClearSelected>
                  </ItemWrapper>
                ) : (
                  <>
                    <Input
                      value={term}
                      onChange={e => setTerm(e.target.value)}
                      required
                      onFocus={checkIfCanFetchData}
                      placeholder={t('Type to search...')}
                    />
                    <AutoCompleteResult>
                      <ResultContainer ref={clickOutsideRef}>
                        {results.map(item => (
                          <SearchItem
                            key={item.externalId}
                            data={item}
                            onClick={() => handleSelect(item)}
                          />
                        ))}
                      </ResultContainer>
                    </AutoCompleteResult>
                  </>
                )}

                <PlatformSelect
                  value={platform}
                  onChange={e => setPlatform(e.target.value)}
                  required
                  placeholder={t('Select the platform')}
                >
                  <option value="" disabled>
                    {t('Select a platform')}
                  </option>
                  {platformsList
                    && platformsList[activeType]
                    && platformsList[activeType].length
                    && platformsList[activeType].map(item => (
                      <option key={item.value} value={item.value}>
                        {item.label}
                      </option>
                    ))}
                </PlatformSelect>
                <h1><center>Search what you need and you will be notified when it becomes available!</center></h1>
                <Button>{t('Proceed')}</Button>
              </Form>
            )}
          </Content>
        </Container>
      </DefaultPageWrapper>
    </DefaultTemplateBackground>
  )
}

export default RecoverPassword
