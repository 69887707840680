import styled from 'styled-components'
import { Grid } from '../../../commons'

export const GlobalSubmit = styled.div`
  margin-top: ${props => props.theme.spacing * 2}px;
  display: flex;
  justify-content: flex-end;
`

export const Container = styled(Grid)`
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 578px;
  align-items: flex-start;
`

export const PaymentInfos = styled(Grid)`
  display: flex;
  flex-direction: column;
  & > div:last-child {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

export const Cards = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
`

export const Card = styled.div`
  position: relative;
  padding: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #253F58;
  border-radius: 5px;
  width: 49%;
  margin-bottom: 12px;
  min-width: 220px;
  border: 2px solid ${props => (props.isDefault ? '#4CD964' : '#253F58')};
  & > div:first-child {
    cursor: pointer;
  }
`

export const IconContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`

export const Form = styled.form`
  margin-top: 18px;
  & > label {
    margin-bottom: 12px;
  }
`

export const SubscriptionInfos = styled.div`
  display: ${props => (props.hasSubscription ? 'flex' : 'none')};
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 34px;
  & > div > p:last-child{
    font-size: 12px;
    color: #7AA6B8;
  }
`

export const SubscriptionHeader = styled.div`
  font-size: 12px;
  font-weight: bold;
  & > img {
    margin-right: 4px;
  }
`

export const styles = {
  base: {
    iconColor: '#FFF',
    color: '#08A4DE',
    fontSize: 16,
    fontFamily: 'Titillium Web',
    '::placeholder': {
      color: '#A5A5A5',
    },
  },
}

export const Subscription = styled(Grid)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  flex: 1;

  @media(max-width: 576px) {
    align-items: center;
   }

`

export const SubscriptionCard = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 323px;
  width: 213px;
  border-radius: 10px;
  border: 2px solid ${props => (props.isRenewable ? '#BA4F1A'
    : props.isSelected ? '#4CD964' : '#253F58')};
  font-size: 10px;
  font-weight: 300;
  text-align: center;

  & > div {
    margin-top: 25px;
  }

  & > div > :nth-child(2) {
    font-weight: 600;
  }

  & > h1 {
    font-weight: 600;
    font-size: 16px;
    text-transform: capitalize;
    margin-top: 16px;
  }

  & > p {
    margin-bottom: 30px;
  }

  & > button {
    background: ${props => (props.isCanceled ? 'linear-gradient(180deg, #EE773B 0%, #BA4F1A 100%);'
    : props.isSelected ? 'linear-gradient(180deg, #4CD964 0%, #0C9D25 93.75%);' : 'linear-gradient(180deg, #00CBFF 0%, #006E9D 100%)')};
    border-radius: 4px;
    padding: 4px 63px;
    color: #FFFFFF;
    text-align: center;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    cursor: pointer;
    position: absolute;
    bottom: 0;
    margin-bottom: 20px;

    &:not(:disabled) {
    cursor: pointer;
    }
    &:disabled {
    opacity: 0.65;
    }
  }
`

export const DialogHeader = styled.div`
  background: #0B1625;
  width: 100%;
  height: 38px;
  padding-left: 18px;
  border-radius: 5px 5px 0px 0px;
  & > h1 {
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
    padding-top: 4px;
  }
`

export const DialogContent = styled.div`
  background: #0D2032;
  width: 100%;
  padding: 20px;
  font-weight: 300;
  font-size: 18px;
  line-height: 28px;
`

export const DialogFooter = styled.div`
  background: #0B1625;
  width: 100%;
  height: 55px;
  padding: 14px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
    border-radius: 0 0 5px 5px;
    & > div:first-child {
      margin-right: 12px;
    }
`
