import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const GlobalMenu = styled.div`
  display: flex;
  align-items: center;
`

export const MenuItem = styled(Link)`
  position: relative;
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  color: ${props => props.theme.colorSecondary};
  padding: 0 ${props => props.theme.spacing * 2}px;
  height: 30px;

  :hover {
    opacity: 0.7;
  }

  :after {
    display: block;
    content: '';
    width: 2px;
    height: 15px;
    background-color: ${props => props.theme.colorPrimaryFeatured};
    position: absolute;
    right: -1px;
  }

  :nth-last-child(1)::after,
  :nth-last-child(2)::after {
    display: none;
  }
`

export const MenuItemSignIn = styled(MenuItem)`
  border: 2px solid rgba(255,255,255,.3);
  border-radius: 15px;
  cursor: pointer;
`
