import React, { useEffect, useReducer } from 'react'
import { useTranslation } from 'react-i18next'
import { Redirect } from 'react-router-dom'
import queryString from 'query-string'
import socket from 'socket.io-client'

import { getUserTrades } from '../../../services/trade'
import { DefaultTemplateBackground } from '../../templates/DefaultTemplate'
import { DefaultPageWrapper } from '../../templates/DefaultPage'
import { Wizard } from './Wizard'
import {
  Transaction, Shipping, TrackingOrChat, Review,
} from './entries'
import { useRouter } from '../../../hooks'
import { updateTrade, INITIAL_STATE, reducer } from './store'

const Transactions = () => {
  const { location } = useRouter()
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE)
  const { t } = useTranslation()

  const io = socket(process.env.REACT_APP_API_URL, {
    transports: ['websocket'],
  })
  useEffect(() => {
    const { id } = queryString.parse(location.hash)
    const fetchTrade = async () => {
      const trades = await getUserTrades()
      dispatch(updateTrade(trades[id]))
    }
    fetchTrade()
  }, [location])
  useEffect(() => {
    io.on('trade', (res) => {
      dispatch(updateTrade(res))
    })

    return () => {
      io.off('trade')
    }
  }, [])
  const entries = [
    {
      title: '',
      label: t('transaction'),
      component: <Transaction state={state} dispatch={dispatch} />,
    },
    {
      title: '',
      label: t('shipping'),
      component: <Shipping state={state} dispatch={dispatch} />,
    },
    {
      title: '',
      label: t('chat / tracking'),
      component: <TrackingOrChat state={state} dispatch={dispatch} />,
    },
    {
      title: '',
      label: t('completion'),
      component: <Transaction isFinished state={state} dispatch={dispatch} />,
    },
    {
      title: '',
      label: t('review'),
      component: <Review state={state} dispatch={dispatch} />,
    },
  ]
  if (state.status === 'declined' || (!state.id && !state.isLoading)) {
    return <Redirect to="/" />
  }
  return (
    <DefaultTemplateBackground top bottom>
      <DefaultPageWrapper
        topXs="10px"
        topSm="30px"
        topLg="40px"
        topXlg="50px"
        botXs="10px"
        botSm="30px"
        botLg="40px"
        botXlg="50px"
      >
        <Wizard entries={entries} dispatch={dispatch} state={state} />
      </DefaultPageWrapper>
    </DefaultTemplateBackground>
  )
}

export default Transactions
