import moment from 'moment'
import { useTranslation } from 'react-i18next'
import 'moment/locale/pt-br'
import 'moment/locale/es'

export default function useMoment() {
  const { i18n } = useTranslation()

  switch (i18n.language) {
    case 'es-US':
      moment.locale('es')
      break
    case 'pt-BR':
      moment.locale('pt-br')
      break
    default:
      moment.locale('en')
  }

  return moment
}
