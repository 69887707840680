import styled from 'styled-components'
import { Wrapper } from '../../../ui'
import { getZindex } from '../../../../helpers'

export default styled(Wrapper)`
  position: relative;
  z-index: ${getZindex('default')};
  color: ${props => props.theme.colorSecondary};
  padding-top: 30px;

  @media (min-width: ${props => props.theme.breakpoints.sm}px) {
    padding-top: 50px;
  }

  @media (min-width: ${props => props.theme.breakpoints.lg}px) {
    padding-top: 100px;
  }

  @media (min-width: ${props => props.theme.breakpoints.xlg}px) {
    padding-top: 130px;
  }

  ${props => props.botXs
    && `
    padding-bottom: ${props.botXs}
    `}
   ${props => props.botSm
     && `
    padding-bottom: ${props.botSm}
    `}
    ${props => props.botMd
      && `
    padding-bottom: ${props.botMd}
    `}
    ${props => props.botLg
      && `
    padding-bottom: ${props.botLg}
    `}
    ${props => props.botXlg
      && `
    padding-bottom: ${props.botXlg}
    `}
`
