import styled from 'styled-components'

export const PopoverContent = styled.div`
  background: #84CFE0;
  border-radius: 5px;
  font-size: 10px;
  font-weight: 300;
  padding-bottom: 8px;
  & > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 16px 16px 8px;
    color: white;
    line-height: 12px;
    font-weight: 300;
    
    & > p > span {
      font-weight: 600;
    }
  }
`
