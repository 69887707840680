import styled from 'styled-components'

export const GlobalMessage = styled.div`
  display: flex;
  justify-content: ${props => (props.data.userId === props.myUserId ? 'flex-end' : 'flex-start')};
`

export const MessageText = styled.div`
  color: ${props => (props.data.userId === props.myUserId ? '#FFF' : '#003049')};
  width: 40%;
  border-radius: ${props => (props.data.userId === props.myUserId ? '10px 0 10px 10px' : '0 10px 10px')};
  background-color: ${props => (props.data.userId === props.myUserId ? '#08A4DE' : '#FFF')};
  padding: ${props => `${props.theme.spacing}px ${props.theme.spacing}px`};
  margin-bottom: ${props => props.theme.spacing}px;
  box-shadow: ${props => (props.data.userId === props.myUserId ? 'none' : '0 0 10px rgba(0,0,0,.2)')};
`

export const GlobalTypingMessage = styled.div`
  display: flex;
  justify-content: flex-end;
`

export const TypingMessage = styled.div`
  display: inline-block;
  color: #fff;
  border-radius: 10px 0 10px 10px;
  background-color: #08a4de;
  padding: ${props => `${props.theme.spacing}px ${props.theme.spacing}px`};
  margin-bottom: ${props => props.theme.spacing}px;
`

export const Disk = styled.div`
  width: 8px;
  height: 8px;
  background-color: #fff;
  border-radius: 50%;
  display: inline-flex;
  margin: 0 ${props => props.theme.spacing / 2}px;
`
