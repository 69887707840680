import React from 'react'
import { withTheme } from 'styled-components'

import { useTranslation } from 'react-i18next'
import { settingsBackground } from '../../../assets'
import { DefaultTemplateBackground } from '../../templates/DefaultTemplate'
import {
  DefaultPageWrapper,
  DefaultPageTitle,
  DefaultPageNewBackground,
  DefaultPageFadeBackground,
} from '../../templates/DefaultPage'
import { Tabs, Tab } from '../../commons'
import TabAddresses from './TabAdresses'
import TabPassword from './TabPassword'
import TabProfile from './TabProfile'
import TabPayment from './TabPayment'

const Settings = () => {
  const { t } = useTranslation()
  return (
    <DefaultTemplateBackground bottom>
      <DefaultPageNewBackground src={settingsBackground} />
      <DefaultPageFadeBackground />

      <DefaultPageWrapper>
        <DefaultPageTitle>{t('Account')}</DefaultPageTitle>

        <Tabs>
          <Tab label={t('Profile')}>
            <TabProfile />
          </Tab>

          <Tab label={t('Addresses')}>
            <TabAddresses />
          </Tab>

          <Tab label={t('Password')}>
            <TabPassword />
          </Tab>

          <Tab label={t('Subscription')}>
            <TabPayment />
          </Tab>
        </Tabs>
      </DefaultPageWrapper>
    </DefaultTemplateBackground>
  )
}

export default withTheme(Settings)
