import styled from 'styled-components'

export const GlobalResults = styled.div`
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.4);
  position: absolute;
  top: ${props => `calc(100% - ${props.theme.spacing * 2}px)`};
  width: 100%;
  max-height: 256px;
  overflow-y: auto;
  z-index: 2;
  background-color: #0d2132;
`
