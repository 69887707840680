export default (targetId) => {
  const target = document.getElementById(targetId)
  const el = document.createElement('input')
  el.style.position = 'absolute'
  el.style.top = '-9999px'
  el.value = target.innerText
  document.body.appendChild(el)
  el.select()
  document.execCommand('copy')
  el.remove()
}
