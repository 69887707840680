import React from 'react'
import { useStore } from '../../../../../../store'
import { Icon, Avatar } from '../../../../../commons'
import ICONS from '../../../../../ui/ICONS'
import { searchBarActions } from '../../../Header/HiddenContent'
import { GlobalMyAccount } from './styles'

const MyAccount = ({ user }) => {
  const { hiddenContent, dispatch } = useStore()

  const toggleMyAccountNavigation = () => {
    if (hiddenContent === 'myAccountNavigation') {
      dispatch(searchBarActions.hideTheHiddenContent())
    } else {
      dispatch(searchBarActions.showAHiddenContent('myAccountNavigation'))
    }
  }

  return (
    <GlobalMyAccount
      onClick={() => toggleMyAccountNavigation()}
      active={hiddenContent === 'myAccountNavigation'}
    >
      <Avatar src={user.avatar} size="medium" title="image user" />

      <Icon icon={ICONS.ARROW_DOWN} size={20} />
    </GlobalMyAccount>
  )
}

export default MyAccount
