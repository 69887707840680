import React, { useState, useEffect } from 'react'
import {
  string, func, number, bool,
} from 'prop-types'
import { useTranslation } from 'react-i18next'
import Compress from 'browser-image-compression'
import api from '../../../services/api'
import { LabelForm } from '../../ui'
import ICONS from '../../ui/ICONS'
import { Button, Loader } from '..'
import Icon from '../Icon'
import ModalCrop from './ModalCrop'
import {
  GlobalSelectImage,
  GlobalContent,
  HiddenInput,
  GlobalButton,
  Picture,
  GlobalStates,
  ChangePhoto,
} from './styles'

const UploadPicture = (props) => {
  const {
    label,
    colorLabel,
    id,
    onChange,
    src,
    aspectToCrop,
    enableCrop,
  } = props
  const [thumb, setThumb] = useState()
  const [imageToCrop, setImageToCrop] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(false)
  const [openModalCrop, setOpenModalCrop] = useState(false)
  const { t } = useTranslation()

  useEffect(() => {
    setThumb(src)
  }, [src])

  const removeImage = (e) => {
    if (e) e.preventDefault()
    onChange(null)
    setThumb(null)
  }

  const uploadImage = async (blobImage) => {
    setIsLoading(true)

    const formData = new FormData()
    formData.append('image', blobImage)

    try {
      const response = await api.post('image/single', formData)

      setError(false)
      setThumb(response.data)
      onChange(response.data)
    } catch {
      setError(true)
      removeImage()
    }

    setIsLoading(false)
    setOpenModalCrop(false)
  }

  const readFile = file => new Promise((resolve) => {
    const reader = new FileReader()
    reader.addEventListener('load', () => resolve(reader.result), false)
    reader.readAsDataURL(file)
  })

  const handleChange = async (input) => {
    if (!input.target.files.length) return

    // Compression config
    const options = {
      // As the key specify the maximum size
      // Leave blank for infinity
      maxSizeMB: 0.1,
      // Use webworker for faster compression with
      // the help of threads
      useWebWorker: true,
      maxWidthOrHeight: 1920,
    }
    const compressedFile = await Compress(input.target.files[0], options)
    if (enableCrop) {
      const imageDataUrl = await readFile(compressedFile)
      setImageToCrop(imageDataUrl)
    } else {
      uploadImage(compressedFile)
    }
  }

  useEffect(() => {
    if (!imageToCrop) return

    setOpenModalCrop(true)
  }, [imageToCrop])

  return (
    <>
      <GlobalSelectImage htmlFor={id}>
        {label && <LabelForm color={colorLabel}>{label}</LabelForm>}

        <GlobalContent>
          <Picture src={thumb} error={error} isLoading={isLoading}>
            {!thumb && !isLoading && !error && (
              <div>
                <Icon icon={ICONS.PHOTO} size={80} color="#547384" />
                {t('upload photo')}
              </div>
            )}

            {!thumb && !isLoading && error && (
              <GlobalStates>
                <Icon icon={ICONS.NO} size={40} />
              </GlobalStates>
            )}

            {thumb && !isLoading && !error && (
              <ChangePhoto>
                <Icon icon={ICONS.PHOTO} size={80} />
                {t('change photo')}
              </ChangePhoto>
            )}

            {isLoading && (
              <GlobalStates>
                <Loader size={40} withShadow />
              </GlobalStates>
            )}
          </Picture>

          {thumb && !isLoading && (
            <GlobalButton className="remove-image">
              <Button
                icon={ICONS.DELETE}
                variation="danger"
                onClick={removeImage}
              />
            </GlobalButton>
          )}
        </GlobalContent>

        <HiddenInput
          type="file"
          id={id}
          onChange={input => handleChange(input)}
        />
      </GlobalSelectImage>

      {imageToCrop && (
        <ModalCrop
          open={openModalCrop}
          handleClose={() => setOpenModalCrop(false)}
          src={imageToCrop}
          aspectToCrop={aspectToCrop}
          onCrop={blobImage => uploadImage(blobImage)}
          isLoading={isLoading}
        />
      )}
    </>
  )
}

UploadPicture.propTypes = {
  id: string.isRequired,
  label: string,
  colorLabel: string,
  src: string,
  enableCrop: bool,
  aspectToCrop: number,
  onChange: func,
}

UploadPicture.defaultProps = {
  label: '',
  colorLabel: '',
  src: '',
  aspectToCrop: 4 / 4,
  onChange: null,
  enableCrop: false,
}

export default UploadPicture
