export const initialState = {}

export default (state, action) => {
  switch (action.type) {
    case 'SET_NOTIFICATIONS':
      const unread = Object.keys(action.payload).filter((keyNotification) => {
        const historyTrade = action.payload[keyNotification].notifications
        return Object.keys(historyTrade).filter(key => !historyTrade[key].read)
          .length
      }).length

      return {
        loading: false,
        data: action.payload,
        nextPage: 0,
        unread,
      }
    case 'SET_NOTIFICATION_FROM_SOCKET':
      let data = JSON.parse(JSON.stringify(state.data))

      data = {
        ...(Object.keys(data).filter(key => key === action.payload.trade.id)
          .length && data),
        [action.payload.trade.id]: {
          ...action.payload.trade,
          notifications: {
            [action.payload.id]: {
              ...action.payload,
            },
            ...(data[action.payload.trade.id]
              && data[action.payload.trade.id].notifications),
          },
        },
        ...(!Object.keys(data).filter(key => key === action.payload.trade.id)
          .length && data),
      }

      const newUnread = Object.keys(data).filter((keyNotification) => {
        const eventsTrade = data[keyNotification].notifications
        return Object.keys(eventsTrade).filter(key => !eventsTrade[key].read)
          .length
      }).length

      return {
        ...state,
        data,
        unread: newUnread,
      }
    case 'SET_ALL_NOTIFICATIONS_AS_READ':
      const allNotifications = JSON.parse(JSON.stringify(state.data))

      Object.keys(allNotifications).map(key => Object.keys(allNotifications[key].notifications).map(
        key2 => (allNotifications[key].notifications[key2].read = true),
      ))

      return {
        ...state,
        data: allNotifications,
        unread: 0,
      }
    case 'SET_ALL_TRADE_NOTIFICATIONS_AS_READ':
      const allNotificationsUser = JSON.parse(JSON.stringify(state.data))

      if (allNotificationsUser[action.tradeId]) {
        Object.keys(allNotificationsUser[action.tradeId].notifications).map(
          key => (allNotificationsUser[action.tradeId].notifications[
            key
          ].read = true),
        )
        const notificationsUnread = Object.keys(allNotificationsUser).filter(
          (keyNotification) => {
            const eventsTrade = allNotificationsUser[keyNotification].notifications
            return Object.keys(eventsTrade).filter(
              key => !eventsTrade[key].read,
            ).length
          },
        ).length

        return {
          ...state,
          data: allNotificationsUser,
          unread: notificationsUnread,
        }
      }

      return {
        ...state,
      }
    case 'NOTIFICATIONS_FETCH_ERROR':
      return {
        loading: false,
        data: {},
        nextPage: 0,
        unread: 0,
      }
    default:
      return state
  }
}
