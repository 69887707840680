import React, { useState, useRef, useEffect } from 'react'
import { bool, string } from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useRouter, useTouchable } from '../../../hooks'
import { useStore } from '../../../store'
import {
  User, Icon, TradeDialog, Loader, DeleteMediaDialog,
} from '..'
import ICONS from '../../ui/ICONS'
import MediaImage from './MediaImage'
import MediaInformationHeader from './MediaInformationHeader'
import {
  GlobalUser,
  MediaTitle,
  StyledMediaCard,
  GlobalImage,
  GlobalActions,
  GlobalIcon,
  GlobalItemAction,
  GlobalSelect,
  GlobalIconSelect,
} from './styles'

const MediaCard = (props) => {
  const {
    actions,
    isPreview,
    data,
    showUser,
    showTitle,
    callbackSelect,
    callbackDelete,
    marginAuto,
    selected,
  } = props
  const { user } = useStore()
  const { history } = useRouter()
  const isTouchable = useTouchable()
  const [selecting, setSelecting] = useState(false)
  const [requestModalIsOpen, setRequestModalIsOpen] = useState(false)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [cardIsTooSmall, setCardIsTooSmall] = useState(false)
  const cardRef = useRef()
  const { t } = useTranslation()

  const onClickRequestItem = async (e) => {
    e.stopPropagation()
    if (Object.keys(user.data).length > 0) {
      setRequestModalIsOpen(true)
    } else {
      history.push('/signin')
    }
  }
  const handleResize = () => setCardIsTooSmall(cardRef.current.clientWidth <= 116)
  useEffect(() => {
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])
  useEffect(() => {
    handleResize()
  })
  const onClickLibrary = (e) => {
    e.stopPropagation()
    history.push(`/library/${data.user.id}`)
  }
  const onClickCard = () => {
    if (
      (actions === 'default' || actions === 'editor')
      && !requestModalIsOpen
      && !isDeleteModalOpen
    ) {
      history.push(`/media/${data.id}`)
    }
  }
  const onClickDelete = (e) => {
    e.stopPropagation()
    setIsDeleteModalOpen(true)
  }
  const onClickEdit = (e) => {
    e.stopPropagation()
    history.push(`/edit-media/${data.id}`)
  }
  const onClickSelect = () => {
    if (selecting) return
    setSelecting(true)
    callbackSelect()
      .then(() => {
        setSelecting(false)
      })
      .catch(() => {
        setSelecting(false)
      })
  }

  return (
    <StyledMediaCard
      actions={actions}
      marginAuto={marginAuto}
      onClick={onClickCard}
      selected={selected}
      isTouchable={isTouchable}
      ref={cardRef}
    >


      <MediaInformationHeader platform={data.platform} />

      <GlobalImage actions={actions}>
        <MediaImage
          src={data.pictures && data.pictures[0] ? data.pictures[0] : null}
          condition={data.condition} name={data.title}
        />

        {actions === 'select' && actions !== 'none' && !selected && (
          <GlobalSelect onClick={onClickSelect} selecting={selecting}>
            <GlobalIconSelect>
              {selecting && <Loader size={40} withShadow />}

              {!selecting && <Icon icon={ICONS.CHECK} size={40} />}
            </GlobalIconSelect>

            {!selecting && t('Select Media')}
          </GlobalSelect>
        )}

        {actions === 'editor' && actions !== 'none' && (
          <GlobalActions>
            <GlobalItemAction
              onClick={onClickEdit}
              cardIsTooSmall={cardIsTooSmall}
              variation="edit"
            >
              <GlobalIcon cardIsTooSmall={cardIsTooSmall} variation="edit">
                <Icon icon={ICONS.EDIT} />
              </GlobalIcon>
              {t('Edit media')}
            </GlobalItemAction>

            <GlobalItemAction
              onClick={onClickDelete}
              cardIsTooSmall={cardIsTooSmall}
              variation="delete"
            >
              <GlobalIcon cardIsTooSmall={cardIsTooSmall} variation="delete">
                <Icon icon={ICONS.DELETE} />
              </GlobalIcon>
              {t('Delete media')}
            </GlobalItemAction>
          </GlobalActions>
        )}

        {actions !== 'select' && actions !== 'none' && (
          <GlobalActions>
            {actions === 'default'
              && data.user
              && data.user.id !== user.data.id && (
                <>
                  <GlobalItemAction
                    onClick={onClickRequestItem}
                    cardIsTooSmall={cardIsTooSmall}
                    variation="request"
                  >
                    <GlobalIcon
                      cardIsTooSmall={cardIsTooSmall}
                      variation="request"
                    >
                      <Icon icon={ICONS.SHAKE_HANDS} />
                    </GlobalIcon>
                    {t('Request Item')}
                  </GlobalItemAction>

                  {requestModalIsOpen && (
                    <TradeDialog
                      isOpen={requestModalIsOpen}
                      handleClose={() => setRequestModalIsOpen(false)}
                      mediaId={data.id}
                      userId={data.user.id}
                    />
                  )}
                </>
            )}

            {!history.location.pathname.includes('library') && (
              <GlobalItemAction
                onClick={onClickLibrary}
                cardIsTooSmall={cardIsTooSmall}
                variation="library"
              >
                <GlobalIcon cardIsTooSmall={cardIsTooSmall} variation="library">
                  <Icon icon={ICONS.LIBRARY} />
                </GlobalIcon>
                {t('See Library')}
              </GlobalItemAction>
            )}
          </GlobalActions>
        )}
      </GlobalImage>

      {!isPreview && showUser && data.user && (
        <GlobalUser>
          <User onClick={onClickLibrary} data={data.user} justify />
        </GlobalUser>
      )}

      {showTitle && <MediaTitle>{data.title}</MediaTitle>}

      <DeleteMediaDialog
        isOpen={isDeleteModalOpen}
        mediaToDelete={data.id}
        callbackDelete={() => callbackDelete(data.id)}
        handleClose={() => setIsDeleteModalOpen(false)}
      />
    </StyledMediaCard>
  )
}

MediaCard.propTypes = {
  showUser: bool,
  isPreview: bool,
  showTitle: bool,
  actions: string,
  marginAuto: bool,
  selected: bool,
}

MediaCard.defaultProps = {
  showUser: false,
  isPreview: false,
  showTitle: true,
  actions: '',
  marginAuto: false,
  selected: false,
}

export default React.memo(MediaCard)
