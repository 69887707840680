import React from 'react'
import styled from 'styled-components'
import { shape } from 'prop-types'

const MediaInformationHeader = ({ platform }) => {
  const StyledMediaInformationHeader = styled.div`
    width: 100%;
    height: 30px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    padding: 0 0.75em;
    border-radius: 0px 45px 0px 0px;
    background-color: ${props => props.color};
    z-index: 1;

    & > img {
      height: 50%;
    }
  `

  return (
    <StyledMediaInformationHeader color={platform.color}>
      <img src={platform.image} alt={platform.name} />
    </StyledMediaInformationHeader>
  )
}

MediaInformationHeader.propTypes = {
  platform: shape({}),
}

MediaInformationHeader.defaultProps = {
  platform: {},
}

export default MediaInformationHeader
