import React, { useState, useEffect } from 'react'
import { useMedia } from '../../../../hooks'
import {
  Container, BackgroundOne, BackgroundTwo, Fade,
} from './styles'
import {
  ALL_IMAGES,
  GAMES_IMAGES,
  BOOKS_IMAGES,
  MUSICS_IMAGES,
  MOVIES_IMAGES,
} from './constants'

function getImagesFor(filter) {
  const types = {
    ALL: () => ALL_IMAGES,
    GAME: () => GAMES_IMAGES,
    BOOK: () => BOOKS_IMAGES,
    MUSIC: () => MUSICS_IMAGES,
    MOVIE: () => MOVIES_IMAGES,
  }
  return types[filter]()
}

export default function AnimatedBanner({ activeFilter }) {
  const [images, setImages] = useState(getImagesFor(activeFilter))
  const [count, setCount] = useState(0)
  const isMd = useMedia('(min-width: 768px)')

  useEffect(() => {
    const countInterval = setInterval(() => {
      setCount((prev) => {
        if (prev === images.length - 1) {
          return 0
        }
        return prev + 1
      })
    }, 4000)
    return () => clearInterval(countInterval)
  }, [])

  useEffect(() => {
    setImages(getImagesFor(activeFilter))
  }, [activeFilter])

  function showBanner() {
    const backgrounds = {
      0: () => <BackgroundOne bgImage={images[0]} title='first background wallpaper page home' />,
      1: () => <BackgroundTwo bgImage={images[1]} title='second background wallpaper page home' />,
    }
    return backgrounds[count]()
  }

  return (
    <Container isMd={isMd} >
      {showBanner()}
      <Fade isMd={isMd} />
    </Container>
  )
}
