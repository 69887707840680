import styled from 'styled-components'
import { DefaultPageBoxContent } from '../../templates/DefaultPage'
import { IconButton } from '../../commons'

export const StyledDefaultPageBoxContent = styled(DefaultPageBoxContent)`
  padding: 24px 24px 96px;
`

export const GlobalActions = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 32px;
`

export const OutlineButton = styled.button`
  display: inline-flex;
  align-items: center;
  height: 40px;
  border: ${props => (props.danger ? '1px solid #bd2130' : '1px solid #1e7e34')};
  ${props => (props.fill
    ? `
    background-color: ${props.danger ? '#bd2130' : '#1e7e34'}
  `
    : '')}
  color: #fff;
  padding: 0 20px;
  border-radius: 4px;
  cursor: pointer;
  margin: 0 8px;

  svg {
    margin: 0 4px 0 -10px;

    path {
      fill: ${props => (props.filled ? '#FFF' : '#1e7e34')};
    }
  }

  :hover {
    background-color: ${props => (props.danger ? '#bd2130' : '#1e7e34')};

    svg {
      path {
        fill: #fff;
      }
    }
  }
`

export const StyledIconButton = styled(IconButton)`
  float: right;
`

export const CustomTitle = styled.h1`
  font-size: 72px;
  font-weight: 500;
  background: -webkit-linear-gradient(#0045fd, #00f4ff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
  margin-bottom: 32px;
`
