import styled from 'styled-components'
import { Grid } from '../../../commons'

export const Subscription = styled(Grid)`
  display: flex;
  flex-direction: column;
  align-items: ${props => props.align};
  justify-content: center;
  flex: 1;
  margin-top: 20px;
  
  @media(max-width: 576px) {
    align-items: center;
   }
   
`

export const SubscriptionCard = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 348px;
  width: 213px;
  border-radius: 10px;
  border: ${props => (props.isSelected ? '2px solid #4CD964' : '')};
  font-size: 10px;
  font-weight: 300;
  text-align: center;
  transition: transform 0.2s;
  background: ${props => !props.isPremium ? 'linear-gradient(360deg, #0A1C2E -15.52%, #0879A6 100%);' : 'linear-gradient(180deg, #0BA0BE 25.86%, #006E9D 100%);'};
  transform: ${props => props.isPremium ? 'translateY(-1.5rem) scale(1.03)' : ''};
  
  & > div > div {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
    & > span {
      color: #F9C040;
      font-size: 18px;
      &:nth-child(1){
        font-size: 30px;
      }
      &:nth-child(2){
        font-size: 40px;
      }
      &:nth-child(even){
        color: #fff;
      }
    }
  }
  
  & > h1 {
    font-weight: normal;
    font-size: 22px;
    text-transform: capitalize;
    margin-top: 25px;
    margin-bottom: 16px;
  }
  
  & > img {
    margin-left: 20px;
  }
  
  & > button {
    background: ${props => (props.isSelected ? 'linear-gradient(180deg, #4CD964 0%, #0C9D25 93.75%);' : 'linear-gradient(180deg, #EE773B 0%, #BA4F1A 100%);')};
    border-radius: 4px;
    padding: 4px;
    height: 32px;
    color: #FFFFFF;
    text-align: center;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    cursor: pointer;
    position: absolute;
    bottom: 0;
    margin-bottom: 10px;
    margin-top: 10px;
    width: 180px;
  }
`

export const Description = styled.p`
  font-size: 16px;
  font-weight: 600; 
`

export const Plus = styled.p`
  font-size: 16px;
  font-weight: 600; 
  color: #F9C040;
`

export const CenteredContent = styled.div`
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  & > p > span {
    text-decoration: underline;
    font-weight: 600;
  }
`

export const BestValue = styled.div`
  background: linear-gradient(180deg, #EE773B 0%, #BA4F1A 100%);
  border-radius: 0 0 30px 30px;
  width: 126px;
  height: 22px;
  position: absolute;
  top: 0;
  margin: 0 auto;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  text-align: center;
`
