import styled from 'styled-components'
import Grid from '../Grid'

export const Content = styled(Grid)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-weight: 300;
  font-size: 35px;
  line-height: 54px;
  color: #364A5C;
  text-align: center;
  & > p > span {
    font-weight: 600;
  }
`
