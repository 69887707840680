import React from 'react'
import { useTranslation } from 'react-i18next'

import { Container, IconCard, Text } from './styles'

export default function EmptyCards() {
  const { t } = useTranslation()
  return (
    <Container>
      <IconCard />
      <Text>{t('noCards')}</Text>
    </Container>
  )
}
