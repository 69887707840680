import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import { State, City } from "country-state-city"
import { useTranslation } from 'react-i18next'
import { useStore } from '../../../../store'
import api from '../../../../services/api'
import {
  Button, Icon, Input, Delivery, IconButton, Select
} from '../../../commons'
import {
  Addresses,
  NewAddress,
  Address,
  Form,
  Container,
  IconContainer,
} from './styles'
import { Title } from '../../../ui'
import ICONS from '../../../ui/ICONS'
import { GlobalActionsEdit } from './Address/styles'
import {
  createAddress,
  deleteAddress,
  updateAddress,
} from '../../../../services/auth'

const TabAddresses = () => {
  const { t } = useTranslation()
  const { user } = useStore()
  const [addresses, setAddresses] = useState([])
  const [callFetchAddresses, setCallFetchAddresses] = useState('')
  const [newAddress, setNewAddress] = useState(false)
  const [editAddress, setEditAddress] = useState({})
  const [isEditing, setIsEditing] = useState(false)

  useEffect(() => {
    if (!Object.keys(user.data).length) return

    const fetchAddresses = async () => {
      const { data } = await api.get('user/address')
      setAddresses(data)
    }

    fetchAddresses()
  }, [user, callFetchAddresses])

  const handleDeleteAddress = async (addressId) => {
    try {
      await deleteAddress(addressId)
      setAddresses(addresses.filter(address => address._id === addressId))
    } catch (error) {
      toast.dismiss()
      toast.error(
        error.response && error.response.data.message
          ? error.response.data.message
          : t('An error occurred. Try again later.'),
      )
    }
  }

  const handleClickOnEdit = (addressId) => {
    const [addressToEdit] = addresses.filter(
      address => address._id === addressId,
    )
    setEditAddress({ ...addressToEdit })
    setIsEditing(true)
  }

  if (newAddress || isEditing) {
    return (
      <NewAddressForm
        setCallFetchAddresses={setCallFetchAddresses}
        setNewAddress={setNewAddress}
        isEditing={isEditing}
        setIsEditing={setIsEditing}
        editAddress={editAddress}
      />
    )
  }

  return (
    <Container container>
      <Addresses item xlg={12} md={12} lg={12} xs={12}>
        {addresses.map((address, index) => (
          <Address key={address._id}>
            <Title>
              {t('Address')}
              {' '}
              {index + 1}
            </Title>
            <p>{address.streetAddress1}</p>
            <IconContainer>
              <IconButton
                icon={ICONS.EDIT}
                size={20}
                color="white"
                onClick={() => handleClickOnEdit(address._id)}
              />
              <IconButton
                icon={ICONS.DELETE}
                size={20}
                color="white"
                onClick={() => handleDeleteAddress(address._id)}
              />
            </IconContainer>
          </Address>
        ))}
        <NewAddress onClick={() => setNewAddress(true)}>
          <Icon icon={ICONS.ADD} size={20} color="#0BA0B5" />
          <p>{t('Add New Address')}</p>
        </NewAddress>
      </Addresses>
    </Container>
  )
}

const NewAddressForm = ({
  setNewAddress,
  setCallFetchAddresses,
  isEditing,
  editAddress,
  setIsEditing,
}) => {
  const { t } = useTranslation()
  const [zipcode, handleChangeZipcode] = useState(
    isEditing ? editAddress.zipcode : '',
  )
  const [streetAddress1, handleChangeStreetAddress1] = useState(
    isEditing ? editAddress.streetAddress1 : '',
  )
  const [streetAddress2, handleChangeStreetAddress2] = useState(
    isEditing ? editAddress.streetAddress2 : '',
  )
  const [city, handleChangeCity] = useState(isEditing ? editAddress.city : '')
  const [state, handleChangeState] = useState(
    isEditing ? editAddress.state : '',
  )
  const [markedAs, handleChangeMarkedAs] = useState(
    isEditing
      ? editAddress.markedAs
      : {
        shippingAddress: false,
        billingAddress: false,
      },
  )
  const [isLoading, setIsLoading] = useState(false)
  const { shippingAddress, billingAddress } = markedAs
  const optionsMarkedAs = [
    {
      title: t('Shipping Address'),
      key: 'shippingAddress',
      status: shippingAddress,
    },
    {
      title: t('Billing Address'),
      key: 'billingAddress',
      status: billingAddress,
    },
  ]
  const handleSubmit = async (event) => {
    event.preventDefault()
    try {
      setIsLoading(true)
      if (isEditing) {
        await updateAddress(
          {
            zipcode,
            streetAddress1,
            streetAddress2,
            city,
            state,
            markedAs,
          },
          editAddress._id,
        )
        setCallFetchAddresses(true)
        setNewAddress(false)
        return setIsEditing(false)
      }
      await createAddress({
        zipcode,
        streetAddress1,
        streetAddress2,
        city,
        state,
        markedAs,
      })
      setCallFetchAddresses(true)
      setNewAddress(false)
      setIsEditing(false)
    } catch (error) {
      toast.dismiss()
      toast.error(
        error.response
          && error.response.data.message === 'Address not validated by shippo'
          ? t(
            'Please verify that the address you entered is a valid US address',
          )
          : t('An error occurred. Try again later.'),
      )
    } finally {
      setIsLoading(false)
    }
  }
  const handleCancel = (event) => {
    event.preventDefault()
    setNewAddress(false)
    setIsEditing(false)
  }
  const getStates = () => {
    return State.getStatesOfCountry('US').map((state) => ({ label: state.name, value: state.isoCode, ...state }));
  }
  const getCitys = (stateId) => {
    return City.getCitiesOfState('US', stateId).map((city) => ({ label: city.name, value: city.name, ...city }));
  }
  
  return (
    <Container>
      <Form onSubmit={handleSubmit}>
        <Input
          id="streetAddress1"
          label={t('Address Line 1')}
          value={streetAddress1}
          onChange={event => handleChangeStreetAddress1(event.target.value)}
          placeholder={t('Address Line 1')}
          spacing={4}
          required
        />
        <Input
          id="streetAddress2"
          label={t('Address Line 2')}
          value={streetAddress2}
          onChange={event => handleChangeStreetAddress2(event.target.value)}
          placeholder={t('Apt, St...')}
          spacing={4}
        />
        <Select
          label={t('State')}
          type="text"
          options={getStates()}
          value={state}
          onChange={event => handleChangeState(event.target.value)}
          required
          emptyOption
        />
        <Select
          label={t('City')}
          type="text"
          options={getCitys(state)}
          value={city}
          onChange={event => handleChangeCity(event.target.value)}
          required
          emptyOption
        />
        <Input
          id="zipcode"
          label={t('Zipcode')}
          value={zipcode}
          onChange={event => handleChangeZipcode(event.target.value)}
          placeholder={t('Zipcode')}
          spacing={4}
          required
        />
        <Delivery
          label={t('Mark as')}
          options={optionsMarkedAs}
          callbackChange={markedAs => handleChangeMarkedAs(markedAs)}
        />
        <GlobalActionsEdit>
          <Button
            variation="ghost"
            onClick={handleCancel}
            isLoading={isLoading}
          >
            {t('Cancel')}
          </Button>
          <Button variation="success" isLoading={isLoading} type="submit">
            {t('Save')}
          </Button>
        </GlobalActionsEdit>
      </Form>
    </Container>
  )
}

export default TabAddresses
