import React from 'react'
import { GlobalMessage, MessageText } from './styles'

const Message = ({ data, myUserId }) => (
  <GlobalMessage data={data} myUserId={myUserId}>
    <MessageText data={data} myUserId={myUserId}>
      {data ? data.message : 'Loading'}
    </MessageText>
  </GlobalMessage>
)

export default Message
