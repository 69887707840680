import React from 'react'

import { Container, MediaImage, MediaOther } from './styles'

export default function MediaSelected() {
  return (
    <Container>
      <MediaImage />
      <MediaOther />
    </Container>
  )
}
