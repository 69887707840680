import styled, { keyframes } from 'styled-components'
import { getZindex } from '../../../helpers'

export const GlobalSelect = styled.div`
  display: ${props => (props.selecting ? 'flex' : 'none')};
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  flex-direction: column;
  text-align: center;
  cursor: pointer;
  font-size: 14px;
  width: 100%;
  padding-bottom: 18px;
`

export const GlobalIconSelect = styled.div`
  border-radius: 50%;
  border: 2px solid ${props => (props.isDelete ? 'red' : 'green')};
  box-sizing: content-box;
  margin: 0 auto 4px;
  background: rgba(0, 0, 0, 0.5);
`

export const GlobalUser = styled.div`
  margin: ${props => `${props.theme.spacing}px 0`};
`

export const MediaTitle = styled.div`
  color: ${props => props.theme.colorSecondary};
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
`

export const GlobalImage = styled.div`
  position: relative;

  :after {
    content: '';
    display: block;
    width: 100%;
    height: 56%;
    position: absolute;
    bottom: 0;
    left: 0;
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
    transition: height 300ms;
  }
`

export const GlobalActions = styled.div`
  display: none;
  flex-direction: column;
  position: absolute;
  left: -10px;
  bottom: ${props => `${props.theme.spacing * 2 + 20}px`};
  justify-content: center;
  z-index: ${getZindex('default')};
`

const SHOW_ITEM_ACTION = keyframes`
  from {
    opacity: 0;
    transform: translateY(100%);
  }

  to {
    opacity: 1;
    transform: translateY(0%);
  }
`

const itemVariations = {
  request: {
    bg: 'linear-gradient(#00CBFF,#006E9D)',
    border: '#08A4DE',
  },
  library: {
    bg: 'linear-gradient(#F9C040,#E69814)',
    border: '#F9C040',
  },
  edit: {
    bg: 'linear-gradient(#EE773B,#BA4F1A)',
    border: '#EE773B',
  },
  delete: {
    bg: 'linear-gradient(#FF0606,#9F080D)',
    border: '#FF0606',
  },
}

export const GlobalIcon = styled.div`
  display: ${props => (props.cardIsTooSmall ? 'none' : 'block')};
  width: 30px;
  height: 30px;
  box-sizing: initial;
  border-radius: 50%;
  border: ${props => `2px solid ${itemVariations[props.variation].border}`};
  padding: 2px;
  margin-right: ${props => `${props.theme.spacing}px`};
  background: ${props => itemVariations[props.variation].bg};

  :nth-child(2) {
    margin-left: ${props => `${props.theme.spacing}px`};
  }
`

export const GlobalItemAction = styled.button`
  display: flex;
  align-items: center;
  color: #fff;
  cursor: pointer;
  margin-top: ${props => `${props.theme.spacing}px`};
  opacity: 0;
  animation: ${SHOW_ITEM_ACTION} 160ms ease-out;
  animation-fill-mode: forwards;
  font-size: 14px;

  ${props => props.cardIsTooSmall
    && `
    border-radius: 4px;
    padding: 0 4px;
    background: ${itemVariations[props.variation].bg};
  `};

  :nth-last-child(2) {
    animation-delay: 80ms;
  }

  :hover ${GlobalIcon} {
    background: transparent;
  }
`

const getSelectors = props => (props.isTouchable ? '&, :hover' : ':hover')

export const StyledMediaCard = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: ${props => (props.marginAuto ? 'auto' : 0)};
  cursor: ${props => (props.actions === 'default' ? 'pointer' : 'initial')};
  opacity: ${props => (props.selected ? '.2' : '1')};

  ${props => getSelectors(props)} {
    ${GlobalImage}:after {
      height: 100%;
    }

    ${GlobalActions} {
      display: inline-flex;
      transform: translateY(0%);
      opacity: 1;
    }

    ${GlobalSelect} {
      display: flex;
    }

    span.image-background {
      transform: scale(1.1);
    }
  }
`
