const initialState = {
  activeFilter: 'ALL',
  searchTerm: '',
  loadingResults: false,
  searchResults: [],
  searchResultsHome: [],
  searchResultsOpen: false,
  searchLocalOpen: false,
  increaseMyChancesOpen: false,
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SEARCH_CHANGE_ACTIVE_FILTER':
      return { ...state, activeFilter: action.payload }
    case 'SEARCH_CHANGE_SEARCH_TERM':
      return { ...state, searchTerm: action.payload }
    case 'SEARCH_FETCH_SEARCH_RESULTS':
      return { ...state, loadingResults: true }
    case 'SEARCH_SUCCESS_SEARCH_RESULTS':
      return {
        ...state,
        searchResults: [...action.payload],
        loadingResults: false,
      }
    case 'SEARCH_ERROR_SEARCH_RESULTS':
      return {
        ...state,
        searchResults: [],
        loadingResults: false,
        searchResultsOpen: false,
      }
    case 'SEARCH_CLEAR_SEARCH_RESULTS':
      return {
        ...state,
        searchResults: [],
        searchTerm: '',
      }
    case 'SEARCH_OPEN_SEARCH_RESULTS':
      return { ...state, searchResultsOpen: true }
    case 'SEARCH_CLOSE_SEARCH_RESULTS':
      return {
        ...state,
        searchResultsOpen: false,
      }
    case 'SEARCH_POPULATE_HOME_SEARCH':
      return {
        ...state,
        searchResultsOpen: false,
        searchResultsHome: action.payload,
      }
    case 'SEARCH_CLEAR_POPULATED_HOME_SEARCH':
      return {
        ...state,
        searchResultsHome: [],
      }
    case 'SEARCH_OPEN_SEARCH_LOCAL':
      return {
        ...state,
        searchLocalOpen: true,
      }
    case 'SEARCH_OPEN_INCREASE_MY_CHANCES':
      return {
        ...state,
        increaseMyChancesOpen: true,
      }
    case 'SEARCH_CLOSE_SEARCH_LOCAL':
      return {
        ...state,
        searchLocalOpen: false,
      }
    case 'SEARCH_CLOSE_INCREASE_MY_CHANCES':
      return {
        ...state,
        increaseMyChancesOpen: false,
      }
    default:
      return state
  }
}

export { initialState as searchInitialState, reducer as searchReducer }
