import styled from 'styled-components'
import { CreditCard } from 'styled-icons/boxicons-regular/CreditCard'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 80px;
`

export const IconCard = styled(CreditCard)`
  color: white;
  height: 128px;
`

export const Text = styled.h1`
  font-size: 14px;
`
