import styled from 'styled-components'

export const GlobalDelivery = styled.div`
  margin-bottom: ${props => (props.noMargin ? '0' : props.theme.spacing * 2)}px;
`

export const StyledDelivery = styled.span`
  cursor: ${props => (props.hasCallback ? 'pointer' : 'initial')};
  display: inline-flex;
  align-items: center;
  border: 1px solid
    ${props => (props.status ? props.theme.colorPositive : props.theme.colorNegative)};
  padding: ${props => `${props.theme.spacing / 2}px ${props.theme.spacing}px ${props.theme
    .spacing / 2}px ${props.theme.spacing / 2}px`};
  margin: ${props => `0 ${props.theme.spacing}px ${props.theme.spacing}px 0`};
  border-radius: 40px;
`
