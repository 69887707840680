import styled, { keyframes } from 'styled-components'
import { Wrapper } from '../../../../../ui'
import { getZindex } from '../../../../../../helpers'

const SHOW_GLOBAL_NAVIGATION = keyframes`
  from {
    transform: translateY(-100%);
  }

  to {
    transform: translateY(0);
  }
`

export const StyledWrapper = styled(Wrapper)`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`

export const GlobalNavigation = styled.div`
  animation: ${SHOW_GLOBAL_NAVIGATION} 150ms ease-out;
  z-index: ${getZindex('default')};
  width: 200px;
  margin-right: calc(100px + ${props => props.theme.spacing * 2}px);
`

export const NavigationLink = styled.button`
  display: block;
  width: 100%;
  text-decoration: none;
  padding: ${props => `${props.theme.spacing}px ${props.theme.spacing * 2}px`};
  background-color: #045374;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  color: #fff;
  text-align: left;
  cursor: pointer;

  :hover {
    background-color: #064661;
  }

  last-child {
    border-bottom: 0;
  }
`
