import { createReducer, createAction } from 'redux-starter-kit'

export const INITIAL_STATE = {
  from: { status: undefined },
  to: { status: undefined },
  messages: [],
  events: [],
  status: undefined,
  createdAt: undefined,
  updatedAt: undefined,
  groupToken: undefined,
  id: undefined,
  isLoading: true,
  currentTradeStatus: 0,
}

export const updateTrade = createAction('trade/fetch')
export const updateTradeStatus = createAction('trade/update-status')
export const navigateToNext = createAction('trade/navigate-to-next')
export const goBack = createAction('trade/go-back')

export const handleUpdateTrade = (state, { payload }) => ({
  ...state,
  ...payload,
  isLoading: false,
})

export const handleUpdateTradeStatus = (state, { payload }) => ({
  ...state,
  currentTradeStatus: payload,
})

export const handleNavigateToNext = state => ({
  ...state,
  currentTradeStatus: state.currentTradeStatus + 1,
})

export const handleGoBack = state => ({
  ...state,
  currentTradeStatus: state.currentTradeStatus - 1,
})

export const reducer = createReducer(INITIAL_STATE, {
  [updateTrade]: handleUpdateTrade,
  [updateTradeStatus]: handleUpdateTradeStatus,
  [navigateToNext]: handleNavigateToNext,
  [goBack]: handleGoBack,
})
