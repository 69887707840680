import styled from 'styled-components'
import { DefaultPageBoxHeader } from '../../templates/DefaultPage'

export const MediaTitle = styled.h1`
  font-size: ${props => props.theme.fontSizes.heading1};
  font-weight: bold;
  margin-bottom: ${props => props.theme.spacing}px;
`

export const BoxHeader = styled(DefaultPageBoxHeader)`
  display: flex;
  padding-left: calc(128px + ${props => props.theme.spacing * 4}px);

  @media (min-width: ${props => props.theme.breakpoints.sm}px) {
    justify-content: flex-end;
  }
`

export const Header = styled.header`
  display: flex;
  align-items: ${props => (props.isSm ? 'flex-end' : 'center')};
  flex-direction: ${props => (props.isSm ? 'initial' : 'column')};
  padding: ${props => (props.isSm
    ? `0 ${props.theme.spacing * 2}px 0 ${props.theme.spacing * 6}px`
    : 0)};
`

export const MediaSection = styled.div`
  margin-bottom: ${props => `${props.theme.spacing * 4}px`};
`

export const HeaderColumn = styled.div`
  :first-child {
    margin-right: ${props => (props.isSm ? `${props.theme.spacing * 4}px` : 0)};
    margin-bottom: ${props => props.isSm ? '-88px' : `${props.theme.spacing * 2}px`};
    width: 184px;
  }

  :last-child {
    display: flex;
    align-items: ${props => (props.isSm ? 'flex-end' : 'center')};
    flex-direction: ${props => (props.isSm ? 'initial' : 'column')};
    justify-content: ${props => (props.isSm ? 'initial' : 'center')};
    flex-grow: 1;
    text-align: ${props => (props.isSm ? 'initial' : 'center')};
  }
`

export const HeaderColumnChildren = styled.div`
  margin-bottom: ${props => props.theme.spacing * 2}px;

  @media (min-width: ${props => props.theme.breakpoints.sm}px) {
    :first-child {
      flex-grow: 1;
    }

    :last-child {
      text-align: right;
    }
  }
`
