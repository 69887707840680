import styled from 'styled-components'

export const Container = styled.div`
  margin: 25px 10px 0 20px;
`

export const GlobalArrowSectionLibrary = styled.div`
  position: absolute;
  top: 30%;
  transform: translateY(-30%);
  left: ${props => (props.next ? 'auto' : '-10px')};
  right: ${props => (props.next ? '-10px' : 'auto')};
  z-index: 1;
`

export const GlobalSeeMore = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10px;
`
