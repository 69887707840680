import React, { useState, useEffect } from 'react'
import { DefaultPageWrapper } from '../../templates/DefaultPage'
import { DefaultTemplateBackground } from '../../templates/DefaultTemplate'
import Init from './Init'
import Completed from './Completed'
import { Container, Content, Header } from './styles'

const RecoverPassword = (props) => {
  const { computedMatch } = props
  const [render, setRender] = useState(<div />)

  useEffect(() => {
    setRender(
      computedMatch.params.email ? (
        <Completed {...props} />
      ) : (
        <Init {...props} />
      ),
    )
  }, [computedMatch.params.email])

  return (
    <DefaultTemplateBackground top bottom>
      <DefaultPageWrapper
        topXs="10px"
        topSm="50px"
        topLg="100px"
        topXlg="160px"
        botXs="10px"
        botSm="50px"
        botLg="100px"
        botXlg="160px"
      >
        <Container>
          <Header />
          <Content>{render}</Content>
        </Container>
      </DefaultPageWrapper>
    </DefaultTemplateBackground>
  )
}

export default RecoverPassword
