import React from 'react'
import { useTranslation } from 'react-i18next'
import { SearchItem, EmptyResults } from '../..'
import { useStore } from '../../../../store'
import { useRouter } from '../../../../hooks'
import * as searchActions from '../actions'

import {
  Container,
  ContainerAbsolute,
  ItemWrapper,
  ButtonWrapper,
  Button,
} from './styles'

export default function SearchResult({
  spacing,
  maxWidthResult,
  maxHeightResult,
  searchCallback,
}) {
  const {
    search: { activeFilter, searchResults, searchTerm },
    dispatch,
  } = useStore()
  const { history } = useRouter()
  const { t } = useTranslation()

  function navigateToItemPage(id) {
    history.push(`/media/${id}`)
    dispatch(searchActions.clearSearchResults())
  }

  return (
    <Container spacing={spacing}>
      {searchResults.length > 0 ? (
        <ContainerAbsolute maxWidthResult={maxWidthResult}>
          <ItemWrapper maxHeight={maxHeightResult}>
            {searchResults.map(item => (
              <SearchItem
                key={item.id}
                data={item}
                onClick={() => navigateToItemPage(item.id)}
              />
            ))}
          </ItemWrapper>
          <ButtonWrapper>
            <Button activeFilter={activeFilter} onClick={searchCallback}>
              {t('Search')}
            </Button>
            <Button
              activeFilter={activeFilter}
              onClick={() => dispatch(searchActions.openSearchLocal())}
            >
              {t('Search Local')}
            </Button>
            <Button
              type="button"
              onClick={() => dispatch(searchActions.openIncreaseMyChances())}
            >
              {t('Increase my Chances')}
            </Button>
          </ButtonWrapper>
        </ContainerAbsolute>
      ) : (
        <ContainerAbsolute maxWidthResult={maxWidthResult}>
          <EmptyResults searchTerm={searchTerm} />
        </ContainerAbsolute>
      )}
    </Container>
  )
}
