import styled from 'styled-components'

const SIZE_AVATAR = 26

export const GlobalMyAccount = styled.div`
  display: flex;
  align-items: center;
  border: 2px solid rgba(255,255,255,.3);
  border-radius: ${SIZE_AVATAR}px;
  padding: 2px 0 2px 2px;
  cursor: pointer;

  svg {
    transform: ${props => (props.active ? 'rotate(-180deg)' : 'rotate(0deg)')};
    transition: transform 300ms ease-out;
  }
`
