// https://github.com/fkhadra/react-toastify#api
import React from 'react'
import { Icon } from '../components/commons'
import ICONS from '../components/ui/ICONS'

export default {
  position: 'bottom-right',
  autoClose: 5000,
  closeButton: <Icon icon={ICONS.CLOSE} />,
  hideProgressBar: false,
  pauseOnHover: false,
  pauseOnFocusLoss: true,
  rtl: false,
  closeOnClick: true,
  newestOnTop: false,
  pauseOnVisibilityChange: true,
  draggable: true,
}
