import React, { useState } from 'react'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import { useStore } from '../../../../../../store'
import { useMedia } from '../../../../../../hooks'
import { notificationsActions } from '.'
import * as searBarActions from '../actions'
import api from '../../../../../../services/api'
import {
  StyledWrapper,
  GlobalNavigation,
  NotificationHeader,
  NotificationFooter,
  GlobalNotifications,
  LimitNavigation,
} from './styles'
import NotificationItem from './NotificationItem'

const Notifications = () => {
  const { notifications, dispatch } = useStore()
  const [openHistory, setOpenHistory] = useState('')
  const isSm = useMedia('(min-width: 576px)')
  const { t } = useTranslation()
  const filtered = Object.values(notifications.data).filter(notification => notification.status != 'completed')

  const onMarkAllAsRead = async () => {
    try {
      await api.post('/notification/read-all')
      dispatch(notificationsActions.setAllNotificationsAsRead())
      dispatch(searBarActions.hideTheHiddenContent())
    } catch (error) {
      toast.dismiss()
      toast.error(
        error.response && error.response.data.error
          ? error.response.data.error
          : 'An error occurred. Try again later.',
      )
    }
  }

  const scrollToTopNotification = (notificationId) => {
    const elmGlobalNotifications = document.getElementById('GlobalNotifications')

    setTimeout(() => {
      const elmNotification = document.getElementById(notificationId)
      elmGlobalNotifications.scrollTop = elmNotification.offsetTop - 36
    }, 200)
  }

  const onOpenHistory = (notificationId) => {
    setOpenHistory(notificationId)

    if (notificationId) {
      scrollToTopNotification(notificationId)
    }
  }

  return (
    <StyledWrapper isSm={isSm}>
      <LimitNavigation>
        <GlobalNavigation isSm={isSm}>
          {notifications.data && Object.keys(notifications.data).length > 0 && (
            <>
              <NotificationHeader>
                <span>{t('NOTIFICATIONS')}</span>

                {notifications.unread > 0 && (
                  <button type="button" onClick={onMarkAllAsRead}>
                    {t('MARK ALL AS READ')}
                  </button>
                )}
              </NotificationHeader>

              <GlobalNotifications id="GlobalNotifications">
                {filtered
                  .map(key => (
                    <NotificationItem
                      id={key.id}
                      key={key.id}
                      data={key}
                      openHistory={openHistory === key.id}
                      callbackOpenHistory={onOpenHistory}
                    />
                  ))}
              </GlobalNotifications>
            </>
          )}

          <NotificationFooter />
        </GlobalNavigation>
      </LimitNavigation>
    </StyledWrapper>
  )
}

export default Notifications
