import React from 'react'

import { Grid, Button } from '../../../../../commons'
import {
  Column,
  ShippingStatus,
  ButtonsGroup,
} from '../../styles'
import { TransactionResume } from '../../components'

const WaitingMyShipping = ({
  myTrade,
  hisTrade,
  setShippingProcess,
  handleSelectPickUp,
  shippingMethod,
  shippingIcon,
  handleCancelTrade,
  isLoading,
  isCanceling,
}) => {
  const [myMedia] = myTrade.trading
  const [hisMedia] = hisTrade.trading
  const handleProceed = async event => {
    event.preventDefault()
    event.stopPropagation()
    if (shippingMethod === 'pickup') {
      return await handleSelectPickUp()
    }
    return setShippingProcess('chooseAddress')
  }
  const label = hisTrade.shipping.method === 'pickup' ? 'Ship it'
    : 'Pickup'
  const buttonLabel = hisTrade.shipping.method === 'pickup' ? 'CHANGE TO SHIP IT'
    : 'CHANGE TO PICKUP'
  const handleChangeMethod = async event => {
    event.preventDefault()
    if (shippingMethod === 'delivery') {
      await handleSelectPickUp()
      return setShippingProcess('waitingOtherUser')
    }
    return setShippingProcess('chooseAddress')
  }
  return (
    <Grid container justify="center">
      <Column item md={12} lg={12}>
        <img src={shippingIcon} alt="pickup" />
      </Column>
      <ShippingStatus item md={12} lg={12}>
        <h1>
          {hisTrade.user.displayName}
          {' '}
          requested
          <span>
            {' '}
            {hisTrade.shipping.method}
          </span>
          , do you want to
          <span>
            {' '}
            Proceed, Cancel or Change to
            {' '}
            {label}
            ?
          </span>
        </h1>
      </ShippingStatus>
      <TransactionResume myMedia={myMedia} hisMedia={hisMedia} />
      <ButtonsGroup>
        <Button variation="change" onClick={handleChangeMethod}>
          {buttonLabel}
        </Button>
        <Button
          onClick={handleCancelTrade}
          disabled={isLoading}
          variation="danger"
          isLoading={isCanceling}
        >
          CANCEL
        </Button>
        <Button
          onClick={handleProceed}
          isLoading={isLoading}
          variation="success"
        >
          PROCEED
        </Button>
      </ButtonsGroup>
    </Grid>
  )
}

export default WaitingMyShipping
