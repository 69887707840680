import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  height: 72px;
  max-height: 72px;
  padding: 12px;
  border-radius: 4px;
  background-color: #112338;
`

export const MediaImage = styled.div`
  width: 46px;
  height: 46px;
  border-radius: 4px;
  margin-right: 12px;
  background-color: #0c131d;
`

export const MediaOther = styled.div`
  flex: 1;
  border-radius: 4px;
  background-color: #0c131d;
`
